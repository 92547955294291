import * as React from 'react';
import {  NodeRequest } from '../../../Services/ProductGroups/Types/ProductGroups.model';
import { findInTree } from 'react-finder/lib/utils';
import CDIFinder from "../../React/Finder/Finder";
import CDIFinderResults from "../../React/Finder/FinderResults";
import MasterContext from '../Misc/MasterContext';

export interface IProps {
}
export interface IState {

    groupName: string,
    groupDescription: string,
    isGroupTypeExpanded: boolean,
    groupTypeId: number,
    displayRuleInProcebook: boolean,
    dimensionGroup: boolean,
    validationErrors: any[],

    

    isDimensionExpanded: boolean,
    dimensions: any[],
    dimension: number,

    finderSource: any[],
    selectedKey: any,
    selectedData: any,

    finderSelectedPaths: number[],
    finderSpinners: boolean[],
    pickedItems:any[]
    


}
export default class CreateProductGroup extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            groupDescription: '',
            groupName: '',
            isGroupTypeExpanded: false,
            groupTypeId: 1,
            displayRuleInProcebook: false,
            dimensionGroup: false,
            validationErrors: [],


            isDimensionExpanded: false,
            dimensions: [],
            dimension: 0,

            finderSource: [],
            selectedKey: {},
            selectedData: null,
            finderSelectedPaths: [],
            finderSpinners: [],
            pickedItems:[]
            
        };

        MasterContext.translate = MasterContext.translate.bind(this);
        this.toggleGroupTypeExpanded = this.toggleGroupTypeExpanded.bind(this);
        this.getGroupTypeName = this.getGroupTypeName.bind(this);
        this.toggleAllOff = this.toggleAllOff.bind(this);
        this.setGroupType = this.setGroupType.bind(this);
        this.handlePricebookCheckChange = this.handlePricebookCheckChange.bind(this);
        this.handleDimensionCheckChange = this.handleDimensionCheckChange.bind(this);
        this.renderCheckboxes = this.renderCheckboxes.bind(this);
        this.handleGroupNameChanged = this.handleGroupNameChanged.bind(this);
        this.handleGroupDescriptionChanged = this.handleGroupDescriptionChanged.bind(this);
        
        this.createGroup = this.createGroup.bind(this);
        this.resetGroup = this.resetGroup.bind(this);
        this.saveGroup = this.saveGroup.bind(this);
        this.showProducts = this.showProducts.bind(this);



        this.detailPanelStyle = this.detailPanelStyle.bind(this);


       
        this.toggleDimensionExpanded = this.toggleDimensionExpanded.bind(this);
        this.getDimensionName = this.getDimensionName.bind(this);
        this.getDimensions = this.getDimensions.bind(this);
        this.setDimension = this.setDimension.bind(this);




        //callbacks       
        this.finderItemClicked = this.finderItemClicked.bind(this);
        this.finderItemPicked = this.finderItemPicked.bind(this);
        this.finderResultsRemoveItem = this.finderResultsRemoveItem.bind(this);
        this.finderResultsClear = this.finderResultsClear.bind(this);
        this.finderResultsSave = this.finderResultsSave.bind(this);
        
        

        this.fetchDropdownData = this.fetchDropdownData.bind(this);





    }
    componentDidMount() {
        this.setState({ finderSelectedPaths: [-1, -1, -1, -1, -1, -1], finderSpinners: [false, false, false, false, false, false] }, () => { this.fetchDropdownData(0); })


    }

    isEven(n) {
        return n % 2 === 0;
    }
    getRowStyle(idx) {

        if (this.isEven(idx)) {
            return { backgroundColor: "#ffffff" };
        }
        else {
            return { backgroundColor: "#35b44a26" };
        }
    } 
    toggleAllOff = () => {
        this.setState({
            isGroupTypeExpanded: false,
            isDimensionExpanded: false
        });

    }

    //registration code
    validateRegistration(): boolean {
        let retVal: boolean = false;

        let newErrors: any[] = [];

        if (this.state.groupName.length === 0) {
            let newErr: any = {};
            newErr.controlName = 'tbGroupName';
            newErr.error = MasterContext.translate("validation_product_groups_create_validate_group_name_missing");
            newErr.severity = 1;
            newErrors.push(newErr);
            retVal = true;
        }


        if (this.state.groupDescription.length === 0) {
            let newErr: any = {};
            newErr.controlName = 'tbGroupDescription';
            newErr.error = MasterContext.translate("validation_product_groups_create_validate_description_missing");
            newErr.severity = 1;
            newErrors.push(newErr);
            retVal = false;
        }

        this.setState({ validationErrors: newErrors });
        return retVal;


    }
    getMyValidationErrors(control: string): string {
        let retVal: string = '';
        if (this.state.validationErrors) {
            for (let err of this.state.validationErrors) {
                if (err.controlName === control) {
                    retVal = retVal + err.error + '\r\n';
                }
            }
        }
        return retVal;
    }

    fetchDropdownData(level: number) {

        let pgqr: NodeRequest = new NodeRequest();

        switch (level) {
            case 0:
                pgqr.Level = 1;
                pgqr.NodeName = ""
                this.setState({ finderSpinners: [true, false, false, false, false, false] }, () => {
                    
                    MasterContext.ProductGroupsService.listProductHeirarchy(pgqr, (e) => {
                    
                        let finderArray: any[] = [];
                        let position: number = 0;
                        for (let item of e) {
                      

                            finderArray.push({ position: position, keyG: item.KeyG, nodeName: item.NodeName, nodeDescription: item.Description, level: item.Level, key: ' ' + item.Description, children: [] })
                            position++;
                        }
                        this.setState({ finderSource: finderArray, finderSpinners: [false, false, false, false, false, false] });
                    }, () => { });
                })

                break;

            case 1:
            case 2:
            case 3:
            case 4:
            case 5:
            case 6:
            case 7:
            case 8:
                pgqr.Level = this.state.selectedData.level + 1;
                pgqr.NodeName = this.state.selectedData.nodeName.trim();

                let lss: boolean[] = [false, false, false, false, false, false];
                if (level === 1) {
                    lss[1] = true;
                }
                if (level === 2) {
                    lss[2] = true;
                }
                if (level === 3) {
                    lss[3] = true;
                }
                if (level === 4) {
                    lss[4] = true;
                }
                if (level === 5) {
                    lss[5] = true;
                }
                this.setState({ finderSpinners: lss }, () => {
                    MasterContext.ProductGroupsService.listProductHeirarchy(pgqr, (e) => {
  
                        let finderArray: any[] = [];
                        let faCopy: any[] = [];
                        Object.assign(faCopy, this.state.finderSource);
                        let position: number = 0;
                        for (let item of e) {


                            finderArray.push({ position: position, keyG: item.KeyG, nodeName: item.NodeName, nodeDescription: item.Description, level: item.Level, key: item.Description, children: [] })
                            position++;
                        }

                        let nodeData = findInTree(this.state.finderSource, (node) => node.key === this.state.selectedData.key, { withAppendData: true })
                        let pos: number = 0;
                        let pos2: number = 0;
                        let pos3: number = 0;
                        let pos4: number = 0;
                        let pos5: number = 0;
                        let pos6: number = 0;
                        switch (nodeData.loc.length.toString()) {
                            case "1":
                                pos = nodeData.loc[0];
                                faCopy[pos].children = finderArray;
                                break;
                            case "2":
                                pos = nodeData.loc[0];
                                pos2 = nodeData.loc[1];
                                faCopy[pos].children[pos2].children = finderArray;
                                break;
                            case "3":
                                pos = nodeData.loc[0];
                                pos2 = nodeData.loc[1];
                                pos3 = nodeData.loc[2];
                                faCopy[pos].children[pos2].children[pos3].children = finderArray;
                                break;
                            case "4":
                                pos = nodeData.loc[0];
                                pos2 = nodeData.loc[1];
                                pos3 = nodeData.loc[2];
                                pos4 = nodeData.loc[3];
                                faCopy[pos].children[pos2].children[pos3].children[pos4].children = finderArray;
                                break;
                            case "5":
                                pos = nodeData.loc[0];
                                pos2 = nodeData.loc[1];
                                pos3 = nodeData.loc[2];
                                pos4 = nodeData.loc[3];
                                pos5 = nodeData.loc[4];
                                faCopy[pos].children[pos2].children[pos3].children[pos4].children[pos5].children = finderArray;
                                break;
                            case "6":
                                pos = nodeData.loc[0];
                                pos2 = nodeData.loc[1];
                                pos3 = nodeData.loc[2];
                                pos4 = nodeData.loc[3];
                                pos5 = nodeData.loc[4];
                                pos6 = nodeData.loc[5];
                                faCopy[pos].children[pos2].children[pos3].children[pos4].children[pos5].children[pos6].children = finderArray;
                                break;
                        }


                        this.setState({  finderSource: faCopy }, () => {
                            this.setState({  finderSpinners: [false, false, false, false, false, false] });
                        });

                    }, () => { })
                    
                });
                break;
        }


    }

    //group type
    toggleGroupTypeExpanded = () => {
        this.toggleAllOff();
        this.setState({ isGroupTypeExpanded: !this.state.isGroupTypeExpanded });
    }
    getGroupTypeName(): string {
        let retVal: string = "";
        switch (this.state.groupTypeId) {
            case 1:
                retVal = MasterContext.translate("product_groups_group_type_rules");
                break;
            case 2:
                retVal = MasterContext.translate("product_groups_group_type_ads");
                break;
        }
        return retVal;
    }
    setGroupType(val: any) {
        this.setState({ groupTypeId: val });
        this.toggleAllOff();
        return 0;
    }

    //display in pricebook
    handlePricebookCheckChange() {
        this.setState({ displayRuleInProcebook: !this.state.displayRuleInProcebook });
    }

    //dimension group
    handleDimensionCheckChange() {
        this.setState({ dimensionGroup: !this.state.dimensionGroup });
    }

    //page visibility stuff
    renderCheckboxes() {
        let retVal = [];
        if (this.state.groupTypeId === 1) {
            retVal.push(<div className="row">
                <div className="row-md-12">
                    <input id="cbDisplayInPricebook" type="checkbox" onChange={() => this.handlePricebookCheckChange()} checked={this.state.displayRuleInProcebook} />
                    <label className="form-check-label" htmlFor="cbDisplayInPricebook"> &nbsp; {MasterContext.translate("product_groups_create_display_in_pricebook")}</label>
                </div>
            </div>);

            retVal.push(<div className="row">
                <div className="row-md-12">
                    <input id="cbDimensionInGroup" type="checkbox" onChange={() => this.handleDimensionCheckChange()} checked={this.state.dimensionGroup} />
                    <label className="form-check-label" htmlFor="cbDimensionInGroup"> &nbsp; {MasterContext.translate("product_groups_create_dimension_group")}</label>
                </div>
            </div>);
        }

        return retVal;
    }

    //group name
    handleGroupNameChanged(event: any) {
        let v = event.target.value;
        this.setState({ groupName: v });
    }

    //group description
    handleGroupDescriptionChanged(event: any) {
        let v = event.target.value;
        this.setState({ groupDescription: v });
    }


    //========================
    toggleDimensionExpanded = () => {
        this.toggleAllOff();
        this.setState({ isDimensionExpanded: !this.state.isDimensionExpanded });
    }

    getDimensionName(): string {

        let retVal: string = MasterContext.translate("product_groups_create_option_not_set");
        for (let itm of this.state.dimensions) {
            if (this.state.dimension === itm.id) {
                retVal = itm.name;
            }
        }

        return retVal;
    }

    getDimensions() {
        let retVal: any[] = [];
        for (let dept of this.state.dimensions) {
            let passVal = dept.id;
            retVal.push(<span className="dropdown-item" onClick={() => this.setDimension(passVal)}>
                {/*
 // @ts-ignore*/}
                {dept.name}
            </span>);
        }


        return retVal;
    }

    setDimension(val) {

        this.setState({ dimension: val });
        this.toggleAllOff();
        return 0;
    }




    //callbacks
    finderItemClicked(item: any, bay: number) {
        let arrCopy: number[] = [];
        Object.assign(arrCopy, this.state.finderSelectedPaths);
        arrCopy[bay] = item.position;
        for (let i = bay + 1; i < arrCopy.length; i++) {
            arrCopy[i] = -1;
        }

        this.setState({ finderSelectedPaths: arrCopy, selectedData: item }, () => {
            this.fetchDropdownData(bay + 1);
        });
    }
    finderItemPicked(item: any, bay: number) {
        let cpy: any[] = [];
        Object.assign(cpy, this.state.pickedItems);
        let found = false;
        for (let itm of cpy) {
            if (itm.keyG === item.keyG) {
                found = true;
            }
        }

        if (found === false) {
            cpy.push(item);
        }

        this.setState({pickedItems:cpy});

    }
    finderResultsRemoveItem(item: any) {
        let cpy: any[] = [];
        let cpy2: any[] = [];
        Object.assign(cpy, this.state.pickedItems)
        for (let itm of cpy) {
            if (itm.keyG !== item.keyG)
            {
                cpy2.push(itm);
            }
        }
        this.setState({ pickedItems: cpy2 });
    }
    finderResultsClear() {
        let cpy: any[] = [];
        this.setState({ pickedItems: cpy });
    }
    finderResultsSave() {
        alert('save this list');
    }

    //button actions
    createGroup() {
        if (this.validateRegistration() === false) {
            console.log('make it');
        }
    }
    resetGroup() {
        if (this.validateRegistration() === false) {
            console.log('reset it');
        }
    }
    saveGroup() {
        if (this.validateRegistration() === false) {
            console.log('save it');
        }
    }

    showProducts() { }
    detailPanelStyle() {
        let retVal: any = {};
        retVal.display = "none";
        if (this.state.groupName.length > 0) {
            retVal = { display: "inline-block" };
            retVal.width = "100%"
        }
        return retVal;
    }

    render() {

        let groupType = `dropdown-menu${this.state.isGroupTypeExpanded ? " show" : ""}`;
        let errorClass = { color: "#ff0000" };

        return (
            <React.Fragment>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-10">
                            <CDIFinder
                                selectedPaths={this.state.finderSelectedPaths}
                                sourceData={this.state.finderSource}
                                depth={6}
                                containerHeight="240px"
                                itemClickedCallback={this.finderItemClicked}
                                itemPickedCallback={this.finderItemPicked}
                                fontSize="11px"
                                hilightedBackgroundColor="#164778"
                                columnWidth="280px"
                                spinners={this.state.finderSpinners}
                                searchMatchColor="#F58484"
                                addCallBackTitle="Add Item"
                                finderName="Product Hierarchy Explorer"
                                pickedItems={this.state.pickedItems}
                            >
                            </CDIFinder>
                        </div>
                        <div className="col-md-2">
                            <CDIFinderResults
                                sourceData={this.state.pickedItems}
                                addCallBackTitle="Remove Item"
                                itemPickedCallback={this.finderResultsRemoveItem}
                                primaryColor="#ffffff"
                                fontSize="11px"
                                alternateColor="#164778"
                                alternateFontColor="#ffffff"
                                finderResultsName="Results"
                                containerHeight="240px"
                                columnWidth="280px"
                                clearButtonText="Clear"
                                saveButtonText="Save"
                                clearAllCallback={this.finderResultsClear}
                                saveCallback={this.finderResultsSave}
                            >
                            </CDIFinderResults>
                        </div>
                    </div>

                    <div id="divFilterPanel" className="row card">
                        <div className="col-md-12">
                            <h4>{MasterContext.translate("product_groups_create_title")}</h4>
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <label className="form-check-label" htmlFor="tbGroupName">{MasterContext.translate("product_groups_create_label_group_name")}</label>
                                                </div>
                                                <div className="col-md-8">
                                                    <div className="form-group">
                                                        <input style={{ width: "303px" }} type="text" id="tbGroupName" value={this.state.groupName} onChange={this.handleGroupNameChanged} />
                                                        <br />
                                                        <span style={errorClass}>{this.getMyValidationErrors('tbGroupName')}</span>
                                                    </div>
                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <label className="form-check-label" htmlFor="tbGroupDescription">{MasterContext.translate("product_groups_create_label_description")}</label>
                                                </div>
                                                <div className="col-md-8">
                                                    <div className="form-group">
                                                        <textarea maxLength={200} cols={40} rows={2} id="tbGroupDescription" value={this.state.groupDescription} onChange={this.handleGroupDescriptionChanged} />
                                                        <br />
                                                        <span style={errorClass}>{this.getMyValidationErrors('tbGroupDescription')}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="row">
                                                <div className="col-md-4">

                                                </div>
                                                <div className="col-md-8">
                                                    <button className="btn btn-outline-primary btn-sm" id="btnCreateNew" onClick={this.createGroup}>{MasterContext.translate("product_groups_create_button_create")}</button> &nbsp;
                                                    <button className="btn btn-outline-primary btn-sm" id="btnCreateNew" onClick={this.resetGroup}>{MasterContext.translate("product_groups_create_button_reset_group")}</button> &nbsp;
                                                    <button className="btn btn-outline-primary btn-sm" id="btnCreateNew" onClick={this.saveGroup}>{MasterContext.translate("product_groups_create_label_save_group")}</button>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-4">

                                                </div>
                                                <div className="col-md-8">
                                                    <span>{MasterContext.translate("product_groups_create_save_group_warning")}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-8">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="dropdown">
                                                <button className="btn btn-sm btn-outline-info dropdown-toggle" onClick={this.toggleGroupTypeExpanded} type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    {this.getGroupTypeName()}
                                                </button>
                                                <div className={groupType} aria-labelledby="dropdownMenuButton">
                                                    <span className="dropdown-item" onClick={() => this.setGroupType(1)}>{MasterContext.translate("product_groups_group_type_rules")}</span>
                                                    <span className="dropdown-item" onClick={() => this.setGroupType(2)}>{MasterContext.translate("product_groups_group_type_ads")}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {this.renderCheckboxes()}
                                </div>
                            </div>
                        </div>
                    </div>

              
                </div>
            </React.Fragment >
        );
    }
}