import IPMOBaseService from '../Generics/Service/IPMOBaseService'
export default class LoginService extends IPMOBaseService {
    logout(callback: any, errorCallback: any) {
        super.get("/api/Sso/Logout", "Logout",
            (result) => {
                if (result.Successful) {
                    callback();
                }
            },
            () => {errorCallback()}
        );
    }
}    