import React, { useEffect, useState, SetStateAction } from 'react';
import UsersService from '../../../Services/Users/Users.service';
import RolesService from '../../../Services/Roles/Roles.service';
import HierarchyService from '../../../Services/Hierarchy/Service/Hierarchy.service';
import ViewWrapper from '../Common/ViewWrapper/ViewWrapper';
import { Spinner, Row, Col, ListGroupItem, ListGroup, FormGroup, Label, Input, Button, Form } from 'reactstrap';
import UsersGrid from './UsersGrid';
import EnhancedRsModal from '../Common/EnhancedRsModal/EnhancedRsModal';
import { IReqSetIdentityProductHierarchyAccess, IUser, IReqUser } from '../../../Services/Users/Users.service.types';
import { IIdentitySimple, IReqGetRoles, IRoleUser } from '../../../Services/Roles/Roles.service.types';
import { IServerSideGetRowsRequest } from '@ag-grid-enterprise/all-modules';
import { INodeSimple } from '../../../Services/Hierarchy/Types/Hierarchy.service.types';

interface IStateInput {
    touched: boolean;
    value: string;
    error: string;
}

interface IProps {
    editUserId: number | null;
    setEditUserId?: React.Dispatch<SetStateAction<number | null>>;
    users?: IUser[];
    setUsers?: React.Dispatch<SetStateAction<IUser[]>>;
    setModal?: React.Dispatch<SetStateAction<any>>;
}

export default function UserForm({ editUserId, setEditUserId, users, setUsers, setModal }: IProps) {
    const usersService = new UsersService();
    const rolesService = new RolesService();
    const hierarchyService = new HierarchyService();

    const [name, setName] = useState<IStateInput>({ touched: false, value: '', error: '' });
    const [firstName, setFirstName] = useState<IStateInput>({ touched: false, value: '', error: '' });
    const [lastName, setLastName] = useState<IStateInput>({ touched: false, value: '', error: '' });
    const [email, setEmail] = useState<IStateInput>({ touched: false, value: '', error: '' });
    const [phoneNumber, setPhoneNumber] = useState<IStateInput>({ touched: false, value: '', error: '' });
    const [company, setCompany] = useState<IStateInput>({ touched: false, value: '', error: '' });
    const [newPassword, setNewPassword] = useState<IStateInput>({ touched: false, value: '', error: '' });

    

    useEffect(() => {
        if (editUserId) {
            usersService.getUserById(
                editUserId,
                (res) => {
                    if (res.Id) {
                        setName({ touched: false, error: '', value: res.Name })
                        setFirstName({ touched: false, error: '', value: res.FirstName })
                        setLastName({ touched: false, error: '', value: res.LastName })
                        setEmail({ touched: false, error: '', value: res.Email })
                        setPhoneNumber({ touched: false, error: '', value: res.PhoneNumber })
                        setCompany({ touched: false, error: '', value: res.Company })
                    }
                },
                (err) => { alert(err)}
            )
        }

    }, [])

    const handleSaveUser = (e) => {
        e.preventDefault();
        // TODO validations or reject
        const postBody = {
            Id: editUserId,
            Name: name.value,
            FirstName: firstName.value,
            LastName: lastName.value,
            Email: email.value,
            PhoneNumber: phoneNumber.value,
            Company: company.value,
            NewPassword: newPassword.value // endpoint checks if empty and skips update
        } as IReqUser;

        usersService.saveUser(
            postBody,
            (res) => {
                console.log('user saved');
                if (users && setUsers) {
                    // update the users in the parent component
                    const newUsers = users.map(u => {
                        if (u.Id === res.Id) {
                            return res;
                        }
                        return u;
                    });
                    if (!!newUsers.find(u => u.Id === res.Id) === false) {
                        newUsers.push(res)
                    }
                    setUsers(newUsers);
                }
                if (setEditUserId) setEditUserId(null);
                if (setModal) setModal(false);
            },
            (err) => alert(err)
        )
        console.log('User Saved')
    }


    return (
        <ViewWrapper>
            <Row>
                <Col>
                    <h2 className="h4">{ editUserId ? 'Edit' : 'Add New'} User</h2>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form autoComplete="off">
                        <FormGroup>
                            <Label>Username</Label>
                            <Input
                                type="text"
                                id="name"
                                name="name"
                                value={name.value || ''}
                                onChange={(e) => {
                                    setName({ value: e.target.value, touched: true, error: "" })
                                }}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label>First Name</Label>
                            <Input
                                type="text"
                                id="firstName"
                                name="firstName"
                                value={firstName.value || ''}
                                onChange={(e) => {
                                    setFirstName({ value: e.target.value, touched: true, error: "" })
                                }}

                            />
                        </FormGroup>
                        <FormGroup>
                            <Label>Last Name</Label>
                            <Input
                                type="text"
                                id="lastName"
                                name="lastName"
                                value={lastName.value || ''}
                                onChange={(e) => {
                                    setLastName({ value: e.target.value, touched: true, error: "" })
                                }}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label>Email</Label>
                            <Input
                                type="email"
                                id="email"
                                name="email"
                                value={email.value || ''}
                                onChange={(e) => {
                                    setEmail({ value: e.target.value, touched: true, error: "" })
                                }}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label>Phone</Label>
                            <Input
                                type="text"
                                id="phoneNumber"
                                name="phoneNumber"
                                value={phoneNumber.value || ''}
                                onChange={(e) => {
                                    setPhoneNumber({ value: e.target.value, touched: true, error: "" })
                                }}
                            />
                            {/* TODO add validator here to count numbers, sanitize other chars at save */}
                        </FormGroup>
                        <FormGroup>
                            <Label>Company</Label>
                            <Input
                                type="text"
                                id="company"
                                name="company"
                                value={company.value || ''}
                                onChange={(e) => {
                                    setCompany({ value: e.target.value, touched: true, error: "" })
                                }}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label>New Password</Label>
                            <Input
                                type="password"
                                id="newPassword"
                                name="newPassword"
                                value={newPassword.value || ''}
                                onChange={(e) => {
                                    setNewPassword({ value: e.target.value, touched: true, error: "" })
                                }}
                                autoComplete="new-password"
                            />
                        </FormGroup>
                        {/* TODO add confirm password field */}
                        <div className="mt-5">
                            <Button
                                size="md"
                                color="primary"
                                onClick={handleSaveUser}
                                className="mr-2"
                            >
                                Save
                            </Button>
                            <Button
                                size="md"
                                color="danger"
                                onClick={() => {
                                    if (setEditUserId) setEditUserId(null);
                                    if (setModal) setModal(false);
                                }}
                            >
                                Cancel
                            </Button>
                        </div>
                    </Form>
                </Col>
            </Row>
       </ViewWrapper>
    )
}