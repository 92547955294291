import RuleTreeNode from "../../RulesExplorer/Types/RuleTreeNode.model";
import PagingFilter from "../../Common/Types/PagingFilter.model";
import MasterContext from '../../../Components/React/Misc/MasterContext';
import IPMOBaseService from '../../Generics/Service/IPMOBaseService';
import CacheManager from '../../CacheManager/CacheManager';

export default class CDI_ModuleFeatureService extends IPMOBaseService {
    listModules(callback: any, errorCallback: any) {
        let methodName = "Module/Feature List";

        let CM: CacheManager = new CacheManager();
        let activeModuleCacheKey = "ACTIVEMODULES";

        var cached;
        try {
            cached = MasterContext.CacheMan.getCacheItem(activeModuleCacheKey);
        } catch {
            cached = null;
        }

        if (cached) {
            callback(cached);
        } else {
            super.startRequest(methodName, '');
            super.post("/api/UserManagement/ListModulesAndFeatures", null, methodName,
                (result) => {
                    let ret: any = result.Data;

                    let activeModules = [];
                    for (let m of ret) {
                        if (m.Active)
                            activeModules.push(m);
                    }
                    CM.setLocalStorageItem(activeModuleCacheKey, activeModules);

                    callback(ret)
                },
                (error) => {
                    errorCallback(error)
                });

        }
    }

    list(params: PagingFilter, callback: any, errorCallback: any) {
        let methodName = "Module/Feature List";
        var cached;
        try {
            cached = MasterContext.CacheMan.getCacheItem("CDI_ModuleFeatureService");
        } catch {
            cached = null;
        }

        if (cached == null) {
            super.startRequest(methodName, params);
            let filt = "Level~eq~1~and~ParentNodeId~eq~0";
            let QS = "?page=1&pageSize= &filter=" + filt + "&count=true";


            super.get("/PricingEngineService/rulesExplorer/" + QS, methodName,
                (result) => {
                    let ret = result as RuleTreeNode[];
                    MasterContext.CacheMan.setCacheItem("CDI_ModuleFeatureService", ret);
                    callback(ret)
                },
                (error) => {
                    errorCallback(error)
                });
        } else {
            callback(cached);
        }
    }
}
