//import { ColDef } from '@ag-grid-community/core';
import { GridApi, ColumnApi,  Column } from '@ag-grid-enterprise/all-modules';

interface ColDict {
    [key: string]: string
}

const gridHelper = {
    makeDefaultColDefs(colDict: ColDict) {
        const columnDefTmpl = (dataKey, uiKey) => {
            return {
                headerName: uiKey,
                field: dataKey,
                valueGetter: (params) => {
                    return params.data?.[dataKey] ?? null
                }

            };
        };
        return Object.keys(colDict).map(dataKey => columnDefTmpl(dataKey, colDict[dataKey]));
    },

    // expands all columns to fit the name inside them (api logic somewhat unknown) // supposed to behave like expandToFitHeaders
    autosizeColumns(colApi: ColumnApi){
        var allColumnIds = [];
        colApi.getAllColumns().forEach(function (col: Column) {
            allColumnIds.push(col.getColId());
        });

        return colApi.autoSizeColumns(allColumnIds);
    },

    // shrinks or expands all cols to fill grid container
    shrinkOrExpandColsToFitGrid(gridApi: GridApi) {
        return gridApi.sizeColumnsToFit();
    },

    // does what the name says, plus a little extra space
    expandToFitHeaders(colApi: ColumnApi) {
        colApi.getAllColumns().forEach(function (col: Column) {
            const colDef = col.getColDef();
            const headerName = colDef.headerName;
            const spaces = headerName.split(/[A-Z]/g).length - 1;
            const width = ((headerName.length + spaces) * 7) + 16 + 16;
            colApi.setColumnWidth(col, width)
        });
    } 
}

export default gridHelper;
