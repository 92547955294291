import React from "react";
import { AdminAuditRequest, AdminAuditResponse } from '../../../Services/AuditObject/Types/AdminAudit.model';
import moment from 'moment';


import { AgGridReact } from '@ag-grid-community/react';
import {
    ClientSideRowModelModule,
    SetFilterModule,
    FiltersToolPanelModule,
    ColumnsToolPanelModule,
    SideBarModule,
    ClipboardModule,
    ExcelExportModule,
    GridChartsModule,
    MenuModule,
} from '@ag-grid-enterprise/all-modules';
import { ColDef, StatusPanelDef } from '@ag-grid-community/core';
import '@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css';
import '@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css';
import "react-datepicker/dist/react-datepicker.css";
import MasterContext from '../Misc/MasterContext';

export interface IProps {

}
export interface IState {
    modules: any[],
    frameworkComponents: any,
    startDate: Date;
    endDate: Date;
    loaded: boolean;
    //validationErrors: any[];
    adminAudits: AdminAuditResponse[],
    columnDefs: any[],
    multiSortKey: string,
    columnTypes: {
        [key: string]: ColDef;
    },
    statusBar: {
        statusPanels: StatusPanelDef[];
    },
}

export default class AdminAudit extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);

        this.state = {
            modules: [
                ClientSideRowModelModule,
                SetFilterModule,
                FiltersToolPanelModule,
                ColumnsToolPanelModule,
                SideBarModule,
                ClipboardModule,
                ExcelExportModule,
                GridChartsModule,
                MenuModule
            ],
            frameworkComponents: {
            },
            startDate: this.getStartDate(),
            endDate: this.getEndDate(),
            loaded: false,
            multiSortKey: 'ctrl',
            //validationErrors: [],
            adminAudits: [],
            statusBar: {
                statusPanels: [
                    {
                        statusPanel: 'agTotalAndFilteredRowCountComponent',
                        align: 'left',
                    },
                ],
            },
            columnDefs: [
                {
                    field: "AuditID",
                    headerName: "Audit ID",
                    width: 100,
                    filter: 'agNumberColumnFilter',
                    sort: true,
                    floatingFilter: true,
                    resizable: true,
                    sortable: true,
                    filterParams: {
                        buttons: ['clear', 'apply'],
                    },
                },
                {
                    field: "UserName",
                    headerName: "User",
                    width: 120, 
                    filter: true, 
                    floatingFilter: true,
                    resizable: true,
                    sortable: true,
                },
                {
                    field: "AuditTimeStamp",
                    headerName: "Timestamp",
                    type: 'dateColumn',
                    width: 175,
                    filter: 'agDateColumnFilter',
                    floatingFilter: true,
                    resizable: true,
                    sortable: true,
                    // add extra parameters for the date filter
                    filterParams: {
                        buttons: ['clear', 'apply'],
                        // provide comparator function
                        comparator: function (filterLocalDateAtMidnight, cellValue) {
                            var dateAsString = cellValue;

                            if (dateAsString == null) {
                                return 0;
                            }

                            // In the example application, dates are stored as 2020-01-31T12:00:00
                            // We create a Date object for comparison against the filter date
                            var dateOnly = dateAsString.split('T')[0];
                            var dateParts = dateOnly.split('-');
                            var day = Number(dateParts[2]);
                            var month = Number(dateParts[1]) - 1;
                            var year = Number(dateParts[0]);
                            var cellDate = new Date(year, month, day);

                            // Now that both parameters are Date objects, we can compare
                            if (cellDate < filterLocalDateAtMidnight) {
                                return -1;
                            } else if (cellDate > filterLocalDateAtMidnight) {
                                return 1;
                            } else {
                                return 0;
                            }
                        }
                    }
                },
                {
                    field: "EventData",
                    headerName: "Data",
                    flex: 1, 
                    minWidth: 200,
                    filter: 'agTextColumnFilter',
                    floatingFilter: true,
                    resizable: true,
                    sortable: true,
                    filterParams: {
                        buttons: ['clear', 'apply'],
                    },
                }
            ],
            columnTypes: {
                dateColumn: {
                    valueFormatter: function (params) {
                        return moment(params.value).format('MM/DD/YYYY hh:mm:ss A');
                    },
                    filter: 'agDateColumnFilter',
                    filterParams: {
                        comparator: function (filterLocalDateAtMidnight, cellValue) {
                            var dateParts = cellValue.split('/');
                            var day = Number(dateParts[0]);
                            var month = Number(dateParts[1]) - 1;
                            var year = Number(dateParts[2]);
                            var cellDate = new Date(year, month, day);
                            if (cellDate < filterLocalDateAtMidnight) {
                                return -1;
                            } else if (cellDate > filterLocalDateAtMidnight) {
                                return 1;
                            } else {
                                return 0;
                            }
                        },
                    },
                }
            }

        }
        //this.handleStartDateChange = this.handleStartDateChange.bind(this);
        //this.handleEndDateChange = this.handleEndDateChange.bind(this);
        //this.getMyValidationErrors = this.getMyValidationErrors.bind(this);
        this.getAuditData = this.getAuditData.bind(this);
        //this.getDownloadButton = this.getDownloadButton.bind(this);
        //this.downloadData = this.downloadData.bind(this);
        this.getStartDate = this.getStartDate.bind(this);
        this.getEndDate = this.getEndDate.bind(this);
        //this.validate = this.validate.bind(this);
    }

    onGridReady = (params) => {
        this.getAuditData();
    };

    getContextMenuItems = (params) => {
        var result = [
            'copy',
            'copyWithHeaders',
            'separator',
            'export',
            'chartRange',
        ];
        return result;
    };

    getStartDate() {
        // TODO - Hard-coded to beginning of 2020 - need to figure out solution for LARGE sets of results
        // with ClientSideRowModel or change to ServerSideRowModel
        let retVal: Date = new Date(2020, 0, 1);
        retVal.setMonth(retVal.getMonth() - 1)
        return retVal;
    }

    getEndDate() {
        let retVal: Date = new Date();
        return retVal;
    }

    /*
    downloadData() {
        if (this.validate() === false) {
            this.setState({ loaded: true }, () => {
                let AAR: AdminAuditRequest = new AdminAuditRequest();
                AAR.Starting = this.state.startDate;
                AAR.Ending = this.state.endDate;
                MasterContext.AdminAuditService.downloadAdminAudits(AAR,
                    (res) => {
                        this.setState({ loaded: false });
                    },
                    (err) => { }
                )
            })

        }
    }
    */

    /*
    validate(): boolean {
        let retVal: boolean = false;

        let newErrors: any[] = [];

        if (this.state.startDate > this.state.endDate) {
            let newErr: any = {};
            newErr.controlName = 'startDate';
            newErr.error = MasterContext.translate('validation_admin_audit_start_date');
            newErr.severity = 1;
            newErrors.push(newErr);
            retVal = true;

            let newErr2: any = {};
            newErr2.controlName = 'endDate';
            newErr2.error = MasterContext.translate('validation_admin_audit_end_date');
            newErr2.severity = 1;
            newErrors.push(newErr2);
            retVal = true;
        }

        this.setState({ validationErrors: newErrors });
        return retVal;
    }
    */

    getAuditData() {
        //if (this.validate() === false) {
            this.setState({ loaded: true }, () => {
                let AAR: AdminAuditRequest = new AdminAuditRequest();
                AAR.Starting = this.state.startDate;
                AAR.Ending = this.state.endDate;
                MasterContext.AdminAuditService.getAdminAudits(AAR,
                    (res) => {
                        this.setState({ adminAudits: res, loaded: false });
                    },
                    (err) => { }
                )
            })

        //}
    }

/*
    getMyValidationErrors(control: string): string {
        let retVal: string = '';
        if (this.state.validationErrors) {
            for (let err of this.state.validationErrors) {
                if (err.controlName === control) {
                    retVal = retVal + err.error + '\r\n';
                }
            }
        }
        return retVal;
    }
*/

    //handleStartDateChange(event: any) {
    //    this.setState({ startDate: event }, () => { this.validate(); });
    //}

    //handleEndDateChange(event: any) {
    //    this.setState({ endDate: event }, () => { this.validate() });
    //}

    //getDownloadButton() {
    //    let retVal: any;
    //    retVal = <span></span>
    //    if (this.state.adminAudits.length > 0) {
    //        retVal = <input type="submit" name="submit" onClick={this.downloadData} className="btn loginColorsButton btn-md float-right" value={MasterContext.translate("admin_audit_download_data")} />
    //    }
    //    return retVal;
    //}

    render() {

//        let errorClass = { color: "#ff0000" };

        return (
            <React.Fragment>
                <div className="container-fluid">
                    <div id="divFilterPanel" className="row card">
                        <div className="col-md-12">
                            <h4>Admin Audit History</h4>
                        </div>
                    </div>
                    <div id="pnlAdminAuditDetail" className="row card">
                        <div className="row" style={{ marginTop: "5px" }}>
                            <div className="col-md-12" style={{ overflowY: "scroll" as "scroll", overflowX: "hidden" as "hidden", height: "75vh" }}>
                                <div className="ag-theme-balham" style={{ height: '100%' }}>
                                    <AgGridReact
                                        debug={true}
                                        modules={this.state.modules}
                                        frameworkComponents={this.state.frameworkComponents}
                                        columnDefs={this.state.columnDefs}
                                        rowData={this.state.adminAudits}
                                        columnTypes={this.state.columnTypes}
                                        onGridReady={this.onGridReady}
                                        statusBar={this.state.statusBar}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}