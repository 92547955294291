import * as React from 'react';

import App_Property from '../../../Services/Common/Types/AppProperty.model'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import MasterContext from '../Misc/MasterContext';

export interface IProps {

}

export interface IState {
    priceChangeDaysProp: App_Property,
    snapDayProp: App_Property,
    showResultModal: boolean,
    priceChangeDaysValidationErrors:any[],
}

export default class AdminpriceChangeDaysProp extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            priceChangeDaysProp: new App_Property(),
            snapDayProp: new App_Property(),
            showResultModal: false,
            priceChangeDaysValidationErrors:[],
        };
        this.handlePriceChangeDaysChanged = this.handlePriceChangeDaysChanged.bind(this);
        this.saveSelection = this.saveSelection.bind(this);
        this.toggleshowResultModal = this.toggleshowResultModal.bind(this);
        this.validate = this.validate.bind(this);
        this.getMyValidationErrors = this.getMyValidationErrors.bind(this);
    }
        
    toggleshowResultModal() {
        this.setState({ showResultModal: !this.state.showResultModal });
    }
    componentDidMount() {
        const ignoreCache = true;

        MasterContext.AppPropertyService.findAppProperty("AppDefaults", "PriceChangeDateDays", 
            (prop) => {
                if (prop != null && prop.Value != null) {
                    this.setState({priceChangeDaysProp: prop})
                }
            }
            ,(err) => {},
            ignoreCache
        );

        MasterContext.AppPropertyService.findAppProperty("AppDefaults", "PriceChgDateSnapDay", 
            (prop) => {
                if (prop != null && prop.Value != null) {
                    this.setState({snapDayProp: prop})
                }
            }
            ,(err) => {},
            ignoreCache
        );
    }
    saveSelection() {
        if (this.validate() === false) {
            MasterContext.AppPropertyService.updateAppProperty(this.state.priceChangeDaysProp, () => { }, () => { });
            MasterContext.AppPropertyService.updateAppProperty(this.state.snapDayProp, () => { }, () => { });
            this.toggleshowResultModal();
        }
    }
    handlePriceChangeDaysChanged(event: any) {
        this.validate()
        let v = event.target.value;
        let cpy:App_Property = new App_Property();
        Object.assign(cpy, this.state.priceChangeDaysProp);
        cpy.Value = v;
        this.setState({ priceChangeDaysProp: cpy });
    }

    validate(): boolean {
        let retVal: boolean = false;

        let newErrors: any[] = [];
        if (this.state.priceChangeDaysProp.Value == null || this.state.priceChangeDaysProp.Value.length === 0) {
            let newErr: any = {};
            newErr.controlName = 'priceChangeDays';
            newErr.error = MasterContext.translate('validation_price_change_days_required');
            newErr.severity = 1;
            newErrors.push(newErr);
            retVal = true;
        }
        this.setState({ priceChangeDaysValidationErrors: newErrors });
        return retVal;
    }

    getMyValidationErrors(control: string): string {
        let retVal: string = '';
        if (this.state.priceChangeDaysValidationErrors) {
            for (let err of this.state.priceChangeDaysValidationErrors) {
                if (err.controlName === control) {
                    retVal = retVal + err.error + '\r\n';
                }
            }
        }
        return retVal;
    }

    handleSelect = event => {
        let newSnapDayProp = this.state.snapDayProp;
        newSnapDayProp.Value = event.target.value;
        this.setState({snapDayProp: newSnapDayProp});
    };

    render() {
        let errorClass = { color: "#ff0000" };

        return (
            <React.Fragment>

                <Modal zIndex={3002} toggle={() => this.toggleshowResultModal()} isOpen={this.state.showResultModal}>
                    <ModalHeader toggle={() => this.toggleshowResultModal()}>{MasterContext.translate("price_change_days_modal_header")}</ModalHeader>
                    <ModalBody>
                        <div>{MasterContext.translate("price_change_days_modal_body")}</div>
                        <div>Snap Day Saved</div>
                    </ModalBody>
                    <ModalFooter>
                        <button className="btn btn-sm btn-outline-info" color="primary" onClick={() => this.toggleshowResultModal()}>{MasterContext.translate("ok_modal_button")}</button>{' '}                       
                    </ModalFooter>
                </Modal>

                <div className="row mt-2">
                    <div className="col-md-4"></div>
                    <div className="col-md-4 card">
                        <div className="row">
                            <div className="col-md-12">
                                <h3 className="text-center text-info">{MasterContext.translate("price_change_days_header")}</h3>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="alert alert-secondary" role="alert">
                                    {MasterContext.translate("price_change_days_description")}
                                </div>
                            </div>
                        </div>
                        <div className="form-group">
                            <div className="row">
                                <div className="col-md-3">
                                    <label className="loginColors">{MasterContext.translate("price_change_days_label")}  </label>
                                </div>
                                <div className="col-md-9">
                                    <input placeholder={MasterContext.translate("price_change_days_placeholder")} 
                                        id='daysToLock' type="number" value={this.state.priceChangeDaysProp?.Value} 
                                        onChange={this.handlePriceChangeDaysChanged} className="form-control" />
                                </div>
                            </div>
                            <br/>
                            <div className="row">
                                <div className="col-md-3">
                                    <label className="loginColors">Snap Day</label>
                                </div>
                                <div className="col-md-9">
                                    <div className="form-check">
                                        <input className="form-check-input" type="radio" name="sunday" id="sunday"
                                        value={0} checked={this.state.snapDayProp.Value === '0'} onChange={this.handleSelect}/>
                                        <label className="form-check-label" htmlFor="sunday">Sunday</label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="radio" name="monday" id="monday"
                                        value={1} checked={this.state.snapDayProp.Value === '1'} onChange={this.handleSelect}/>
                                        <label className="form-check-label" htmlFor="monday">Monday</label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="radio" name="tuesday" id="tuesday"
                                        value={2} checked={this.state.snapDayProp.Value === '2'} onChange={this.handleSelect}/>
                                        <label className="form-check-label" htmlFor="tuesday">Tuesday</label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="radio" name="wednesday" id="wednesday"
                                        value={3} checked={this.state.snapDayProp.Value === '3'} onChange={this.handleSelect}/>
                                        <label className="form-check-label" htmlFor="wednesday">Wednesday</label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="radio" name="thursday" id="thursday"
                                        value={4} checked={this.state.snapDayProp.Value === '4'} onChange={this.handleSelect}/>
                                        <label className="form-check-label" htmlFor="thursday">Thursday</label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="radio" name="friday" id="friday"
                                        value={5} checked={this.state.snapDayProp.Value === '5'} onChange={this.handleSelect}/>
                                        <label className="form-check-label" htmlFor="friday">Friday</label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="radio" name="saturday" id="saturday"
                                        value={6} checked={this.state.snapDayProp.Value === '6'} onChange={this.handleSelect}/>
                                        <label className="form-check-label" htmlFor="saturday">Saturday</label>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-3">
                                </div>
                                <div className="col-md-9">
                                    <span className="pull-right" style={errorClass}>{this.getMyValidationErrors('daysToLock')}</span>
                                </div>
                            </div>
                        </div>
                        <div className="row mb-2">
                            <div className="col-md-6"></div>
                            <div className="col-md-6">
                                <button className="btn btn-outline-primary btn-sm float-right" id="btnSaveSelection" 
                                    onClick={this.saveSelection}>{MasterContext.translate("save_button")}
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4"></div>
                </div>
            </React.Fragment>
        )
    }
}