import CreateMessageRequest from '../Types/MessageManager.model';
import IPMOBaseService from '../../Generics/Service/IPMOBaseService'
import * as types from '../../Login/Types/Login.model';
export default class MessageManagerService extends IPMOBaseService {

    createMessage(data: CreateMessageRequest, callback: any, errorCallback: any) {
        let methodName = "CreateMessage";
        super.post("/api/Messaging/CreateMessage", data, methodName,
            (result) => {
                if (result.Successful) {

                    if (callback !== null) {
                        callback(result.Data);
                    }
                } else {
                    if (errorCallback != null)
                        errorCallback(result);
                }
            },
            (error) => {
                errorCallback(error)
            });
    }
    listAll(data: types.LoginToken, callback: any, errorCallback: any) {
        let methodName = "ListAll";
        super.post("/api/Messaging/ListAll", data, methodName,
            (result) => {
                if (result.Successful) {

                    if (callback !== null) {
                        callback(result.Data);
                    }
                } else {
                    if (errorCallback != null)
                        errorCallback(result);
                }
            },
            (error) => {
                errorCallback(error)
            });
    }
    deleteMessage(data: CreateMessageRequest, callback: any, errorCallback: any) {
        let methodName = "DeleteMessage";
        super.post("/api/Messaging/DeleteMessage", data, methodName,
            (result) => {
                if (result.Successful) {

                    if (callback !== null) {
                        callback(result.Data);
                    }
                } else {
                    if (errorCallback != null)
                        errorCallback(result);
                }
            },
            (error) => {
                errorCallback(error)
            });
    }
    updateMessage(data: CreateMessageRequest, callback: any, errorCallback: any) {
        let methodName = "UpdateMessage";
        super.post("/api/Messaging/UpdateMessage", data, methodName,
            (result) => {
                if (result.Successful) {

                    if (callback !== null) {
                        callback(result.Data);
                    }
                } else {
                    if (errorCallback != null)
                        errorCallback(result);
                }
            },
            (error) => {
                errorCallback(error)
            });
    }

}