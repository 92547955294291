import React from 'react';
import './GreyPaddedWrapper.css'

interface IProps {
    children?: React.ReactNode;
}

export default function GreyPaddedWrapper({ children, ...restProps }: IProps) {
    return (
        <div className="GreyPaddedWrapper" {...restProps}>
            { children }
        </div>
    )
}


