import * as React from "react";
import TableauPanel from "./TableauPanel";
import {
    TreeView,
    processTreeViewItems,
    moveTreeViewItem,
    TreeViewDragAnalyzer,
    TreeViewDragClue
} from "@progress/kendo-react-treeview";
import "@progress/kendo-react-animation";



import ReactTooltip from "react-tooltip";
import MasterContext from '../Misc/MasterContext';


export default class TableauEditor extends React.Component<{}, {}> {
    constructor(props: any) {
        super(props);


        this.addNewItem = this.addNewItem.bind(this);
        this.savedCallBackFunction = this.savedCallBackFunction.bind(this);

        this.cancelCallBackFunction = this.cancelCallBackFunction.bind(this);

        this.deletedCallBackFunction = this.deletedCallBackFunction.bind(this);
    }
    dragClue: any;
    dragOverCnt = 0;
    isDragDrop = false;

    state = {
        backupTree: {},

        tree: MasterContext.TableauService.getReports((res) => {
            this.setState({ tree: res }, () => {
                this.setState({ backupTree: JSON.parse(JSON.stringify(this.state.tree))})
            })
            //this.state.tree = res;
            //this.state.backupTree = JSON.parse(JSON.stringify(this.state.tree));

            this.forceUpdate();}, () => { }),
            //.then((res: any) => {
            //    this.state.tree = res;
            //    this.state.backupTree = JSON.parse(JSON.stringify(this.state.tree));

            //    this.forceUpdate();
            //})
            //.catch(),
        expand: { ids: [], idField: "text" },
        select: { ids: [], idField: "text" },
        tableauID: {
            id: 0,
            data: {
                TableauWorkbook: "",
                UpdateDate: {},
                CreatedDate: {},
                ReportName: "",
                ReportDescription: "",
                TableauView: "",
                ParentId: "",
                ReportId: "",
                ActiveFlag: false
            }
        }
    };

    addNewItem() {
        let old: any = this.state.tree;

        let te = MasterContext.TableauService.buildTableau(
            Math.floor(Math.random() * 100000) * -1,
            MasterContext.translate(
                "tableau_form_newitem"
            ),
            "",
            "",
            "",
            "",
            new Date(),
            "",
            new Date(),
            true,
            0
        );

        old.push(te);
        this.setState({ tree: old });
        this.forceUpdate();



        let ids = [];
        ids.push(te.text);

        this.setState({
            select: { ids, idField: "text" },
            tableauID: te
        });

        this.forceUpdate();
    }

    savedCallBackFunction(data: any) {
        let ctxt = this;
        MasterContext.TableauService.upsertReport(data.data, (res) => {
            ctxt.setState({
                tree: res,
                backupTree: JSON.parse(JSON.stringify(res))
            });
            ctxt.forceUpdate();
        }, () => { });

        //    .then(function (res) {

        //    ctxt.setState({
        //        tree: res,
        //        backupTree: JSON.parse(JSON.stringify(res))
        //    });
        //    ctxt.forceUpdate();
        //});

        this.forceUpdate();
    }

    cancelCallBackFunction(data: any) {
        this.setState({
            tree: JSON.parse(JSON.stringify(this.state.backupTree)),
            tableauID: {
                id: 0,
                data: {
                    TableauWorkbook: "",
                    UpdateDate: {},
                    CreatedDate: {},
                    ReportName: "",
                    ReportDescription: "",
                    TableauView: "",
                    ParentId: "",
                    ReportId: "",
                    ActiveFlag: false
                }
            }
        });
        this.forceUpdate();
    }
    deletedCallBackFunction(data: any) {
        let confirm = true;
        if (confirm
            === true
        ) {
            let ctxt = this;
            MasterContext.TableauService.deleteReport(data.data, (res) => {

                ctxt.setState({
                    tree: res,
                    backupTree: JSON.parse(JSON.stringify(res)),
                    tableauID: {
                        id: 0,
                        data: {
                            TableauWorkbook: "",
                            UpdateDate: {},
                            CreatedDate: {},
                            ReportName: "",
                            ReportDescription: "",
                            TableauView: "",
                            ParentId: "",
                            ReportId: "",
                            ActiveFlag: false
                        }
                    }
                });
            }, () => { })
            //    .then(function (res) {
            //    ctxt.setState({
            //        tree: res,
            //        backupTree: JSON.parse(JSON.stringify(res)),
            //        tableauID: {
            //            id: 0,
            //            data: {
            //                TableauWorkbook: "",
            //                UpdateDate: {},
            //                CreatedDate: {},
            //                ReportName: "",
            //                ReportDescription: "",
            //                TableauView: "",
            //                ParentId: "",
            //                ReportId: "",
            //                ActiveFlag: false
            //            }
            //        }
            //    });
            //});
            this.forceUpdate();
        }
    }

    onItemDragOver = (event: any) => {
        this.dragOverCnt++;
        this.dragClue.show(
            event.pageY + 10,
            event.pageX,
            event.item.text,
            this.getClueClassName(event)
        );
    };

    onItemDragEnd = (event: any) => {
        this.isDragDrop = this.dragOverCnt > 0;
        this.dragOverCnt = 0;
        this.dragClue.hide();

        const eventAnalyzer = new TreeViewDragAnalyzer(event).init();

        if (eventAnalyzer.isDropAllowed && event.item.id !== 0) {
            const updatedTree = moveTreeViewItem(
                event.itemHierarchicalIndex,
                //@ts-ignore
                this.state.tree,
                eventAnalyzer.getDropOperation(),
                eventAnalyzer.destinationMeta.itemHierarchicalIndex
            );

            //@ts-ignore
            this.state.tree.dataItem(event.destinationMeta);

            this.setState({ tree: updatedTree });
            //here you need to send up the whole tree object
            /*if(event.itemHierarchicalIndex){
              let seqArr = event.itemHierarchicalIndex.split("_");
              let ind = seqArr.length - 1;
              if(seqArr.length < 1){
                ind = 0;
              }
              event.item.data.SequenceId = +seqArr[ind]; //parse to int
            }*/

            /*let ctxt = this;
            MasterContext.TableauService.upsertReport(updatedTree).then(function(res){
              ctxt.setState({tree: res});
              ctxt.forceUpdate();
            });*/
        }
    };

    onItemClick = (event: any) => {
        if (!this.isDragDrop && event.item.text !== "Reports") {
            // let ids = this.state.select.ids.slice();
            // const index = ids.indexOf(event.item.text);
            let ids = [];
            //index === -1 ?
            ids.push(event.item.text);

            this.setState({
                select: { ids, idField: "text" },

                tableauID: event.item
            });

            this.forceUpdate();
        }
    };

    onExpandChange = (event: any) => {
        event.item.expanded = !event.item.expanded;
        this.forceUpdate();
    };

    getClueClassName(event: any) {
        const SEPARATOR = "_";
        const eventAnalyzer = new TreeViewDragAnalyzer(event).init();
        const { itemHierarchicalIndex: itemIndex } = eventAnalyzer.destinationMeta;

        if (eventAnalyzer.isDropAllowed) {
            switch (eventAnalyzer.getDropOperation()) {
                case "child":
                    return "k-i-plus";
                case "before":
                    return itemIndex === "0" || itemIndex.endsWith(`${SEPARATOR}0`)
                        ? "k-i-insert-up"
                        : "k-i-insert-middle";
                case "after":
                    const siblings = getSiblings(itemIndex, this.state.tree);
                    const lastIndex = Number(itemIndex.split(SEPARATOR).pop());

                    return lastIndex < siblings.length - 1
                        ? "k-i-insert-middle"
                        : "k-i-insert-down";
                default:
                    break;
            }
        }

        return "k-i-cancel";
    }
    getTableauPanel() {
        let res;
        if (this.state.tableauID.id === 0) {
            res = MasterContext.translate(
                "tableau_blank_panel"
            );
        } else {
            res = (
                <TableauPanel

                    tableauID={this.state.tableauID}

                    savedCallBack={this.savedCallBackFunction}
                    deletedCallBack={this.deletedCallBackFunction}
                    cancelCallBack={this.cancelCallBackFunction}
                    itemsList={this.state.tree}
                ></TableauPanel>
            );
        }

        return res;
    }
    escFunction(event: any) {

    }
    componentDidMount() {
        document.addEventListener("keydown", this.escFunction, false);
    }

    render() {
        let fonting = {
            fontFamily: "Roboto, Tahoma, Geneva, sans-serif",
            fontSize: "14px",
            fontWeight: 400
        };

        return (
            <React.Fragment>
                <div

                    style={fonting}
                >
                    <div style={{ marginLeft: "15px", marginRight: "15px" }}>
                        <div className="row" style={{ marginTop: "10px" }}></div>

                        <div className="row" style={{ marginBottom: "10px" }}>
                            <div className="col-md-3">
                                {" "}
                                <h2>
                                    {MasterContext.translate(
                                        "tableau_title"
                                    )}
                                </h2>
                            </div>
                            <div className="col-md-7"></div>
                            <div className="col-md-2 text-right pull-right">

                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-3">
                                <div className="panel panel-primary">
                                    <div className="panel-heading">
                                        <div
                                            className="row"
                                            style={{
                                                marginRight: "15px",
                                                marginBottom: "-8px"
                                            }}
                                        >
                                            <div className="col-md-11">
                                                <h3 className="panel-title">
                                                    {MasterContext.translate(
                                                        "tableau_form_existinglabel"
                                                    )}
                                                </h3>
                                            </div>
                                            <div className="col-md-1 text right pull-right">
                                                <button
                                                    data-delay-hide="500"
                                                    data-tip={MasterContext.translate(
                                                        "tableau_form_newreportbutton_tt"
                                                    )}
                                                    className="btn btn-primary"
                                                    onClick={this.addNewItem}
                                                    style={{ marginTop: "-6px", borderColor: "#ffffff" }}
                                                >
                                                    {MasterContext.translate("tableau_form_newreportbutton")}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className="panel-body"

                                        style={{
                                            height: "430px",
                                            maxHeight: "430px",

                                            overflowX: "hidden",

                                            overflowY: "auto"
                                        }}
                                    >
                                        <TreeView
                                            draggable={false}
                                            onItemDragOver={this.onItemDragOver}
                                            onItemDragEnd={this.onItemDragEnd}
                                            data={processTreeViewItems(
                                                //@ts-ignore
                                                this.state.tree,
                                                {
                                                    expand: this.state.expand,
                                                    select: this.state.select
                                                }
                                            )}
                                            expandIcons={true}
                                            onExpandChange={this.onExpandChange}
                                            onItemClick={this.onItemClick}
                                        />
                                        <TreeViewDragClue
                                            ref={dragClue => (this.dragClue = dragClue)}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-9">
                                <div className="panel panel-primary">
                                    <div className="panel-heading">
                                        <h3 className="panel-title" style={{ marginBottom: "3px" }}>
                                            {MasterContext.translate(
                                                "tableau_form_detaillabel"
                                            )}
                                        </h3>
                                    </div>
                                    <div className="panel-body" style={{ minHeight: "430px" }}>
                                        {this.getTableauPanel()}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <ReactTooltip />
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

function getSiblings(itemIndex: any, data: any) {
    const SEPARATOR = "_";
    let result = data;

    const indices = itemIndex.split(SEPARATOR).map((index: any) => Number(index));
    for (let i = 0; i < indices.length - 1; i++) {
        result = result[indices[i]].items;
    }

    return result;
}
