import React, { useEffect, useState } from 'react';
import { IRolePermissionTemplate, IReqRolePermission } from '../../../Services/Roles/Roles.service.types';
import RolesService from '../../../Services/Roles/Roles.service';
import { Label } from 'reactstrap';
import SpinnerWithMessage from '../Common/SpinnerWithMessage/SpinnerWithMessage';


import { AgGridReact } from '@ag-grid-community/react';
import {
    ColDef, ColGroupDef, ICellRendererParams
} from '@ag-grid-community/core';
import {
    GridApi, ColumnApi, RowNode, AllCommunityModules, Column, RowDataTransaction, ClientSideRowModelModule, RowGroupingModule
} from '@ag-grid-enterprise/all-modules';
import { CellChangedEvent } from '@ag-grid-community/core/dist/cjs/entities/rowNode';
import { post } from 'jquery';
import gridHelper from '../helpers/grid-helper';

interface IRolePermissionCellRendererParams extends ICellRendererParams {
    data: IRolePermissionTemplate;
    rolePermissions?: IRolePermissionTemplate[];
    masterPermissions?: IRolePermissionTemplate[];
    permissions: IRolePermissionTemplate[];
}


const CheckboxRenderer = (params: IRolePermissionCellRendererParams) => {
    const globalAdminId = 1;
    const checkedHandler = (event) => params.setValue(event.target.checked);

    let hasPermBitOption = false;
    if (params.data) {
        hasPermBitOption = params.colDef.field === 'shallRead' && params.data.hasRead
            || params.colDef.field === 'shallEdit' && params.data.hasEdit
            || params.colDef.field === 'shallDelete' && params.data.hasDelete
            || params.colDef.field === 'shallExecute' && params.data.hasExecute;
    } else {
        return null
    }
    
    if (hasPermBitOption) {
        return (
            <div>
                <input
                    type="checkbox"
                    onClick={checkedHandler}
                    checked={params.value}
                    disabled={params.data.identityId === globalAdminId}
                    />
            </div>
        );
    } else {
        return <></>
    }
}

interface IProps {
    masterPermissions: IRolePermissionTemplate[];
    rolePermissions: IRolePermissionTemplate[];
    roleId: number | null;
}


export default function RolePermissionsFormGrid({ masterPermissions, rolePermissions, roleId }: IProps) {
    const rolesService = new RolesService();
    const [gridApi, setGridApi] = useState<GridApi>(null);
    const [colApi, setColApi] = useState<ColumnApi>(null);
    const [permissions, setPermissions] = useState<IRolePermissionTemplate[]>([]);
    const [colDefs, setColDefs] = useState<(ColDef | ColGroupDef)[]>([
        {
            headerName: "Component",
            field: "component",
            width: 100,
            rowGroup: true,
            hide: true,
        },
        {
            headerName: "Permission",
            field: "permissionName",
            width: 260,
        },
        {
            headerName: "Read",
            field: "shallRead",
            width: 80,
            cellRenderer: "checkboxRenderer",
        },
        {
            headerName: "Edit",
            field: "shallEdit",
            width: 80,
            cellRenderer: "checkboxRenderer",
        },
        {
            headerName: "Delete",
            field: "shallDelete",
            width: 80,
            cellRenderer: "checkboxRenderer",
        },
        // {
        //    headerName: "Execute",
        //    field: "shallExecute",
        //    width: 80,
        //    cellRenderer: "checkboxRenderer",
        // }
    ]);
    const [colDefComponents, setColDefComponents] = useState<object>({
        checkboxRenderer: CheckboxRenderer
    })

    useEffect(() => {
        if (gridApi) {
            gridApi.expandAll();
            gridHelper.shrinkOrExpandColsToFitGrid(gridApi);
        }
    }, [permissions])

    useEffect(() => {
        // Combines the two perm props to form a form template + selections for selected role perms (rolePermissions alone is incomplete)
        let newPermissions = masterPermissions.map(mp => {
            const rolePermBit = rolePermissions.find(p => {
                return p.permissionId === mp.permissionId
            }) ?? null;
            
            if (rolePermBit) {
                // TODO fix the data from backend so that "has" reflects perm option is avail and "shall" reflects perm bit is on or off
                rolePermBit.shallRead = rolePermBit.hasRead
                rolePermBit.shallEdit = rolePermBit.hasEdit
                rolePermBit.shallDelete = rolePermBit.hasDelete
                rolePermBit.shallExecute = rolePermBit.hasExecute

                rolePermBit.hasRead = mp.hasRead
                rolePermBit.hasEdit = mp.hasEdit
                rolePermBit.hasDelete = mp.hasDelete
                rolePermBit.hasExecute = mp.hasExecute

                return rolePermBit
            }

            mp.shallRead = false;
            mp.shallEdit = false; 
            mp.shallDelete = false; 
            mp.shallExecute = false; 

            if (roleId)
                mp.identityId = roleId;

            return mp
        })
        setPermissions(newPermissions)

    }, [masterPermissions, rolePermissions])

    useEffect(() => {
        if (gridApi) {
            gridApi.setColumnDefs(colDefs)
        }
    }, [colDefs])

    const onGridReady = (params: any) => {
        setGridApi(params.api);
        setColApi(params.columnApi)
    }

    const postRolePermission = (data: IRolePermissionTemplate) => {
        let postBody = {
            PermissionId: data.permissionId,
            RoleId: roleId,
            ShallRead: data.shallRead,
            ShallEdit: data.shallEdit,
            ShallDelete: data.shallDelete,
            ShallExecute: data.shallExecute
        } as IReqRolePermission;

        rolesService.setRolePermissions(
            roleId,
            [postBody],
            (res: number) => {
                // TODO toast for success ( on checkbox change/save)
                //console.log('saved', res)
            },
            (err: string) => {
                alert(err);
            },
        )
    }

    const onCellValueChanged = (params: CellChangedEvent) => {
        if (permissions.length > 0 && roleId) {
            postRolePermission(params.node.data)
        }
        let newPermissions = [];
        gridApi.forEachNode((n: RowNode) => {
            if (n.data)
                newPermissions.push(n.data)
        });
        setPermissions(newPermissions);
    }

    return (
        <div>
            <Label>Permissions</Label>
            <div className='ag-theme-balham' >
                <AgGridReact
                    modules={[ClientSideRowModelModule, RowGroupingModule]}
                    onGridReady={onGridReady}
                    onCellValueChanged={onCellValueChanged}
                    domLayout={'autoHeight'}
                    columnDefs={colDefs as ColDef[]}
                    defaultColDef={{
                        resizable: true,
                        width: 80
                    }}
                    autoGroupColumnDef={{
                        headerName: 'Component',
                        minWidth: 180,
                        cellRendererParams: {
                            suppressCount: false,
                        }
                    }}
                    animateRows={true}
                    rowData={permissions}
                    rowModelType={'clientSide'}
                    frameworkComponents={{
                        customLoadingOverlayComponent: SpinnerWithMessage,
                        ...colDefComponents
                    }}
                    loadingOverlayComponent="customLoadingOverlayComponent"
                    loadingOverlayComponentParams={{
                        loadingMessage: 'Loading Permissions...',
                    }}
                />
            </div>
        </div>
    )
}