import IPMOBaseService from '../../Generics/Service/IPMOBaseService'
// import * as types from '../Types/Buydown.model';
import { GetLovsRequest } from '../../Generics/Types/IPMOBaseService.model';

import { IServerSideGetRowsRequest } from '@ag-grid-enterprise/all-modules';

export default class BuydownService extends IPMOBaseService{
    search(data: IServerSideGetRowsRequest, callback: any, errorCallback: any) {
        let methodName = "Buydown Search";
        super.post("/PricingEngineService/Buydown/Search", data, methodName,
            (success) => {
                callback(success.Data, success.TotalCount);
            },
            (error) => {
                errorCallback(error)
            });
    }

    getLovs(getLovsRequest: GetLovsRequest, callback: any, errorCallback: any) {
        let methodName = "Buydown GetLovs";
        super.post("/PricingEngineService/Buydown/GetLovs", getLovsRequest, methodName,
            (success) => {
                callback(success.Data, success.TotalCount);
            },
            (error) => {
                errorCallback(error)
            });
    }

    exportBuydowns(request: IServerSideGetRowsRequest, callback: any, errorCallback: any) {
        let methodName = "Buydown Export";
        super.downloadFilePost("/PricingEngineService/Buydown/Export", request, methodName,
            (success) => {
                callback(success);
            }, (error) => {
                errorCallback(error);
            })
    }
}