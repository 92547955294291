import * as React from "react";
import Moment from "react-moment";
import MasterContext from '../Misc/MasterContext';

export default class TableauPanel extends React.Component<
    {
        tableauID: any;
        savedCallBack: any;
        deletedCallBack: any;
        cancelCallBack: any;
        itemsList: any;
    },
    {}
    > {
    state = {

        tableauID: this.props.tableauID,
        dirty: false
    };

    constructor(props: any) {
        super(props);
        this.handleChangeName = this.handleChangeName.bind(this);
        this.getSaveButton = this.getSaveButton.bind(this);
        this.handleChangeDescription = this.handleChangeDescription.bind(this);
        this.getDeleteButton = this.getDeleteButton.bind(this);
        this.getCancelButton = this.getCancelButton.bind(this);
        this.handleChangeWorkbook = this.handleChangeWorkbook.bind(this);
        this.handleChangeView = this.handleChangeView.bind(this);
        this.handleChangeReportId = this.handleChangeReportId.bind(this);
        this.handleChangeParentId = this.handleChangeParentId.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChangeNull = this.handleChangeNull.bind(this);
        this.handleChangeActive = this.handleChangeActive.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.getParentItmes = this.getParentItmes.bind(this);
    }

    componentDidUpdate(prevProps: any, prevState: any) {
        if (prevState.tableauID !== this.state.tableauID) {
            //    this.reloadGrid();
        }
    }

    static getDerivedStateFromProps(nextProps: any, prevState: any) {
        let ret: any = {};
        if (nextProps.tableauID !== prevState.tableauID) {

            ret.tableauID = nextProps.tableauID;
        }

        return ret;
    }
    handleCancel(event: any) {
        event.preventDefault();


        this.props.cancelCallBack(this.state.tableauID);
        this.setState({ dirty: false });
        this.forceUpdate();
    }
    handleDelete(event: any) {
        event.preventDefault();

        this.props.deletedCallBack(this.state.tableauID);

        this.setState({ dirty: false });
        this.forceUpdate();
    }
    handleSubmit(event: any) {
        event.preventDefault();


        this.props.savedCallBack(this.state.tableauID);
        this.setState({ dirty: false });
        this.forceUpdate();
    }
    handleChangeNull(event: any) { }
    handleChangeDescription(event: any) {
        this.setState({ dirty: true });
        let v = event.target.value;
        let existing = this.state.tableauID;
        existing.data.ReportDescription = v;
        this.setState({ tableauID: existing });
        this.forceUpdate();
    }

    handleChangeActive(event: any) {
        this.setState({ dirty: true });
        let v = event.target.checked;

        let existing = this.state.tableauID;
        existing.data.ActiveFlag = v;
        this.setState({ tableauID: existing });
        this.forceUpdate();
    }

    handleChangeWorkbook(event: any) {
        this.setState({ dirty: true });
        let v = event.target.value;
        let existing = this.state.tableauID;
        existing.data.TableauWorkbook = v;
        this.setState({ tableauID: existing });
        this.forceUpdate();
    }
    handleChangeView(event: any) {
        this.setState({ dirty: true });
        let v = event.target.value;
        let existing = this.state.tableauID;
        existing.data.TableauView = v;
        this.setState({ tableauID: existing });
        this.forceUpdate();
    }

    handleChangeReportId(event: any) {
        this.setState({ dirty: true });
        let v = event.target.value;
        let existing = this.state.tableauID;
        existing.data.ReportId = v;
        this.setState({ tableauID: existing });
        this.forceUpdate();
    }

    handleChangeParentId(event: any) {
        this.setState({ dirty: true });
        let v = event.target.value;
        let existing = this.state.tableauID;
        existing.data.ParentId = v;
        this.setState({ tableauID: existing });
        this.forceUpdate();
    }

    handleChangeName(event: any) {
        this.setState({ dirty: true });
        let v = event.target.value;
        let existing = this.state.tableauID;
        existing.text = v;
        existing.data.ReportName = v;
        this.setState({ tableauID: existing });
        this.forceUpdate();
    }

    getDeleteButton() {
        let retVal;

        if (this.state.tableauID.data.ReportName !== "") {
            retVal = (
                <button onClick={this.handleDelete} className="btn btn-danger">
                    {MasterContext.translate("tableau_form_deletebutton")}

                </button>
            );
        }
        return retVal;
    }

    getCancelButton() {
        let retVal;
        if (this.state.dirty === true) {
            retVal = (
                <button onClick={this.handleCancel} className="btn btn-danger">
                    {MasterContext.translate("tableau_form_cancelbutton")}

                </button>
            );
        }
        return retVal;
    }

    getSaveButton() {
        let retVal;
        if (this.state.dirty === true) {
            retVal = (
                <button onClick={this.handleSubmit} className="btn btn-primary">
                    {MasterContext.translate("tableau_form_savebutton")}
                </button>
            );
        }

        return retVal;
    }
    getParentItmes(ref: any): any {
        let retVal = [];
        for (let itm of ref) {
            if (
                itm.data.TableauWorkbook === null ||
                itm.data.TableauWorkbook.length === 0
            ) {
                retVal.push(
                    <option value={itm.data.ReportId}>{itm.data.ReportName}</option>
                );
            }
            if (itm.items !== null && itm.items !== undefined && itm.items.length > 0) {
                let tempArr = this.getParentItmes(itm.items);
                for (let ret of tempArr) {

                    retVal.push(ret);
                }
            }
        }
        return retVal;
    }
    render() {
        let formStyle = {
            height: "400px",
            maxHeight: "400px",
            border: "none"
        };
        let inputStyle = {
            width: "350px"
        };
        let inputStyle2 = {
            width: "350px",
            height: "130px"
        };
        let cbStyle = {
            posision: "absolute",
            opacity: 0,
            cursor: "pointer"
        };

        return (
            <React.Fragment>
                <form
                    className="form-horizontal"
                    style={formStyle}
                //onSubmit={this.handleSubmit}
                >
                    <div className="panel panel-default" style={{ border: "none" }}>
                        <div className="panel-body" style={{ border: "none" }}>
                            <div className="row form-group" style={{ border: "none" }}>
                                <div className="col-md-6">
                                    <div>
                                        <div className="row form-group">
                                            <div className="col-md-3 text-right">
                                                <span style={inputStyle}>
                                                    {MasterContext.translate("tableau_form_name")}

                                                </span>
                                            </div>
                                            <div className="col-md-9">
                                                <input
                                                    maxLength={100}

                                                    style={inputStyle}
                                                    type="text"
                                                    value={this.state.tableauID.data.ReportName}
                                                    onChange={this.handleChangeName}
                                                    className="form-control"
                                                    placeholder={MasterContext.translate(
                                                        "tableau.form.nameplaceholder"
                                                    )}
                                                    aria-describedby="basic-addon1"
                                                ></input>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div>
                                        <div className="row form-group">
                                            <div className="col-md-3  text-right">
                                                <span style={inputStyle}>
                                                    {MasterContext.translate("tableau_form_workbook")}
                                                </span>
                                            </div>
                                            <div className="col-md-9">
                                                <input
                                                    maxLength={255}

                                                    style={inputStyle}
                                                    type="text"
                                                    value={this.state.tableauID.data.TableauWorkbook}
                                                    onChange={this.handleChangeWorkbook}
                                                    className="form-control"
                                                    placeholder={MasterContext.translate(
                                                        "tableau.form.workbookplaceholder"
                                                    )}
                                                    aria-describedby="basic-addon1"
                                                ></input>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row form-group" style={{ marginTop: "-20px" }}>
                                <div className="col-md-6">
                                    <div>
                                        <div className="row form-group">
                                            <div className="col-md-3  text-right">
                                                <span style={inputStyle}>
                                                    {MasterContext.translate("tableau_form_description")}
                                                </span>
                                            </div>
                                            <div className="col-md-9">
                                                <textarea
                                                    maxLength={5000}

                                                    style={inputStyle2}
                                                    value={this.state.tableauID.data.ReportDescription}
                                                    onChange={this.handleChangeDescription}
                                                    className="form-control"
                                                    placeholder={MasterContext.translate(
                                                        "tableau.form.descriptionplaceholder"
                                                    )}
                                                    aria-describedby="basic-addon1"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div>
                                        <div className="row form-group">
                                            <div className="col-md-3  text-right">
                                                <span style={inputStyle}>
                                                    {MasterContext.translate("tableau_form_view")}
                                                </span>
                                            </div>
                                            <div className="col-md-9">
                                                <input
                                                    maxLength={255}

                                                    style={inputStyle}
                                                    type="text"
                                                    value={this.state.tableauID.data.TableauView}
                                                    onChange={this.handleChangeView}
                                                    className="form-control"
                                                    placeholder={MasterContext.translate(
                                                        "tableau.form.viewplaceholder"
                                                    )}
                                                    aria-describedby="basic-addon1"
                                                ></input>
                                            </div>
                                        </div>
                                        <div className="row form-group">
                                            <div className="col-md-3  text-right">
                                                <span style={inputStyle}>
                                                    {MasterContext.translate("tableau_form_parentid")}
                                                </span>
                                            </div>
                                            <div className="col-md-9">
                                                <select
                                                    value={this.state.tableauID.data.ParentId}
                                                    onChange={this.handleChangeParentId}
                                                >
                                                    {this.getParentItmes(this.props.itemsList)}
                                                    {/* <option value="0">Root</option>
                          <option value="2">Promotions</option> */}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="row form-group">
                                            <div className="col-md-3 text-right">
                                                <span style={inputStyle}>
                                                    {MasterContext.translate("tableau_form_reportid")}
                                                </span>
                                            </div>
                                            <div className="col-md-9">
                                                <input

                                                    disabled
                                                    maxLength={255}

                                                    style={inputStyle}
                                                    type="text"
                                                    value={this.state.tableauID.data.ReportId}
                                                    onChange={this.handleChangeReportId}
                                                    className="form-control"
                                                    placeholder={MasterContext.translate(
                                                        "tableau.form.reportidplaceholder"
                                                    )}
                                                    aria-describedby="basic-addon1"
                                                ></input>
                                            </div>
                                        </div>
                                        <div className="row form-group">
                                            <div className="col-md-3 text-right">
                                                <div>
                                                    <span>
                                                        {MasterContext.translate("tableau_form_isactive")}
                                                    </span>
                                                </div>
                                            </div>
                                            <div
                                                className="col-md-9"
                                                style={{ paddingRight: "120px" }}
                                            >
                                                <div>
                                                    &nbsp;
                          <label className="pull-right checkbox-label">
                                                        <input
                                                            style={cbStyle}
                                                            type="checkbox"
                                                            checked={this.state.tableauID.data.ActiveFlag}
                                                            onChange={this.handleChangeActive}
                                                        ></input>
                                                        <span className="checkbox-custom rectangular"></span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row form-group">
                                            <div className="col-md-3 ">
                                                <div></div>
                                            </div>
                                            <div
                                                className="col-md-9 text-right"
                                                style={{ paddingRight: "120px" }}
                                            >
                                                <div>
                                                    <span>
                                                        {MasterContext.translate("tableau_form_updatedby")}
{" "}
                                                        {this.state.tableauID.data.UpdatedBy}
                                                    </span>
                          &nbsp;
                          <span>
                                                        {MasterContext.translate("tableau_form_updatedon")}

                            &nbsp;
                          </span>
                                                    <span>
                                                        <Moment
                                                            date={this.state.tableauID.data.UpdatedDate}
                                                            format="MM/DD/YYYY"
                                                        ></Moment>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row  form-group">
                                            <div className="col-md-3 ">
                                                <div></div>
                                            </div>
                                            <div
                                                className="col-md-9 text-right"
                                                style={{ paddingRight: "120px" }}
                                            >
                                                <div>
                                                    <span>
                                                        {MasterContext.translate("tableau_form_createdby")}

                                                        {this.state.tableauID.data.CreatedBy}
                                                    </span>
                          &nbsp;
                          <span>
                                                        {MasterContext.translate("tableau_form_createdon")}

                            &nbsp;
                          </span>
                                                    <span>
                                                        <Moment
                                                            date={this.state.tableauID.data.CreatedDate}
                                                            format="MM/DD/YYYY"
                                                        ></Moment>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row form-group">
                                <div className="col-md-6">
                                    <div className="pull-left">{this.getDeleteButton()}</div>
                                </div>
                                <div className="col-md-6">
                                    <div className="pull-right">
                                        {this.getCancelButton()}
                    &nbsp;
                    {this.getSaveButton()}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </React.Fragment>
        );
    }
}
