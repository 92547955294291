import HomeMessage from '../Types/home.model';
import IPMOBaseService from '../../Generics/Service/IPMOBaseService'
import * as types from '../../Login/Types/Login.model';
export default class HomeService extends IPMOBaseService {
    getHomeMessages(data: types.LoginToken,callback: any, errorCallback: any) {
        let methodName = "Get Home Messages";
        super.post("/api/Home/CheckMessages",data, methodName,
            (result) => {
                for (let msg of result.Data) {
                    msg.read = true;
                }
                callback(result as HomeMessage);
            },
            (error) => {
                errorCallback(error)
            });
    }
    confirmHomeMessages(data: any, callback: any, errorCallback: any) {
        let methodName = "Confirm Home Messages";
        super.post("/api/Home/ConfirmMessages", data, methodName,
            (result) => {
                callback(result);
            },
            (error) => {
                errorCallback(error)
            });
    }

    
    
}