import * as React from "react";
import { ColDef } from '@ag-grid-community/all-modules';

export interface IProps {
    api: any;
    color: string;
    rowIndex: number;
    colDef: ColDef;
}

export interface IState {
}

export default class TaskCustomTooltip extends React.Component<IProps, IState> {
    // Constructor
    constructor(props: IProps) {
        super(props);

        this.state = {
        };
    }

    getReactContainerClasses() {
        return ['custom-tooltip'];
    }

    render() {
        const data = this.props.api.getDisplayedRowAtIndex(this.props.rowIndex).data;
        return (
            <div
                className="custom-tooltip"
                style={{ backgroundColor: this.props.color || 'white' }}
            >
                <p>
                    <span><strong>Product: </strong></span>{data.ProductNodeId}&nbsp;{data.ProductNodeDesc}
                </p>
                <p>
                    <span><strong>Location: </strong></span>{data.LocationNodeId}&nbsp;-&nbsp;{data.LocationNodeDesc}
                </p>
            </div>
        );
    }
}

