export class AdminAuditRequest {
    Starting: Date = new Date();
    Ending: Date = new Date();
}

export class AdminAuditResponse {
    AuditID: number = 0;
    AuditTimeStamp: Date = new Date();
    UserName: string = "";
    EventData: string = "";
}
