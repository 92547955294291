import * as React from 'react';
import LoginService from '../../../Services/Login/Service/Login.service';
import * as types from '../../../Services/Login/Types/Login.model'
import Spinner from '../../React/Misc/Spinner';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import MasterContext from '../Misc/MasterContext';


export interface IProps {
}
export interface IState {
    userName: string;
    password: string;

    registerUserName: string;
    registerFirstName: string;
    registerLastName: string;
    registerCompanyName: string;
    registerEmailAddress: string;
    registerPhoneNumber: string;
    registrationValidationErrors: any[],
    loaded: boolean;
    showRegisteredModal: boolean;
    isUpdating:boolean



}

export default class UserProfile extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            userName: '',
            password: '',
            registerUserName: '',
            registerFirstName: '',
            registerLastName: '',
            registerCompanyName: '',
            registerEmailAddress: '',
            registerPhoneNumber: '',
            registrationValidationErrors: [],
            loaded: false,
            showRegisteredModal: false,
            isUpdating:false

        };

        //bind events
        this.handleUserNameChange = this.handleUserNameChange.bind(this);
        this.handlePasswordChange = this.handlePasswordChange.bind(this);
        this.saveChanges = this.saveChanges.bind(this);
        this.startLoad = this.startLoad.bind(this);
        this.finishLoad = this.finishLoad.bind(this);

        this.handleFirstNameChanged = this.handleFirstNameChanged.bind(this);
        this.handleLastNameChanged = this.handleLastNameChanged.bind(this);


        this.handleCompanyNameChange = this.handleCompanyNameChange.bind(this);
        this.handleEmailAddressChange = this.handleEmailAddressChange.bind(this);
        this.handleRegisterUserNameChange = this.handleRegisterUserNameChange.bind(this);
        this.handlePhoneNumberChange = this.handlePhoneNumberChange.bind(this);

        this.validateRegistration = this.validateRegistration.bind(this);
        this.toggleRegisteredModal = this.toggleRegisteredModal.bind(this);

        this.getUserData = this.getUserData.bind(this);
        this.getUpdateButton = this.getUpdateButton.bind(this);


    }
    componentDidMount() {
        this.getUserData();
    }
    getUserData() {
        let LS: LoginService = new LoginService();
        //let RT: types.RegistrationToken = new types.RegistrationToken();
        //RT.UserName = MasterContext.UserData.userName;

        this.startLoad();

        LS.getUserData(MasterContext.UserData.userName,
            (res) => {
                if (res.EmailAddress === null) {
                    res.EmailAddress = '';
                }
                this.setState(
                    {
                        registerUserName: res.UserName,
                        registerEmailAddress: res.EmailAddress,
                        registerCompanyName: res.CompanyName,
                        registerFirstName: res.FirstName,
                        registerLastName: res.LastName,
                        registerPhoneNumber: res.PhoneNumber                      

                    }
                );
                this.finishLoad();


                console.log(res)
            },
            (res) => { }

        )
    }
    getUpdateButton() {
        let retVal: any = <i />;
        if (this.state.isUpdating === false) {
            retVal = <input type="submit" name="submit" onClick={this.saveChanges} className="btn loginColorsButton btn-md" value={MasterContext.translate("user_profile_update_button")} />

        }
        return retVal
    }

    toggleRegisteredModal() {
        this.setState({ showRegisteredModal: !this.state.showRegisteredModal });
    }
    startLoad() {
        this.setState({ loaded: true });
    }

    finishLoad() {
        this.setState({ loaded: false });
    }

    
    validateRegistration(): boolean {
        let retVal: boolean = false;

        let newErrors: any[] = [];
        //do examination of the this.state.firstName value and see if it has any problems
        if (this.state.registerFirstName.length === 0) {
            let newErr: any = {};
            newErr.controlName = 'firstName';
            newErr.error = MasterContext.translate('validation_user_profile_fn');
            newErr.severity = 1;
            newErrors.push(newErr);
            retVal = true;
        }

        //do examination of the this.state.firstName value and see if it has any problems
        if (this.state.registerLastName.length === 0) {
            let newErr: any = {};
            newErr.controlName = 'lastName';
            newErr.error = MasterContext.translate('validation_user_profile_ln');
            newErr.severity = 1;
            newErrors.push(newErr);
            retVal = true;
        }

        if (this.state.registerCompanyName.length === 0) {
            let newErr: any = {};
            newErr.controlName = 'registerCompanyName';
            newErr.error = MasterContext.translate('validation_user_profile_cn');
            newErr.severity = 1;
            newErrors.push(newErr);
            retVal = true;
        }

        if (this.state.registerUserName.length === 0) {
            let newErr: any = {};
            newErr.controlName = 'registerUserName';
            newErr.error = MasterContext.translate('validation_user_profile_un');
            newErr.severity = 1;
            newErrors.push(newErr);
            retVal = true;
        }

        if (this.state.registerEmailAddress.length === 0) {
            let newErr: any = {};
            newErr.controlName = 'registerEmail';
            newErr.error = MasterContext.translate('validation_user_profile_email');
            newErr.severity = 1;
            newErrors.push(newErr);
            retVal = true;
        }



        if (this.state.registerPhoneNumber.length === 0) {
            let newErr: any = {};
            newErr.controlName = 'registerPhoneNumber';
            newErr.error = MasterContext.translate('validation_user_profile_phone');
            newErr.severity = 1;
            newErrors.push(newErr);
            retVal = true;
        }
        else {
            if (MasterContext.PHONEREGEX.test(this.state.registerPhoneNumber) === false) {
                let newErr: any = {};
                newErr.controlName = 'registerPhoneNumber';
                newErr.error = MasterContext.translate('validation_user_profile_phone_invalid');
                newErr.severity = 1;
                newErrors.push(newErr);
                retVal = true;
            }

        }

        this.setState({ registrationValidationErrors: newErrors });
        return retVal;


    }

    handleCompanyNameChange(event: any) {
        let v = event.target.value;
        this.setState({ registerCompanyName: v }, () => { this.validateRegistration()});
    }
    handleEmailAddressChange(event: any) {
        let v = event.target.value;
        this.setState({ registerEmailAddress: v }, () => { this.validateRegistration() });
    }
    handleRegisterUserNameChange(event: any) {
        let v = event.target.value;
        this.setState({ registerUserName: v }, () => { this.validateRegistration() });
    }
    handlePhoneNumberChange(event: any) {
        let v = event.target.value;
        this.setState({ registerPhoneNumber: v }, () => { this.validateRegistration() });
    }
    handleFirstNameChanged(event: any) {
        let v = event.target.value;
        this.setState({ registerFirstName: v }, () => { this.validateRegistration() });
    }
    handleLastNameChanged(event: any) {
        let v = event.target.value;
        this.setState({ registerLastName: v }, () => { this.validateRegistration() });
    }
    handleUserNameChange(event: any) {
        let v = event.target.value;
        this.setState({ userName: v }, () => { this.validateRegistration() });
    }
    handlePasswordChange(event: any) {
        let v = event.target.value;
        this.setState({ password: v }, () => { this.validateRegistration() });
    }

    getMyValidationErrors(control: string): string {
        let retVal: string = '';
        if (this.state.registrationValidationErrors) {
            for (let err of this.state.registrationValidationErrors) {
                if (err.controlName === control) {
                    retVal = retVal + err.error + '\r\n';
                }
            }
        }
        return retVal;
    }

    saveChanges() {
        if (this.validateRegistration() === false) {
            this.setState({isUpdating:true});
            let LS: LoginService = new LoginService();
            let RT: types.RegistrationToken = new types.RegistrationToken();
            RT.FirstName = this.state.registerFirstName;
            RT.LastName = this.state.registerLastName;
            RT.CompanyName = this.state.registerCompanyName;
            RT.UserName = this.state.registerUserName;
            RT.EmailAddress = this.state.registerEmailAddress;
            RT.PhoneNumber = this.state.registerPhoneNumber;
            this.startLoad();

            LS.updateUser(RT,
                (res) => {
                    if (res.RegistrationResultStatus === 1) {
                        this.toggleRegisteredModal();
                        this.finishLoad();
                        this.setState({ isUpdating: false });
                    }
                    //console.log(res)
                },
                (res) => {
                    this.setState({ isUpdating: false });
                }

            )
        }
    }
    render() {
        let errorClass = { color: "#ff0000" };

        return (
            <React.Fragment>
                <Spinner
                    spinnerActive={this.state.loaded}
                    heightSet="99vh"
                    paddingSet="10%"
                    spinnerText={MasterContext.translate("spinner_updating_your_profile")}
                />
                <Modal zIndex={3002} isOpen={this.state.showRegisteredModal}>
                    <ModalHeader>{MasterContext.translate("login_registration_success_modal_header_update")}</ModalHeader>
                    <ModalBody>
                        {MasterContext.translate("login_registration_success_modal_body_update")}
                    </ModalBody>
                    <ModalFooter>
                        <button className="btn btn-sm btn-outline-info" color="primary" onClick={() => this.toggleRegisteredModal()}>{MasterContext.translate("login_registration_success_modal_ok")}</button>{' '}

                    </ModalFooter>
                </Modal>
                <div className="row">
                    <div className="col-md-4"></div>
                    <div className="col-md-4">
                        <div className='container card'>

                            <h3 className="text-center text-info">{MasterContext.translate("user_profile_header")}</h3>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="alert alert-secondary" role="alert">
                                        {MasterContext.translate("user_profile_header_body")}   
                                    </div>

                                </div>
                            </div>
                            <div className="form-group">
                                <div className="row">
                                    <div className="col-md-3">
                                        <label className="loginColors">{MasterContext.translate("user_profile_first_name")}  </label>
                                    </div>
                                    <div className="col-md-9">
                                        <input placeholder={MasterContext.translate("user_profile_placeholder_first_name")} type="text" id="firstName" value={this.state.registerFirstName} onChange={this.handleFirstNameChanged} className="form-control" />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-3">
                                    </div>
                                    <div className="col-md-9">
                                        <span className="pull-right" style={errorClass}>{this.getMyValidationErrors('firstName')}</span>
                                    </div>
                                </div>



                            </div>
                            <div className="form-group">
                                <div className="row">
                                    <div className="col-md-3">
                                        <label className="loginColors">{MasterContext.translate("user_profile_last_name")} </label>
                                    </div>
                                    <div className="col-md-9">
                                        <input placeholder={MasterContext.translate("user_profile_placeholder_last_name")} type="text" id="lastName" value={this.state.registerLastName} onChange={this.handleLastNameChanged} className="form-control" />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-3">
                                    </div>
                                    <div className="col-md-9">
                                        <span className="pull-right" style={errorClass}>{this.getMyValidationErrors('lastName')}</span>
                                    </div>
                                </div>



                            </div>
                            <div className="form-group">
                                <div className="row">
                                    <div className="col-md-3">
                                        <label className="loginColors">{MasterContext.translate("user_profile_company")} </label>
                                    </div>
                                    <div className="col-md-9">
                                        <input placeholder={MasterContext.translate("user_profile_placeholder_company_name")} type="text" id="registerCompanyName" value={this.state.registerCompanyName} onChange={this.handleCompanyNameChange} className="form-control" />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-3">
                                    </div>
                                    <div className="col-md-9">
                                        <span className="pull-right" style={errorClass}>{this.getMyValidationErrors('registerCompanyName')}</span>
                                    </div>
                                </div>



                            </div>
                            <div className="form-group">
                                <div className="row">
                                    <div className="col-md-3">
                                        <label className="loginColors">{MasterContext.translate("user_profile_username")} </label>
                                    </div>
                                    <div className="col-md-9">
                                        <input placeholder={MasterContext.translate("user_profile_placeholder_user_name")} disabled type="text" id="registerUserName" value={this.state.registerUserName} className="form-control" />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-3">

                                    </div>
                                    <div className="col-md-9">
                                        <span className="pull-right" style={errorClass}>{this.getMyValidationErrors('registerUserName')}</span>
                                    </div>
                                </div>



                            </div>
                            <div className="form-group">
                                <div className="row">
                                    <div className="col-md-3">
                                        <label className="loginColors">{MasterContext.translate("user_profile_email")} </label>
                                    </div>
                                    <div className="col-md-9">
                                        <input placeholder={MasterContext.translate("user_profile_placeholder_email_address")} disabled type="text" id="registerEmail" value={this.state.registerEmailAddress} className="form-control" />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-3">
                                    </div>
                                    <div className="col-md-9">
                                        <span className="pull-right" style={errorClass}>{this.getMyValidationErrors('registerEmail')}</span>
                                    </div>
                                </div>



                            </div>

                            <div className="form-group">
                                <div className="row">
                                    <div className="col-md-3">
                                        <label className="loginColors">{MasterContext.translate("user_profile_phone_number")}</label>
                                    </div>
                                    <div className="col-md-9">
                                        <input placeholder={MasterContext.translate("user_profile_placeholder_phone_number")} type="text" id="registerPhoneNumber" value={this.state.registerPhoneNumber} onChange={this.handlePhoneNumberChange} className="form-control" />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-3">
                                    </div>
                                    <div className="col-md-9">
                                        <span className="pull-right" style={errorClass}>{this.getMyValidationErrors('registerPhoneNumber')}</span>
                                    </div>
                                </div>



                            </div>
                            <div className="form-group">
                                {this.getUpdateButton()}
                            </div>
                        </div></div>
                    <div className="col-md-4"></div>
                </div>



            </React.Fragment>
        );
    }
}