import React, { useState, useEffect, SetStateAction } from 'react';
import { Link } from 'react-router-dom';

import UsersService from '../../../Services/Users/Users.service';
import { IUser, IReqUser } from '../../../Services/Users/Users.service.types';

import dateHelper from '../helpers/date-helper';
import gridHelper from '../helpers/grid-helper';


import { AgGridReact } from '@ag-grid-community/react';
import { ColDef, ColGroupDef, ICellRendererParams } from '@ag-grid-community/core';


import {
    GridApi,
    ColumnApi,
    ClientSideRowModelModule,
    SetFilterModule,
    ClipboardModule,
    ExcelExportModule,
    GridChartsModule,
    MenuModule,
} from '@ag-grid-enterprise/all-modules';
import '@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css';
import '@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css';


import SpinnerWithMessage from '../Common/SpinnerWithMessage/SpinnerWithMessage';
import { Button, Input } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinusCircle, faPen, faTrashCan, faUser, } from '@fortawesome/free-solid-svg-icons';

interface IUserCellRendererParams extends ICellRendererParams {
    data: IUser;
    setEditRolesForId: React.Dispatch<SetStateAction<number | null>>;
    setEditUserForId: React.Dispatch<SetStateAction<number | null>>;
    saveUser?: React.Dispatch<SetStateAction<IUser | null>>;
    deleteUser?: React.Dispatch<SetStateAction<number>>;
    setUsers?: React.Dispatch<SetStateAction<IUser[]>>;
}

const EditRolesButtonRenderer = (params: IUserCellRendererParams) => {
    return (
        <Link
            to="#"
            onClick={() => {
                params.setEditRolesForId(params.data.Id);
            }}
            color="link"
        >
            <FontAwesomeIcon icon={faPen} />
            {' '}Roles
        </Link>
    )
}

const EditUserButtonRenderer = (params: IUserCellRendererParams) => {
    return (
        <Link
            to="#"
            onClick={() => {
                params.setEditUserForId(params.data.Id);
            }}
            color="link"
        >
            <FontAwesomeIcon icon={faUser} />
            {' '}Edit
        </Link>
    )
}

const DeleteUserButtonRenderer = (params: IUserCellRendererParams) => {
    return (
        <Link
            to="#"
            onClick={() => {
                const newUsers = [];
                
                if (params.deleteUser) {
                    if (window.confirm('Are you sure you wish to delete user '+params.data.Name+'?')){
                        params.deleteUser(params.data.Id);
                        params.api.forEachNode(n => {
                            if (n.data.Id !== params.data.Id)
                                newUsers.push(n.data)
                        });
                        if (params.setUsers) {
                            params.setUsers(newUsers)
                        }
                    }
                }
            }}
            color="link"
            className="text-danger"
        >
            <FontAwesomeIcon icon={faTrashCan} />
        </Link>
    )
}

const CheckboxRenderer = (props: IUserCellRendererParams) => {
    const checkedHandler = (event) => {
        let checked = event.target.checked;
        let fieldName = props.colDef.field;
        props.setValue(checked);
        if (props.saveUser && (fieldName === 'IsApproved' || fieldName === 'IsLockedOut')) {
            props.saveUser(props.data);
        }

    };
    return (
        <div>
            <input
                type="checkbox"
                onClick={checkedHandler}
                checked={props.value}
            />
        </div>

    );
}

interface IProps {
    users: IUser[];
    setUsers: React.Dispatch<SetStateAction<IUser[]>>;
    setEditRolesForId: React.Dispatch<SetStateAction<number | null>>;
    setEditUserForId: React.Dispatch<SetStateAction<number | null>>;
    setAddUserModal: React.Dispatch<SetStateAction<boolean>>;
}

export default function UsersGrid({ users, setEditRolesForId, setEditUserForId, setUsers }: IProps) {
    const usersService = new UsersService();

    const [gridApi, setGridApi] = useState<GridApi>(null);
    const [colApi, setColApi] = useState<ColumnApi>(null);

    const [colDefs, setColDefs] = useState<(ColDef | ColGroupDef)[]>([
        {
            headerName: "Delete",
            field: "RemoveUser",
            width: 80,
            cellRenderer: "deletUserButtonRenderer",
            cellRendererParams: {
                deleteUser: deleteUser,
                setUsers: setUsers
            }
        },
        {
            headerName: "Approved",
            field: "IsApproved",
            minWidth: 95,
            cellRenderer: "checkboxRenderer",
            cellRendererParams: {
                saveUser: saveUser
            }
        },
        {
            headerName: "Locked Out",
            field: "IsLockedOut",
            minWidth: 95,
            cellRenderer: "checkboxRenderer",
            cellRendererParams: {
                saveUser: saveUser
            }
        },
        {
            headerName: "User Name",
            field: "Name",
            minWidth: 110,
            sortable: true,
            filter: "agTextColumnFilter"
        },
        {
            headerName: "First Name",
            field: "FirstName",
            width: 120,
            sortable: true,
            filter: "agTextColumnFilter"
        },
        {
            headerName: "Last Name",
            field: "LastName",
            width: 120,
            sortable: true,
            filter: "agTextColumnFilter"
        },
        {
            headerName: "Company",
            field: "Company",
            width: 140,
            sortable: true,
            filter: "agTextColumnFilter"
        },
        {
            headerName: "Roles",
            field: "Roles",
            width: 140,
            sortable: true,
            cellRenderer: (params: IUserCellRendererParams) => {
                return `(${params.data.Roles.length}) ${params.data.Roles.map(r => r.DisplayName).join(', ')}`;
            },
            filter: "agTextColumnFilter"
        },
        {
            headerName: "Departments",
            field: "Departments",
            width: 140,
            sortable: true,
            filter: "agTextColumnFilter"
        },
        {
            headerName: "Phone",
            field: "PhoneNumber",
            width: 100,
        },
        {
            headerName: "Email",
            field: "Email",
            width: 140,
            filter: "agTextColumnFilter"
        },
        {
            headerName: "Last Modified",
            field: "LastModifiedDate",
            width: 110,
            cellRenderer: (params: IUserCellRendererParams) => {
                return dateHelper.isoStringToMMDDYYYY(params.data.LastModifiedDate);
            },
            sortable: true,
        },
        {
            headerName: "Last Login",
            field: "LastLoginDate",
            width: 110,
            cellRenderer: (params: IUserCellRendererParams) => {
                return params.data.LastLoginDate ? dateHelper.isoStringToMMDDYYYY(params.data.LastLoginDate) : "";
            },
            sortable: true,
        },
        {
            headerName: "",
            field: "EditRoles",
            cellRenderer: 'editRolesButtonRenderer',
            cellRendererParams: {
                setEditRolesForId: setEditRolesForId
            },
            width: 110,
        },
        {
            headerName: "",
            field: "EditUser",
            cellRenderer: 'editUserButtonRenderer',
            cellRendererParams: {
                setEditUserForId: setEditUserForId
            },
            width: 110,
        },
    ]);
    const [colDefComponents, setColDefComponents] = useState<object>({
        editRolesButtonRenderer: EditRolesButtonRenderer,
        checkboxRenderer: CheckboxRenderer,
        editUserButtonRenderer: EditUserButtonRenderer,
        deletUserButtonRenderer: DeleteUserButtonRenderer
    })

    useEffect(() => {
        if (gridApi) {
            gridApi.setColumnDefs(colDefs);
            gridHelper.shrinkOrExpandColsToFitGrid(gridApi)
        }
    }, [colDefs, colApi]);

    const onGridReady = (params: any) => {
        setGridApi(params.api);
        setColApi(params.columnApi)
    }

    // must be accessable from above coldefs cellrenderer params
    function saveUser(data: IUser | null) {

        const postBody = {
            Id: data.Id,
            Name: data.Name,
            FirstName: data.FirstName,
            LastName: data.LastName,
            Email: data.Email,
            PhoneNumber: data.PhoneNumber,
            Company: data.Company,
            NewPassword: '',
            IsApproved: data.IsApproved, // potential field being patched
            IsLockedOut: data.IsLockedOut, // potential field being patched
        } as IReqUser;

        usersService.saveUser(
            postBody,
            (res) => {
                console.log('user saved')
            },
            (err) => { alert(err) }
        )
    }

    function deleteUser(id: number) {
        usersService.deleteUserById(
            id,
            (res) => {
                console.log('user deleted')
                //debugger
                //const newUsers = users.filter(u => u.Id === id)
                //setUsers(newUsers);
                //console.log(res.Data);
            },
            (err) => alert(err)
        )
    }

    return (
        <div>
            <div className='ag-theme-balham' style={{ width: '100%', height: '700px' }}>
                <AgGridReact
                    modules={[
                        ClientSideRowModelModule,
                        SetFilterModule,
                        ClipboardModule,
                        ExcelExportModule,
                        GridChartsModule,
                        MenuModule,
                    ]}
                    onGridReady={onGridReady}
                    columnDefs={colDefs as ColDef[]}

                    defaultColDef={{
                        resizable: true,
                        width: 80
                    }}
                    rowData={users}
                    rowModelType="clientSide"
                    frameworkComponents={{
                        customLoadingOverlayComponent: SpinnerWithMessage,
                        ...colDefComponents
                    }}
                    loadingOverlayComponent="customLoadingOverlayComponent"
                    loadingOverlayComponentParams={{
                        loadingMessage: 'Loading Users...',
                    }}
                />
            </div>
        </div>
    )
}