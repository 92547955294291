import { urlConfig } from "../../Misc/dataLayer.config";
import moment from 'moment';
import MasterContext from '../../../Components/React/Misc/MasterContext';

export default class IPMOBaseService {
    houseKeeping() {       
    }
    startRequest(methodData: string, logData: any) {
        let nowDate = moment();
        if (methodData === 'Login' || methodData === 'Tableau Get Reports') {
        }
        else {
            let lastCheck;
            try {
                lastCheck = moment(localStorage.getItem('lastOp'));
            }
            catch
            {
                lastCheck = moment();
            }

            if (nowDate.diff(lastCheck) >= MasterContext.ClientTimeoutValue) {
                localStorage.removeItem("cachedUser");
                localStorage.removeItem("navigationPermissions");
                localStorage.removeItem("clientTimeoutValue");
                localStorage.setItem('FLO','1');
                MasterContext.UserData = { userName: '', navigationPermissions: []};
                //alert('Sorry, but you will now be logged out due to inactivity.');
                window.location.reload();
            }
        }
        localStorage.setItem("lastOp", nowDate.toString());
        // if (urlConfig.debugRequests === true && window.location.hostname === 'localhost') {
        //     console.log(new Date().toTimeString() + " - Request Data for " + methodData, logData);
        // }
    }
    endRequest(methodData: string, logData: any) {
         if (urlConfig.debugResponses === true && window.location.hostname === 'localhost') {
            console.log(new Date().toTimeString() + " - Response Data for " + methodData, logData);
        }
    }
    handleError(methodData: string, logData: any) {
        if (urlConfig.debugRequests === true && window.location.hostname === 'localhost') {
            console.log(new Date().toTimeString() + " -  Service Tier Error " + methodData, logData);
        }
    }
    put(endpoint: string, data: any, methodName: string, callback: any, errorCallback: any) {
        this.houseKeeping();
        //debugger;
        this.startRequest(methodName, data);
        var token = localStorage.getItem("userToken");
        let head: any = { 'Content-Type': 'application/json' };
        if (token != null)
            head = {
                'Content-Type': 'application/json',
                'apiKey': '' + token
            }

        fetch(urlConfig.apiBase + endpoint, {
            method: 'PUT',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: head,
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
            body: JSON.stringify(data)
        }
        )
            .then(res => res.json())
            .then((result) => {
                // this.endRequest(methodName, result)
                callback(result);
            })
            .catch((err) => {
                this.handleError(methodName, err)
                errorCallback(err);
            });
    }
    soapPost(endpoint:string, data:string, soapEndpoint:string , methodName:string, callback:any,errorCallback:any) {
        //debugger;
        this.houseKeeping();
        this.startRequest(methodName, data);
        var token = localStorage.getItem("userToken");
        let head: any = { 'Content-Type': 'text/xml', 'SOAPAction': 'http://tempuri.org/GetProductGroups'};
        if (token != null)
            head = {
                'Content-Type': 'text/xml',
                'SOAPAction': 'http://tempuri.org/GetProductGroups',
                'apiKey': '' + token
            }
        fetch(urlConfig.apiBase + endpoint, {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: head,
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
            body: data
        }
        )
            .then(res => res.text())
            .then((result) => {
                // this.endRequest(methodName, result)
                callback(result);         
            })
            .catch((err) => {
                this.handleError(methodName, err)
                errorCallback(err);
            });
    }
    post(endpoint: string, data: any, methodName: string, callback: any, errorCallback: any) {
        this.startRequest(methodName, data);
        var token = localStorage.getItem("userToken");
        let head:any = { 'Content-Type': 'application/json'};
        if (token != null)
            head = {
                'Content-Type': 'application/json',
                'apiKey': ''+token
            }

        fetch(urlConfig.apiBase + endpoint, {
                method: 'POST',
                mode: 'cors',
                cache: 'no-cache',
                credentials: 'same-origin',
                headers: head,
                redirect: 'follow',
                referrerPolicy: 'no-referrer',
                body: JSON.stringify(data)
            }
        )
            .then(res => res.json())
            .then((result) => {
                // this.endRequest(methodName, result)
                callback(result);
            })
            .catch((err) => {
                this.handleError(methodName, err)
                errorCallback(err);
            });
    }
    downloadFilePost(endpoint: string, data: any, methodName: string, callback: any, errorCallback: any) {
        try {
            this.startRequest(methodName, data);
            var token = localStorage.getItem("userToken");
            let head:any = { 'Content-Type': 'application/json'};
            let fileName = 'Filename.txt';
            if (token != null)
                head = {
                    'Content-Type': 'application/json',
                    'apiKey': ''+token
                }
    
            fetch(urlConfig.apiBase + endpoint, {
                    method: 'POST',
                    mode: 'cors',
                    cache: 'no-cache',
                    credentials: 'same-origin',
                    headers: head,
                    redirect: 'follow',
                    referrerPolicy: 'no-referrer',
                    body: JSON.stringify(data)
                }
            )
            .then((response) => {
                if (response != null && response.headers.get('Filename') != null) {
                    fileName = response.headers.get('Filename');
                }
                return response.blob();
            })
            .then((blob) => {
              // Create blob link to download
              const url = window.URL.createObjectURL(
                new Blob([blob]),
              );
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute(
                'download',
                fileName,
              );
          
              // Append to html link element page
              document.body.appendChild(link);
          
              // Start download
              link.click();
          
              // Clean up and remove the link
              link.parentNode.removeChild(link);
              callback(fileName);
            })
            .catch(err => {
                this.handleError(methodName, err);
                errorCallback(err);
            });
    
        } catch (ex) {

        }
    }
    get(endpoint: string, methodName: string, callback: any, errorCallback: any) {

        var token = localStorage.getItem("userToken");
        let head: any = { 'Content-Type': 'application/json' };
        if (token != null)
            head = {
                'Content-Type': 'application/json',
                'apiKey': '' + token
            }
        this.startRequest(methodName, "");
        fetch(urlConfig.apiBase + endpoint, {
            method: 'GET',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: head,
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
        }
        )
            .then(res => res.json())
            .then((result) => {
                // this.endRequest(methodName, result)
                callback(result);
            })
            .catch((err) => {
                this.handleError(methodName, err)
                errorCallback(err);
            });
    }
}