import IPMOBaseService from '../Generics/Service/IPMOBaseService'
import { GetLovsRequest } from '../Generics/Types/IPMOBaseService.model';
import {
    IRoleLegacy, IIdentitySimple, IRolePermissionTemplate, IReqGetRoleUsers, IReqUpdateRoleDescriptors,
    IReqCreateRole, IReqAddRoleUser, IReqDeleteRoleUser, IReqRolePermission, IResRowsAffected,
    IResAddRoleUsers, IResGetRoleUsers, IResCreateRole, IReqDeleteRole, IResGetRoles, IReqGetRoles
} from './Roles.service.types';

export default class RolesService extends IPMOBaseService {

    getRoles(postBody: IReqGetRoles | null, callback: (res: IIdentitySimple[]) => void, errorCallback: (err: string) => void) {
        let methodName = "Get Roles By User Identity Id";
        super.post(
            "/PricingEngineService/GetRoles",
            postBody,
            methodName,
            (res: IResGetRoles) => {
                if (!res.Successful) throw res.ErrorMessage;
                callback(res.Data)
            },
            (err: string) => { 
                errorCallback(`Error getting roles: ${err}`) 
            }
        )
    }

    getRolePermissionsByIdentityId(roleIdentityId: number, callback: (res: IRolePermissionTemplate[]) => void, errorCallback: (err: string) => void) {
        let methodName = "Get Role Permissions By Identity Id"
        super.get(
            `/PricingEngineService/PermissionTemplates?RoleId=${roleIdentityId}`, // Legacy
            methodName,
            (res: IRolePermissionTemplate[]) => {
                if (!Array.isArray(res)) throw res;
                callback(res)
            },
            (err: string) => {
                errorCallback(`Error getting role permissions for identity [${roleIdentityId}]: ${err}`)
            }
        )
    }

    // TODO combine this and "createRole" into one endpoint and method: "postRole"
    updateRoleDescriptors(postBody: IReqUpdateRoleDescriptors, callback: any, errorCallback: (err: string) => void) {
        let methodName = "Post Role Descriptors"
        super.post(
            "/PricingEngineService/Roles2UpdateDescriptors", // Legacy
            postBody,
            methodName,
            (res: IRoleLegacy[]) => {
                callback(res)
            },
            (err: any) => {
                errorCallback(`Error saving role descriptors for identity: ${postBody.Id} - ${JSON.stringify(err)}`)
            }
        )
    }

    getRoleUsers(postBody: IReqGetRoleUsers | null, callback: (res: IIdentitySimple[]) => void, errorCallback: (err: string) => void) {
        let methodName = "Get Role Users By Role Id"
        super.post(
            `/PricingEngineService/GetRoleUsers`, 
            postBody,
            methodName,
            (res: IResGetRoleUsers) => {
                if (!res.Successful) throw res.ErrorMessage;
                callback(res.Data)
            },
            (err: string) => {
                errorCallback(`Error getting users ${postBody.Id ? `for role identity[${ postBody.Id }]` : ''}: ${err}`)
            }
        )
    }

    addRoleUsers(postBody: IReqAddRoleUser[], callback: any, errorCallback: (err: string) => void) {
        let methodName = "Add Role Users"
        super.post(
            "/PricingEngineService/AddRoleUsers",
            postBody,
            methodName,
            (res: IResAddRoleUsers) => {
                if (!res.Successful) throw res.ErrorMessage;
                callback(res.Data)
            },
            (err: string) => {
                errorCallback(`Error adding users to role: ${err}`)
            }
        )
    }
    
    deleteRoleUser(postBody: IReqDeleteRoleUser, callback: any, errorCallback: (err: string) => void) {
        let methodName = "Delete Role Users"
        super.post(
            "/PricingEngineService/DeleteRoleUser",
            postBody,
            methodName,
            (res: IResRowsAffected) => {
                if (!res.Successful) throw res.ErrorMessage;
                callback(res.Data)
            },
            (err: string) => {
                errorCallback(`Error adding users to role: ${err}`)
            }
        )
    }

    setRolePermissions(roleId: number, postBody: IReqRolePermission[], callback: any, errorCallback: (err: string) => void) {
        let methodName = "Set Role Permissions"
        super.post(
            "/PricingEngineService/SetRolePermissions", 
            postBody,
            methodName,
            (res: IResRowsAffected) => {
                if (!res.Successful) throw res.ErrorMessage;
                callback(res.Data)
            },
            (err: string) => {
                errorCallback(`Error setting permissions for role identity [${roleId}]: ${err}`)
            }
        )
    }

    createRole(postBody: IReqCreateRole, callback: (res: IIdentitySimple) => void, errorCallback: (err: string) => void) {
        let methodName = "Create Role" 
        super.post(
            "/PricingEngineService/CreateRole",
            postBody,
            methodName,
            (res: IResCreateRole) => {
                if (!res.Successful) throw res.ErrorMessage;
                callback(res.Data)
            },
            (err: string) => {
                errorCallback(`Error creating role [${postBody.Name}]: ${err}`)
            }
        )
    }

    deleteRole(postBody: IReqDeleteRole, callback: any, errorCallback: (err: string) => void) {
        let methodName = "Delete Role"
        super.post(
            "/PricingEngineService/DeleteRole", 
            postBody,
            methodName,
            (res: IResRowsAffected) => {
                if (!res.Successful) throw res.ErrorMessage;
                callback(res.Data);
            },
            (err: string) => {
                errorCallback(`Error deleting role: ${err}`)
            }
        )
    }
}