import { IServerSideGetRowsParams } from '@ag-grid-enterprise/all-modules';
import MasterContext from '../../../Components/React/Misc/MasterContext';
import HierarchyService from './Hierarchy.service';
import ProductHierarchyTree from '../../../Components/React/Hierarchy/ProductHierarchyTree';
import LocationHierarchyTree from '../../../Components/React/Hierarchy/LocationHierarchyTree';

export default class HierarchyDatasource {
    createProductHierarchyDatasource = (hierService: HierarchyService, prodHierTree: ProductHierarchyTree) => {
        function ProductHierarchyDatasource(hierService: HierarchyService, prodHierTree: ProductHierarchyTree) {
        }

        ProductHierarchyDatasource.prototype.getRows = function (params: IServerSideGetRowsParams) {
            console.log('[ProductHierarchyDatasource] - rows requested by grid: ', params.request);

            let groupKeys = params.request.groupKeys;
            if (groupKeys.length === 0) {
                MasterContext.HierarchyService.getProductRootNode('0', 0, 
                    (data) => {
                        let nodesAr = [
                            {
                                Group: true,
                                NodeId: data.NodeId,
                                NodeName: data.Name,
                                NodeDesc: data.Name,
                                ProductId: null,
                                UPC: null,
                                Description: null,
                                PriceFamilyId: null,
                                PriceFamilyDesc: null,
                                IsMaster: null,
                                Brand: null
                            }
                        ];
                        params.successCallback(nodesAr, 1);
                    },
                    (error) => {
                    });
            } else {
                let level = groupKeys.length - 1;
                let parentNodeId = groupKeys[level];
                MasterContext.HierarchyService.getProductNodes(parentNodeId, level, 
                    (data) => {
                        let nodesAr = data.map(function (d) {
                            let node = {
                                Group: !d.LeafNodeFlag,
                                NodeId: d.NodeId,
                                Level: d.NodeLevel,
                                NodeName: d.NodeName,
                                NodeDesc: d.NodeName,
                                ProductId: null,
                                UPC: null,
                                Description: null,
                                PriceFamilyId: null,
                                PriceFamilyDesc: null,
                                IsMaster: null,
                                Brand: null
                            };
                            if (d.Product) {
                                node.ProductId = d.Product.ProductId;
                                node.UPC = d.Product.UPC;
                                node.Description = d.Product.Description;
                                node.PriceFamilyId = d.Product.PriceFamilyId;
                                node.PriceFamilyDesc = d.Product.PriceFamilyDesc;
                                node.IsMaster = d.Product.Master;
                                node.Brand = d.Product.Brand;
                            }
                            return node;
                        });
                        params.successCallback(nodesAr, nodesAr.length);
                    },
                    (error) => {
                    });
            }

        };
        return new ProductHierarchyDatasource(hierService, prodHierTree);
    }

    createLocationHierarchyDatasource = (hierService: HierarchyService, locHierTree: LocationHierarchyTree) => {
        function LocationHierarchyDatasource(hierService: HierarchyService, locHierTree: LocationHierarchyTree) {
        }

        LocationHierarchyDatasource.prototype.getRows = function (params: IServerSideGetRowsParams) {
            console.log('[LocationHierarchyDatasource] - rows requested by grid: ', params.request);

            let groupKeys = params.request.groupKeys;
            if (groupKeys.length === 0) {
                MasterContext.HierarchyService.getLocationRootNode('0', 0,
                    (data) => {
                        let nodesAr = [
                            {
                                Group: true,
                                NodeId: data.NodeId,
                                NodeName: data.Name,
                                NodeDesc: data.Name,
                                LocationId: null,
                                UPC: null,
                                Description: null,
                                PriceFamilyId: null,
                                PriceFamilyDesc: null,
                                IsMaster: null,
                                Brand: null
                            }
                        ];
                        params.successCallback(nodesAr, 1);
                    },
                    (error) => {
                    });
            } else {
                let level = groupKeys.length - 1;
                let parentNodeId = groupKeys[level];
                MasterContext.HierarchyService.getLocationNodes(parentNodeId, level,
                    (data) => {
                        let nodesAr = data.map(function (d) {
                            let node = {
                                Group: !d.LeafNodeFlag,
                                NodeId: d.NodeId,
                                Level: d.NodeLevel,
                                NodeName: d.NodeName,
                                NodeDesc: d.NodeName,
                                LocationId: null,
                                UPC: null,
                                Description: null,
                                PriceFamilyId: null,
                                PriceFamilyDesc: null,
                                IsMaster: null,
                                Brand: null
                            };
                            if (d.Location) {
                                node.LocationId = d.Location.LocationId;
                                node.UPC = d.Location.UPC;
                                node.Description = d.Location.Description;
                                node.PriceFamilyId = d.Location.PriceFamilyId;
                                node.PriceFamilyDesc = d.Location.PriceFamilyDesc;
                                node.IsMaster = d.Location.Master;
                                node.Brand = d.Location.Brand;
                            }
                            return node;
                        });
                        params.successCallback(nodesAr, nodesAr.length);
                    },
                    (error) => {
                    });
            }

        };
        return new LocationHierarchyDatasource(hierService, locHierTree);
    }

}

