export default class LOV_Column {
  LOV_Field_ID: number =0;
  LOV_ID: number =0;
  Column_Name: string ="";
  Position: number =0;
  Table_Name: string ="";
  FK_Name: string ="";
  PK_Name: string ="";
  Join_Type: string ="";
  Alias: string ="";
  NullBlock: string ="";
}
