export default class Tableau_Data {
  ReportId: number =0;
  ReportName: string ="";
  ReportDescription: string ="";
  TableauWorkbook: string ="";
  TableauView: string ="";
  CreatedBy: string ="";
  CreatedDate: Date = new Date();
  UpdatedBy: string ="";
  UpdateDate: Date = new Date();
  ActiveFlag: boolean = false;
  ParentId: number =0;
}
