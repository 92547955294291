import { AdminAuditResponse, AdminAuditRequest } from '../Types/AdminAudit.model';
import IPMOBaseService from '../../Generics/Service/IPMOBaseService'

export default class AdminAuditService extends IPMOBaseService {
    getAdminAudits(data: AdminAuditRequest, callback: any, errorCallback: any) {
        let methodName = "Get Admin Audit Data";
        super.post("/api/Audit/AdminAudit", data, methodName,
            (result) => {
                callback(result.Data as AdminAuditResponse[]);
            },
            (error) => {
                errorCallback(error)
            });
    }

    downloadAdminAudits(data: AdminAuditRequest, callback: any, errorCallback: any) {
        let methodName = "Download Admin Audit Data";
        super.post("/api/Audit/DownloadAdminAudit", data, methodName,
            (result) => {
                callback(result.Data as AdminAuditResponse[]);
            },
            (error) => {
                errorCallback(error)
            });
    }
}