import React from "react";
import CacheManager from "../../../Services/CacheManager/CacheManager";
import LocalPermissionsService from '../../../Services/local-permissions-service';
import LoginService from '../../../Services/Login/Service/Login.service';
import * as types from '../../../Services/Login/Types/Login.model';
import MasterContext from "../Misc/MasterContext";
import '../css/sso-login.css';

export interface IProps {
    handleLogin: Function;
    ssoRedirectUrl: string;
}
export interface IState {
    isLoggingIn: boolean;
    loginError: number;
    loaded: boolean;
    message: string;
}

export default class SsoLogin extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            isLoggingIn: false,
            loginError: 0,
            loaded: false,
            message: "Authenticating you via Single Sign On"
        };

        this.ssoLogin = this.ssoLogin.bind(this);
    }

    componentDidMount() {
        this.ssoLogin();
    }

    ssoRedirect() {
        console.log(this.props.ssoRedirectUrl);
        window.location.href = this.props.ssoRedirectUrl;
    }

    ssoLogin() {
        let cacheManager: CacheManager = new CacheManager();
        cacheManager.clearLocalStorageCache();
        this.setState({ isLoggingIn: true });
        let loginService: LoginService = new LoginService();
        let loginToken: types.LoginToken = new types.LoginToken();
        loginToken.ClientVersion = MasterContext.ClientVersion;

        loginService.oktaLogin(loginToken,
            (resp) => {
                if (resp.Status === "success") {

                    this.setState({ message: "Signing into ClearDemand..." });

                    let navigationPermissions = [];

                    if (resp !== null && resp.TokenTimeout !== null) {
                        MasterContext.ClientTimeoutValue = +resp.TokenTimeout;
                        localStorage.setItem("clientTimeoutValue", resp.TokenTimeout);
                    }

                    for (let navigationPermission of resp.NavigationPermissions) {
                        navigationPermissions.push(navigationPermission.PermissionName);
                    }

                    localStorage.setItem("cachedUser", resp.UserName);
                    localStorage.setItem("firstName", resp.FirstName);
                    localStorage.setItem("navigationPermissions", JSON.stringify(navigationPermissions));
                    MasterContext.UserData = { userName: resp.UserName, navigationPermissions: navigationPermissions };
                    localStorage.setItem("userToken", resp.Token);
                    LocalPermissionsService.savePermissions(resp.UserUiPermissions);
                    this.props.handleLogin();
                }
                else {
                    this.setState({ message: "Bad Response..." });
                    this.setState({ loginError: 1, isLoggingIn: false });
                    this.setState({ loaded: false });
                    this.ssoRedirect();
                }
            },
            (resp) => {

                this.setState({ loginError: 1, isLoggingIn: false });
                this.setState({ loaded: false });
                this.ssoRedirect();
            }
        )
    }

    render() {
        let outer = { height: "100%", margin: "0" };
        let main = { margin: "0 auto", width: "700px", padding: "30px", font: "16px Helvetica" };

        const styles = {
            alignCenter: { textAlign: 'center' },
        } as const;

        return (
            <React.Fragment>
                <div className="loginBody">
                    <div className="row" style={{ width: "100%", padding: "0 0 45px 0" }}>
                        <div className="col-md-12 text-center">
                            <img alt='' src="https://cleardemand.com/wp-content/uploads/2022/08/loginLogo.png" /> <br />
                            <br />
                            <br />
                            <h2>We are securely authenticating your account using Okta<span className="dot">.</span><span className="dot">.</span><span className="dot">.</span></h2>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
