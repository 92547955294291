import React from 'react';
import { Spinner } from 'reactstrap';
import './SpinnerWithMessage.css';

interface IProps {
    loadingMessage: string
}

export default function SpinnerWithMessage({ loadingMessage }: IProps) {
    return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <Spinner color="primary" />
            <span style={{ fontSize: '16px' }} className="ml-2">{loadingMessage}</span>
        </div>
    )
}