import * as React from 'react';
export interface IProps {
}
export interface IState {
    loaded: boolean;
}

export default class Register extends React.Component<IProps, IState> {

    render() {
        let style1 = { height: "405px" }
        let style2 = { border: "0px", height: "75vh" };
        return (
            <div id="mainBody" className="main-body container-fill ng-scope body-fill" event-control="" cdi-body-fill-container="" ui-view="" role="main" style={style1}>
                REGISTER
                <iframe title='foo' src="/Configuration/Rules/EditRule.aspx?source=C3PO" width="100%" style={style2} iframe-on-load="container.iframeLoaded()" className="cdi-auto-fill"></iframe>
            </div>
        );

    }

}