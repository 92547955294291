import * as React from 'react';

import App_Property from '../../../Services/Common/Types/AppProperty.model'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import MasterContext from '../Misc/MasterContext';

export interface IProps {

}

export interface IState {
    futureBuydownDaysProp: App_Property,
    showResultModal: boolean,
    futureBuydownDaysValidationErrors:any[]
}

export default class AdminFutureBuydownDaysProp extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            futureBuydownDaysProp: new App_Property(),
            showResultModal: false,
            futureBuydownDaysValidationErrors:[]
        };
    }
    componentDidMount() {
        MasterContext.AppPropertyService.findAppProperty("AppDefaults", "FutureBuydownDays", 
            (prop) => {
                if (prop != null && prop.Value != null) {
                    this.setState({futureBuydownDaysProp: prop})
                }
            }
            ,(err) => {

            }
        );
    }   
    toggleshowResultModal = () => {
        this.setState({ showResultModal: !this.state.showResultModal });
    }
    saveSelection = () => {
        if (this.validate() === false) {
            MasterContext.AppPropertyService.updateAppProperty(this.state.futureBuydownDaysProp, (e) => {
                this.toggleshowResultModal();
            }, () => { 
                alert("Error: Could not update Future Buydown Days");
            });
        }
    }
    handleFutureBuydownDaysChanged = (event: any) => {
        this.validate()
        let v = event.target.value;
        let cpy:App_Property = new App_Property();
        Object.assign(cpy, this.state.futureBuydownDaysProp);
        cpy.Value = v;
        this.setState({ futureBuydownDaysProp: cpy });
    }

    validate = (): boolean => {
        let retVal: boolean = false;

        let newErrors: any[] = [];
        if (this.state.futureBuydownDaysProp.Value == null || this.state.futureBuydownDaysProp.Value.length === 0) {
            let newErr: any = {};
            newErr.controlName = 'FutureBuydownDays';
            newErr.error = MasterContext.translate('validation_future_buydown_days_required');
            newErr.severity = 1;
            newErrors.push(newErr);
            retVal = true;
        }
        this.setState({ futureBuydownDaysValidationErrors: newErrors });
        return retVal;
    }

    getMyValidationErrors = (control: string): string => {
        let retVal: string = '';
        if (this.state.futureBuydownDaysValidationErrors) {
            for (let err of this.state.futureBuydownDaysValidationErrors) {
                if (err.controlName === control) {
                    retVal = retVal + err.error + '\r\n';
                }
            }
        }
        return retVal;
    }


    render() {
        let errorClass = { color: "#ff0000" };

        return (
            <React.Fragment>

                <Modal zIndex={3002} toggle={() => this.toggleshowResultModal()} isOpen={this.state.showResultModal}>
                    <ModalHeader toggle={() => this.toggleshowResultModal()}>{MasterContext.translate("future_buydown_days_modal_header")}</ModalHeader>
                    <ModalBody>{MasterContext.translate("future_buydown_days_modal_body")}</ModalBody>
                    <ModalFooter>
                        <button className="btn btn-sm btn-outline-info" color="primary" onClick={() => this.toggleshowResultModal()}>{MasterContext.translate("ok_modal_button")}</button>{' '}                       
                    </ModalFooter>
                </Modal>

                <div className="row">
                    <div className="col-md-4"></div>
                    <div className="col-md-4 card">
                        <div className="row">
                            <div className="col-md-12">
                                <h3 className="text-center text-info">{MasterContext.translate("future_buydown_days_header")}</h3>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="alert alert-secondary" role="alert">
                                    {MasterContext.translate("future_buydown_days_description")}
                                </div>
                            </div>
                        </div>
                        <div className="form-group">
                            <div className="row">
                                <div className="col-md-3">
                                    <label className="loginColors">{MasterContext.translate("future_buydown_days_label")}  </label>
                                </div>
                                <div className="col-md-9">
                                    <input placeholder={MasterContext.translate("future_buydown_days_placeholder")} 
                                        id='daysToLock' type="number" value={this.state.futureBuydownDaysProp?.Value} 
                                        onChange={this.handleFutureBuydownDaysChanged} className="form-control" />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-3">
                                </div>
                                <div className="col-md-9">
                                    <span className="pull-right" style={errorClass}>{this.getMyValidationErrors('daysToLock')}</span>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6"></div>
                            <div className="col-md-6">
                                <button className="btn btn-outline-primary btn-sm float-right" id="btnSaveSelection" 
                                    onClick={this.saveSelection}>{MasterContext.translate("save_button")}</button>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4"></div>
                </div>
            </React.Fragment>
        )
    }
}