import React, { useEffect, useState } from 'react';
import UsersService from '../../../Services/Users/Users.service';
import RolesService from '../../../Services/Roles/Roles.service';
import HierarchyService from '../../../Services/Hierarchy/Service/Hierarchy.service';
import ViewWrapper from '../Common/ViewWrapper/ViewWrapper';
import { Spinner, Row, Col, ListGroupItem, ListGroup, FormGroup, Label, Input, Button } from 'reactstrap';
import UsersGrid from './UsersGrid';
import UserRolesDeptsForm from './UserRolesDeptsForm';
import UserForm from './UserForm';
import EnhancedRsModal from '../Common/EnhancedRsModal/EnhancedRsModal';
import { IReqSetIdentityProductHierarchyAccess, IUser } from '../../../Services/Users/Users.service.types';
import { IIdentitySimple, IReqGetRoles, IRoleUser } from '../../../Services/Roles/Roles.service.types';
import { IServerSideGetRowsRequest } from '@ag-grid-enterprise/all-modules';
import { INodeSimple } from '../../../Services/Hierarchy/Types/Hierarchy.service.types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserPlus } from '@fortawesome/free-solid-svg-icons';

export default function Users() {
    const usersService = new UsersService();
    const [users, setUsers] = useState<IUser[]>([]);
    const [gridCriteria, setGridCriteria] = useState<IServerSideGetRowsRequest>({
        startRow: 1,
        endRow: 10000,
        rowGroupCols: null,
        valueCols: null,
        pivotCols: null,
        pivotMode: false,
        groupKeys: null,
        filterModel: null,
        sortModel: null
    });

    // edit user's roles 
    const [editRolesForId, setEditRolesForId] = useState<number | null>(null); // When id is set, modal is open
    const [editRolesModal, setEditRolesModal] = useState<boolean>(false);

    // edit user's fields
    const [editUserForId, setEditUserForId] = useState<number | null>(null); // When id is set, modal is open
    const [editUserModal, setEditUserModal] = useState<boolean>(false);
    // TODO all the input states for this form

    // add user modal state
    const [addUserModal, setAddUserModal] = useState<boolean>(false);

    useEffect(() => {
        usersService.getUsers(
            gridCriteria,
            res => setUsers(res),
            err => alert(err)
        )
    }, []);

    useEffect(() => {
        if (editRolesForId) {
            setEditRolesModal(true);
        }
        else {
            setEditRolesModal(false);
        }
    }, [editRolesForId])

    useEffect(() => {
        if (!editRolesModal) {
            setEditRolesForId(null);
        }
    }, [editRolesModal])

    useEffect(() => {
        console.log('edit for user ide changed')
        if (editUserForId) {
            console.log('modal open')
            setEditUserModal(true);
        }
        else {
            console.log('no id modal closes')
            setEditUserModal(false);
        }
    }, [editUserForId])

    useEffect(() => {
        if (!editUserModal) {
            setEditUserForId(null);
        }
    }, [editUserModal])

     
    return (
        <ViewWrapper>
            <Row className="mb-4">
                <Col>
                    <h2 className="h4">Users</h2>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Button
                        color="primary"
                        size="sm"
                    onClick={() => setAddUserModal(true)}
                    className="float-right mb-2"
                    >
                        <FontAwesomeIcon icon={faUserPlus} />
                        {' '}
                        <span>Add User</span>
                    </Button>
                    </Col>
                </Row>
            <Row>
                <Col>
                    <UsersGrid
                        users={users}
                        setUsers={setUsers}
                        setEditRolesForId={setEditRolesForId}
                        setEditUserForId={setEditUserForId}
                        setAddUserModal={setAddUserModal}
                    />
                    <EnhancedRsModal
                        isOpen={editRolesModal}
                        setIsOpen={setEditRolesModal}
                        isFullScreen={true}
                    >
                        <UserRolesDeptsForm
                            users={users}
                            setUsers={setUsers}
                            editRolesForId={editRolesForId}
                            setEditRolesForId={setEditRolesForId}
                        />
                    </EnhancedRsModal>
                    <EnhancedRsModal
                        isOpen={editUserModal}
                        setIsOpen={setEditUserModal}
                        isFullScreen={false}
                    >
                        <UserForm
                            editUserId={editUserForId}
                            setEditUserId={setEditUserForId}
                            users={users}
                            setUsers={setUsers}
                        />
                    </EnhancedRsModal>
                    <EnhancedRsModal
                        isOpen={addUserModal}
                        setIsOpen={setAddUserModal}
                        isFullScreen={false}
                    >
                        <UserForm
                            editUserId={null}
                            users={users}
                            setUsers={setUsers}
                            setModal={setAddUserModal}
                        />
                    </EnhancedRsModal>
                </Col>
            </Row>
        </ViewWrapper>
    )
}