import * as React from 'react';
import './Explorer.css';

export interface IProps {
    treeData: any[];
    treeItemClicked: Function;
    treeItemRIghtClicked: Function;
    treeItemExpanded: Function;
}

export interface IState {
    
}

export default class CDIExplorerTreeView extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
           
        };
        this.renderItems = this.renderItems.bind(this);
        this.toggleExpanded = this.toggleExpanded.bind(this);
        this.renderItemText = this.renderItemText.bind(this);
        
        
    }
    toggleExpanded(item: any) {
        this.props.treeItemExpanded(item);
    }
    renderItemText(item: any) {
        return <span style={{ cursor: "pointer" }} onClick={() => { this.props.treeItemClicked(item) }}>{item.name}</span>
    }
    renderItem(item:any):any[] {
        let retVal: any[] = [];
        for (let item2 of item) {
            
            if (item2.children) {
                if (item2.expanded === true) {
                    retVal.push(<li className="treeItem"><span style={{ cursor: "pointer" }} onClick={() => this.toggleExpanded(item2)}><i className="fa fa-caret-down" aria-hidden="true"></i></span>{this.renderItemText(item2)}</li>)
                    let childs = this.renderItem(item2.children);
                    retVal.push(<ul className="treeMenuGroup">{childs}</ul>)
                }
                else {
                    retVal.push(<li className="treeItem"><span style={{ cursor: "pointer" }} onClick={() => this.toggleExpanded(item2)}><i className="fa fa-caret-right" aria-hidden="true"></i></span>{this.renderItemText(item2)}</li>)
                }
            }
            else {
                retVal.push(<li className="treeItem">&nbsp;{this.renderItemText(item2)}</li>)
            }           
        }
        return retVal;
    }
    renderItems()
    {
        let retVal: any[] = [];
        for (let item of this.props.treeData) {
            
            if (item.children) {
                if (item.expanded === true) {
                    retVal.push(<li className="treeItem"><span style={{ cursor: "pointer" }} onClick={() => this.toggleExpanded(item)}><i className="fa fa-caret-down" aria-hidden="true"></i></span>{this.renderItemText(item)}</li>)
                    let childs = this.renderItem(item.children);
                    retVal.push(<ul className="treeMenuGroup">{childs}</ul>)
                }
                else {
                    retVal.push(<li className="treeItem"><span style={{ cursor: "pointer" }} onClick={() => this.toggleExpanded(item)}><i className="fa fa-caret-right" aria-hidden="true"></i></span>{this.renderItemText(item)}</li>)
                }

            }
            else {
                retVal.push(<li className="treeItem">&nbsp;{this.renderItemText(item)}</li>)
            }
            
        }
        return retVal;
    }
    render() {

        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-md-12" style={{overflowX:"auto"}}>
                        <ul className="treeMenuGroup">
                            {this.renderItems()}
                        </ul>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}