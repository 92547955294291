import LOV_Filter from "./LOV_Filter.model";
import LOV_Column from "./LOV_Column.model";
export default class LOV {
  constructor() {
    this.LOV_ID = 0;
    this.LOV_Alias = "";
    this.LOV_Filters = LOV_Filter[0];
    this.LOV_Columns = LOV_Column[0];
  }
  id: number =0;
  text: string ="";
  expanded: boolean = false;
    items: LOV[];
  name: string ="";
  displayName: string ="";
  LOV_ID: number =0;
  LOV_Alias: string ="";
  LOV_Table: string ="";
  LOV_PK_Column: string ="";
  LOV_Name_Column: string ="";
  LOV_Created: Date = new Date();
  LOV_Description: string ="";
  LOV_Created_By: string ="";
  LOV_Updated: Date = new Date();
  LOV_GridField: number =0;
    LOV_Filters: LOV_Filter[];
    LOV_Columns: LOV_Column[];
}
