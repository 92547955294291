import { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';

/**
 * Track browser tabs and windows for an origin with localStorage
 * If all tabs and windows are closed or browser is closed clear localStorage by default
 * Or pass a callback to execute another action
 */
export const ActiveOrigin = (props) => {
	const { inactiveCallback, children } = props;

	const idKey = 'windowId';
	const IdObjKey = 'activeWindowIds';

	const getId = useCallback(() => {
		return window.sessionStorage.getItem(idKey);
	}, []);
	
	const setId = useCallback(() => {
		const id = getId() || window.crypto.getRandomValues(new Uint32Array(3)).join('');
		window.sessionStorage.setItem(idKey, id);
		return id;
	}, [getId]);
	
	const getObj = useCallback(() => {
		return JSON.parse(window.localStorage.getItem(IdObjKey));
	}, []);

	const getObjCount = useCallback(() => {
		const obj = getObj() ?? {};
		return Object.keys(obj).length;
	}, [getObj]);

	const setObjId = useCallback((id) => {
		const currObj = getObj();
		const newObj = currObj ? {...currObj, [id]: true} : {[id]: true};
		window.localStorage.setItem(IdObjKey, JSON.stringify(newObj));
	}, [getObj]);

	const deleteObjId = useCallback(() => {
		const windowId = getId();
		const windowObj = getObj();
		delete windowObj[windowId];
		window.localStorage.setItem(IdObjKey, JSON.stringify(windowObj));
	}, [getId, getObj ]);

	const incrementActive = useCallback(() => {
		const windowId = setId(); // Set the windowId on sessionStorage
		setObjId(windowId); 			// set new windowId on localStorage activeWindowIds object
	}, [setId, setObjId]);

	const decrementActive = useCallback(() => {
		deleteObjId(); 	// delete windowId from localStorage activeWindowIds object
		delay(250); 		// delay the end of the unload event with syncrhonous function
	}, [deleteObjId]);

	const delay = (ms) => {
		const start = new Date().getTime();
		while(new Date().getTime() < start + ms);
	};

	useEffect(() => {
		// If this is the first tab or window from this origin that has been opened then
		// We reload the page so that cleared localStorage items are not available for authentication
		if (getObjCount() === 0 && getId() === null) {
			inactiveCallback(); // Clear localStorage and reload the page
		}

		incrementActive();

		window.addEventListener('unload', decrementActive);
		return () => window.removeEventListener('unload', decrementActive);
	}, [getObjCount, getId, inactiveCallback, incrementActive, decrementActive]);

	return children || null;
};

ActiveOrigin.defaultProps = {
	inactiveCallback: () => {
		window.localStorage.clear();
		window.location.reload();
	},
};

ActiveOrigin.propTypes = {
	inactiveCallback: PropTypes.func,
};
