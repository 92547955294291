import IPMOBaseService from '../Generics/Service/IPMOBaseService'
import {
    IResGetUsers, IUserLegacy, IUser, IResSetUserRoles, IReqUpdateUser, IResGetProductHierarchyAccessByIdentityId,
    IReqSetIdentityProductHierarchyAccess, IResSetIdentityProductHierarchyAccess, IResGetUser, IReqUser, IResSaveUser
} from './Users.service.types';
import { INodeSimple } from '../Hierarchy/Types/Hierarchy.service.types';
import { IRoleUser } from '../Roles/Roles.service.types';
import { IServerSideGetRowsRequest } from '@ag-grid-community/all-modules';
import { identity } from 'angular';
import { IRes } from '../Common/Types/Common.types';

export default class UsersService extends IPMOBaseService {

    getUsersLegacy(callback: (res: IUserLegacy[]) => void, errorCallback: (err: string) => void) {
        let methodName = "Get Users";
        super.get(
            "/PricingEngineService/Users", // Legacy
            methodName,
            (res: IUserLegacy[]) => {
                callback(res)
            },
            (err: any) => { 
                errorCallback(`Error getting users: ${JSON.stringify(err)}`) 
            }
        )
    }

    getUsers(postBody: IServerSideGetRowsRequest, callback: (res: IUser[]) => void, errorCallback: (err: string) => void) {
        let methodName = "Get Users";
        super.post(
            "/PricingEngineService/GetUsers", 
            postBody,
            methodName,
            (res: IResGetUsers) => {
                if (!res.Successful) throw res.ErrorMessage;
                callback(res.Data)
            },
            (err: string) => {
                errorCallback(`Error getting users: ${err}`)
            }
        )
    }

    setUserRoles(postBody: IRoleUser[], callback: (res: IUser) => void, errorCallback: (err: string) => void) {
        let methodName = "Set User Roles"
        super.post(
            "/PricingEngineService/SetUserRoles",
            postBody,
            methodName,
            (res: IResSetUserRoles) => {
                if (!res.Successful) throw res.ErrorMessage;
                callback(res.Data)
            },
            (err: string) => {
                errorCallback(`Error adding users to role: ${err}`)
            }
        )
    }

    updateUser(postBody: IReqUpdateUser, callback: (res: IUser) => void, errorCallback: (err: string) => void) {
        let methodName = "Update User"
        super.post(
            "/PricingEngineService/UpdateUser",
            postBody,
            methodName,
            (res: IResSetUserRoles) => {
                if (!res.Successful) throw res.ErrorMessage;
                callback(res.Data)
            },
            (err: string) => {
                errorCallback(`Error updating user ${postBody.Id}: ${err}`)
            }
        )
    }

    getProductHierarchyAccessByIdentityId(identityId: number, callback: (res: INodeSimple[]) => void, errorCallback: (err: string) => void) {
        let methodName = "getProductHierarchyAccessByIdentityId"
        super.get(
            `/PricingEngineService/GetProductHierarchyAccessByIdentityId/${identityId}`,
            methodName,
            (res: IResGetProductHierarchyAccessByIdentityId) => {
                if (!res.Successful) throw res.ErrorMessage;
                callback(res.Data)
            },
            (err: string) => {
                errorCallback(`Error getting prod hier access for identity ${identityId}: ${err}`)
            }
        )
    }

    setIdentityProductHierarchyAccess(postBody: IReqSetIdentityProductHierarchyAccess, callback: (res: INodeSimple[]) => void, errorCallback: (err: string) => void) {
        let methodName = "setIdentityProductHierarchyAccess"
        super.post(
            "/PricingEngineService/SetIdentityProductHierarchyAccess",
            postBody,
            methodName,
            (res: IResSetIdentityProductHierarchyAccess) => {
                if (!res.Successful) throw res.ErrorMessage;
                callback(res.Data)
            },
            (err: string) => {
                errorCallback(`Error setting access for identity ${postBody.IdentityId}: ${err}`)
            }
        )
    }

    getUserById(identityId: number, callback: (res: IUser) => void, errorCallback: (err: string) => void) {
        let methodName = "GetUserIdentityId"
        super.get(
            `/PricingEngineService/GetUserByIdentityId/${identityId}`,
            methodName,
            (res: IResGetUser) => {
                if (!res.Successful) throw res.ErrorMessage;
                callback(res.Data)
            },
            (err: string) => {
                errorCallback(`Error user ${identityId}: ${err}`)
            }
        )
    }

    saveUser(postBody: IReqUser, callback: (resIUser) => void, errorCallback: (err: string) => void) {
        let methodName = "saveUser";
        super.post(
            "/PricingEngineService/SaveUser",
            postBody,
            methodName,
            (res: IResSaveUser) => {
                if (!res.Successful) throw res.ErrorMessage;
                callback(res.Data)
            },
            (err: string) => {
                errorCallback(`Error saving user ${postBody.Id ? postBody.Id : postBody.FirstName}: ${err}`)
            }
        )
    }

    deleteUserById(identityId: number, callback: (res: string) => void, errorCallback: (err: string) => void) {
        let methodName = "deleteUserById"
        super.get(
            `/PricingEngineService/DeleteUserByIdentityId/${identityId}`,
            methodName,
            (res: IRes) => {
                if (!res.Successful) throw res.ErrorMessage;
                callback(res.Data)
            },
            (err: string) => {
                errorCallback(`Error deleting user ${identityId}: ${err}`)
            }
        )
    }
}