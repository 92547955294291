export default class CacheManager {
  store = {};

  constructor() {
    this.store = {};
  }
  setCacheItem(name: string, val: any) {
    this.store[name] = val;
  }

  getCacheItem(name: string) {
    let retVal: any;
    try {
      retVal = this.store[name];
    } catch {
      retVal = -1;
    }

    return retVal;
    }

  removeCacheItem(name: string) {
    if (this.store && this.store[name]) {
      delete this.store[name];

    }
  }

    LocalStoragePrefix = "CACHESVC_";

    setLocalStorageItem(key: string, value: any) {
        let cacheKey = this.LocalStoragePrefix + key;
        let item = {
            "created": new Date(),
            "value": value
        };
        let itemStr = JSON.stringify(item);
        localStorage.setItem(cacheKey, itemStr);
    }

    getLocalStorageItem(key: string) {
        let cacheKey = this.LocalStoragePrefix + key;
        let itemStr = localStorage.getItem(cacheKey);

        let value = null;
        if (!itemStr) {
            let item = JSON.parse(itemStr);
            value = item.value;           
        }
        
        return value;
    }

    clearLocalStorageCache() {
        for (let key in localStorage) {
            if (key.startsWith(this.LocalStoragePrefix)) {
                localStorage.removeItem(key);
            }
        }
    }
}
