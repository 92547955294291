import React, { useEffect, useState } from 'react';
import RolePermissionsFormGrid from './RolePermissionsFormGrid';
import ViewWrapper from '../Common/ViewWrapper/ViewWrapper';
import GreyPaddedWrapper from '../Common/GreyPaddedWrapper/GreyPaddedWrapper';
import EnhancedRsModal from '../Common/EnhancedRsModal/EnhancedRsModal';
import RolesService from '../../../Services/Roles/Roles.service';
import NavigationRolesService from '../../../Services/Roles/NavigationRoles.service';
import {
    IRolePermissionTemplate, IIdentitySimple, IReqGetRoleUsers, IReqUpdateRoleDescriptors,
    IReqAddRoleUser, IReqDeleteRoleUser, IReqCreateRole, IReqDeleteRole
} from '../../../Services/Roles/Roles.service.types';
import { Spinner, Row, Col, Form, FormGroup, Label, Input, Button, ListGroup, ListGroupItem } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserPlus, faPlus, faMinusCircle, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { NavigationPermissionIdentity } from 'Services/Roles/NavigationRoles.service.types';
import RoleNavigationFormGrid from './RoleNavigationFormGrid';


interface IStateInput {
    touched: boolean;
    value: string;
    error: string;
}

interface IStateSelectOption {
    label: string;
    id: number;
}

interface IProps {
}

export default function Roles({ }: IProps) {
    const rolesService = new RolesService();
    const navigationRolesService = new NavigationRolesService();

    const [roles, setRoles] = useState<IIdentitySimple[]>([])
    const [rolesLoading, setRolesLoading] = useState<boolean>(false);
    const [roleId, setRoleId] = useState<number | null>(null);

    const [masterPermissions, setMasterPermissions] = useState<IRolePermissionTemplate[]>([]);
    const [rolePermissions, setRolePermissions] = useState<IRolePermissionTemplate[]>([]);

    const [masterNavigationPermissions, setNavigationMasterPermissions] = useState<NavigationPermissionIdentity[]>([]);
    const [roleNavigationPermissions, setNavigationPermissions] = useState<NavigationPermissionIdentity[]>([]);

    const [roleName, setRoleName] = useState<IStateInput>({ touched: false, value: '', error: '' });
    const [displayName, setDisplayName] = useState<IStateInput>({ touched: false, value: '', error: '' });
    const [description, setDescription] = useState<IStateInput>({ touched: false, value: '', error: '' });

    const [masterMembers, setMasterMembers] = useState<IIdentitySimple[]>([]);
    const [roleMembers, setRoleMembers] = useState<IIdentitySimple[]>([]);
    const [addMemberIds, setAddMemberIds] = useState<number[]>([]);

    const [memberModal, setMemberModal] = useState<boolean>(false);
    const [confirmModal, setConfirmModal] = useState<boolean>(false);

    useEffect(() => {
        setRolesLoading(true);
        rolesService.getRoles(
            null,
            (res: IIdentitySimple[]) => {
                setRolesLoading(false);
                setRoles(res);
            },
            (err: string) => {
                setRolesLoading(false);
                alert(err);
            }
        )
        rolesService.getRolePermissionsByIdentityId(
            1, // Get Global Admin perms as a base template
            (res: IRolePermissionTemplate[]) => { setMasterPermissions(res) },
            (err: string) => { alert(err) }
        )

        navigationRolesService.getByIdentityId(
            1, // Get Global Admin perms as a base template
            (res: NavigationPermissionIdentity[]) => { setNavigationMasterPermissions(res) },
            (err: string) => { alert(err) }
        )

        return () => { }
    }, []);

    useEffect(() => {
        if (roleId) {
            let foundRole = roles.find(r => r.Id === roleId);
            if (foundRole) {
                setRoleName({ touched: false, error: '', value: foundRole.Name });
                setDisplayName({ touched: false, error: '', value: foundRole.DisplayName });
                setDescription({ touched: false, error: '', value: foundRole.Description });
            }
            rolesService.getRolePermissionsByIdentityId(
                roleId,
                (res: IRolePermissionTemplate[]) => { setRolePermissions(res) },
                (err: string) => { alert(err) }
            )
            navigationRolesService.getByIdentityId(
                roleId,
                (res: NavigationPermissionIdentity[]) => { setNavigationPermissions(res) },
                (err: string) => { alert(err) }
            )
            rolesService.getRoleUsers(
                { Id: roleId } as IReqGetRoleUsers,
                (res: IIdentitySimple[]) => { setRoleMembers(res) },
                (err: string) => { alert(err) }
            )
        }
        return () => { }
    }, [roleId])

    useEffect(() => {
        if (memberModal) {
            rolesService.getRoleUsers(
                null,
                (res: IIdentitySimple[]) => { setMasterMembers(res) },
                (err: string) => { alert(err) }
            )
        }
        setAddMemberIds([]);
        return () => { }
    }, [memberModal])

    const handleCreateRole = () => {

        const postBody = {
            Name: `New Role ${roles.length + 1}`,
            DisplayName: "",
            Description: ""
        } as IReqCreateRole;

        rolesService.createRole(
            postBody,
            (res: IIdentitySimple) => {
                const newRoles = [...roles, res];

                setRoles(newRoles);
                setRoleId(res.Id);
            },
            (err: string) => {
                alert(err)
            }
        )
    }

    const handleSaveRoleDescriptors = (e) => {
        e.preventDefault();

        const postBody = {
            Id: roleId,
            IdentityTypeId: 2,
            Name: roleName.value,
            DisplayName: displayName.value,
            Description: description.value
        } as IReqUpdateRoleDescriptors;

        rolesService.updateRoleDescriptors(
            postBody,
            (res: []) => {
                const newRoles = roles.map(r => {
                    if (r.Id === roleId) {
                        const updatedRole = { ...r, ...postBody }
                        return updatedRole;
                    }
                    return r
                })
                setRoleName(prev => ({ ...prev, touched: false, error: "" }))
                setDisplayName(prev => ({ ...prev, touched: false, error: "" }))
                setDescription(prev => ({ ...prev, touched: false, error: "" }))
                setRoles(newRoles);
            },
            (err: string) => {
                alert(err)
            }
        )
    }

    const handleCancelRoleDescriptors = (e) => {
        let foundRole = roles.find(r => r.Id === roleId);
        if (foundRole) {
            setRoleName({ value: foundRole.Name, touched: false, error: "" })
            setDisplayName({ value: foundRole.DisplayName, touched: false, error: "" })
            setDescription({ value: foundRole.Description, touched: false, error: "" })
        }
    }

    const handleAddRoleUsers = () => {
        const postMembers = masterMembers.filter(m => addMemberIds.includes(m.Id));
        const postBody = postMembers.map(mu => {
            return {
                RoleId: roleId,
                IdentityId: mu.Id
            };
        }) as IReqAddRoleUser[];

        rolesService.addRoleUsers(
            postBody,
            (res: any) => {
                const newRoleMembers = [...roleMembers, ...postMembers] as IIdentitySimple[];
                setRoleMembers(newRoleMembers);
                setMemberModal(false);
            },
            (err: string) => { alert(err) }
        );
    }

    const handleDeleteRoleUser = (userId) => {
        const memberToDel = roleMembers.find(rm => rm.Id === userId);
        const roleToDelFrom = roles.find(r => r.Id === roleId)
        if (memberToDel && roleToDelFrom) {
            const postBody = {
                RoleId: roleId,
                IdentityId: memberToDel.Id
            } as IReqDeleteRoleUser;

            rolesService.deleteRoleUser(
                postBody,
                (res: any) => {
                    // TODO toast for success
                    const newRoleMembers = roleMembers.filter(rm => rm.Id !== memberToDel.Id);
                    setRoleMembers(newRoleMembers);
                },
                (err: string) => { alert(err) }
            );
        }
    }

    const handleDeleteRole = (roleId: number) => {
        const roleToDel = roles.find(rm => rm.Id === roleId);

        // TODO add confirmation modal

        if (roleToDel) {
            const postBody = {
                Id: roleId,
            } as IReqDeleteRole;

            rolesService.deleteRole(
                postBody,
                (res: number) => {
                    // TODO toast for success
                    const newRoles = roles.filter(rm => rm.Id !== roleToDel.Id);
                    setRoleId(null);
                    setRoles(newRoles);
                    setConfirmModal(false);

                },
                (err: string) => { alert(err) }
            );
        }
    }


    return (
        <ViewWrapper>
            <Row className="mb-4">
                <Col>
                    <h2 className="h4">Roles</h2>
                </Col>
            </Row>
            <Row>
                <Col md={4}>
                    <Button color="link" size="sm" className="mt-2 pl-0"onClick={handleCreateRole}>
                        <FontAwesomeIcon icon={faPlus} style={{ marginRight: '8px' }} />
                        Create New Role
                    </Button>
                    {/*<Label>Edit Role</Label>*/}
                    <GreyPaddedWrapper>
                        {
                            rolesLoading
                                ? <Spinner color="primary" />
                                : (
                                    roles.map((ro, i) =>
                                        <FormGroup key={i} style={{ marginLeft: '18px' }} className="d-flex justify-content-between">
                                            <Label>
                                                <Input
                                                    type="radio"
                                                    value={ro.Id}
                                                    name="roleId"
                                                    onChange={e => {
                                                        let idNum = parseInt(e.target.value);
                                                        if (idNum)
                                                            setRoleId(idNum)
                                                    }}
                                                    checked={roleId === ro.Id}
                                                />
                                                {ro.Name}
                                            </Label>
                                            {
                                                roleId && roleId === ro.Id
                                                    ? (
                                                        <Button
                                                            color="danger"
                                                            size="sm"
                                                            //style={{ color: '#dc3545' }}
                                                            onClick={() => setConfirmModal(true)}
                                                            disabled={roleId === 1}
                                                        >
                                                            Delete Role
                                                            {/*    {' '}<FontAwesomeIcon icon={faTrash} />*/}
                                                        </Button>
                                                    )
                                                    : null
                                            }
                                        </FormGroup>
                                    )
                                )
                        }



                    </GreyPaddedWrapper>

                </Col>
                {
                    !roleId
                        ? (
                            <div>No role selected.</div>
                        )
                        : (
                            <Col>
                                {/*Alt button placement option*/}
                                {/*<Row>*/}
                                {/*    <Col>*/}
                                {/*        <Button*/}
                                {/*            color="danger"*/}
                                {/*            size="sm"*/}
                                {/*            className="float-right"*/}
                                {/*            onClick={() => handleDeleteRole(roleId)}*/}
                                {/*        >*/}
                                {/*            Delete Role*/}
                                {/*        </Button>*/}
                                {/*    </Col>*/}
                                {/*</Row>*/}
                                <Row>
                                    <Col>
                                        <Form onSubmit={handleSaveRoleDescriptors}>
                                            <FormGroup>
                                                <Label>Name</Label>
                                                <Input
                                                    type="text"
                                                    id="roleName"
                                                    name="roleName"
                                                    value={roleName.value || ''}
                                                    onChange={(e) => {
                                                        setRoleName({ value: e.target.value, touched: true, error: "" })
                                                    }}

                                                />
                                            </FormGroup>
                                            <FormGroup>
                                                <Label>Display Name</Label>
                                                <Input
                                                    type="text"
                                                    id="displayName"
                                                    name="displayName"
                                                    value={displayName.value || ''}
                                                    onChange={(e) => {
                                                        setDisplayName({ value: e.target.value, touched: true, error: "" })
                                                    }}
                                                />
                                            </FormGroup>
                                            <FormGroup>
                                                <Label>Description</Label>
                                                <Input
                                                    type="textarea"
                                                    id="description"
                                                    name="description"
                                                    value={description.value || ''}
                                                    onChange={(e) => {
                                                        setDescription({ value: e.target.value, touched: true, error: "" })
                                                    }}
                                                />
                                            </FormGroup>
                                            {
                                                roleName.touched || displayName.touched || description.touched
                                                    ? (
                                                        <div>
                                                            <Button
                                                                type="submit"
                                                                color="primary"
                                                                size="sm"
                                                                disabled={!!roleName.value === false}
                                                            >Save Changes</Button>
                                                            <Button
                                                                color="danger"
                                                                size="sm"
                                                                className="ml-2"
                                                                onClick={handleCancelRoleDescriptors}
                                                            >Cancel</Button>
                                                        </div>
                                                    )
                                                    : null
                                            }
                                        </Form>
                                    </Col>
                                    <Col>
                                        <FormGroup>
                                            <Label>Members</Label>
                                            <ListGroup style={{ overflowY: 'scroll', maxHeight: '232px' }}>
                                                {
                                                    roleMembers
                                                        .sort((a, b) => a.Name.toLowerCase().localeCompare(b.Name.toLowerCase()))
                                                        .map((m, i) =>
                                                            <ListGroupItem key={i} className="d-flex justify-content-between">
                                                                {m.Name}
                                                                <Button
                                                                    onClick={() => handleDeleteRoleUser(m.Id)}
                                                                    color="link"
                                                                    size="sm"
                                                                    style={{ color: '#dc3545' }}
                                                                >
                                                                    <FontAwesomeIcon icon={faTrashCan} />
                                                                </Button>
                                                            </ListGroupItem>
                                                        )
                                                }
                                            </ListGroup>
                                        </FormGroup>
                                        <Button
                                            color="link"
                                            size="sm"
                                            className="pl-0"
                                            onClick={() => setMemberModal(true)}
                                        >
                                            <FontAwesomeIcon icon={faUserPlus} style={{ marginRight: '8px' }} />
                                            Add Members
                                        </Button>
                                        <EnhancedRsModal isOpen={memberModal} setIsOpen={setMemberModal}>
                                            <ViewWrapper>
                                                <div className="d-flex justify-content-between">
                                                    <Label>Add Members to Role:</Label>
                                                    <Label>{roleName.value}</Label>
                                                </div>
                                                <ListGroup style={{ overflowY: 'scroll', maxHeight: '232px' }}>
                                                    {masterMembers
                                                        .filter(m => {
                                                            let existingMember = roleMembers.find(mem => mem.Id === m.Id)
                                                            if (!existingMember) {
                                                                return m
                                                            }
                                                            return null
                                                        })
                                                        .sort((a, b) => a.Name.toLowerCase().localeCompare(b.Name.toLowerCase()))
                                                        .map((m, i) => {
                                                            return (
                                                                <ListGroupItem key={i}>
                                                                    <FormGroup check>
                                                                        <Label check>
                                                                            <Input
                                                                                type="checkbox"
                                                                                id={`${i}`}
                                                                                onChange={(e) => {
                                                                                    if (e.target.checked) {
                                                                                        let newIds = [...addMemberIds.filter(id => id !== m.Id), m.Id];
                                                                                        setAddMemberIds(newIds);
                                                                                    } else {
                                                                                        let newIds = addMemberIds.filter(mid => mid !== m.Id);
                                                                                        setAddMemberIds(newIds);
                                                                                    }
                                                                                }}
                                                                            />
                                                                            {' '}{m.Name}
                                                                        </Label>
                                                                    </FormGroup>
                                                                </ListGroupItem>
                                                            )
                                                        })
                                                    }
                                                </ListGroup>
                                                <div className="mt-5">
                                                    <Button
                                                        size="sm"
                                                        color="primary"
                                                        onClick={handleAddRoleUsers}
                                                        className="mr-2"
                                                    >
                                                        Add Selected
                                                    </Button>
                                                    <Button
                                                        size="sm"
                                                        color="danger"
                                                        onClick={() => setMemberModal(false)}
                                                    >
                                                        Cancel
                                                    </Button>
                                                </div>
                                            </ViewWrapper>
                                        </EnhancedRsModal>

                                        <EnhancedRsModal isOpen={confirmModal} setIsOpen={setConfirmModal}>
                                            <ViewWrapper>
                                                <p>Are you sure you want to delete role "{roleName.value}"?</p>
                                                <div className="mt-5">
                                                    <Button
                                                        color="primary"
                                                        size="sm"
                                                        onClick={() => {
                                                            handleDeleteRole(roleId)
                                                        }}
                                                        className="mr-2"
                                                    >
                                                        Confirm
                                                    </Button>
                                                    <Button
                                                        color="danger"
                                                        size="sm"
                                                        onClick={() => {
                                                            setConfirmModal(false);
                                                        }}
                                                    >
                                                        Cancel
                                                    </Button>
                                                </div>
                                            </ViewWrapper>
                                        </EnhancedRsModal>
                                    </Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col>
                                        <RolePermissionsFormGrid
                                            masterPermissions={masterPermissions}
                                            rolePermissions={rolePermissions}
                                            roleId={roleId}
                                        />
                                    </Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col>
                                        <RoleNavigationFormGrid
                                            masterPermissions={masterNavigationPermissions}
                                            rolePermissions={roleNavigationPermissions}
                                            roleId={roleId}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        )
                }

            </Row>
        </ViewWrapper>
    )
}