import { IServerSideGetRowsParams, IServerSideGetRowsRequest } from '@ag-grid-enterprise/all-modules';
import JobStatusGrid from '../../../Components/React/JobControl/JobStatusGrid';
import TaskStatusGrid from '../../../Components/React/JobControl/TaskStatusGrid';
import JobControlService from './JobControl.service';
import RunManager from '../../../Components/React/JobControl/RunManager';

export default class JobControlDatasource {

    createJobDetailsDatasource = (jobControlService: JobControlService, jobStatusGrid: JobStatusGrid) => {
        return {
            getRows: function (params: IServerSideGetRowsParams) {
                // console.log('[Datasource] - rows requested by grid: ', params.request);

                let request: IServerSideGetRowsRequest = params.request;

                jobControlService.getJobDetails(request, (data: any[], totalCount: number) => {
                    if (totalCount != null) {
                        jobStatusGrid.setState({
                            totalRowCount: totalCount
                        });
                    }
                    params.successCallback(data, totalCount);
                }, () => {
                        // alert('Error: ' + JSON.stringify(err));
                    params.failCallback();
                });
            },
        };
    }

    createTaskDetailsDatasource = (jobControlService: JobControlService, taskStatusGrid: TaskStatusGrid) => {
        return {
            getRows: function (params: IServerSideGetRowsParams) {
                // console.log('[Datasource] - rows requested by grid: ', params.request);

                let request: IServerSideGetRowsRequest = params.request;

                jobControlService.getTaskDetails(request, (data: any[], totalCount: number) => {
                    if (totalCount != null) {
                        taskStatusGrid.setState({
                            totalRowCount: totalCount
                        });
                    }
                    params.successCallback(data, totalCount);
                }, () => {
                    // alert('Error: ' + JSON.stringify(err));
                    params.failCallback();
                });
            },
        };
    }

    createFakeServer = () => {
        function FakeServer() {
            this.data = 
            [
                {
                    NodeId: '1',
                    NodeName: 'Enterprise',
                    NodeDesc: 'Enterprise',
                    Children: [
                        {
                            NodeId: '1/1',
                            NodeName: 'Dept 1',
                            NodeDesc: 'Department 1',
                            Children: [
                                {
                                    NodeId: '1/1/1',
                                    NodeName: 'D1-Category 1',
                                    NodeDesc: 'D1: Category 1',
                                    Children: [
                                        {
                                            NodeId: '1/1/1/1',
                                            NodeName: '1111',
                                            NodeDesc: 'Product 1111', 
                                            Product:
                                            {
                                                ProductId: '1111', 
                                                UPC: '002820000386',
                                                Description: 'Product 1111',
                                                PriceFamilyId: 'P1',
                                                PriceFamilyDesc: 'Price Family P1', 
                                                IsMaster: true, 
                                                Brand: 'Brand 1'
                                            }
                                        }
                                    ]
                                },
                                {
                                    NodeId: '1/1/2',
                                    NodeName: 'D1-Category 2',
                                    NodeDesc: 'D1: Category 2',
                                },
                                {
                                    NodeId: '1/1/3',
                                    NodeName: 'D1-Category 3',
                                    NodeDesc: 'D1: Category 3',
                                },
                            ]
                        },
                        {
                            NodeId: '1/2',
                            NodeName: 'Dept 2',
                            NodeDesc: 'Department 2',
                            Children: [
                                {
                                    NodeId: '1/2/1',
                                    NodeName: 'D2-Category 1',
                                    NodeDesc: 'D2: Category 1',
                                },
                                {
                                    NodeId: '1/2/2',
                                    NodeName: 'D2-Category 1',
                                    NodeDesc: 'D2: Category 2',
                                },
                                {
                                    NodeId: '1/2/3',
                                    NodeName: 'D2-Category 1',
                                    NodeDesc: 'D2: Category 3',
                                },
                            ]
                        },
                        {
                            NodeId: '1/3',
                            NodeName: 'Dept 3',
                            NodeDesc: 'Department 3',
                            Children: [
                                {
                                    NodeId: '1/3/1',
                                    NodeName: 'D3-Category 1',
                                    NodeDesc: 'D3: Category 1',
                                },
                                {
                                    NodeId: '1/3/2',
                                    NodeName: 'D3-Category 2',
                                    NodeDesc: 'D3: Category 2',
                                },
                                {
                                    NodeId: '1/3/3',
                                    NodeName: 'D3-Category 3',
                                    NodeDesc: 'D3: Category 3',
                                },
                            ]
                        }
                    ]
                }
            ];
        }

        FakeServer.prototype.getData = function (request) {
            function extractRowsFromData(groupKeys, data) {
                if (groupKeys.length === 0) {
                    return data.map(function (d) {
                        let node = {
                            Group: !!d.Children,
                            NodeId: d.NodeId,
                            NodeName: d.NodeName,
                            NodeDesc: d.NodeDesc,
                        };
                        return node;
                    });
                }
                var key = groupKeys[0];
                for (var i = 0; i < data.length; i++) {
                    if (data[i].NodeId === key) {
                        return extractRowsFromData(
                            groupKeys.slice(1),
                            data[i].Children.slice()
                        );
                    }
                }
            }
            return extractRowsFromData(request.groupKeys, this.data);
        };
        return new FakeServer();
    }

    createProductHierarchyDatasource = (jobControlService: JobControlService, runManager: RunManager, fakeServer: any) => {
        function ProductHierarchyDatasource(jobControlService: JobControlService, runManager: RunManager, fakeServer: any) {
            this.fakeServer = fakeServer;
        }

        ProductHierarchyDatasource.prototype.getRows = function (params: IServerSideGetRowsParams) {
            console.log('[ProductHierarchyDatasource] - rows requested by grid: ', params.request);

            var rows = this.fakeServer.getData(params.request);
            setTimeout(function () {
                params.successCallback(rows, rows.length);
            }, 200);

            //jobControlService.getTaskDetails(request, (data: any[], totalCount: number) => {
            //    if (totalCount != null) {
            //        runManager.setState({
            //            totalRowCount: totalCount
            //        });
            //    }
            //    params.successCallback(data, totalCount);
            //}, () => {
            //    // alert('Error: ' + JSON.stringify(err));
            //    params.failCallback();
            //});
        };
        return new ProductHierarchyDatasource(jobControlService, runManager, fakeServer);
    }

}

