import Tableau_Data from "../Types/TableauData.model";
import Tableau_Entry from "../Types/TableauEntry.model";
import IPMOBaseService from '../../Generics/Service/IPMOBaseService';
export default class TableauService extends IPMOBaseService {
    buildTableau(
        id: number,
        name: string,
        desc: string,
        workbook: string,
        view: string,
        creator: string,
        createDate: Date,
        updator: string,
        updateDate: Date,
        ActiveFlag: boolean,
        parent: number
    ) {
        let p;
        p = new Tableau_Data();
        p.ReportId = id;
        p.ReportName = name;
        p.ReportDescription = desc;
        p.TableauWorkbook = workbook;
        p.TableauView = view;
        p.CreatedBy = creator;
        p.CreatedDate = createDate;
        p.UpdatedBy = updator;
        p.UpdateDate = updateDate;
        p.ActiveFlag = ActiveFlag;
        p.ParentId = parent;

        let itm;
        itm = new Tableau_Entry();
        itm.id = id;
        itm.text = name;
        itm.items = [];
        itm.data = p;
        itm.expanded = true;
        return itm;
    }
    recur(obj: any, searchVal: any) {
        for (let o of obj.children) {
            if (searchVal === o.permissionName) {
                return o;
            }
        }
    }    deleteReport(dto: any, callback: any, errorCallback: any) {
        let methodName = "Tableau Delete Report";
        super.post('/PricingEngineService/reports/delete/', dto, methodName,
            (result) => {
                let retVal;

                retVal = this.buildTableau(
                    -1,
                    "Reports",
                    "",
                    "",
                    "",
                    "",
                    new Date(),
                    "",
                    new Date(),
                    false,
                    -1
                );

                retVal.items = result.data;
                let ret2 = [];
                ret2.push(retVal);
                callback(ret2)
            },
            (error) => {
                errorCallback(error)

            });
    }
    upsertReport(dto: any, callback: any, errorCallback: any) {
        let methodName = "Tableau Upsert Report";
        super.post('/PricingEngineService/reports/upsert/', dto, methodName,
            (result) => {
                let retVal;

                retVal = this.buildTableau(
                    -1,
                    "Reports",
                    "",
                    "",
                    "",
                    "",
                    new Date(),
                    "",
                    new Date(),
                    false,
                    -1
                );

                retVal.items = result.data;
                let ret2 = [];
                ret2.push(retVal);
                callback(ret2);
            },
            (error) => {
                errorCallback(error)

            });
    }
    getReports(callback: any, errorCallback: any) {
        let methodName = "Tableau Get Reports";
        super.get('/PricingEngineService/reports/menu/', methodName,
            (result) => {

                    let retVal: any;

                    retVal = this.buildTableau(
                        -1,
                        "Reports",
                        "",
                        "",
                        "",
                        "",
                        new Date(),
                        "John Rossitter",
                        new Date(),
                        false,
                        -1
                    );

                    retVal.items = result.menuItems;
                    let ret2: any[] = [];
                    ret2.push(retVal);
                    callback(ret2);
              
            },
            (error) => {
                errorCallback(error)
            });
    }
}
