import React, { forwardRef, SetStateAction, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { IRolePermissionTemplate, IReqRolePermission, IRole, IIdentitySimple } from '../../../Services/Roles/Roles.service.types';
import { IFrequency, IAuditActionFrequencyAccess, IAuditActionFrequencyAccessGridData, IAuditDetailType, IAuditAction, IReqSaveAuditActionFrequencyAccess, IReqDeleteAuditActionFrequencyAccess } from '../../../Services/AuditAction/AuditAction.service.types';
import { Input, Label } from 'reactstrap';
import SpinnerWithMessage from '../Common/SpinnerWithMessage/SpinnerWithMessage';


import { AgGridReact } from '@ag-grid-community/react';
import {
    ColDef, ColGroupDef, ICellEditorParams, ICellRendererParams,
} from '@ag-grid-community/core';
import {
    GridApi, ColumnApi, RowNode, Column, RowDataTransaction, AllCommunityModules, ClientSideRowModelModule, RowGroupingModule
} from '@ag-grid-enterprise/all-modules';
import { CellChangedEvent } from '@ag-grid-community/core/dist/cjs/entities/rowNode';
import gridHelper from '../helpers/grid-helper';
import { IStateSelectOption } from '../../../Services/Common/Types/Common.types';
import ActionService from '../../../Services/AuditAction/AuditAction.service';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { faMinusCircle, faPlus, faUser, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import RolesService from '../../../Services/Roles/Roles.service';
import ReactDOM from 'react-dom';




interface AafaCellRendererParams extends ICellRendererParams {
    data: IAuditActionFrequencyAccessGridData;
    selectOptions?: IStateSelectOption[];
    createOrUpdate?: React.Dispatch<SetStateAction<IReqSaveAuditActionFrequencyAccess>>; //(postBody: IReqSaveAuditActionFrequencyAccess) => void;
    deleteRecord?: React.Dispatch<SetStateAction<number | null>>;
}


// Dropdown Component Value Renderer
const SelectRenderer = (props: AafaCellRendererParams) => {

    if (!props.selectOptions) {
        return null
    }
    return (
        <Input
            type="select"
            name=""
            value={`${props.value}`}
            onChange={(e) => {
                let idInt = parseInt(e.target.value);
                props.setValue(idInt)
            }}
        >
            {props?.selectOptions.map((so: IStateSelectOption, i: number) =>
                <option key={i} value={so.id}>{so.label}</option>
            ) }
        </Input>
    )
}

const AddNewAafaButtonRenderer = (params: AafaCellRendererParams) => {
    return (
        <Link
            to="#"
            onClick={() => {
                const { TreeId, HierarchyTypeId, FrequencyId, AuditActionId, IdentityId } = params.data;
     
                    const newAafa = {
                        Id: null,
                        AuditActionId: AuditActionId,
                        TreeId: TreeId,
                        HierarchyTypeId: HierarchyTypeId,
                        FrequencyId: FrequencyId,
                        IdentityId: IdentityId
                } as IReqSaveAuditActionFrequencyAccess;

                if (params.createOrUpdate) {
                    params.createOrUpdate(newAafa);
                }
                // TODO remove unstable_flushDiscreteUpdates error with below
                //params.api.forEachNode(n => {
                //    if (n.data.Id !== params.data.Id)
                //        newUsers.push(n.data)
                //})
                //if (params.setUsers) params.setUsers(newUsers)
             
            }}
            color="link"
        >
            <FontAwesomeIcon icon={faPlus} />
            {/*{' '}New*/}
        </Link>
    )
}

const DeleteAafaButtonRenderer = (params: AafaCellRendererParams) => {
    return (
        <Link
            to="#"
            onClick={() => {
                if (params.deleteRecord) params.deleteRecord(params.data.Id);
                // TODO remove unstable_flushDiscreteUpdates error with below
                //params.api.forEachNode(n => {
                //    if (n.data.Id !== params.data.Id)
                //        newUsers.push(n.data)
                //})
                //if (params.setUsers) params.setUsers(newUsers)
            }}
            color="link"
            className="text-danger"
        >
            <FontAwesomeIcon icon={faMinusCircle} />
        </Link>
    )
}



interface IProps {
    treeId: number | null;
    hierTypeId: number | null;
}


export default function PermissionActionIdentityFrequencyFormGrid({ treeId, hierTypeId }: IProps) {
    const actionService = new ActionService();
    const rolesService = new RolesService();

    const [gridApi, setGridApi] = useState<GridApi>(null);
    const [colApi, setColApi] = useState<ColumnApi>(null);

    const [aafaRecords, setAafaRecords] = useState<IAuditActionFrequencyAccess[]>([]);
    const [aaRecords, setAaRecords] = useState<IAuditAction[]>([]);
    const [sourceData, setSourceData] = useState<IAuditActionFrequencyAccessGridData[]>([]);
    const [sourceDataLoading, setSourceDataLoading] = useState<boolean>(false);

    const [frequencyOptions, setFrequencyOptions] = useState<IFrequency[]>([]);
    const [identityOptions, setIdentityOptions] = useState<IIdentitySimple[]>([]);

    const [postBody, setPostBody] = useState<IReqSaveAuditActionFrequencyAccess>(null);
    const [idToDelete, setIdToDelete] = useState<number | null>(null);
   
    const [colDefs, setColDefs] = useState<(ColDef | ColGroupDef)[]>([
        {
            headerName: "",
            field: "Delete",
            cellRenderer: 'deleteAafaButtonRenderer',
            cellRendererParams: {
                deleteRecord: setIdToDelete
            },
            width: 40,
            cellStyle: (params) => {

                if (params.data.Id && params.data.TreeId != null) {
                    return { visibility: 'visible' }
                }
                return { visibility: 'hidden' }
            }
        },
        {
            headerName: '',
            field: 'AuditDetailTypeGroupDisplayName',
            //cellRenderer: 'agGroupCellRenderer',
            rowGroup: true,
            hide: false,
            cellStyle: (params) => {
                return params.data.Id ? { visibility: 'hidden' } : null;
            },
        },
        {
            headerName: 'Listeners',
            field: 'AuditDetailTypeDisplayName',
            //cellRenderer: 'agGroupCellRenderer',
            rowGroup: true,
            hide: false,
            width: 150,
            cellStyle: (params) => {
                return params.data.Id ? { visibility: 'hidden' } : null;
            },
            tooltipValueGetter: (params) => {
                return params.data.AuditActionPseudocode 
            }
        },
        {
            headerName: 'Recipients',
            field: 'IdentityId',
            width: 150,
            editable: true,
            cellRenderer: 'selectRenderer',
            cellRendererParams: {
                   selectOptions: identityOptions.map(f => { return { id: f.Id, label: f.DisplayName } as IStateSelectOption }) as IStateSelectOption[],
            },
            cellStyle: (params) => {
                return !params.data.Id ? { visibility: 'hidden' } : { display: 'flex', alignItems: 'center', justifyContent: 'center' };
            },
        },
        {
            headerName: "Frequency",
            field: "FrequencyId",
            width: 150,
            editable: true,
            cellRenderer: 'selectRenderer',
            cellRendererParams: {
                selectOptions: frequencyOptions.map(f => { return { id: f.Id, label: f.Name } as IStateSelectOption }) as IStateSelectOption[],
            },
            cellStyle: (params) => {
                return !params.data.Id ? { visibility: 'hidden' } : { display: 'flex', alignItems: 'center', justifyContent: 'center' };
            },
        },
        {
            headerName: "",
            field: "AddNew",
            cellRenderer: 'addNewAafaButtonRenderer',
            cellRendererParams: {
                createOrUpdate: setPostBody
            },
            width: 40,
            cellStyle: (params) => {
                
                if (!params.data.Id && params.data.AuditDetailTypeDisplayName && params.data.TreeId != null) {
                    return { visibility: 'visible' }
                }
                return { visibility: 'hidden' }
            }
        },
     
      
    ]);
    const [colDefComponents, setColDefComponents] = useState<object>({
        selectRenderer: SelectRenderer,
        addNewAafaButtonRenderer: AddNewAafaButtonRenderer,
        deleteAafaButtonRenderer: DeleteAafaButtonRenderer,
    })

    const createOrUpdate = (postBody: IReqSaveAuditActionFrequencyAccess) => {
        actionService.saveAuditActionFrequencyAccess(
            postBody,
            (res) => {
                let newAafas = [...aafaRecords];

                newAafas = newAafas.map(r => {
                    if (res.Id === r.Id) {
                        return res
                    }
                    return r
                })

                if (!!newAafas.find(a => a.Id === res.Id) === false) {
                    newAafas.push(res);
                }

                setAafaRecords(newAafas);
            },
            (err) => {
                alert(err)
            }
        )
    }

    const deleteRecord = (id: number) => {
        const deletePostBody = { Id: id } as IReqDeleteAuditActionFrequencyAccess;
        actionService.deleteAuditActionFrequencyAccess(
            deletePostBody,
            (res) => {
                console.log('Deleted')
                const newAafas = aafaRecords.filter(r => r.Id !== id)
                setAafaRecords(newAafas)
            },
            (err) => alert(err)
        )
    }

    // When grid mounts, get all of the dropdown options
    useEffect(() => {
        actionService.getFrequencies(
            (res) => setFrequencyOptions(res),
            (err) => alert(err)
        )
        rolesService.getRoles(
            null,
            (res) => setIdentityOptions(res),
            (err) => alert(err)
        )
        return () => {}
    }, [])

    // When select options update, reset the colDefs 
    useEffect(() => {
        if (frequencyOptions.length > 0 || identityOptions.length > 0) {
            let newColDefs = [...colDefs] as ColDef[];

            for (let cd of newColDefs) {
                if (cd.field === "FrequencyId") {
                    cd.cellRendererParams.selectOptions = frequencyOptions.map(f => { return { id: f.Id, label: f.Name } as IStateSelectOption }) as IStateSelectOption[];
                }
                if (cd.field === "IdentityId") {
                    cd.cellRendererParams.selectOptions = identityOptions.map(i => { return { id: i.Id, label: i.DisplayName } as IStateSelectOption }) as IStateSelectOption[];
                }
            }
            setColDefs(newColDefs);
        }
        return () => { }
    }, [frequencyOptions, identityOptions])

    // When "Add New" plus button is clicked it will set a postbody for call 
    useEffect(() => {
        if (postBody) {
            createOrUpdate(postBody)
        }
        return () => {}
    }, [postBody])

    // When "Delete" is clicked it will set an id for a delete call 
    useEffect(() => {
        if (idToDelete) {
            deleteRecord(idToDelete)
        }
        return () => { }
    }, [idToDelete])

    // Get the template (AuditActions), and the data (AuditActionFrequencyAccess) 
    useEffect(() => {
            actionService.getAuditActions(
                (res) => {
                    setAaRecords(res)
                },
                (err) => {
                    alert(err)
                }
            )
            if (treeId && hierTypeId == 0) {
                actionService.getAuditActionFrequencyAccessByTreeId(
                    treeId,
                    (res) => {
                        setAafaRecords(res);
                    },
                    (err) => {
                        alert(err)
                    }
                )
            }
     
        return () => {}
    }, [treeId, hierTypeId])

    // Format col widths to fit screen
    useEffect(() => {
        if (gridApi) {
            gridApi.expandAll();
            gridHelper.shrinkOrExpandColsToFitGrid(gridApi);
        }
        return () => { }
    }, [gridApi])

    // When the template or data changes reformat the source data
    useEffect(() => {
        if (aaRecords.length > 0 && aafaRecords) {
  
            const newSourceData = [] as IAuditActionFrequencyAccessGridData[];

            const uniqAaGroupNames = aaRecords.reduce((a: IAuditAction[], b: IAuditAction) => { return a.filter(r => r.AuditDetailTypeGroupDisplayName === b.AuditDetailTypeGroupDisplayName).length > 0 ? a : [...a, b] }, []);
            const uniqAaDisplayNames = aaRecords.reduce((a: IAuditAction[], b: IAuditAction) => { return a.filter(r => r.AuditDetailTypeDisplayName === b.AuditDetailTypeDisplayName).length > 0 ? a : [...a, b] }, []);

             // Build the data the permission template
            for (let uniqAdtGroupName of uniqAaGroupNames) {

                const newRow = {
                    AuditDetailTypeGroupDisplayName: uniqAdtGroupName.AuditDetailTypeGroupDisplayName,
                    Data: uniqAaDisplayNames  
                        .filter(uniqAaDisplayName => uniqAdtGroupName.AuditDetailTypeGroupDisplayName === uniqAaDisplayName.AuditDetailTypeGroupDisplayName)
                        .map(uniqAaDisplayName => {
                            // These parent rows have the Add New button. Pass params for default post fields on the new record.
                            return {
                                AuditDetailTypeId: uniqAaDisplayName.Id,
                                AuditDetailTypeDisplayName: uniqAaDisplayName.AuditDetailTypeDisplayName,
                                AuditActionId: uniqAaDisplayName.Id, // TODO make sure AuditAction GroupDisplayName with DisplayName combo is unique, to tie in correct AuditActionId
                                TreeId: treeId,
                                HierarchyTypeId: hierTypeId,
                                FrequencyId: 4, // default "None"
                                IdentityId: 1, // default "Global Admin"
                                AuditActionPseudocode: uniqAaDisplayName.Pseudocode,
                                Data: aafaRecords
                                    .filter(af => af.AuditDetailTypeDisplayName === uniqAaDisplayName.AuditDetailTypeDisplayName)
                            }
                        })
                } as IAuditActionFrequencyAccessGridData;

                newSourceData.push(newRow);
            }
            setSourceData(newSourceData)
        }
        return () => {}
    }, [aaRecords, aafaRecords])

    const onGridReady = (params: any) => {
        setGridApi(params.api);
        setColApi(params.columnApi)
    }

    const onCellValueChanged = (params: CellChangedEvent) => {
        const patchBody = {
            Id: params.node.data.Id,
            TreeId: treeId,
            HierarchyTypeId: hierTypeId,
            AuditActionId: params.node.data.AuditActionId,
            FrequencyId: params.node.data.FrequencyId,
            IdentityId: params.node.data.IdentityId,   
        } as IReqSaveAuditActionFrequencyAccess;

        createOrUpdate(patchBody);
    }

    return (
        <div>
            <div className='ag-theme-balham'>
                <AgGridReact
                    modules={[...AllCommunityModules, RowGroupingModule, ClientSideRowModelModule]}
                    onGridReady={onGridReady}
                    onCellValueChanged={onCellValueChanged}
                    domLayout={'autoHeight'}
                    columnDefs={colDefs as ColDef[]}
                    defaultColDef={{
                        resizable: true,
                        width: 80
                    }}
                    autoGroupColumnDef={{
                        headerName: '',
                       maxWidth: 0,
                        hide: true,
                        cellRendererParams: {
                            suppressCount: true,
                        },
                    }}
                    getRowHeight={(params) => {
                        if (params.data.Id) {
                            return 60
                        }
                        return null
                    }}
                    getNodeChildDetails={(parent: IAuditActionFrequencyAccessGridData) => {
                        if (parent.Data) {
                            return {
                                group: true,
                                children: parent.Data,
                                expanded: true
                            };
                        }
                        else {
                            return null;
                        }
                    }}
                    animateRows={true}
                    rowData={sourceData}
                    rowModelType={'clientSide'}
                    frameworkComponents={{
                        customLoadingOverlayComponent: SpinnerWithMessage,
                        ...colDefComponents
                    }}
                    loadingOverlayComponent="customLoadingOverlayComponent"
                    loadingOverlayComponentParams={{
                        loadingMessage: 'Loading Permissions...',
                    }}
                />
            </div>
        </div>
    )
}