import * as React from 'react';
import MasterContext from "../Misc/MasterContext"
import {  Redirect } from 'react-router';
import Spinner from '../../React/Misc/Spinner';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import LoginService from '../../../Services/Login/Service/Login.service';
import * as types from '../../../Services/Login/Types/Login.model'


export interface IProps {

}
export interface IState {
    passwordAlreadyUsed: boolean;
    passwordError: string;
    oldPassword: string;
    newPassword: string;
    confirmNewPassword: string;
    resetPasswordValidationErrors: any[];
    loaded: boolean;
    showPasswordChangedModal: boolean;
    okToRedirect: boolean;
    isUpdating:boolean


}

export default class ChangePassword extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            passwordError:'',
            passwordAlreadyUsed:false,
            oldPassword: '',
            newPassword: '',
            confirmNewPassword: '',
            resetPasswordValidationErrors: [],
            loaded: false,
            showPasswordChangedModal: false,
            okToRedirect: false,
            isUpdating: false
        }
        this.handleOldPasswordChange = this.handleOldPasswordChange.bind(this);
        this.handleNewPasswordChange = this.handleNewPasswordChange.bind(this);
        this.handleNewConfirmPasswordChange = this.handleNewConfirmPasswordChange.bind(this);
        this.togglepasswordChangedModal = this.togglepasswordChangedModal.bind(this);
        this.validateResetPassword = this.validateResetPassword.bind(this);
        this.changePassword = this.changePassword.bind(this);
        this.CloseDialog = this.CloseDialog.bind(this);
        this.showRedirect = this.showRedirect.bind(this);


        this.startLoad = this.startLoad.bind(this);
        this.finishLoad = this.finishLoad.bind(this);
        this.getModalMessage = this.getModalMessage.bind(this);
    }
    getModalMessage() {
        if (this.state.passwordAlreadyUsed === false) {
            return MasterContext.translate("change_password_modal_body");
        }
        else {
            //return MasterContext.translate("change_password_history_error");
            return this.state.passwordError;
        }
    }
    togglepasswordChangedModal() {
        this.setState({ showPasswordChangedModal: !this.state.showPasswordChangedModal });
    }
    startLoad() {
        this.setState({ loaded: true });
    }

    finishLoad() {
        this.setState({ loaded: false });
    }

    changePassword() {
        if (this.validateResetPassword() === false) {
            this.setState({isUpdating:true});
            this.startLoad();
            //make server call to change password

            let loginService: LoginService = new LoginService();
            let loginToken: types.LoginToken = new types.LoginToken();
            loginToken.UserName = MasterContext.UserData.userName;
            loginToken.Password = this.state.oldPassword;
            loginToken.NewPassword = this.state.newPassword;
;
            this.startLoad();

            loginService.changePassword(loginToken,
                (res) => {
                    console.log(res);
                    if (res.Data === true) {
                        this.setState({ passwordAlreadyUsed: false }, () => {
                            this.togglepasswordChangedModal();
                            this.finishLoad();
                        });
                    }
                    else {
                        this.setState({ passwordAlreadyUsed: true, passwordError: res.ErrorMessage }, () => {
                            
                            this.togglepasswordChangedModal();
                            this.finishLoad();
                        });

                    }
                    this.setState({ isUpdating: false });
                    //console.log(res)
                },
                (res) => {
                    
                    this.setState({ isUpdating: false });
                }
            )
        }

    }
    handleOldPasswordChange(event: any) {
        let v = event.target.value;
        this.setState({ oldPassword: v }, () => { this.validateResetPassword()});
    }

    handleNewPasswordChange(event: any) {
        let v = event.target.value;
        this.setState({ newPassword: v }, () => { this.validateResetPassword() });
    }
    handleNewConfirmPasswordChange(event: any) {
        let v = event.target.value;
        this.setState({ confirmNewPassword: v }, () => { this.validateResetPassword() });
    }

    checkPasswordRegex(suggestedPassword: string): boolean {
        let retVal: boolean = true

        
        if (MasterContext.PWREGEX.test(suggestedPassword) === false) {
            retVal = false;
        }
        return retVal;
    }


    //registration code
    validateResetPassword(): boolean {
        let retVal: boolean = false;

        let newErrors: any[] = [];

        if (this.state.oldPassword.length === 0) {
            let newErr: any = {};
            newErr.controlName = 'oldPassword';
            newErr.error = MasterContext.translate('validation_change_password_old');
            newErr.severity = 1;
            newErrors.push(newErr);
            retVal = true;
        }

        if (this.state.newPassword.length === 0) {
            let newErr: any = {};
            newErr.controlName = 'newPassword';
            newErr.error = MasterContext.translate('validation_change_password_new');
            newErr.severity = 1;
            newErrors.push(newErr);
            retVal = true;
        }


        if (this.state.confirmNewPassword.length === 0) {
            let newErr: any = {};
            newErr.controlName = 'confirmNewPassword';
            newErr.error = MasterContext.translate('validation_change_password_confirm');
            newErr.severity = 1;
            newErrors.push(newErr);
            retVal = true;
        }

        if (this.state.oldPassword.length > 0 && this.state.oldPassword.length > 0) {
            if (this.state.newPassword !== this.state.confirmNewPassword) {
                let newErr: any = {};
                newErr.controlName = 'newPassword';
                newErr.error = MasterContext.translate('validation_change_password_no_match');
                newErr.severity = 1;
                newErrors.push(newErr);

                let newErr2: any = {};
                newErr2.controlName = 'confirmNewPassword';
                newErr2.error = MasterContext.translate('validation_change_password_no_match');
                newErr2.severity = 1;
                newErrors.push(newErr2);
                retVal = true;
            }
            else {


                if (this.checkPasswordRegex(this.state.newPassword) === true) {

                }
                else {

                    let newErr: any = {};
                    newErr.controlName = 'newPassword';
                    newErr.error = MasterContext.translate('validation_change_password_complexity');
                    newErr.severity = 1;
                    newErrors.push(newErr);

                    let newErr2: any = {};
                    newErr2.controlName = 'confirmNewPassword';
                    newErr2.error = MasterContext.translate('validation_change_password_complexity');
                    newErr2.severity = 1;
                    newErrors.push(newErr2);
                    retVal = true;
                }



            }

        }

        this.setState({ resetPasswordValidationErrors: newErrors });
        return retVal;


    }
    getMyValidationErrors(control: string): string {
        let retVal: string = '';
        if (this.state.resetPasswordValidationErrors) {
            for (let err of this.state.resetPasswordValidationErrors) {
                if (err.controlName === control) {
                    retVal = retVal + err.error + '\r\n';
                }
            }
        }
        return retVal;
    }
    CloseDialog() {
        if (this.state.passwordAlreadyUsed === false) {
            localStorage.removeItem("cachedUser");
            localStorage.removeItem("navigationPermissions");
            MasterContext.UserData = { userName: '', navigationPermissions: [] };
            this.setState({ okToRedirect: true });
        } else {
            
            this.setState({
                showPasswordChangedModal: false,
                oldPassword: '',
                newPassword: '',
                confirmNewPassword:''
            });
        }

    }
    getPWComplexityData() {
        return MasterContext.PWREGEXINFO;
    }
    showRedirect() {
        let retVal: any;
        retVal = <div></div>
        if (this.state.okToRedirect === true) {
            retVal = <Redirect to='/logout' />
        }
        return retVal;
    }
    getUpdateButton() {
        let retVal: any = <i />;
        if (this.state.isUpdating === false) {
            retVal =<input type="submit" name="submit" onClick={this.changePassword} className="btn loginColorsButton btn-md" value={MasterContext.translate("change_password_button")} />

        }
        return retVal
        
    }
    render() {
        
        let errorClass = { color: "#ff0000" };


        return (
            <React.Fragment>
                <Spinner
                    spinnerActive={this.state.loaded}
                    heightSet="99vh"
                    paddingSet="10%"
                    spinnerText={MasterContext.translate("spinner_updating")}
                />
                <Modal zIndex={3002} isOpen={this.state.showPasswordChangedModal}>
                    <ModalHeader>{MasterContext.translate("change_password_modal_title")}</ModalHeader>
                    <ModalBody>
                        { this.getModalMessage()}
                        
                    </ModalBody>
                    <ModalFooter>
                        <button className="btn btn-sm btn-outline-info" color="primary" onClick={() => this.CloseDialog()}>{MasterContext.translate("change_password_modal_ok")}</button>{' '}

                    </ModalFooter>
                </Modal>



                <div className="row">
                    <div className="col-md-4"></div>
                    <div className="col-md-4">
                        <div className='container card'>

                            <h3 className="text-center text-info">{MasterContext.translate("change_password_title")}</h3>

                            <div className="row">
                                <div className="col-md-12">
                                    <div className="alert alert-secondary" role="alert">
                                        {MasterContext.translate("change_password_body")}
                            </div>

                                </div>
                            </div>

                            <div className="form-group">
                                <div className="row">
                                    <div className="col-md-3">
                                        <label className="loginColors">{MasterContext.translate("change_password_old_password")}</label>
                                    </div>
                                    <div className="col-md-9">
                                        <input type="password" id="oldPassword" value={this.state.oldPassword} onChange={this.handleOldPasswordChange} className="form-control" />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-3">
                                    </div>
                                    <div className="col-md-9">
                                        <span className="pull-right"  style={errorClass}>{this.getMyValidationErrors('oldPassword')}</span>
                                    </div>
                                </div>

                            </div>

                            <div className="row">
                                <div className="col-md-12">
                                    <div className="alert alert-secondary" style={{ fontSize: "12px", fontStyle: "italic", paddingLeft: "20px", color: "#0b243c" }}>
                                        {
                                            this.getPWComplexityData()
                                        }
                                    </div>
                                </div>
                            </div>

                            <div className="form-group">
                                <div className="row">
                                    <div className="col-md-3">
                                        <label className="loginColors">{MasterContext.translate("change_password_new_password")}</label>
                                    </div>
                                    <div className="col-md-9">
                                        <input type="password" id="newPassword" value={this.state.newPassword} onChange={this.handleNewPasswordChange} className="form-control" />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-3">
                                    </div>
                                    <div className="col-md-9">
                                        <span className="pull-right"  style={errorClass}>{this.getMyValidationErrors('newPassword')}</span>
                                    </div>
                                </div>



                            </div>
                            <div className="form-group">
                                <div className="row">
                                    <div className="col-md-3">
                                        <label className="loginColors">{MasterContext.translate("change_password_confirm_password")}</label>
                                    </div>
                                    <div className="col-md-9"><input type="password" id="confirmNewPassword" value={this.state.confirmNewPassword} onChange={this.handleNewConfirmPasswordChange} className="form-control" />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-3">
                                    </div>
                                    <div className="col-md-9"><span className="pull-right" style={errorClass}>{this.getMyValidationErrors('confirmNewPassword')}</span>
                                    </div>
                                </div>



                            </div>
                            <div className="form-group">
                                {this.getUpdateButton()}
                                
                            </div>
                            
                        </div>
                    </div>
                    <div className="col-md-4"></div>
                </div>



                {this.showRedirect()}
            </React.Fragment>
        );
    }
}