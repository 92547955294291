
export class LoginToken {
    public UserName: string = "";
    public Password: string = "";
    public NewPassword: string = "";
    public ClientVersion: string = "";
}

export class RegistrationToken {
    public FirstName: string = "";
    public LastName: string = "";
    public CompanyName: string = "";
    public UserName: string = "";
    public EmailAddress: string = "";
    public Password: string = "";
    public PhoneNumber: string = "";
}