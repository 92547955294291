import * as React from 'react';
import MasterContext from "../Misc/MasterContext"
import Spinner from '../../React/Misc/Spinner';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { AgGridReact } from '@ag-grid-community/react';
import { ColDef } from '@ag-grid-community/core';
import '@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css';
import '@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css';
import moment from 'moment';
import * as types from '../../../Services/Login/Types/Login.model';
import CreateMessageRequest from '../../../Services/MessageManager/Types/MessageManager.model';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

export interface IProps {

}
export interface IState {
    loaded:boolean,
    messages: any[],
    columnDefs: any[],
    columnTypes: {
        [key: string]: ColDef;
    },
    startDate: Date,
    endDate: Date,
    messageBody: string;
    validationErrors: any[];
    targetUsers: string;
    submitMode: number
    messageID: number;
    showDeleteModal:boolean
}

export default class MessageManager extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            messageID:0,
            submitMode:1,
            targetUsers:'',
            messageBody:'',
            validationErrors:[],
            startDate: this.getStartDate(),
            endDate: this.getEndDate(),
            loaded:false,
            messages: [],
            columnDefs: [
                {
                    field: "MessageID",
                    headerName: "Message ID",
                    width: 100
                },
                {
                    field: "ValidFrom",
                    headerName: "Valid From",
                    type: 'dateColumn',
                    width: 150
                },
                {
                    field: "ValidTo",
                    headerName: "Valid To",
                    type: 'dateColumn',
                    width: 150
                },
                {
                    field: "Message",
                    headerName: "Message",
                    minWidth: 800,
                }
            ],
            columnTypes: {
                dateColumn: {
                    valueFormatter: function (params) {
                        return moment(params.value).format('MM/DD/YYYY hh:mm:ss A');
                    },
                    filter: 'agDateColumnFilter',
                    filterParams: {
                        comparator: function (filterLocalDateAtMidnight, cellValue) {
                            var dateParts = cellValue.split('/');
                            var day = Number(dateParts[0]);
                            var month = Number(dateParts[1]) - 1;
                            var year = Number(dateParts[2]);
                            var cellDate = new Date(year, month, day);
                            if (cellDate < filterLocalDateAtMidnight) {
                                return -1;
                            } else if (cellDate > filterLocalDateAtMidnight) {
                                return 1;
                            } else {
                                return 0;
                            }
                        },
                    },
                }
            },
            showDeleteModal:false
        }
        this.getMesageData = this.getMesageData.bind(this);
        this.getStartDate = this.getStartDate.bind(this);
        this.getEndDate = this.getEndDate.bind(this);
        this.handleStartDateChange = this.handleStartDateChange.bind(this);
        this.handleEndDateChange = this.handleEndDateChange.bind(this);
        this.validate = this.validate.bind(this);
        this.getMyValidationErrors = this.getMyValidationErrors.bind(this);
        this.createMessage = this.createMessage.bind(this);
        this.updateMessage = this.updateMessage.bind(this);       
        this.handleMessageBodyChange = this.handleMessageBodyChange.bind(this);
        this.handleTargetUsersChange = this.handleTargetUsersChange.bind(this);
        this.resetForm = this.resetForm.bind(this);
        this.getContextMenuItems = this.getContextMenuItems.bind(this);
        this.convertUsersToString = this.convertUsersToString.bind(this);
        this.cancelUpdate = this.cancelUpdate.bind(this);
        this.deleteMessage = this.deleteMessage.bind(this);
        this.cancelDeleteMessage = this.cancelDeleteMessage.bind(this);
        
        
        
    }
    getMyValidationErrors(control: string): string {
        let retVal: string = '';
        if (this.state.validationErrors) {
            for (let err of this.state.validationErrors) {
                if (err.controlName === control) {
                    retVal = retVal + err.error + '\r\n';
                }
            }
        }
        return retVal;
    }
    getEndDate() {
        let retVal: Date = new Date();
        retVal.setMonth(retVal.getMonth() + 1)
        return retVal;
    }
    getStartDate() {
        let retVal: Date = new Date();
        return retVal;
    }
    componentDidMount() {
        this.getMesageData();
    }
    getMesageData() {
        this.setState({ loaded: true }, () => {
            let AAR: types.LoginToken = new types.LoginToken();
            AAR.UserName = MasterContext.UserData.userName;
            MasterContext.MessageManagerService.listAll(AAR,
                (res) => {
                    this.setState({ messages: res, loaded: false });
                },
                (err) => { }
            )
        })
    }
    handleStartDateChange(event: any) {
        this.setState({ startDate: event }, () => { this.validate(); });
    }
    handleEndDateChange(event: any) {
        this.setState({ endDate: event }, () => { this.validate() });
    }
    handleMessageBodyChange(event: any) {
        let v = event.target.value;
        this.setState({ messageBody: v }, () => { this.validate() });
    }
    handleTargetUsersChange(event: any) {
    let v = event.target.value;
    this.setState({ targetUsers: v }, () => { this.validate() });
}
    validate(): boolean {
        let retVal: boolean = false;

        let newErrors: any[] = [];

        if (this.state.startDate > this.state.endDate) {
            let newErr: any = {};
            newErr.controlName = 'startDate';
            newErr.error = MasterContext.translate('validation_message_manager_start_date');
            newErr.severity = 1;
            newErrors.push(newErr);
            retVal = true;

            let newErr2: any = {};
            newErr2.controlName = 'endDate';
            newErr2.error = MasterContext.translate('validation_message_manager_end_date');
            newErr2.severity = 1;
            newErrors.push(newErr2);
            retVal = true;

        }
        if (this.state.messageBody.length === 0) {
            let newErr: any = {};
            newErr.controlName = 'messageBody';
            newErr.error = MasterContext.translate('validation_message_manager_body_missing');
            newErr.severity = 1;
            newErrors.push(newErr);
            retVal = true;
        }
        if (this.state.targetUsers.length === 0) {
            let newErr: any = {};
            newErr.controlName = 'users';
            newErr.error = MasterContext.translate('validation_message_manager_users_missing');
            newErr.severity = 1;
            newErrors.push(newErr);
            retVal = true;
        }
        


        this.setState({ validationErrors: newErrors });
        return retVal;


    }
    resetForm() {
        this.setState(
            {
                startDate: this.getStartDate(),
                endDate: this.getEndDate(),
                messageBody: '',
                targetUsers:''
            }
        );
    }
    updateMessage() {
        if (this.validate() === false) {
            this.setState({ loaded: true }, () => {
                let CMR: CreateMessageRequest = new CreateMessageRequest();
                CMR.Message = this.state.messageBody;
                CMR.ValidFrom = this.state.startDate;
                CMR.ValidTo = this.state.endDate;
                CMR.Users = this.state.targetUsers.split(',');
                CMR.MessageID = this.state.messageID;
                MasterContext.MessageManagerService.updateMessage(CMR,
                    (res) => {

                        this.setState({ messages: res, loaded: false, submitMode:1 }, () => {
                            this.resetForm();
                        });
                    },
                    (err) => {
                        this.setState({ loaded: false, submitMode: 1 });
                    })
            })
        }
    }
    createMessage() {
        if (this.validate() === false) {
            this.setState({ loaded: true }, () => {
                let CMR: CreateMessageRequest = new CreateMessageRequest();
                CMR.Message = this.state.messageBody;
                CMR.ValidFrom = this.state.startDate;
                CMR.ValidTo = this.state.endDate;
                CMR.Users = this.state.targetUsers.split(',');
                MasterContext.MessageManagerService.createMessage(CMR,
                    (res) => {
                        
                        this.setState({ messages: res, loaded: false }, () => {
                            this.resetForm();
                        });
                    },
                    (err) => {
                        this.setState({ loaded: false });
                    })
            })
        }
    }
    convertUsersToString(users: string[]): string {
        let retVal: string = '';
        for (let itm of users) {
            retVal += itm + ",";
        }
        retVal = retVal.substring(0,retVal.length- 1);
        return retVal;
    }
    deleteMessage() {
        this.setState({ loaded: true });
        let CMR: CreateMessageRequest = new CreateMessageRequest();
        CMR.MessageID = this.state.messageID;
        MasterContext.MessageManagerService.deleteMessage(CMR,
            (res) => {
                this.setState({ loaded: false, showDeleteModal:false, messages:res });
            },  
            (err) => {
                this.setState({ loaded: false, showDeleteModal: false });
            })
    }
    cancelDeleteMessage() {
        this.setState({ showDeleteModal: false, messageID:0 });
    }
    getContextMenuItems(params) {
        let me = this;
        var result = [
            {
                name: 'Delete Message' ,
                action: function () {
                    me.setState({ showDeleteModal: true,messageID:params.node.data.MessageID });
                },
                cssClasses: ['redFont', 'bold'],
            },
            {
                name: 'Edit Message',
                action: function () {
                    me.setState({
                        submitMode: 2,
                        messageBody: params.node.data.Message,
                        messageID:params.node.data.MessageID,
                        //startDate: params.node.data.ValidFrom,
                        //endDate: params.node.data.ValidTo,
                        targetUsers: me.convertUsersToString(params.node.data.Users)
                    });
                    
                    
                },
                cssClasses: ['redFont', 'bold'],
            }

        ];
        return result;
    };
    cancelUpdate() {
        this.setState({
            submitMode: 1,
            messageID: 0,
            messageBody: '',
            startDate: this.getStartDate(),
            endDate: this.getEndDate(),
            targetUsers: ''
        });
    }
    getActionButton() {
        let retVal: any = <i />

        if (this.state.submitMode === 1) {
            retVal = <input type="submit" name="submit" onClick={this.createMessage} style={{ marginLeft: "5px" }} className="btn loginColorsButton btn-md float-right" value={MasterContext.translate("message_manager_create")} />
        }
        if (this.state.submitMode === 2) {
            retVal = <div>
                <input type="submit" name="submit" onClick={this.cancelUpdate} style={{ marginLeft: "5px" }} className="btn  btn-md float-right btn-danger" value={MasterContext.translate("message_manager_cancel_update")} />
                <input type="submit" name="submit" onClick={this.updateMessage} style={{ marginLeft: "5px" }} className="btn loginColorsButton btn-md float-right" value={MasterContext.translate("message_manager_update")} />
                
                </div>
        }

        return retVal;
    }
    render() {
        let errorClass = { color: "#ff0000" };

        return <React.Fragment>
            <Spinner
                spinnerActive={this.state.loaded}
                heightSet="99vh"
                paddingSet="10%"
                spinnerText={MasterContext.translate("spinner_updating")}
            />
            <Modal zIndex={3002} isOpen={this.state.showDeleteModal}>
                <ModalHeader>{MasterContext.translate("home_message_modal")}</ModalHeader>
                <ModalBody>
                    <div style={{ height: "300px", overflowY: "auto" }}>
                        {MasterContext.translate('message_manager_confirm_delete')}
                    </div>
                </ModalBody>
                <ModalFooter>
                    <button className="btn btn-sm btn-outline-info" color="primary" onClick={() => {this.deleteMessage() }}>{MasterContext.translate("message_manager_confirm_delete_yes")}</button>{' '}
                    <button className="btn btn-sm btn-outline-info" color="primary" onClick={() => { this.cancelDeleteMessage() }}>{MasterContext.translate("message_manager_confirm_delete_no")}</button>

                </ModalFooter>
            </Modal>
            <div className="row">
                <div className="col-md-4">
                </div>
                <div className="col-md-4">
                    <div className='container card'>
                        <h3 className="text-center text-info">{MasterContext.translate("message_manager_title")}</h3>

                        <div className="form-group">
                            <div className="row">
                                <div className="col-md-3">
                                    <label className="loginColors pull-right">{MasterContext.translate("message_manager_date_from")}:</label>
                                </div>
                                <div className="col-md-9">
                                    <DatePicker
                                        selected={this.state.startDate}
                                        onChange={this.handleStartDateChange}
                                        wrapperClassName="pull-right"
                                    />

                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-3">
                                </div>
                                <div className="col-md-9">
                                    <span className="pull-right" style={errorClass}>{this.getMyValidationErrors('startDate')}</span>
                                </div>
                            </div>
                        </div>

                        <div className="form-group">
                            <div className="row">
                                <div className="col-md-3">
                                    <label className="loginColors pull-right">{MasterContext.translate("message_manager_date_to")}:</label>
                                </div>
                                <div className="col-md-9">
                                    <DatePicker
                                        selected={this.state.endDate}
                                        onChange={this.handleEndDateChange}
                                        wrapperClassName="pull-right"

                                    />

                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-3">
                                </div>
                                <div className="col-md-9">
                                    <span className="pull-right" style={errorClass}>{this.getMyValidationErrors('endDate')}</span>
                                </div>
                            </div>
                        </div>

                        <div className="form-group">
                            <div className="row">
                                <div className="col-md-3">
                                    <label className="loginColors pull-right">{MasterContext.translate("message_manager_users")}:</label>
                                </div>
                                <div className="col-md-9">
                                    <input style={{width:"408px"}} placeholder={MasterContext.translate("message_manager_users_placeholder")} className="pull-right" value={this.state.targetUsers} onChange={this.handleTargetUsersChange} type='text' />
                                    

                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-3">
                                </div>
                                <div className="col-md-9">
                                    <span className="pull-right" style={errorClass}>{this.getMyValidationErrors('users')}</span>
                                </div>
                            </div>
                        </div>

                        <div className="form-group">
                            <div className="row">
                                <div className="col-md-3">
                                    <label className="loginColors pull-right">{MasterContext.translate("message_manager_message_body")}:</label>
                                </div>
                                <div className="col-md-9">
                                    <textarea placeholder={MasterContext.translate('message_manager_message_body_placeholder')} cols={55} className="pull-right" value={this.state.messageBody} onChange={this.handleMessageBodyChange} />

                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-3">
                                </div>
                                <div className="col-md-9">
                                    <span className="pull-right" style={errorClass}>{this.getMyValidationErrors('messageBody')}</span>
                                </div>
                            </div>
                        </div>

                        <div className="form-group">&nbsp; &nbsp;
                                {this.getActionButton()}
                          
                        </div>
                    </div>


                </div>
                <div className="col-md-4">
                </div>
            </div>
            <br />
            <div className="row">
                <div className="col-md-2">
                </div>
                <div className="col-md-8">
                    <div className="ag-theme-balham" style={{ height: '60vh' }}>
                        <AgGridReact
                            columnDefs={this.state.columnDefs}
                            rowData={this.state.messages}
                            columnTypes={this.state.columnTypes}
                            allowContextMenuWithControlKey={true}
                            getContextMenuItems={this.getContextMenuItems}
                        />
                    </div>
                </div>
                <div className="col-md-2">
                </div>
            </div>
            </React.Fragment>
    }
     createFlagImg(flag) {
    return (
        '<img border="0" width="15" height="10" src="https://flags.fmcdn.net/data/flags/mini/' +
        flag +
        '.png"/>'
    );
}
}