import * as React from 'react';
import MasterContext from '../Misc/MasterContext';
export default class PermissionWarning extends React.Component {
    render() {
        return (
            <div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="alert alert-danger" role="alert">
                            {MasterContext.translate('no_permission')}
                        </div>
                    </div>
                </div>
                
            </div>
        );

    }

}