import { IServerSideGetRowsParams, IServerSideGetRowsRequest } from '@ag-grid-enterprise/all-modules';
import StateMinimumService from './StateMinimum.service';
import { StateMinimumDetails } from '../../../Components/React/StateMinimum/StateMinimum';

export default class StateMinimumDatasource {

    createServerSideDatasource = (stateMinimumService: StateMinimumService, stateMinDetailsGrid: StateMinimumDetails) => {
        return {
            getRows: function (params: IServerSideGetRowsParams) {
                //console.log('[Datasource] - rows requested by grid: ', params.request);

                let request: IServerSideGetRowsRequest = params.request;

                stateMinimumService.getStateMinimums(request, (data: any[], totalCount: number) => {
                    if (totalCount != null) {
                        stateMinDetailsGrid.setState({
                            totalRowCount: totalCount
                        });
                    }
                    params.successCallback(data, totalCount);
                }, () => {
                        // alert('Error: ' + JSON.stringify(err));
                    params.failCallback();
                });
            },
        };
    }

    createServerSideDatasourceStateMinProps = (stateMinimumService: StateMinimumService) => {
        return {
            getRows: function (params: IServerSideGetRowsParams) {
                // console.log('[Datasource] - rows requested by grid: ', params.request);
                let request: IServerSideGetRowsRequest = params.request;
                stateMinimumService.getStateMinimumProperties(request, (data: any[], totalCount: number) => {
                    let lastRow = totalCount;
                    params.successCallback(data, lastRow);
                }, () => {
                    // alert('Error: ' + JSON.stringify(err));
                    params.failCallback();
                });
            },
        };
    }
}