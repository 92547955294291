import * as React from 'react';
import './Explorer.css';

export interface IProps {
    detailData: any;
    detailChanged: Function;
}

export interface IState {
    tabId:number

}

export default class CDIExplorerDetail extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            tabId:-1
        };
        this.setTab = this.setTab.bind(this);
        this.getTabs = this.getTabs.bind(this);
        
    }
    setTab(tabID :number) {
        this.setState({tabId:tabID});
    }
    getTabs() {
        let retVal: any[] = [];
        if (this.props.detailData.tabs) {
            for (let itm of this.props.detailData.tabs) {
                retVal.push(<li className="nav-item">
                    <span onClick={() => { this.setTab(itm.tabId) }}className="nav-link">{itm.tabTitle}</span>
                </li>);
            }
        }
        return retVal;
    }
    render() {

        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-md-12">
                        <ul className="nav nav-tabs">
                            {this.getTabs()}
                        </ul>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        {this.props.detailData.val1}
                    </div>
                </div>
            </React.Fragment>
        )
    }
}