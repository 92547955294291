import * as React from "react";
import MasterContext from './MasterContext';

export default class Spinner extends React.Component<
    {
        spinnerActive: boolean;
        heightSet: string;
        paddingSet: string;
        spinnerText?: string;
    },
    {}
    > {
    state = {
        spinnerActive:
            this.props.spinnerActive,
        heightSet: this.props.heightSet,
        paddingSet: this.props.paddingSet,
        spinnerText: this.props.spinnerText
    };

    static getDerivedStateFromProps(nextProps: any, prevState: any) {
        if (nextProps.spinnerActive !== prevState.spinnerActive) {
            return { spinnerActive: nextProps.spinnerActive };
        } else return null;
    }

    render() {


        const iStyle = {
            fontSize: "32px"
        };
        if (this.state.spinnerActive === true) {
            return (
                <div style={{ position: "absolute", height: "255px", margin: "5% 40% 0 40%", width: "410px", borderRadius: "9px", paddingTop: "2%", boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 4px 14px 0 rgba(0, 0, 0, 0.10)", zIndex: 2000 }} className="cardSpinner">
                    <div>
                        <div >
                            <div className="cssload-spin-box"></div>
                        </div>
                        {this.state.spinnerText !== '' ? <div style={{ paddingTop: "15px", fontSize: "26px" }} ><br />{this.state.spinnerText}...</div> : <i />}
                    </div>
                </div>


            );
        } else {
            return (
                <div>
                </div>
            );
        }
    }
}
