import LOV from "../Types/LOV.model";
import IPMOBaseService from '../../Generics/Service/IPMOBaseService'
export default class LOVService extends IPMOBaseService {
    fetchLOV(LOVID: number, callback: any, errorCallback: any) {
        let methodName = "Fetch LOV";
        super.get('/PricingEngineService/UIGridFields/FetchLOV?LOVID=' + LOVID, methodName,
            (result) => {
                callback(result);
            },
            (error) => {
                errorCallback(error)
            });    
    }
    fetchLOVs(callback: any, errorCallback: any) {
        let methodName = "Fetch LOVs";
        super.get('/PricingEngineService/UIGridFields/ListLOVs', methodName,
            (result) => {
                super.endRequest(methodName, result);
                let itM = [];
                let itX = new LOV();
                itX.id = Math.floor(Math.random() * 1000000);
                itX.text = "LOVs";
                itX.expanded = true;
                itX.items = [];

                for (let it of result) {
                    let newIT = new LOV();

                    newIT.id = it.LOV_ID;
                    newIT.text = it.LOV_Alias;

                    newIT.name = it.LOV_Alias;
                    newIT.displayName = it.LOV_Alias;

                    newIT.items = [];
                    itX.items.push(newIT);
                }
                itM.push(itX);

                callback(itM)
            },
            (error) => {
                errorCallback(error)
            });     
    }
    testLOV(filter: any, callback: any, errorCallback: any) {
        let methodName = "Test LOV";
        super.get("/PricingEngineService/UIGridFields/Get2?filterName=" + filter, methodName,
            (result) => {
                callback(result);
            },
            (error) => {
                errorCallback(error)
            });  
    }
    upsertFilter(filter: any, callback: any, errorCallback: any) {
        let methodName = "Upsert Filter";
        if (filter.LOV_Filter_ID === 93000000000) {
            filter.LOV_Filter_ID = 0;
        }

        super.post("/PricingEngineService/UIGridFields/SaveLOVFilter",filter, methodName,
            (result) => {
                callback(result);
            },
            (error) => {
                errorCallback(error)
            }); 

       
    }
    upsertColumn(filter: any, callback: any, errorCallback: any) {
        let methodName = "Upsert Column";
        if (filter.LOV_ID === 93000000000) {
            filter.LOV_ID = 0;
        }
        super.post("/PricingEngineService/UIGridFields/SaveLOVCOlumn", filter, methodName,
            (result) => {
                callback(result);
            },
            (error) => {
                errorCallback(error)
            }); 
    }
    upsertLOV(filter: LOV, callback: any, errorCallback: any) {
        let methodName = "Upsert LOV";
        if (filter.LOV_ID === 93000000000) {
            filter.LOV_ID = 0;
        }
        super.post("/PricingEngineService/UIGridFields/SaveLOV", filter, methodName,
            (result) => {
                callback(result);
            },
            (error) => {
                errorCallback(error)
            });
    }
    deleteFilter(filter: any, callback: any, errorCallback: any) {
        let methodName = "Delete Filter";
        if (filter.LOV_Filter_ID === 93000000000) {
            filter.LOV_Filter_ID = 0;
        }
        super.post("/PricingEngineService/UIGridFields/DeleteLOVFilter", filter, methodName,
            (result) => {
                callback(result);
            },
            (error) => {
                errorCallback(error)
            });
    }
    deleteColumn(filter: any, callback: any, errorCallback: any) {
        let methodName = "Delete Column";
        if (filter.LOV_Field_ID === 93000000000) {
            filter.LOV_Field_ID = 0;
        }
        super.post("/PricingEngineService/UIGridFields/DeleteLOVColumn", filter, methodName,
            (result) => {
                callback(result);
            },
            (error) => {
                errorCallback(error)
            });
    }
    deleteLOV(filter: LOV, callback: any, errorCallback: any) {
        let methodName = "Delete LOV";

        super.post("/PricingEngineService/UIGridFields/DeleteLOV", filter, methodName,
            (result) => {
                callback(result);
            },
            (error) => {
                errorCallback(error)
            });
    }
}
