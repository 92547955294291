import * as React from "react";
import Spinner from "../Misc/Spinner";
import Moment from "react-moment";
import { TreeView, processTreeViewItems } from "@progress/kendo-react-treeview";
import LOV from "../../../Services/LOV/Types/LOV.model";
import LOV_Filter from "../../../Services/LOV/Types/LOV_Filter.model";
import LOV_Column from "../../../Services/LOV/Types/LOV_Column.model";

import MasterContext from '../Misc/MasterContext';

export default class LOVX extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            dirty: false,
            roleDirty: false,
            SpinnerState2: true,
            SpinnerState3: false,
            expand: { ids: [], idField: "text" },
            select: { ids: [], idField: "text" },

            tree: MasterContext.LOVService.fetchLOVs((res) => {
                this.setState({ SpinnerState2: false, tree: res });
            }, () => { }),
            //.then((res) => {
            //this.setState({ SpinnerState2: false, tree: res });
            //})

            selected: [this.buildBlankLOV(true)],
            selectedColumn: { LOV_Field_ID: -1 },
            selectedFilter: { LOV_Filter_ID: -1 },
            selectedFilterDirty: false,
            selectedColumnDirty: false,
        };
        this.SaveLOV = this.SaveLOV.bind(this);
        this.getSaveFilterButton = this.getSaveFilterButton.bind(this);
        this.getSaveColumnButton = this.getSaveColumnButton.bind(this);

        this.SaveColumn = this.SaveColumn.bind(this);
        this.SaveFilter = this.SaveFilter.bind(this);
        this.deleteFilter = this.deleteFilter.bind(this);
        this.deleteColumn = this.deleteColumn.bind(this);
        this.newLOV = this.newLOV.bind(this);
        this.handleChangeLOVAlias = this.handleChangeLOVAlias.bind(this);
        this.handleChangeLOVTable = this.handleChangeLOVTable.bind(this);
        this.handleChangeLOVPK = this.handleChangeLOVPK.bind(this);
        this.handleChangeLOVNameCol = this.handleChangeLOVNameCol.bind(this);
        this.handleChangeLOVDescription = this.handleChangeLOVDescription.bind(
            this
        );
        this.handleChangeLOVGFID = this.handleChangeLOVGFID.bind(this);
        this.getSaveButton = this.getSaveButton.bind(this);
        this.getCancelButton = this.getCancelButton.bind(this);
        this.cancelSave = this.cancelSave.bind(this);
        this.getTestButton = this.getTestButton.bind(this);
        this.getDeleteLOVButton = this.getDeleteLOVButton.bind(this);

        this.testLOV = this.testLOV.bind(this);
        this.getNewColumnButton = this.getNewColumnButton.bind(this);
        this.getNewFilterButton = this.getNewFilterButton.bind(this);

        this.addNewColumn = this.addNewColumn.bind(this);
        this.addNewFilter = this.addNewFilter.bind(this);

        this.editSelectedColumn = this.editSelectedColumn.bind(this);
        this.editSelectedFilter = this.editSelectedFilter.bind(this);

        this.handleChangeFilterPosition = this.handleChangeFilterPosition.bind(
            this
        );
        this.handleChangeFilterValue = this.handleChangeFilterValue.bind(this);
        this.handleChangeFilterColumnName = this.handleChangeFilterColumnName.bind(
            this
        );
        this.deleteLOV = this.deleteLOV.bind(this);
        this.handleChangeColumnNull = this.handleChangeColumnNull.bind(this);
        this.handleChangeColumnAlias = this.handleChangeColumnAlias.bind(this);
        this.handleChangeColumnJoin = this.handleChangeColumnJoin.bind(this);
        this.handleChangeColumnPK = this.handleChangeColumnPK.bind(this);
        this.handleChangeColumnFK = this.handleChangeColumnFK.bind(this);
        this.handleChangeColumnTableName = this.handleChangeColumnTableName.bind(
            this
        );
        this.handleChangeColumnPosition = this.handleChangeColumnPosition.bind(
            this
        );
        this.handleChangeColumnName = this.handleChangeColumnName.bind(this);
        this.getDeleteFilterButton = this.getDeleteFilterButton.bind(this);
        this.getDeleteColumnButton = this.getDeleteColumnButton.bind(this);
    }

    buildBlankLOV(initial: any) {
        let retVal = new LOV();
        if (initial === true) {
            retVal.LOV_ID = 0;
        } else {
            retVal.LOV_ID = 93000000000;
        }
        retVal.LOV_Filters = [];
        retVal.LOV_Columns = [];
        return retVal;
    }

    handleChangeFilterPosition(event: any) {
        let v = event.target.value;
        let existing: any = this.state.selectedFilter;
        existing.Position = v;

        this.setState({ selectedFilter: existing, selectedFilterDirty: true });
        this.forceUpdate();
    }
    handleChangeFilterValue(event: any) {
        let v = event.target.value;
        let existing = this.state.selectedFilter;

        existing.Filter_Value = v;

        this.setState({ selectedFilter: existing, selectedFilterDirty: true });
        this.forceUpdate();
    }
    handleChangeFilterColumnName(event: any) {
        let v = event.target.value;
        let existing = this.state.selectedFilter;

        existing.ColumnName = v;
        this.setState({ selectedFilter: existing, selectedFilterDirty: true });
        this.forceUpdate();
    }

    handleChangeColumnNull(event: any) {
        let v = event.target.value;
        let existing = this.state.selectedColumn;

        existing.NullBlock = v;
        this.setState({ selectedColumn: existing, selectedColumnDirty: true });
        this.forceUpdate();
    }
    handleChangeColumnAlias(event: any) {
        let v = event.target.value;
        let existing = this.state.selectedColumn;

        existing.Alias = v;
        this.setState({ selectedColumn: existing, selectedColumnDirty: true });
        this.forceUpdate();
    }
    handleChangeColumnJoin(event: any) {
        let v = event.target.value;
        let existing = this.state.selectedColumn;

        existing.Join_Type = v;
        this.setState({ selectedColumn: existing, selectedColumnDirty: true });
        this.forceUpdate();
    }
    handleChangeColumnPK(event: any) {
        let v = event.target.value;
        let existing = this.state.selectedColumn;

        existing.PK_Name = v;
        this.setState({ selectedColumn: existing, selectedColumnDirty: true });
        this.forceUpdate();
    }
    handleChangeColumnFK(event: any) {
        let v = event.target.value;
        let existing = this.state.selectedColumn;

        existing.FK_Name = v;
        this.setState({ selectedColumn: existing, selectedColumnDirty: true });
        this.forceUpdate();
    }
    handleChangeColumnTableName(event: any) {
        let v = event.target.value;
        let existing = this.state.selectedColumn;

        existing.Table_Name = v;
        this.setState({ selectedColumn: existing, selectedColumnDirty: true });
        this.forceUpdate();
    }
    handleChangeColumnPosition(event: any) {
        let v = event.target.value;
        let existing = this.state.selectedColumn;

        existing.Position = v;
        this.setState({ selectedColumn: existing, selectedColumnDirty: true });
        this.forceUpdate();
    }
    handleChangeColumnName(event: any) {
        let v = event.target.value;
        let existing = this.state.selectedColumn;

        existing.Column_Name = v;
        this.setState({ selectedColumn: existing, selectedColumnDirty: true });
        this.forceUpdate();
    }

    handleChangeLOVAlias(event: any) {
        console.log(this.state.tree);
        let eTree = this.state.tree;
        let v = event.target.value;
        let existing = this.state.selected;
        existing[0].LOV_Alias = v;
        existing[0].text = v;

        for (let itm of eTree[0].items) {
            if (itm.id === existing[0].LOV_ID) {
                itm.text = v;
                itm.name = v;
                itm.displayName = v;
            }
        }

        this.setState({ roleDirty: true, selected: existing, tree: eTree });
        this.forceUpdate();
    }

    handleChangeLOVTable(event: any) {
        let v = event.target.value;
        let existing = this.state.selected;
        existing[0].LOV_Table = v;
        this.setState({ roleDirty: true });

        this.setState({ selected: existing });
        this.forceUpdate();
    }

    handleChangeLOVPK(event: any) {
        let v = event.target.value;
        let existing = this.state.selected;
        existing[0].LOV_PK_Column = v;
        this.setState({ roleDirty: true });

        this.setState({ selected: existing });
        this.forceUpdate();
    }

    handleChangeLOVNameCol(event: any) {
        let v = event.target.value;
        let existing = this.state.selected;
        existing[0].LOV_Name_Column = v;
        this.setState({ roleDirty: true });

        this.setState({ selected: existing });
        this.forceUpdate();
    }

    handleChangeLOVDescription(event: any) {
        let v = event.target.value;
        let existing = this.state.selected;
        existing[0].LOV_Description = v;
        this.setState({ roleDirty: true });

        this.setState({ selected: existing });
        this.forceUpdate();
    }

    handleChangeLOVGFID(event: any) {
        let v = event.target.value;
        let existing = this.state.selected;
        existing[0].LOV_GridField = v;
        this.setState({ roleDirty: true });

        this.setState({ selected: existing });
        this.forceUpdate();
    }


    newLOV() {
        let newLOV = this.buildBlankLOV(false);

        let newNum = Math.floor(Math.random() * 1000000);

        newLOV.id = 93000000000;
        newLOV.text = "Enter New LOV Name" + newNum;
        newLOV.name = "NameOfNewGroup" + newNum;
        //newLOV.displayName = "Friendly Display Name";
        newLOV.LOV_Table = "";
        newLOV.LOV_PK_Column = "";
        newLOV.LOV_Name_Column = "";
        newLOV.LOV_Description = "";
        newLOV.LOV_Created_By = "JRO";
        newLOV.LOV_Created = new Date();
        newLOV.LOV_Updated = new Date();
        newLOV.LOV_GridField = 0;
        newLOV.LOV_ID = 93000000000;

        newLOV.LOV_Alias = "";

        let copyarr = this.state.tree;
        for (let item of copyarr[0].items) {
            item.selected = false;
        }

        copyarr[0].items.push(newLOV);

        this.setState({ tree: copyarr, selected: [newLOV] });
        this.forceUpdate();
    }
    testLOV() {
        this.setState({ SpinnerState3: true });
        this.forceUpdate();
        MasterContext.LOVService.testLOV(this.state.selected[0].LOV_Alias, (res) => {
            alert("SQL Query is: \r\n" + res[0].SQL);
            this.setState({ SpinnerState3: false });
            this.forceUpdate();
        }, () => { });
        //    .then((res) => {
        //    alert("SQL Query is: \r\n" + res[0].SQL);
        //    this.setState({ SpinnerState3: false });
        //    this.forceUpdate();
        //});
    }
    addNewColumn() {
        let itm = new LOV_Column();
        itm.Column_Name = "NEW Column";
        itm.Alias = "";
        itm.FK_Name = "";
        itm.Join_Type = "";
        itm.LOV_Field_ID = 93000000000;
        itm.LOV_ID = this.state.selected[0].LOV_ID;
        itm.NullBlock = "";
        itm.PK_Name = "";
        itm.Position = 0;
        itm.Table_Name = "";

        let copyarr = this.state.selected;
        copyarr[0].LOV_Columns.push(itm);

        this.setState({ selected: copyarr });
        this.forceUpdate();
    }
    addNewFilter() {
        let itm = new LOV_Filter();
        itm.ColumnName = "NEW Filter";
        itm.Filter_Value = "";
        itm.LOV_Filter_ID = 93000000000;
        itm.LOV_ID = this.state.selected[0].LOV_ID;
        itm.Position = 0;

        let copyarr = this.state.selected;
        copyarr[0].LOV_Filters.push(itm);

        this.setState({ selected: copyarr });
        this.forceUpdate();
    }
    SaveLOV() {
        console.log(this.state.selected[0]);
        MasterContext.LOVService.upsertLOV(this.state.selected[0], (res) => {
            this.setState({ roleDirty: false });
            this.forceUpdate();
        }, () => { })
        //    .then((res) => {
        //    this.setState({ roleDirty: false });
        //    this.forceUpdate();
        //});
    }
    deleteLOV() {
        if (
            window.confirm(
                MasterContext.translate(
                    "lov_main_panel_confirm_delete_lov"
                )
            ) === true
        ) {
            MasterContext.LOVService.deleteLOV(this.state.selected[0], (res) => {
                MasterContext.LOVService.fetchLOVs((res2) => {
                    this.setState({ SpinnerState2: false, tree: res });
                }, () => { })
            }, () => { })
            //.then((res) => {
            //MasterContext.LOVService.fetchLOVs()
            //.then((res) => {
            //DO THIS
            //this.setState({ SpinnerState2: false, tree: res });
            //});
            //});
        }
    }


    getNewColumnButton() {
        let retVal: any;
        if (this.state.selected[0].LOV_ID > 0) {
            retVal = <button
                data-delay-hide="500"
                className="btn btn-sm btn-primary"
                onClick={this.addNewColumn}
                style={{ marginTop: "-6px", borderColor: "#ffffff" }}
            >
                {MasterContext.translate(
                    "rolemanager_panel_button_new"
                )}
            </button>

        }
        return retVal;
    }

    getNewFilterButton() {
        let retVal: any;
        if (this.state.selected[0].LOV_ID > 0) {

            retVal = <button
                data-delay-hide="500"
                className="btn btn-sm btn-primary"
                onClick={this.addNewFilter}
                style={{ marginTop: "-6px", borderColor: "#ffffff" }}
            >
                {MasterContext.translate(
                    "rolemanager_panel_button_new"
                )}
            </button>

        }
        return retVal;
    }

    getTestButton() {
        let retVal: any;
        if (this.state.selected[0].LOV_ID > 0) {
            retVal = <button
                data-delay-hide="500"
                className="btn btn-success"
                onClick={this.testLOV}
            >
                {MasterContext.translate(
                    "lov_panel_test_button"
                )}
            </button>
        }
        return retVal;
    }

    getDeleteLOVButton() {
        let retVal: any;
        if (this.state.selected[0].LOV_ID > 0) {
            retVal = <button
                data-delay-hide="500"
                className="btn btn-danger"
                onClick={this.deleteLOV}
            >
                {MasterContext.translate(
                    "lov_delete_button"
                )}
            </button>
        }
        return retVal;
    }

    deleteColumn() {
        if (
            window.confirm(
                MasterContext.translate(
                    "lov_main_panel_confirm_delete_column"
                )
            ) === true
        ) {
            MasterContext.LOVService.deleteColumn(this.state.selectedColumn, (res) => {
                let copy = this.state.selected;
                let newF: any = [];
                for (let fil of copy[0].LOV_Columns) {
                    if (fil.LOV_Field_ID !== this.state.selectedColumn.LOV_Field_ID) {

                        newF.push(fil);
                    }
                }
                copy[0].LOV_Columns = newF;

                this.setState({
                    selected: copy,
                    selectedColumnDirty: false,
                    selectedColumn: { LOV_Field_ID: -1 },
                });
                this.forceUpdate();
            }, () => { })
            //    .then((res) => {
            //    let copy = this.state.selected;
            //    let newF: any = [];
            //    for (let fil of copy[0].LOV_Columns) {
            //        if (fil.LOV_Field_ID !== this.state.selectedColumn.LOV_Field_ID) {

            //            newF.push(fil);
            //        }
            //    }
            //    copy[0].LOV_Columns = newF;

            //    this.setState({
            //        selected: copy,
            //        selectedColumnDirty: false,
            //        selectedColumn: { LOV_Field_ID: -1 },
            //    });
            //    this.forceUpdate();
            //});
        }
    }

    getDeleteColumnButton() {
        let retVal: any;
        if (this.state.selectedColumn.LOV_Field_ID > 0) {
            retVal = <button
                data-delay-hide="500"
                className="btn btn-danger"
                onClick={this.deleteColumn}
            >
                {MasterContext.translate(
                    "lov_delete_column_button"
                )}
            </button>
        }
        return retVal;
    }
    deleteFilter() {
        if (
            window.confirm(
                MasterContext.translate(
                    "lov_main_panel_confirm_delete_filter"
                )
            ) === true
        ) {
            MasterContext.LOVService.deleteFilter(this.state.selectedFilter, (res) => {
                let copy = this.state.selected;
                let newF = [];
                for (let fil of copy[0].LOV_Filters) {
                    if (fil.LOV_Filter_ID !== this.state.selectedFilter.LOV_Filter_ID) {
                        newF.push(fil);
                    }
                }
                copy[0].LOV_Filters = newF;

                this.setState({
                    selected: copy,
                    selectedFilterDirty: false,
                    selectedFilter: { LOV_Filter_ID: -1 },
                });
                this.forceUpdate();
            }, () => { })
            //    .then((res) => {
            //    let copy = this.state.selected;
            //    let newF = [];
            //    for (let fil of copy[0].LOV_Filters) {
            //        if (fil.LOV_Filter_ID !== this.state.selectedFilter.LOV_Filter_ID) {
            //            newF.push(fil);
            //        }
            //    }
            //    copy[0].LOV_Filters = newF;

            //    this.setState({
            //        selected: copy,
            //        selectedFilterDirty: false,
            //        selectedFilter: { LOV_Filter_ID: -1 },
            //    });
            //    this.forceUpdate();
            //});
        }
    }

    getDeleteFilterButton() {
        let retVal: any;
        if (this.state.selectedFilter.LOV_Filter_ID > 0) {
            retVal = <button
                data-delay-hide="500"
                className="btn btn-danger"
                onClick={this.deleteFilter}
            >
                {MasterContext.translate(
                    "lov_delete_filter_button"
                )}
            </button>
        }
        return retVal;
    }

    SaveColumn() {
        MasterContext.LOVService.upsertColumn(this.state.selectedColumn, (res) => {

            this.setState({ selectedColumnDirty: false });
            this.forceUpdate();
        }, () => { })
        //    .then((res) => {
        //    this.setState({ selectedColumnDirty: false });
        //    this.forceUpdate();
        //});
    }
    SaveFilter() {
        MasterContext.LOVService.upsertFilter(this.state.selectedFilter, (res) => {
            this.setState({ selectedFilterDirty: false });
            this.forceUpdate();
        }, () => { })
        //    .then((res) => {
        //    this.setState({ selectedFilterDirty: false });
        //    this.forceUpdate();
        //});
    }

    getSaveColumnButton() {
        let retVal: any;
        if (this.state.selectedColumnDirty === true) {
            retVal = <button
                data-delay-hide="500"
                className="btn btn-primary"
                onClick={this.SaveColumn}
            >
                {MasterContext.translate(
                    "rolemanager_panel_button_save"
                )}
            </button>
        }
        return retVal;
    }

    getSaveFilterButton() {
        let retVal: any;
        if (this.state.selectedFilterDirty === true) {

            retVal = <button
                data-delay-hide="500"
                className="btn btn-primary"
                onClick={this.SaveFilter}
            >
                {MasterContext.translate(
                    "rolemanager_panel_button_save"
                )}
            </button>

        }
        return retVal;
    }


    getCancelButton() {
        let retVal: any;
        if (this.state.roleDirty === true) {
            retVal = <button
                data-delay-hide="500"
                className="btn btn-danger"
                onClick={this.cancelSave}
            >
                {MasterContext.translate(
                    "rolemanager_panel_button_cancel"
                )}
            </button>
        }
        return retVal;
    }


    getSaveButton() {
        let retVal: any;
        if (this.state.roleDirty === true) {
            retVal = <button
                data-delay-hide="500"
                className="btn btn-primary"
                onClick={this.SaveLOV}
            >
                {MasterContext.translate(
                    "rolemanager_panel_button_save"
                )}
            </button>
        }
        return retVal;
    }
    cancelSave() {
        if (
            window.confirm(
                MasterContext.translate(
                    "rolemanager_confirm_cancel",
                )
            ) === true
        ) {
            this.setState({ changesTracked: [] });
            this.setState({ roleDirty: false });
            this.setState({ SpinnerState: false });
            this.forceUpdate();
        }
    }

    onItemClick = (event: any) => {
        if (event.item.id === 93000000000) {
        } else {
            this.setState({ SpinnerState2: true });
            this.forceUpdate();
            MasterContext.LOVService.fetchLOV(event.item.id, (res) => {
                console.log(res);
                this.setState({
                    selected: res,
                    SpinnerState2: false,
                    selectedColumn: { LOV_Field_ID: -1 },
                    selectedFilter: { LOV_Filter_ID: -1 },
                });
                this.forceUpdate();
            }, () => { })
            //    .then((res) => {
            //    console.log(res);
            //    this.setState({
            //        selected: res,
            //        SpinnerState2: false,
            //        selectedColumn: { LOV_Field_ID: -1 },
            //        selectedFilter: { LOV_Filter_ID: -1 },
            //    });
            //    this.forceUpdate();
            //});
        }
    };
    getNewButton() {
        return (
            <button
                data-delay-hide="500"
                className="btn btn-sm btn-primary"
                onClick={this.newLOV}
                style={{ marginTop: "-6px", borderColor: "#ffffff" }}
            >
                {MasterContext.translate(
                    "rolemanager_panel_button_new"
                )}
            </button>
        );
    }

    editSelectedColumn(event: any) {
        for (let itm of this.state.selected[0].LOV_Columns) {
            if (itm.Column_Name === event.target.text) {
                this.setState({ selectedColumn: itm });
                break;
            }
        }
    }
    editSelectedFilter(event: any) {
        for (let itm of this.state.selected[0].LOV_Filters) {
            if (itm.ColumnName === event.target.text) {
                this.setState({ selectedFilter: itm });
                break;
            }
        }
    }
    render() {
        let selectedGroupName = "";
        let fonting = {
            fontFamily: "Roboto, Tahoma, Geneva, sans-serif",
            fontSize: "14px",
            fontWeight: 400,
        };
        let fixMargin = {
            marginLeft: "15px",
            marginRight: "15px",
        };
        let btPadding = {
            paddingRight: "25px",
        };
        let panelHeight = {
            height: "753px",
        };

        let inputStyle = {
            width: "280",
        };
        let panelPropsStyle = {
            height: "230px",
        };
        let panelPropsStyle2 = {
            height: "150px",
        };
        let panelPropsStyle3 = {
            height: "191",
        };

        let lilOverflowUL = {
            listStyleType: "none",
        };
        let hoverHand = {
            cursor: "pointer",
        };
        let fixLIMargin = {
            marginLeft: "-42px",
        };
        return (
            <React.Fragment>
                <div style={fonting}
                >
                    <div
                        style={fixMargin}

                    >
                        <div className="row" style={{ marginTop: "10px" }}></div>
                        <div className="row">
                            <div className="col-md-2">
                                <h2>
                                    {MasterContext.translate(
                                        "lov_title"
                                    )}
                                </h2>
                            </div>
                            <div className="col-md-10">
                                <div className="row">
                                    <div className="col-md-8">
                                        <h2> {selectedGroupName}</h2>
                                    </div>
                                    <div
                                        className="col-md-4 text-right pull-right"
                                        style={btPadding}
                                    >

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2">
                                <div className="panel panel-primary" style={panelHeight}>
                                    <div className="panel-heading">
                                        <div className="row">
                                            <div className="col-md-8">
                                                <h3 className="panel-title">
                                                    {MasterContext.translate(
                                                        "lov_panel_select_lov"
                                                    )}
                                                </h3>
                                            </div>
                                            <div className="col-md-4 text-right pull-right">
                                                {this.getNewButton()}
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className="panel-body"

                                        style={{ overflowY: "auto", height: "753px", marginLeft: "-50px" }}>
                                        <Spinner

                                            spinnerActive={this.state.SpinnerState2}
                                            heightSet="99vh"
                                            paddingSet="10%"
                                            spinnerText={MasterContext.translate("spinner_loading")}
                                        ></Spinner>
                                        <TreeView
                                            draggable={false}
                                            data={processTreeViewItems(

                                                this.state.tree,
                                                {
                                                    expand: this.state.expand,
                                                    select: this.state.select,
                                                }
                                            )}
                                            onItemClick={this.onItemClick}
                                        ></TreeView>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-10">
                                {/* MAIN PANEL */}

                                <div className="row">
                                    <div className="col-12">
                                    <div className="panel panel-primary">
                                        <div className="panel-heading">
                                                <h3 className="panel-title">
                                                    {MasterContext.translate("lov_panel_selected_lov")}
                   
                                            </h3>
                                        </div>
                                        <div className="panel-body" style={panelPropsStyle}>
                                            {this.state.selected[0].LOV_ID !== 0 ? (
                                                <div>
                                                    <Spinner

                                                        spinnerActive={this.state.SpinnerState3}
                                                        heightSet="99vh"
                                                        paddingSet="10%"
                                                            spinnerText={MasterContext.translate("spinner_loading")}
                                                    ></Spinner>
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="row">
                                                                <div className="col-md-6 text-right">
                                                                        <span style={inputStyle}>
                                                                            {MasterContext.translate("lov_field_alias")}
                                                                    </span>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <input
                                                                        maxLength={255}
                                                                        disabled={false}

                                                                        style={inputStyle}
                                                                        type="text"
                                                                        value={this.state.selected[0].LOV_Alias}
                                                                        onChange={this.handleChangeLOVAlias}
                                                                        className="form-control"
                                                                        placeholder={MasterContext.translate(
                                                                            "tableau.form.rolemanager_properties_role_name_placeholder"
                                                                        )}
                                                                        aria-describedby="basic-addon1"
                                                                    ></input>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="row">
                                                                <div className="col-md-3">
                                                                        <span style={inputStyle}>
                                                                            {MasterContext.translate("lov_field_table")}
                                                                    </span>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <input
                                                                        maxLength={255}
                                                                        disabled={false}

                                                                        style={inputStyle}
                                                                        type="text"
                                                                        value={this.state.selected[0].LOV_Table}
                                                                        onChange={this.handleChangeLOVTable}
                                                                        className="form-control"
                                                                        placeholder={MasterContext.translate(
                                                                            "tableau.form.rolemanager_properties_role_name_placeholder"
                                                                        )}
                                                                        aria-describedby="basic-addon1"
                                                                    ></input>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="row">
                                                                <div className="col-md-6 text-right">
                                                                        <span style={inputStyle}>
                                                                            {MasterContext.translate("lov_field_pk_col")}
                                                                    </span>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <input
                                                                        maxLength={255}
                                                                        disabled={false}

                                                                        style={inputStyle}
                                                                        type="text"
                                                                        value={this.state.selected[0].LOV_PK_Column}
                                                                        onChange={this.handleChangeLOVPK}
                                                                        className="form-control"
                                                                        placeholder={MasterContext.translate(
                                                                            "tableau.form.rolemanager_properties_role_name_placeholder"
                                                                        )}
                                                                        aria-describedby="basic-addon1"
                                                                    ></input>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="row">
                                                                <div className="col-md-3">
                                                                        <span style={inputStyle}>
                                                                            {MasterContext.translate("lov_field_name_column")}
                                                                    </span>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <input
                                                                        maxLength={255}
                                                                        disabled={false}

                                                                        style={inputStyle}
                                                                        type="text"
                                                                        value={
                                                                            this.state.selected[0].LOV_Name_Column
                                                                        }
                                                                        onChange={this.handleChangeLOVNameCol}
                                                                        className="form-control"
                                                                        placeholder={MasterContext.translate(
                                                                            "tableau.form.rolemanager_properties_role_name_placeholder"
                                                                        )}
                                                                        aria-describedby="basic-addon1"
                                                                    ></input>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="row">
                                                                <div className="col-md-6 text-right">
                                                                        <span style={inputStyle}>
                                                                            {MasterContext.translate("lov_field_created")}

                                                                    </span>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <span>
                                                                        <Moment
                                                                            date={this.state.selected[0].LOV_Created}
                                                                            format="MM/DD/YYYY"
                                                                        ></Moment>
                                                                    </span>
                                                                    {/* <input
                                    maxLength={255}
                                    disabled={false}
                                    
                                    style={inputStyle}
                                    type="text"
                                    value={this.state.selected[0].LOV_Created}
                                    // onChange={
                                    //   this
                                    //     .handleChangeselectedRoleItemName
                                    // }
                                    className="form-control"
                                    placeholder={TL.translate(
                                      "tableau.form.rolemanager_properties_role_name_placeholder",
                                      MasterContext.selectedLanguage,
                                      translationSource
                                    )}
                                    aria-describedby="basic-addon1"
                                  ></input> */}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="row">
                                                                <div className="col-md-3">
                                                                        <span style={inputStyle}>
                                                                            {MasterContext.translate("lov_field_description")}
  
                                                                    </span>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <input
                                                                        maxLength={255}
                                                                        disabled={false}

                                                                        style={inputStyle}
                                                                        type="text"
                                                                        value={
                                                                            this.state.selected[0].LOV_Description
                                                                        }
                                                                        onChange={this.handleChangeLOVDescription}
                                                                        className="form-control"
                                                                        placeholder={MasterContext.translate(
                                                                            "tableau.form.rolemanager_properties_role_name_placeholder"
                                                                        )}
                                                                        aria-describedby="basic-addon1"
                                                                    ></input>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="row">
                                                                <div className="col-md-6 text-right">
                                                                        <span style={inputStyle}>
                                                                            {MasterContext.translate("lov_field_updated")}
    
                                                                    </span>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <span>
                                                                        <Moment
                                                                            date={this.state.selected[0].LOV_Updated}
                                                                            format="MM/DD/YYYY"
                                                                        ></Moment>
                                                                    </span>

                                                                    {/* <input
                                    maxLength={255}
                                    disabled={false}
                                    
                                    style={inputStyle}
                                    type="text"
                                    value={this.state.selected[0].LOV_Updated}
                                    // onChange={
                                    //   this
                                    //     .handleChangeselectedRoleItemName
                                    // }
                                    className="form-control"
                                    placeholder={TL.translate(
                                      "tableau.form.rolemanager_properties_role_name_placeholder",
                                      MasterContext.selectedLanguage,
                                      translationSource
                                    )}
                                    aria-describedby="basic-addon1"
                                  ></input> */}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="row">
                                                                <div className="col-md-3">
                                                                        <span style={inputStyle}>
                                                                            {MasterContext.translate("lov_field_created_by")}
                                                                    </span>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <input
                                                                        maxLength={255}
                                                                        disabled={true}

                                                                        style={inputStyle}
                                                                        type="text"
                                                                        value={
                                                                            this.state.selected[0].LOV_Created_By
                                                                        }
                                                                        // onChange={
                                                                        //   this
                                                                        //     .handleChangeselectedRoleItemName
                                                                        // }
                                                                        className="form-control"
                                                                        placeholder={MasterContext.translate(
                                                                            "tableau.form.rolemanager_properties_role_name_placeholder"
                                                                        )}
                                                                        aria-describedby="basic-addon1"
                                                                    ></input>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="row">
                                                                <div className="col-md-6 text-right">
                                                                        <span style={inputStyle}>
                                                                            {MasterContext.translate("lov_field_gridfield")}
  
                                                                    </span>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <input
                                                                        maxLength={255}
                                                                        disabled={false}

                                                                        style={inputStyle}
                                                                        type="text"
                                                                        value={this.state.selected[0].LOV_GridField}
                                                                        onChange={this.handleChangeLOVGFID}
                                                                        className="form-control"
                                                                        placeholder={MasterContext.translate(
                                                                            "tableau.form.rolemanager_properties_role_name_placeholder"
                                                                        )}
                                                                        aria-describedby="basic-addon1"
                                                                    ></input>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="row"></div>
                                                        </div>
                                                    </div>

                                                    <div className="row">
                                                        <div
                                                            className="col-md-12 text-right pull-right"
                                                            style={btPadding}
                                                        >
                                                            {this.getDeleteLOVButton()} &nbsp;
                              {this.getTestButton()} &nbsp;
                              {this.getCancelButton()} &nbsp;
                              {this.getSaveButton()}
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : (
                                                    MasterContext.translate(
                                                        "lov_main_panel_select_an_lov"
                                                    )
                                                )}
                                        </div>
                                    </div>
                                        </div>
                                    </div>

                                {/* END MAIN PANEL */}

                                {/* COLUMNS */}
                                <div className="row">
                                    <div className="col-12">
                                    <div className="panel panel-primary">
                                        <div className="panel-heading">
                                            <div className="row">
                                                <div className="col-md-4">
                                                        <h3 className="panel-title">
                                                            {MasterContext.translate("lov_panel_selected_lov_columns")}

                                                    </h3>
                                                </div>
                                                <div className="col-md-8">
                                                    <div className="col-md-4 text-right pull-right">
                                                        {this.getNewColumnButton()}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="panel-body" style={panelPropsStyle3}>
                                            {this.state.selected[0].LOV_ID !== 0 ? (
                                                <div>
                                                    <div className="row">

                                                        <div className="col-md-2" style={{ height: "120px", overflowY: "auto", marginLeft: "8px", borderWidth: "1px", borderStyle: "solid", borderColor: "#337ab7", borderRadius: "5px" }}>
                                                            <ul style={lilOverflowUL}>
                                                                {this.state.selected[0].LOV_Columns.map(
                                                                    (dat) => {
                                                                        return (
                                                                            <li style={fixLIMargin}>
                                                                                <span
                                                                                    onClick={this.editSelectedColumn}
                                                                                    style={hoverHand}
                                                                                >
                                                                                    {dat.Column_Name}
                                                                                </span>
                                                                            </li>
                                                                        );
                                                                    }
                                                                )}
                                                            </ul>
                                                        </div>
                                                        <div className="col-md-8">
                                                            {

                                                                this.state.selectedColumn.LOV_Field_ID > 0 ? (
                                                                    <div>
                                                                        <div className="row">
                                                                            <div className="col-md-6">
                                                                                <div className="row">
                                                                                    <div className="col-md-6 text-right">
                                                                                            <span style={inputStyle}>
                                                                                                {MasterContext.translate("lov_column_columnName")}
                                                                                        </span>
                                                                                    </div>
                                                                                    <div className="col-md-6">
                                                                                        <input
                                                                                            maxLength={255}
                                                                                            disabled={false}

                                                                                            style={inputStyle}
                                                                                            type="text"
                                                                                            value={

                                                                                                this.state.selectedColumn.Column_Name
                                                                                            }
                                                                                            onChange={
                                                                                                this.handleChangeColumnName
                                                                                            }
                                                                                            className="form-control"
                                                                                            placeholder={MasterContext.translate(
                                                                                                "tableau.form.rolemanager_properties_role_name_placeholder"
                                                                                            )}
                                                                                            aria-describedby="basic-addon1"
                                                                                        ></input>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-6">
                                                                                <div className="row">
                                                                                    <div className="col-md-6 text-right">
                                                                                            <span style={inputStyle}>
                                                                                                {MasterContext.translate("lov_column_position")}
                                                                                        </span>
                                                                                    </div>
                                                                                    <div className="col-md-6">
                                                                                        <input
                                                                                            maxLength={255}
                                                                                            disabled={false}

                                                                                            style={inputStyle}
                                                                                            type="number"
                                                                                            value={

                                                                                                this.state.selectedColumn.Position
                                                                                            }
                                                                                            onChange={
                                                                                                this.handleChangeColumnPosition
                                                                                            }
                                                                                            className="form-control"
                                                                                            placeholder={MasterContext.translate(
                                                                                                "tableau.form.rolemanager_properties_role_name_placeholder"
                                                                                            )}
                                                                                            aria-describedby="basic-addon1"
                                                                                        ></input>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div className="row">
                                                                            <div className="col-md-6">
                                                                                <div className="row">
                                                                                    <div className="col-md-6 text-right">
                                                                                            <span style={inputStyle}>
                                                                                                {MasterContext.translate("lov_column_tablename")}
                                                                                        </span>
                                                                                    </div>
                                                                                    <div className="col-md-6">
                                                                                        <input
                                                                                            maxLength={255}
                                                                                            disabled={false}

                                                                                            style={inputStyle}
                                                                                            type="text"
                                                                                            value={

                                                                                                this.state.selectedColumn.Table_Name
                                                                                            }
                                                                                            onChange={
                                                                                                this.handleChangeColumnTableName
                                                                                            }
                                                                                            className="form-control"
                                                                                            placeholder={MasterContext.translate(
                                                                                                "tableau.form.rolemanager_properties_role_name_placeholder"
                                                                                            )}
                                                                                            aria-describedby="basic-addon1"
                                                                                        ></input>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-6">
                                                                                <div className="row">
                                                                                    <div className="col-md-6 text-right">
                                                                                            <span style={inputStyle}>
                                                                                                {MasterContext.translate("lov_column_fk")}
                                                                                        </span>
                                                                                    </div>
                                                                                    <div className="col-md-6">
                                                                                        <input
                                                                                            maxLength={255}
                                                                                            disabled={false}

                                                                                            style={inputStyle}
                                                                                            type="text"
                                                                                            value={

                                                                                                this.state.selectedColumn.FK_Name
                                                                                            }
                                                                                            onChange={
                                                                                                this.handleChangeColumnFK
                                                                                            }
                                                                                            className="form-control"
                                                                                            placeholder={MasterContext.translate(
                                                                                                "tableau.form.rolemanager_properties_role_name_placeholder"
                                                                                            )}
                                                                                            aria-describedby="basic-addon1"
                                                                                        ></input>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div className="row">
                                                                            <div className="col-md-6">
                                                                                <div className="row">
                                                                                    <div className="col-md-6 text-right">
                                                                                            <span style={inputStyle}>
                                                                                                {MasterContext.translate("lov_column_pk")}
                                                                                        </span>
                                                                                    </div>
                                                                                    <div className="col-md-6">
                                                                                        <input
                                                                                            maxLength={255}
                                                                                            disabled={false}

                                                                                            style={inputStyle}
                                                                                            type="text"
                                                                                            value={

                                                                                                this.state.selectedColumn.PK_Name
                                                                                            }
                                                                                            onChange={
                                                                                                this.handleChangeColumnPK
                                                                                            }
                                                                                            className="form-control"
                                                                                            placeholder={MasterContext.translate(
                                                                                                "tableau.form.rolemanager_properties_role_name_placeholder"
                                                                                            )}
                                                                                            aria-describedby="basic-addon1"
                                                                                        ></input>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-6">
                                                                                <div className="row">
                                                                                    <div className="col-md-6 text-right">
                                                                                            <span style={inputStyle}>
                                                                                                {MasterContext.translate("lov_column_join_type")}      
                                                                                        </span>
                                                                                    </div>
                                                                                    <div className="col-md-6">
                                                                                        <input
                                                                                            maxLength={255}
                                                                                            disabled={false}

                                                                                            style={inputStyle}
                                                                                            type="text"
                                                                                            value={

                                                                                                this.state.selectedColumn.Join_Type
                                                                                            }
                                                                                            onChange={
                                                                                                this.handleChangeColumnJoin
                                                                                            }
                                                                                            className="form-control"
                                                                                            placeholder={MasterContext.translate(
                                                                                                "tableau.form.rolemanager_properties_role_name_placeholder"
                                                                                            )}
                                                                                            aria-describedby="basic-addon1"
                                                                                        ></input>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div className="row">
                                                                            <div className="col-md-6">
                                                                                <div className="row">
                                                                                    <div className="col-md-6 text-right">
                                                                                            <span style={inputStyle}>
                                                                                                {MasterContext.translate("lov_column_alias")}
                                                                                        </span>
                                                                                    </div>
                                                                                    <div className="col-md-6">
                                                                                        <input
                                                                                            maxLength={255}
                                                                                            disabled={false}

                                                                                            style={inputStyle}
                                                                                            type="text"
                                                                                            value={

                                                                                                this.state.selectedColumn.Alias
                                                                                            }
                                                                                            onChange={
                                                                                                this.handleChangeColumnAlias
                                                                                            }
                                                                                            className="form-control"
                                                                                            placeholder={MasterContext.translate(
                                                                                                "tableau.form.rolemanager_properties_role_name_placeholder"
                                                                                            )}
                                                                                            aria-describedby="basic-addon1"
                                                                                        ></input>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-6">
                                                                                <div className="row">
                                                                                    <div className="col-md-6 text-right">
                                                                                            <span style={inputStyle}>
                                                                                                {MasterContext.translate("lov_column_nullblock")}
                                                                                        </span>
                                                                                    </div>
                                                                                    <div className="col-md-6">
                                                                                        <input
                                                                                            maxLength={255}
                                                                                            disabled={false}

                                                                                            style={inputStyle}
                                                                                            type="text"
                                                                                            value={

                                                                                                this.state.selectedColumn.NullBlock
                                                                                            }
                                                                                            onChange={
                                                                                                this.handleChangeColumnNull
                                                                                            }
                                                                                            className="form-control"
                                                                                            placeholder={MasterContext.translate(
                                                                                                "tableau.form.rolemanager_properties_role_name_placeholder"
                                                                                            )}
                                                                                            aria-describedby="basic-addon1"
                                                                                        ></input>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                ) : (
                                                                        MasterContext.translate(
                                                                            "lov_main_panel_select_an_lov_columns"
                                                                        )
                                                                    )
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div
                                                            className="col-md-12 text-right pull-right"
                                                            style={btPadding}
                                                        >
                                                            {this.getDeleteColumnButton()}&nbsp;
                              {this.getSaveColumnButton()}
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : (
                                                    MasterContext.translate(
                                                        "lov_main_panel_select_an_lov"
                                                    )
                                                )}
                                        </div>
                                    </div>

                                    </div>
                                </div>

                                {/* END COLUMNS */}

                                {/* FILTERS */}

                                <div className="row">
                                    <div className="col-12">
                                    <div className="panel panel-primary">
                                        <div className="panel-heading">
                                            <div className="row">
                                                <div className="col-md-4">
                                                        <h3 className="panel-title">
                                                            {MasterContext.translate("lov_panel_selected_lov_filters")}
                                                    </h3>
                                                </div>
                                                <div className="col-md-8">
                                                    <div className="col-md-4 text-right pull-right">
                                                        {this.getNewFilterButton()}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="panel-body" style={panelPropsStyle2}>
                                            {this.state.selected[0].LOV_ID !== 0 ? (
                                                <div>
                                                    <div className="row">

                                                        <div className="col-md-2" style={{
                                                            height: "120px",
                                                            overflowY: "auto",
                                                            marginLeft: "8px",
                                                            borderWidth: "1px",
                                                            borderStyle: "solid",
                                                            borderColor: "#337ab7",
                                                            borderRadius: "5px",
                                                        }}>
                                                            <ul style={lilOverflowUL}>
                                                                {this.state.selected[0].LOV_Filters.map(
                                                                    (dat) => {
                                                                        return (
                                                                            <li style={fixLIMargin}>
                                                                                <span
                                                                                    onClick={this.editSelectedFilter}
                                                                                    style={hoverHand}
                                                                                >
                                                                                    {dat.ColumnName}
                                                                                </span>
                                                                            </li>
                                                                        );
                                                                    }
                                                                )}
                                                            </ul>
                                                        </div>
                                                        <div className="col-md-8">
                                                            {

                                                                this.state.selectedFilter.LOV_Filter_ID > 0 ? (
                                                                    <div>
                                                                        <div className="row">
                                                                            <div className="col-md-6">
                                                                                <div className="row">
                                                                                    <div className="col-md-6 text-right">
                                                                                            <span style={inputStyle}>
                                                                                                {MasterContext.translate("lov_filter_column_name")}
                                                                                        </span>
                                                                                    </div>
                                                                                    <div className="col-md-6">
                                                                                        <input
                                                                                            maxLength={255}
                                                                                            disabled={false}

                                                                                            style={inputStyle}
                                                                                            type="text"
                                                                                            value={

                                                                                                this.state.selectedFilter.ColumnName
                                                                                            }
                                                                                            onChange={
                                                                                                this
                                                                                                    .handleChangeFilterColumnName
                                                                                            }
                                                                                            className="form-control"
                                                                                            placeholder={MasterContext.translate(
                                                                                                "tableau.form.rolemanager_properties_role_name_placeholder"
                                                                                            )}
                                                                                            aria-describedby="basic-addon1"
                                                                                        ></input>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-6">
                                                                                <div className="row">
                                                                                    <div className="col-md-6 text-right">
                                                                                            <span style={inputStyle}>
                                                                                                {MasterContext.translate("lov_filter_value")}
                                                                                        </span>
                                                                                    </div>
                                                                                    <div className="col-md-6">
                                                                                        <input
                                                                                            maxLength={255}
                                                                                            disabled={false}

                                                                                            style={inputStyle}
                                                                                            type="text"
                                                                                            value={

                                                                                                this.state.selectedFilter.Filter_Value
                                                                                            }
                                                                                            onChange={
                                                                                                this.handleChangeFilterValue
                                                                                            }
                                                                                            className="form-control"
                                                                                            placeholder={MasterContext.translate(
                                                                                                "tableau.form.rolemanager_properties_role_name_placeholder"
                                                                                            )}
                                                                                            aria-describedby="basic-addon1"
                                                                                        ></input>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div className="row">
                                                                            <div className="col-md-6">
                                                                                <div className="row">
                                                                                    <div className="col-md-6 text-right">
                                                                                            <span style={inputStyle}>
                                                                                                {MasterContext.translate("lov_filter_position")}
                                                                                        </span>
                                                                                    </div>
                                                                                    <div className="col-md-6">
                                                                                        <input
                                                                                            maxLength={255}
                                                                                            disabled={false}

                                                                                            style={inputStyle}
                                                                                            type="number"
                                                                                            value={

                                                                                                this.state.selectedFilter.Position
                                                                                            }
                                                                                            onChange={
                                                                                                this.handleChangeFilterPosition
                                                                                            }
                                                                                            className="form-control"
                                                                                            placeholder={MasterContext.translate(
                                                                                                "tableau.form.rolemanager_properties_role_name_placeholder"
                                                                                            )}
                                                                                            aria-describedby="basic-addon1"
                                                                                        ></input>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-6"></div>
                                                                        </div>
                                                                    </div>
                                                                ) : (
                                                                        MasterContext.translate(
                                                                            "lov_main_panel_select_an_lov_filters"
                                                                        )
                                                                    )
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div
                                                            className="col-md-12 text-right pull-right"
                                                            style={btPadding}
                                                        >
                                                            {this.getDeleteFilterButton()}&nbsp;
                              {this.getSaveFilterButton()}
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : (
                                                    MasterContext.translate(
                                                        "lov_main_panel_select_an_lov"
                                                    )
                                                )}
                                        </div>
                                    </div>
                                        </div>
                                    </div>
                                {/* END FILTERS   */}
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
