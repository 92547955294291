import Permission from "../Types/Permission.model";
import Role from "../Types/Role.model";
import RoleUser from "../Types/RoleUser.model";
import PagingFilter from "../../../Services/Common/Types/PagingFilter.model";
import IPMOBaseService from '../../Generics/Service/IPMOBaseService'


//import jsonQ from "jsonq";

export default class PermissionsService extends IPMOBaseService{
    buildPerm(
        featureName: string,
        componentName: string,
        PermName: string,
        read: boolean, 
        edit: boolean,
        del: boolean,
        execute: boolean,
        enabled: boolean
    ) {
        let p;
        p = new Permission();
        p.permissionName = PermName;
        p.hasRead = read;
        p.hasEdit = edit;
        p.hasDelete = del;
        p.hasExecute = execute;
        p.isEnabled = enabled;
        p.children = [];

        return p;
    }
    query(key: string, context: Permission, subKey: string) {
        var family:any = {} //jsonQ(context);
        var name = family.find("permissionName", function () {
            
            return this === key;
        });

        var perm = name.sibling(subKey);
        return perm.value();
    }
    permissions(params: PagingFilter) {
        let Perms;
        Perms = this.buildPerm(
            "UserInterface",
            "Login",
            "cleardemand",
            true,
            true,
            true,
            true,
            true
        );

        let Modules;
        Modules = this.buildPerm(
            "UserInterface",
            "Login",
            "cleardemand_modules",
            true,
            true,
            true,
            true,
            true
        );

        let RulesExplorer;
        RulesExplorer = this.buildPerm(
            "UserInterface",
            "Login",
            "cleardemand_modules_rulesexplorer",
            true,
            true,
            true,
            true,
            true
        );

        let LOVs;
        LOVs = this.buildPerm(
            "UserInterface",
            "Login",
            "cleardemand_modules_lovs",
            true,
            true,
            true,
            true,
            true
        );

        RulesExplorer.children.push(
            this.buildPerm(
                "UserInterface",
                "Login",
                "cleardemand_modules_rulesexplorer_treeview",
                true,
                true,
                true,
                true,
                true
            )
        );

        let Panes;
        Panes = this.buildPerm(
            "UserInterface",
            "Login",
            "cleardemand_modules_rulesexplorer_panes",
            true,
            true,
            true,
            true,
            true
        );
        let ScenarioPane;
        ScenarioPane = this.buildPerm(
            "UserInterface",
            "Login",
            "cleardemand_modules_rulesexplorer_panes_scenarios",
            true,
            true,
            true,
            true,
            true
        );
        ScenarioPane.children.push(
            this.buildPerm(
                "UserInterface",
                "Login",
                "cleardemand_modules_rulesexplorer_panes_scenarios_grid",
                true,
                true,
                true,
                true,
                true
            )
        );
        ScenarioPane.children.push(
            this.buildPerm(
                "UserInterface",
                "Login",
                "cleardemand_modules_rulesexplorer_panes_scenarios_exportexcel",
                true,
                true,
                true,
                true,
                true
            )
        );

        Panes.children.push(ScenarioPane);

        Panes.children.push(
            this.buildPerm(
                "UserInterface",
                "Login",
                "cleardemand_modules_rulesexplorer_panes_panels",
                true,
                true,
                true,
                true,
                true
            )
        );
        Panes.children.push(
            this.buildPerm(
                "UserInterface",
                "Login",
                "cleardemand_modules_rulesexplorer_panes_rules",
                true,
                true,
                true,
                true,
                true
            )
        );

        RulesExplorer.children.push(Panes);

        let TableauModule;
        TableauModule = this.buildPerm(
            "UserInterface",
            "Login",
            "cleardemand_modules_tableau",
            true,
            true,
            true,
            true,
            true
        );

        let SettingsModule;
        SettingsModule = this.buildPerm(
            "UserInterface",
            "Login",
            "cleardemand_modules_settings",
            true,
            true,
            true,
            true,
            true
        );

        let RoleManger;
        RoleManger = this.buildPerm(
            "UserInterface",
            "Login",
            "cleardemand_modules_rolemanager",
            true,
            true,
            true,
            true,
            true
        );

        Modules.children.push(LOVs);
        Modules.children.push(RulesExplorer);
        Modules.children.push(TableauModule);
        Modules.children.push(SettingsModule);
        Modules.children.push(RoleManger);
        Perms.children.push(Modules);

        return Perms;
    }
    usersOfRole(data: any, callback: any, errorCallback: any) {
        let methodName = "Users of Role";

        super.post('/PricingEngineService/Roles2Users', data, methodName,
            (result) => {
                callback(result);
            },
            (error) => {
                errorCallback(error)
            });     
    }
    addUsers(users: RoleUser[], callback: any, errorCallback: any) {
        let methodName = "Add User";

        super.post('/PricingEngineService/Roles2AddUsers', users, methodName,
            (result) => {
                callback(result);
            },
            (error) => {
                errorCallback(error)
            }); 
    }
    fetchUsers(callback: any, errorCallback: any) {
        let methodName = "Fetch Users";

        super.post('/PricingEngineService/Roles2ListUsers', {}, methodName,
            (result) => {
                callback(result);
            },
            (error) => {
                errorCallback(error)
            }); 

    }
    fetchRoles(callback: any, errorCallback: any) {
        let methodName = "Fetch Roles";
        super.get('/PricingEngineService/Roles2',  methodName,
            (result) => {
                let itM = [];
                let itX = new Role();
                itX.id = Math.floor(Math.random() * 1000000);
                itX.text = "Roles";
                itX.expanded = true;
                itX.items = [];

                for (let it of result) {
                    let newIT = new Role();

                    newIT.id = it.Id;
                    newIT.text = it.Name;
                    newIT.identityTypeId = it.IdentityTypeId;
                    newIT.name = it.Name;
                    newIT.displayName = it.DisplayName;
                    newIT.userID = it.UserID;
                    newIT.roleID = it.RoleId;
                    newIT.description = it.Description;

                    newIT.items = [];
                    itX.items.push(newIT);
                }
                itM.push(itX);
                callback(itM)
            },
            (error) => {
                errorCallback(error)
            }); 

      
    }
    fetch(params: PagingFilter, callback: any, errorCallback: any) {
        let methodName = "Fetch";
        super.startRequest(methodName, params);
        let globalAdminID = "1";
        switch (window.location.hostname) {
            case "murphysandbox.cleardemand.com":
            case "murphydev.cleardemand.com":
            case "murphy.cleardemand.com":
            case "murphysandbox.cleardemand.local":
            case "murphydev.cleardemand.local":
            case "murphy.cleardemand.local":
                globalAdminID = "1";
                break;
            case "sfmqa.cleardamand.com":
            case "sfmstaging.cleardamand.com":
            case "sfmstaging2.cleardamand.com":
            case "sfm.cleardamand.com":
            case "sfmqa.cleardamand.local":
            case "sfmstaging.cleardamand.local":
            case "sfmstaging2.cleardamand.local":
            case "sfm.cleardamand.local":
                globalAdminID = "1";
                break;
            case "localhost":
                globalAdminID = "1";
                break;
        }
        super.get("/PricingEngineService/PermissionTemplates?RoleId=" + globalAdminID, methodName,
            (result) => {
                let itM: Permission[] = [];
                let itX = new Permission();
                itX.id = Math.floor(Math.random() * 1000000);
                itX.text = "Roles";
                itX.permissionName = "Permissions";
                itX.expanded = true;
                itX.items = [];

                //first pass will get the item template and store its information into itM
                for (let it of result) {
                    let newIT = new Permission();

                    newIT.id = it.permissionId;
                    newIT.text = it.feature + "/" + it.component + "/" + it.permissionName;
                    newIT.feature = it.feature;
                    newIT.component = it.component;
                    newIT.permissionName = it.permissionName;
                    newIT.hasRead = it.hasRead;
                    newIT.hasEdit = it.hasEdit;
                    newIT.hasDelete = it.hasDelete;
                    newIT.hasExecute = it.hasExecute;
                    newIT.shallRead = false;
                    newIT.shallEdit = false;
                    newIT.shallDelete = false;
                    newIT.shallExecute = false;
                    newIT.description = it.description ? it.description : "";
                    newIT.items = [];
                    newIT.permissionID = it.permissionId;
                    newIT.identityId = params.ruleID; //it.identityId;
                    itX.items.push(newIT);
                }

                itM.push(itX);

                super.get("/PricingEngineService/PermissionTemplates?RoleId=" + params.ruleID, methodName,
                    (res2) => {

                        for (let itm of res2) {
                            // loop inner array

                            for (let i = 0; i < itM[0].items.length; i++) {
                                itM[0].items[i].identityId = itm.identityId;
                                if (
                                    itm.feature === itM[0].items[i].feature &&
                                    itm.component === itM[0].items[i].component &&
                                    itm.permissionName === itM[0].items[i].permissionName
                                ) {
                                    if (itm.hasRead === true) {
                                        itM[0].items[i].shallRead = true;
                                    } else {
                                        itM[0].items[i].shallRead = false;
                                    }
                                    if (itm.hasExecute === true) {
                                        itM[0].items[i].shallExecute = true;
                                    } else {
                                        itM[0].items[i].shallExecute = false;
                                    }
                                    if (itm.hasDelete === true) {
                                        itM[0].items[i].shallDelete = true;
                                    } else {
                                        itM[0].items[i].shallDelete = false;
                                    }
                                    if (itm.hasEdit === true) {
                                        itM[0].items[i].shallEdit = true;
                                    } else {
                                        itM[0].items[i].shallEdit = false;
                                    }
                                }
                            }
                        }
                        //reconstruct
                        //layer 1
                        let newOrder = [];
                        for (let root of itM[0].items) {
                            let exists = false;
                            for (let NORoot of newOrder) {
                                if (NORoot.feature === root.feature) {
                                    exists = true;
                                }
                            }
                            if (exists === false) {
                                let newPerm = new Permission();
                                newPerm.id = Math.floor(Math.random() * 1000000) * -1;
                                newPerm.text = root.feature;
                                newPerm.feature = root.feature;
                                newPerm.component = "";
                                newPerm.permissionName = root.feature;
                                newPerm.items = [];
                                newOrder.push(newPerm);
                            }
                        }

                        //layer 2
                        for (let root2 of newOrder) {

                            for (let itms of itM[0].items) {
                                if (root2.feature === itms.feature) {
                                    let exists = false;
                                    for (let root2items of root2.items) {
                                        if (root2items.component === itms.component) {
                                            exists = true;
                                        }
                                    }
                                    if (exists === false) {
                                        let newPerm = new Permission();
                                        newPerm.id = Math.floor(Math.random() * 1000000) * -1;
                                        newPerm.text = itms.component;
                                        newPerm.feature = itms.feature;
                                        newPerm.component = itms.component;
                                        newPerm.permissionName = itms.component;
                                        newPerm.items = [];
                                        root2.items.push(newPerm);
                                    }
                                }
                            }
                        }

                        //layer 3
                        for (let root2 of newOrder) {
                            for (let root3 of root2.items) {

                                for (let itms of itM[0].items) {
                                    if (
                                        root3.feature === itms.feature &&
                                        root3.component === itms.component
                                    ) {
                                        let exists = false;
                                        for (let root3items of root3.items) {
                                            if (root3items.permissionName === itms.permissionName) {
                                                exists = true;
                                            }
                                        }
                                        if (exists === false) {
                                            let newPerm = new Permission();
                                            newPerm.id = Math.floor(Math.random() * 1000000) * -1;
                                            newPerm.text = itms.component;
                                            newPerm.feature = itms.feature;
                                            newPerm.component = itms.component;
                                            newPerm.permissionName = itms.permissionName;
                                            newPerm.permissionID = itms.permissionID;
                                            newPerm.identityId = itms.identityId;
                                            newPerm.id = itms.permissionID;
                                            newPerm.hasRead = itms.hasRead;
                                            newPerm.hasEdit = itms.hasEdit;
                                            newPerm.hasDelete = itms.hasDelete;
                                            newPerm.hasExecute = itms.hasExecute;
                                            newPerm.shallRead = itms.shallRead;
                                            newPerm.shallEdit = itms.shallEdit;
                                            newPerm.shallDelete = itms.shallDelete;
                                            newPerm.shallExecute = itms.shallExecute;
                                            newPerm.description = itms.description;
                                            newPerm.expanded = true;

                                            newPerm.items = [];
                                            root3.items.push(newPerm);
                                        }
                                    }
                                }
                            }
                        }

                        itM[0].items = newOrder;
                        console.log(itM);
                        callback(itM);
                    },
                    (error2) => { });
            },
            (error) => {
                errorCallback(error)
            }); 
    }
    deleteRole(data: any, callback: any, errorCallback: any) {
        let methodName = "Delete Role";
        super.post('/PricingEngineService/DeleteRole2', data, methodName,
            (result) => {
                callback(result);
            },
            (error) => {
                errorCallback(error)
            }); 
    }
    removeUser(data: any, callback: any, errorCallback: any) {
        let methodName = "Remove User";
        super.post('/PricingEngineService/DeleteUser2FromRole', data, methodName,
            (result) => {
                callback(result);
            },
            (error) => {
                errorCallback(error)
            }); 
    }
    saveRoles(data: any, callback: any, errorCallback: any) {
        let methodName = "Save Roles";
        super.post('/PricingEngineService/Roles2Post', data, methodName,
            (result) => {
                callback(result);
            },
            (error) => {
                errorCallback(error)
            }); 
    }
    saveRoleDescriptors(data: any, callback: any, errorCallback: any) {
        let methodName = "Save Role Descriptors";
        super.post('/PricingEngineService/Roles2UpdateDescriptors', data, methodName,
            (result) => {
                callback(result);
            },
            (error) => {
                errorCallback(error)
            });
    } 
    newRole(data: Role, callback: any, errorCallback: any) {
        let methodName = "New Role";
        super.post('/PricingEngineService/Roles2NewRole', data, methodName,
            (result) => {
                callback(result);
            },
            (error) => {
                errorCallback(error)
            });
    }
}
