const dateHelper = {

    // pass in iso string "2021-05-25T00:00:00"
    // get "05/25/2021"
    isoStringToMMDDYYYY(val: string) { // aka "dateStringFromIso" inside val-helper TODO consolidate from here
        let dateVal = new Date(val);
        return ((dateVal.getMonth() > 8)
            ? (dateVal.getMonth() + 1)
            : ('0' + (dateVal.getMonth() + 1))) + '/' + ((dateVal.getDate() > 9)
                ? dateVal.getDate()
                : ('0' + dateVal.getDate())) + '/' + dateVal.getFullYear()

    },

    // pass in js Date
    // get "03/01/2020"
    dateToMMDDYYYYString(date: Date) {
        let dArr = date.toISOString().substr(0, 10).split('-')
        return dArr[1] + '/' + dArr[2] + '/' + dArr[0]
    }

}

export default dateHelper;