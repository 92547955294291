import * as React from 'react';
import Spinner from '../../React/Misc/Spinner';
import MasterContext from '../../React/Misc/MasterContext';
export interface IProps {
}
export interface IState {
    loaded: boolean;
}

export default class EngineRun extends React.Component<IProps, IState> {

        constructor(props: IProps) {
            super(props);
            this.state = { loaded: true }
            this.finishLoad = this.finishLoad.bind(this);
        }

        finishLoad() {
            this.setState({ loaded: false });
        }


        getURL(): string {
            let retVal = '';
            retVal = MasterContext.DOTNETURL + "Engine/run.aspx?source=C3PO&apiKey=" + localStorage.getItem("userToken");
            return retVal;
        }

    
        render() {
            let style1 = { height: "405px" }
            let style2 = { border: "0px", height: "97vh" };        return (
                <div id="mainBody" className="main-body container-fill ng-scope body-fill" event-control="" cdi-body-fill-container="" ui-view="" role="main" style={style1}>
                      <Spinner
                        spinnerActive={this.state.loaded}
                        heightSet="99vh"
                        paddingSet="10%"
                        spinnerText={MasterContext.translate("spinner_loading_engine_run_manager")}  
                    />
                    <iframe id="iframeEngineRun" title='iframeEngineRun' onLoad={this.finishLoad}  src={this.getURL()} width="100%" style={style2} ></iframe>
                </div>
            );

        }

}