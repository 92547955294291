import LockDaysReq from '../Types/LockDays.model';
import IPMOBaseService from '../../Generics/Service/IPMOBaseService'
export default class LockDaysService extends IPMOBaseService {
    getLockDays(callback: any, errorCallback: any) {
        let methodName = "Get Lock Days";

        super.get("/PricingEngineService/AppProperties/AppDefaults/LockDays", methodName,
            (result) => {
                callback(result);
            },
            (error) => {
                errorCallback(error)
            });
    }
    setLockDays(data: LockDaysReq, callback: any, errorCallback: any) {
        let methodName = "Set Lock Days Search";

        super.put("/PricingEngineService/AppProperties/AppDefaults/LockDays", data, methodName,
            (result) => {
                callback(result);

            },
            (error) => {
                errorCallback(error)
            });
    }
}