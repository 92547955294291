import * as React from "react";
import MasterContext from '../Misc/MasterContext';

export default class Settings extends React.Component<{}, {}> {
    state = {
        visible: false
    };

    constructor(props: any) {
        super(props);
        this.state = {
            visible: false
        };
        this.changeLang = this.changeLang.bind(this);
        this.getLanguages = this.getLanguages.bind(this);
        this.changePageSize = this.changePageSize.bind(this);
        this.getPageSizes = this.getPageSizes.bind(this);
        
        
    }
    getPageSizes() {
        let retVal: any[] = [];
        if (MasterContext.pageSize === '25') {
            retVal.push(<option selected value="25">25</option>);
        }
        else {
            retVal.push(<option value="25">25</option>);
        }
        if (MasterContext.pageSize === '50') {
            retVal.push(<option selected value="50">50</option>);
        }
        else {
            retVal.push(<option value="50">50</option>);
        }
        if (MasterContext.pageSize === '100') {
            retVal.push(<option selected value="100">100</option>);
        }
        else {
            retVal.push(<option value="100">100</option>);
        }

        return retVal;
    }
    changePageSize(ps: any) {
        let v = ps.target.value;
        localStorage.setItem("pageSize", v);
        MasterContext.pageSize = v;
        
    }
    changeLang(lang: any) {
        let v = lang.target.value;
        localStorage.setItem("selectedLanguage", v);
        MasterContext.selectedLanguage = v;
        window.location.reload(false);
    }
    getLanguages() {
        let retVal: any[] = [];
        if (MasterContext.selectedLanguage === 'en') {
            retVal.push(<option selected value="en">English</option>);
        }
        else {
            retVal.push(<option value="en">English</option>);
        }
        if (MasterContext.selectedLanguage === 'es') {
            retVal.push(<option selected value="es">Español</option>);
        }
        else {
            retVal.push(<option value="es">Español</option>);
        }
        if (MasterContext.selectedLanguage === 'fr') {
            retVal.push(<option selected value="fr">Français</option>);
        }
        else {
            retVal.push(<option value="fr">Français</option>);
        }

        return retVal;
    }
    render() {
        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-md-4"></div>
                    <div className="col-md-4">
                        <div className='container card'>
                            <h3 className="text-center text-info">{MasterContext.translate("settings_header")}</h3>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="alert alert-secondary" role="alert">
                                        {MasterContext.translate("settings_header_details")}
                                    </div>

                                </div>
                            </div>
                            <div className="form-group">
                                <div className="row">
                                    <div className="col-md-3">
                                        <label className="loginColors">{MasterContext.translate("settings_pick_language")}</label>
                                    </div>
                                    <div className="col-md-9">
                                        <select className="form-control" onChange={this.changeLang}>
                                            {this.getLanguages()}
                                        </select>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-3">
                                    </div>
                                    <div className="col-md-9">
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="row">
                                    <div className="col-md-3">
                                        <label className="loginColors">{MasterContext.translate("settings_page_size")}</label>
                                    </div>
                                    <div className="col-md-9">
                                        <select className="form-control" onChange={this.changePageSize}>
                                            {this.getPageSizes()}
                                        </select>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-3">
                                    </div>
                                    <div className="col-md-9">
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                            </div>
                        </div></div>
                    <div className="col-md-4"></div>
                </div>
            </React.Fragment>
        );
    }
}
