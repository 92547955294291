import * as React from 'react';

import LockDaysReq from '../../../Services/LockDays/Types/LockDays.model';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import MasterContext from '../Misc/MasterContext';


export interface IProps {

}

export interface IState {
    lock: LockDaysReq,
    showResultModal: boolean,
    lockDaysValidationErrors:any[]
}

export default class LockDays extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {

            lock: new LockDaysReq(),
            showResultModal: false,
            lockDaysValidationErrors:[]
        };
        this.handleLockDaysChanged = this.handleLockDaysChanged.bind(this);
        this.saveSelection = this.saveSelection.bind(this);
        this.toggleshowResultModal = this.toggleshowResultModal.bind(this);
        this.validate = this.validate.bind(this);
        this.getMyValidationErrors = this.getMyValidationErrors.bind(this);
        
    }

    
    toggleshowResultModal() {
        this.setState({ showResultModal: !this.state.showResultModal });
    }



    componentDidMount() {
        MasterContext.LockDaysService.getLockDays((e) => {
            this.setState({lock:e});
        }, () => { }); 
    }
    saveSelection() {
        if (this.validate() === false) {
            MasterContext.LockDaysService.setLockDays(this.state.lock, (e) => {
                // this.setState({ lock: e });
                this.toggleshowResultModal();
            }, () => { });
        }
    }
    handleLockDaysChanged(event: any) {
        this.validate()
        let v = event.target.value;
        let cpy:LockDaysReq = new LockDaysReq();
        Object.assign(cpy, this.state.lock);
        cpy.Value = v;
        this.setState({ lock: cpy });
    }

    validate(): boolean {
        let retVal: boolean = false;

        let newErrors: any[] = [];
        if (this.state.lock.Value.length === 0) {
            let newErr: any = {};
            newErr.controlName = 'daysToLock';
            newErr.error = MasterContext.translate('validation_lock_days_required');
            newErr.severity = 1;
            newErrors.push(newErr);
            retVal = true;
        }

       

        this.setState({ lockDaysValidationErrors: newErrors });

        return retVal;


    }

    getMyValidationErrors(control: string): string {
        let retVal: string = '';
        if (this.state.lockDaysValidationErrors) {
            for (let err of this.state.lockDaysValidationErrors) {
                if (err.controlName === control) {
                    retVal = retVal + err.error + '\r\n';
                }
            }
        }
        return retVal;
    }


    render() {
        let errorClass = { color: "#ff0000" };

        return (
            <React.Fragment>

                <Modal zIndex={3002} toggle={() => this.toggleshowResultModal()} isOpen={this.state.showResultModal}>
                    <ModalHeader toggle={() => this.toggleshowResultModal()}>{MasterContext.translate("lock_days_modal_header")}</ModalHeader>
                    <ModalBody>{MasterContext.translate("lock_days_modal_body")}</ModalBody>
                    <ModalFooter>
                        <button className="btn btn-sm btn-outline-info" color="primary" onClick={() => this.toggleshowResultModal()}>{MasterContext.translate("lock_days_modal_button")}</button>{' '}                       
                    </ModalFooter>
                </Modal>

                <div className="row">
                    <div className="col-md-4"></div>
                    <div className="col-md-4 card">
                        
                        <div className="row">
                            <div className="col-md-12">
                                <h3 className="text-center text-info">{MasterContext.translate("lock_days_header")}</h3>
                                </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="alert alert-secondary" role="alert">

                                    {MasterContext.translate("lock_days_description")}
                                    </div>
                            </div>
                        </div>

                        <div className="form-group">
                            <div className="row">
                                <div className="col-md-3">
                                    <label className="loginColors">{MasterContext.translate("lock_days_label")}  </label>
                                </div>
                                <div className="col-md-9">
                                    <input placeholder={MasterContext.translate("lock_days_placeholder")} id='daysToLock' type="number" value={this.state.lock.Value} onChange={this.handleLockDaysChanged} className="form-control" />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-3">
                                </div>
                                <div className="col-md-9">
                                    <span className="pull-right" style={errorClass}>{this.getMyValidationErrors('daysToLock')}</span>
                                </div>
                            </div>



                        </div>


                        <div className="row">

                            <div className="col-md-6">
                            </div>
                            <div className="col-md-6">
                                <button className="btn btn-outline-primary btn-sm float-right" id="btnSaveSelection" onClick={this.saveSelection}>{MasterContext.translate("lock_days_button")}</button>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4"></div>
                </div>
            </React.Fragment>
        )
    }
}