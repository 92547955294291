export class ProductGroupQueryRequest {
    public SearchType : number = 0;
    public GroupSourceTypeId: number = 0;
    public GroupTypeId: number = 0;
    public GroupId: number = 0;
    public Count: number = 0;
    public PrefixText: string = "";
    public Phrase: string = "";
    public Page: number = 0;
    public Items: number[] = [];
}

export class ProductGroupQueryResult {
    public sessGroupSourceType: any;
    public _groupSourceTypeId: any;
    public _groupReadonlyFlag: any;
    public btnCreateVisible: any;
    public btnDeleteVisible: any;
    public btnRefreshGroupsVisible: any;
    public ddlGroupSourceTypesItems: any;
    public lblMsgText: any;
    public lnkJobStatusVisible: any;
    public _groupSvc: any;
    public grps: any;
}

export class NodeRequest {
    public Level: number;
    public NodeName: string;
}
