import { ProductGroupQueryRequest, NodeRequest } from '../Types/ProductGroups.model';
import IPMOBaseService from '../../Generics/Service/IPMOBaseService'

export default class ProductGroupsService extends IPMOBaseService {
    search(data: ProductGroupQueryRequest, callback: any, errorCallback: any) {
        let methodName = "Product Group Search";
        super.post("/Aspx/ProductGroup/Search/Init", data, methodName,
            (result) => {
                for (let itm of result.Data.grps) {
                    itm.checked = false;
                }
                if (callback !== null) {
                    callback(result.Data);
                }
            },
            (error) => {
                errorCallback(error)
            });
    }
    delete(data: ProductGroupQueryRequest, callback: any, errorCallback: any) {
        let methodName = "Product Group Delete";
        super.post("/Aspx/ProductGroup/Search/Delete", data, methodName,
            (result) => {

                callback(result.Data);

            },
            (error) => {
                errorCallback(error)
            });
    }
    searchAutoComplete(data: ProductGroupQueryRequest, callback: any, errorCallback: any) {
        let methodName = "Search AutoComplete";

        super.soapPost("/WebServices/AutoComplete.asmx",
            '<s:Envelope xmlns:s="http://schemas.xmlsoap.org/soap/envelope/"><s:Body><GetProductGroups xmlns="http://tempuri.org/" xmlns:i="http://www.w3.org/2001/XMLSchema-instance"><prefixText>' + data.PrefixText + '</prefixText><count>' + data.Count + '</count><contextKey>SearchType=' + data.SearchType + '&amp;GroupSourceTypeId=' + data.GroupSourceTypeId + '</contextKey></GetProductGroups></s:Body></s:Envelope>',
            methodName,
            'http://tempuri.org/GetProductGroups',
            (res) => {
                let retVal: any[] = [];
                var parser, xmlDoc;
                parser = new DOMParser();
                xmlDoc = parser.parseFromString(res, "text/xml");
                var nodes = xmlDoc.getElementsByTagName("GetProductGroupsResult")[0].childNodes;
                for (let item of nodes) {
                    let newV = item.innerHTML;
                    let J = JSON.parse(newV)
                    retVal.push(J);
                }

                if (callback !== null) {
                    callback(retVal);
                }
            },
            (error) => {
                errorCallback(error)
            });
    }
    listProductHeirarchy(data: NodeRequest, callback: any, errorCallback: any) {
        let methodName = "List Product Hierarchy";
        super.post("/Aspx/ProductGroup/Hier/Search", data, methodName,
            (result) => {

                callback(result.Data.hierData);

            },
            (error) => {
                errorCallback(error)
            });
    }
    listDimensions(callback: any) {
        let retval: any[] = [];
        let itm = { id: 1, name: "BRAND NAME" };
        let itm2 = { id: 2, name: "CAT_RANK" };
        let itm3 = { id: 3, name: "CUSTOM_ATTRIB" };
        retval.push(itm);
        retval.push(itm2);
        retval.push(itm3);
        callback(retval);
    }
}