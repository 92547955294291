import React from "react";
import moment from 'moment';


import { AgGridReact } from '@ag-grid-community/react';
import { ColDef, ColGroupDef, SideBarDef } from '@ag-grid-community/core';
import {
	StatusPanelDef,
	IServerSideGetRowsRequest,
	ColumnApi,
	ClientSideRowModelModule,
	ServerSideRowModelModule,
	SetFilterModule,
    FiltersToolPanelModule,
	ColumnsToolPanelModule,
	SideBarModule,
	StatusBarModule,
    ClipboardModule,
    ExcelExportModule,
    GridChartsModule,
    MenuModule,
    RowGroupingModule,
} from '@ag-grid-enterprise/all-modules';
import '@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css';
import '@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css';
import '../css/cdi-ag-grid.css';

import StatusBarComponent from './StatusBar';
import MasterContext from '../Misc/MasterContext';
import { Modal, ModalBody, ModalFooter, ModalHeader, Collapse, NavbarToggler, DropdownItem, DropdownMenu, Navbar, DropdownToggle, UncontrolledDropdown, Nav, Spinner } from 'reactstrap';
import { GetLovsRequest } from '../../../Services/Generics/Types/IPMOBaseService.model';

export interface IProps {
    handleProps?: any,
    onSelectedState?: any,
    selectedState?: any,
    search?: boolean,
    isExportingFlag?: boolean,
    isLoadingExportFlag?: boolean,
    onExport?: any
}
export interface IState {
    debug: boolean,
    modules: any[],
    frameworkComponents: any,
    rowData: any[],
    totalRowCount: number,
    columnDefs?: (ColDef | ColGroupDef)[];
    multiSortKey: string,
    rowModelType: string,
    rowSelection: string,
    cacheBlockSize: number,
    maxBlocksInCache: number,
    blockLoadDebounceMillis: number,
    defaultColDef?: ColDef,
    columnTypes: {
        [key: string]: ColDef;
    },
    autoGroupColumnDef?: any,
    groupDefaultExpanded?: number,
    sideBar: (string | boolean | SideBarDef),
    statusBar: {
        statusPanels: StatusPanelDef[];
    },
    rowClassRules: any,
    gridApi: any,
    gridColumnApi: ColumnApi,
    lastFilterColumn: string,
    getDataPath?: any,
    onTest?: any,
    selectedState?: any,
    exportDataAsCsv?: any,
    exportDataAsExcel?: any,
    suppressExcelExport?: any,
    showdModal?: boolean,
    isLoadingExportFlag?: boolean,
    exportStateMinDetails?: any

}

export default class StateMinimumHeader extends React.Component<any, any> {
    constructor(props) {
        super(props);
        this.state = {
            selectedDataItem: {
                StateCode: "",
                StateMinimumDescription: "",
                ProductId: "",
                LocationNodeName: "",
                Formula: "",
                StartDate: "",
                EndDate: "",
                GrossCost: "",
                NetCost: "",
                RetailPrice: "",
                StateMinimumPrice: "",
                Difference: ""
            },
            search: false,           
            toggleMenuCollapse: false,
            isMenuOpen: false,
            isExportingFlag: false,
            isLoadingExportFlag: false
        };
    }

    generateStateMinimums = () => {
        MasterContext.StateMinimumService.generateStateMinimums(
            (success) => {
                console.log("Successfully sent out a request to generate state minimums.");
            }, (err) => {

            });
    }

    // Set the export flag to true. This tells the child to export the file
    exportStateMinimums = () => {
        this.setState({ isExportingFlag: true, isLoadingExportFlag: true });
    }

    // Set the export flag. This is called when the child has received/acknowledged the export call 
    handleExport = (exportFlag, loadingFlag) => {
        this.setState({ isExportingFlag: exportFlag ?? false, isLoadingExportFlag: loadingFlag ?? false });
    }

    // Update the selected data item with the new state minimum data item
    handleSelectedState = (dataItem, search) => {
        this.setState({ selectedDataItem: dataItem, search: search });
    } 

    toggle = () => {
        this.setState({ isMenuOpen: !this.state.isMenuOpen})
    }

    crrencyInputFormat(value) {
        try {
            return (value != null && value != "") ? ("$" + Number(value).toFixed(2)) : '';
        } catch(ex) {
            return value;
        }
    }

    render() {
        let inputStyle = { fontSize: 'small' }; //width: "280" };
        let navBarHeader = { paddingLeft: "15px"};
        return (
            <div className="container-fluid">
                <div id="divFilterPanel" className="row card">
                    <span>
                        <Navbar color="light" light expand="md" id="StateMinNavbar">
                            <NavbarToggler onClick={this.toggle} />
                            <Collapse isOpen={this.state.isMenuOpen} navbar>
                                <Nav className="mr-auto" navbar id="StateMinNav">
                                    <UncontrolledDropdown nav inNavbar>
                                        <DropdownToggle nav caret className="btn btn-primary" id="StateMinDropdownToggle">
                                            <i className="fa fa-bars"></i>
                                        </DropdownToggle>
                                        <DropdownMenu left="0" id="StateMinDropdownMenu">
                                            <DropdownItem onClick={this.generateStateMinimums} id="GenStateMinDropdownItem">
                                                {MasterContext.translate("state_min_editor_generate")}
                                            </DropdownItem>
                                            <DropdownItem onClick={this.exportStateMinimums} id="ExportStateMinDropdownItem" disabled={this.state.isLoadingExportFlag}> 
                                                {MasterContext.translate("export_state_minimums")}                                            
                                            </DropdownItem>
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                    <div style={navBarHeader} id="StateMinExplorer">
                                        <h4>{MasterContext.translate("state_minimum_editor")}</h4>
                                    </div>
                                </Nav>
                            </Collapse>
                        </Navbar>
                    </span>
                </div>
                <div>
                    <div className="row">
                        <div className="col-md-2" style={{paddingLeft: "0", paddingRight: "5px"}}>
                            <StateMinimumProperties
                                onSelectedState={this.handleSelectedState} />
                        </div>
                        <div className="col-md-10">
                            <div className="row form-group">
                                <StateMinimumDetails
                                    onSelectedState={this.handleSelectedState}
                                    selectedState={this.state.selectedDataItem?.StateMinimumDescription}
                                    search={this.state.search} 
                                    onExport={this.handleExport}
                                    isExportingFlag={this.state.isExportingFlag}
                                    isLoadingExportFlag={this.state.isLoadingExportFlag} />
                            </div>
                            <div className="col-md-12 small">
                                <div className="row form-group">
                                </div>
                                <div className="row form-group">
                                    <div className="col-md-1 text-right bold">
                                        <span style={inputStyle}>
                                            {MasterContext.translate("state_min_editor_desc_label")}
                                        </span>
                                    </div>
                                    <div className="col-md-5">
                                        <input
                                            name="StateMinDesc"
                                            style={inputStyle}
                                            className="form-control"
                                            placeholder={MasterContext.translate("state_min_editor_desc_label")}
                                            readOnly
                                            defaultValue={this.state.selectedDataItem?.StateMinimumDescription != null
                                                ? this.state.selectedDataItem.StateMinimumDescription : ''} />
                                    </div>
                                    <div className="col-md-1 text-right bold">
                                        <span style={inputStyle}>
                                            {MasterContext.translate("state_min_editor_product_label")}
                                        </span>
                                    </div>
                                    <div className="col-md-1">
                                        <input
                                            name="ProductId"
                                            type="text"
                                            className="form-control"
                                            readOnly
                                            defaultValue={this.state.selectedDataItem?.ProductId != null 
                                                ? this.state.selectedDataItem.ProductId : ''}
                                        />
                                    </div>
                                    <div className="col-md-1 text-right bold">
                                        <label style={inputStyle}>
                                            {MasterContext.translate("state_min_editor_location_label")}
                                        </label>
                                    </div>
                                    <div className="col-md-1">
                                        <input
                                            name="LocationNodeName"
                                            type="text"
                                            className="form-control"
                                            readOnly
                                            defaultValue={this.state.selectedDataItem?.LocationNodeName != null
                                                ? this.state.selectedDataItem.LocationNodeName : ''}
                                        />
                                    </div>
                                </div>
                                <div className="row form-group">
                                    <div className="col-md-1 text-right bold">
                                        <span style={inputStyle}>
                                            {MasterContext.translate("state_min_editor_formula_label")}
                                        </span>
                                    </div>
                                    <div className="col-md-5">
                                        <textarea
                                            name="StateMinFormula"
                                            style={inputStyle}
                                            className="form-control"
                                            placeholder={MasterContext.translate(
                                                "state_min_editor_formula_label"
                                            )}
                                            readOnly
                                            aria-describedby="basic-addon1"
                                            defaultValue={this.state.selectedDataItem?.Formula != null ?
                                                this.state.selectedDataItem.Formula : ''}
                                        ></textarea>
                                    </div>
                                    <div className="col-md-1 text-right bold">
                                        <label style={inputStyle}>
                                            {MasterContext.translate("start_date_label")}
                                        </label>
                                    </div>
                                    <div className="col-md-1">
                                        <input
                                            name="StateMinStartDate"
                                            className="form-control"
                                            readOnly
                                            defaultValue={this.state.selectedDataItem?.StartDate != null
                                                ? this.state.selectedDataItem.StartDate : ''}
                                        />
                                    </div>
                                    <div className="col-md-1 text-right bold">
                                        <label style={inputStyle}>
                                            {MasterContext.translate("end_date_label")}
                                        </label>
                                    </div>
                                    <div className="col-md-1">
                                        <input
                                            name="StateMinEndDate"
                                            className="form-control"
                                            readOnly
                                            defaultValue={this.state.selectedDataItem?.EndDate != null
                                                ? this.state.selectedDataItem.EndDate : ''}
                                        />
                                    </div>
                                </div>
                                <div className="row form-group">
                                    <div className="col-md-1 text-right bold">
                                        <label style={inputStyle}>
                                            {MasterContext.translate("gross_cost_label")}
                                        </label>
                                    </div>
                                    <div className="col-md-1">                                     
                                        <input
                                            name="GrossCost"
                                            className="form-control"
                                            readOnly
                                            defaultValue={this.crrencyInputFormat(this.state.selectedDataItem?.GrossCost)}
                                        />
                                    </div>
                                    <div className="col-md-1 text-right bold">
                                        <label style={inputStyle}>
                                            {MasterContext.translate("net_cost_label")}
                                        </label>
                                    </div>
                                    <div className="col-md-1">
                                        <input
                                            name="NetCost"
                                            className="form-control"
                                            readOnly
                                            defaultValue={this.crrencyInputFormat(this.state.selectedDataItem?.NetCost)}
                                        />
                                    </div>
                                    <div className="col-md-1 text-right bold">
                                        <label style={inputStyle}>
                                            {MasterContext.translate("retail_price_label")}
                                        </label>
                                    </div>
                                    <div className="col-md-1">
                                        <input
                                            name="RetailPrice"
                                            className="form-control"
                                            readOnly
                                            defaultValue={this.crrencyInputFormat(this.state.selectedDataItem?.RetailPrice)}
                                        />
                                    </div>
                                    <div className="col-md-1 text-right bold">
                                        <label style={inputStyle}>
                                            {MasterContext.translate("state_minimum_price_label")}
                                        </label>
                                    </div>
                                    <div className="col-md-1">
                                        <input
                                            name="StateMinPrice"
                                            className="form-control"
                                            readOnly
                                            defaultValue={this.crrencyInputFormat(this.state.selectedDataItem?.StateMinimumPrice)}
                                        />
                                    </div> 
                                    <div className="col-md-1 text-right bold">
                                        <label style={inputStyle}>
                                            {MasterContext.translate("state_minimum_price_diff_label")}
                                        </label>
                                    </div>
                                    <div className="col-md-1">
                                        <input
                                            name="StateMinDiff"
                                            className="form-control"
                                            readOnly
                                            defaultValue={this.crrencyInputFormat(this.state.selectedDataItem?.Difference)}
                                        />
                                    </div> 
                                </div>
                            </div>                  
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export class StateMinimumProperties extends React.Component<IProps, IState> {
//ClientSideRowModelModule, RowGroupingModule, MenuModule, ColumnsToolPanelModule, SetFilterModule
    constructor(props) {
        super(props);
        
        this.state = {
            gridApi: null,
            gridColumnApi: null,
            debug: false,
            rowData: [],
            columnDefs: this.getColumnDefs(),
            autoGroupColumnDef: false,
            // {
            //     headerName: "State Code",
            //     flex: 1, 
            //     cellRendererParams: { suppressCount: true }
            // },
            groupDefaultExpanded: -1,
            modules: [ClientSideRowModelModule, SetFilterModule, MenuModule, RowGroupingModule],
            frameworkComponents: {}, //{ statusBarComponent: StatusBarComponent },
            totalRowCount: 0,
            multiSortKey: 'ctrl',
            rowModelType: 'clientSide',
            rowSelection: 'single',
            cacheBlockSize: null,
            maxBlocksInCache: null,
            blockLoadDebounceMillis: null,
            lastFilterColumn: null,
            defaultColDef: {
                editable: false,
                filter: 'agSetColumnFilter',
                filterParams: {
                    buttons: ['clear', 'apply'],
                },
                floatingFilter: true,
                resizable: true,
                sortable: true,
                //enableRowGroup: true,
                enablePivot: true,
                enableValue: true,
                flex: 1
            },
            columnTypes: {
                nonEditableColumn: { editable: false },
            },
            sideBar: null,
            statusBar: null,
            rowClassRules: {},
            getDataPath: function (data) {
                return data.Parent;
            },
        };
    }

    getColumnDefs = (): (ColDef | ColGroupDef)[] => {
        var colDefs: (ColDef | ColGroupDef)[] = [
            {
                headerName: 'State',
                field: 'StateCode',
                flex: 1,
                //rowGroupIndex: 0
                //rowGroup: true,
                //hide: true
            },
            {
                headerName: 'Description',
                field: 'StateMinimumDescription',
                flex: 2,
                //rowGroupIndex: 1
            }
        ];
        return colDefs;
    }

    // When a row is selected, send the data of the selected record from the side panel back to the Parent
    // Child to Parent
    handleRowClicked = () => {
        var selectedRow = this.state.gridApi.getSelectedRows();
        this.props.onSelectedState(selectedRow[0], true);
    }

    onGridReady = params => {
        this.setState({
            gridApi: params.api,
            gridColumnApi: params.columnApi
        });
        let request: IServerSideGetRowsRequest = {
            startRow: 1,
            endRow: 200,
            rowGroupCols: null,
            valueCols: null,
            pivotCols: null,
            pivotMode: false,
            groupKeys: null,
            filterModel: null,
            sortModel: null
        };
        MasterContext.StateMinimumService.getStateMinimumProperties(request, (data, totalCount) => {
            // for (let i of data) {
            //     i["Parent"] = ["0", i.Id];
            // }
            // data.push({
            //     Parent: ["0"],
            //     StateMinimumDescription: "Enterprise",
            //     State: "Enterprise"
            // });
            this.setState({ rowData: data });
        }, () => {
        });
    };

    render() {
        return (
            <div className="container-fluid">
                <div id="stateMinTreeView" className="row card">
                    <div className="row" style={{ marginTop: "5px" }}>
                        <div className="col-md-12" style={{ overflowY: "hidden" as "hidden", overflowX: "hidden" as "hidden", height: "80vh" }}>
                            <div className="ag-theme-balham" style={{ height: '100%' }}>
                                <AgGridReact
                                    debug={this.state.debug}
                                    modules={this.state.modules}
                                    rowData={this.state.rowData}
                                    columnDefs={this.state.columnDefs}
                                    defaultColDef={this.state.defaultColDef}
                                    autoGroupColumnDef={this.state.autoGroupColumnDef}
                                    groupDefaultExpanded={this.state.groupDefaultExpanded}
                                    onGridReady={this.onGridReady}
                                    columnTypes={this.state.columnTypes}
                                    cacheBlockSize={this.state.cacheBlockSize}
                                    maxBlocksInCache={this.state.maxBlocksInCache}
                                    blockLoadDebounceMillis={this.state.blockLoadDebounceMillis}
                                    sideBar={this.state.sideBar}
                                    //statusBar={this.state.statusBar}
                                    //enableRangeSelection={true}
                                    //frameworkComponents={this.state.frameworkComponents}
                                    rowSelection={this.state.rowSelection}
                                    enableCharts={true}
                                    multiSortKey={this.state.multiSortKey}
                                    rowModelType={this.state.rowModelType}
                                    //treeData={true}
                                    //animateRows={true}
                                    //getDataPath={this.state.getDataPath}
                                    onRowClicked={this.handleRowClicked}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            )
    }
}

export class StateMinimumDetails extends React.Component<IProps, IState> {
    constructor(props) {
        super(props);

        this.state = {
            gridApi: null,
            gridColumnApi: null,
            suppressExcelExport: true,
            debug: false,
            modules: [
                ServerSideRowModelModule,
                SetFilterModule,
                FiltersToolPanelModule,
                ColumnsToolPanelModule,
                StatusBarModule,
                SideBarModule,
                ClipboardModule,
                ExcelExportModule,
                GridChartsModule,
                MenuModule,
            ],
            frameworkComponents: {
                statusBarComponent: StatusBarComponent,
            },
            rowData: [],
            totalRowCount: 0,
            columnDefs: this.getColumnDefs(),
            multiSortKey: 'ctrl',
            rowModelType: 'serverSide',
            rowSelection: 'multiple',
            cacheBlockSize: 1000,
            maxBlocksInCache: 100,
            blockLoadDebounceMillis: 300,
            lastFilterColumn: null,
            defaultColDef: {
                editable: false,
                filter: 'agSetColumnFilter',
                filterParams: {
                    buttons: ['clear', 'apply'],
                },
                floatingFilter: true,
                resizable: true,
                sortable: true,
                enableRowGroup: true,
                enablePivot: true,
                enableValue: true,
            },
            columnTypes: {
                numberColumn: {
                    filter: 'agNumberColumnFilter',
                    filterParams: {
                        buttons: ['clear', 'apply'],
                    },
                    type: 'numericColumn'
                },
                currencyColumn: {
                    filter: 'agNumberColumnFilter',
                    filterParams: {
                        buttons: ['clear', 'apply'],
                    },
                    valueFormatter: function (params) {
                        return '$ ' + this.formatNumber(params.value);
                    },
                    type: 'numericColumn'
                },
                nonEditableColumn: { editable: false },
                dateColumn: {
                    valueFormatter: function (params) {
                        return moment(params.value).format('MM/DD/YYYY');
                    },
                    filter: 'agDateColumnFilter',
                    filterParams: {
                        buttons: ['clear', 'apply'],
                        comparator: function (filterLocalDateAtMidnight, cellValue) {
                            var dateParts = cellValue.split('/');
                            var day = Number(dateParts[0]);
                            var month = Number(dateParts[1]) - 1;
                            var year = Number(dateParts[2]);
                            var cellDate = new Date(year, month, day);
                            if (cellDate < filterLocalDateAtMidnight) {
                                return -1;
                            } else if (cellDate > filterLocalDateAtMidnight) {
                                return 1;
                            } else {
                                return 0;
                            }
                        },
                    },
                },
            },
            sideBar: {
                toolPanels: [
                    'filters',
                    {
                        id: 'columns',
                        labelDefault: 'Columns',
                        labelKey: 'columns',
                        iconKey: 'columns',
                        toolPanel: 'agColumnsToolPanel',
                        toolPanelParams: {
                            suppressRowGroups: true,
                            suppressValues: true,
                            suppressPivots: true,
                            suppressPivotMode: true,
                            suppressSideButtons: false,
                            suppressColumnFilter: false,
                            suppressColumnSelectAll: false,
                            suppressColumnExpandAll: false,
                            suppressSyncLayoutWithGrid: true,
                        },
                    }
                ],
            },
            statusBar: {
                statusPanels: [
                    {
                        statusPanel: 'statusBarComponent',
                        align: 'left',
                    },
                ],
            },
            rowClassRules: {},
            selectedState: null,
            exportStateMinDetails: this.exportStateMinDetails
        };

        // Function Binding
        this.getColumnDefs = MasterContext.translate.bind(this);
        MasterContext.translate = MasterContext.translate.bind(this);
        this.currencyFormatter = this.currencyFormatter.bind(this);
        this.formatNumber = this.formatNumber.bind(this);
        this.getColumnValues = this.getColumnValues.bind(this);
        this.getContextMenuItems = this.getContextMenuItems.bind(this);
        this.onModelUpdated = this.onModelUpdated.bind(this);
    }

    getColumnDefs = (): (ColDef | ColGroupDef)[] => {
        var colDefs: (ColDef | ColGroupDef)[] = [
            {
                headerName: "State Minimums",
                children: [
                    {
                        field: "StateCode",
                        headerName: "State",
                        width: 100, 
                        filter: 'agSetColumnFilter',
                        filterParams: {
                            buttons: ['clear', 'apply'],
                            values: this.getColumnValues,
                            closeOnApply: true,
                            refreshValuesOnOpen: true
                        },
                        cellClass: '.grid-cell-center',
                        headerClass: '.grid-header-cell-center',
                    }, {
                        field: "StateMinimumDescription",
                        headerName: "Description",
                        width: 120, 
                        filter: 'agSetColumnFilter',
                        filterParams: {
                            buttons: ['clear', 'apply'],
                            values: this.getColumnValues,
                            closeOnApply: true,
                            refreshValuesOnOpen: true
                        }
                    }, {
                        field: "ProductId",
                        headerName: "Product",
                        width: 100, 
                        filter: 'agSetColumnFilter',
                        filterParams: {
                            buttons: ['clear', 'apply'],
                            values: this.getColumnValues,
                            closeOnApply: true,
                            refreshValuesOnOpen: true
                        }
                    }, {
                        field: "LocationNodeName",
                        headerName: "Location",
                        width: 100, 
                        filter: 'agSetColumnFilter',
                        filterParams: {
                            buttons: ['clear', 'apply'],
                            values: this.getColumnValues,
                            closeOnApply: true,
                            refreshValuesOnOpen: true
                        },
                        cellClass: '.grid-cell-center',
                        headerClass: '.grid-header-cell-center',
                    }, {
                        field: "PriceFamilyId",
                        headerName: "Price Family Id",
                        width: 120,
                        filter: 'agSetColumnFilter',
                        filterParams: {
                            buttons: ['clear', 'apply'],
                            values: this.getColumnValues,
                            closeOnApply: true,
                            refreshValuesOnOpen: true
                        },
                        cellClass: '.grid-cell-center',
                        headerClass: '.grid-header-cell-center',
                    }, {
                        field: "PriceFamilyName",
                        headerName: "Price Family",
                        width: 200,
                        filter: 'agSetColumnFilter',
                        filterParams: {
                            buttons: ['clear', 'apply'],
                            values: this.getColumnValues,
                            closeOnApply: true,
                            refreshValuesOnOpen: true
                        }
                    }, {
                        field: "Brand",
                        headerName: "Brand",
                        width: 175,
                        filter: 'agSetColumnFilter',
                        filterParams: {
                            buttons: ['clear', 'apply'],
                            values: this.getColumnValues,
                            closeOnApply: true,
                            refreshValuesOnOpen: true
                        }
                    }, {
                        field: "GrossCost",
                        headerName: "Gross Cost",
                        width: 90,
                        type: 'numericColumn',
                        valueFormatter: this.currencyFormatter,
                        filter: 'agNumberColumnFilter',
                        filterParams: {
                            buttons: ['apply', 'reset'],
                            closeOnApply: true,
                        },
                    }, {
                        field: "NetCost",
                        headerName: "Net Cost",
                        width: 90,
                        type: 'numericColumn',
                        valueFormatter: this.currencyFormatter,
                        filter: 'agNumberColumnFilter',
                        filterParams: {
                            buttons: ['apply', 'reset'],
                            closeOnApply: true
                        },
                    }, {
                        field: "TotalBuydownAmount",
                        headerName: "Total Buydown Amount",
                        type: 'numericColumn',
                        width: 175,
                        valueFormatter: this.currencyFormatter,
                        filter: 'agNumberColumnFilter',
                        filterParams: {
                            buttons: ['apply', 'reset'],
                            closeOnApply: true,
                        },
                    }, {
                        field: "RetailPrice",
                        headerName: "Retail Price",
                        type: 'numericColumn',
                        width: 100,
                        valueFormatter: this.currencyFormatter,
                        filter: 'agNumberColumnFilter',
                        filterParams: {
                            buttons: ['apply', 'reset'],
                            closeOnApply: true,
                        },
                    }, {
                        field: "StateMinimumPrice",
                        headerName: "State Min Price",
                        type: 'numericColumn',
                        width: 120,
                        valueFormatter: this.currencyFormatter,
                        filter: 'agNumberColumnFilter',
                        filterParams: {
                            buttons: ['apply', 'reset'],
                            closeOnApply: true,
                        },
                    }, {
                        field: "Difference",
                        headerName: "Difference",
                        width: 90,
                        type: 'numericColumn',
                        filter: false,
                        sortable: false
                    }, {
                        field: "Formula",
                        headerName: "Expression",
                        minWidth: 500,
                        filter: 'agSetColumnFilter',
                        filterParams: {
                            buttons: ['clear', 'apply'],
                            values: this.getColumnValues,
                            closeOnApply: true,
                            refreshValuesOnOpen: true
                        }
                    },
                ]
            }
        ];
        return colDefs;
    }

    currencyFormatter = (params) => {
        if (params.value != null)
            return '$' + params.value;
        else
            return '$0.00';
    }

    formatNumber = (number) => {
        return Math.floor(number)
            .toString()
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    }

    getContextMenuItems = (params) => {
        var result = [
            'copy',
            'copyWithHeaders',
            'separator',
            'export',
            'chartRange',
        ];
        return result;
    };

    getColumnValues = (params: any) => {
        let field: string = params.colDef.field;
        var filterModel = this.state.gridApi.getFilterModel();
        let getRowsReq: IServerSideGetRowsRequest = {
            startRow: 1,
            endRow: 50000,
            sortModel: null,
            filterModel: filterModel,
            rowGroupCols: null,
            pivotCols: null,
            pivotMode: false,
            valueCols: null,
            groupKeys: null
        };

        let request: GetLovsRequest = {
            LovName: field,
            SearchCriteria: getRowsReq
        };

        MasterContext.StateMinimumService.getLovs(request, (data, totalCount) => {
            var values: string[] = data;
            this.setState({
                lastFilterColumn: field
            });
            params.success(values);
        }, (err) => {
            console.log('Error: ' + JSON.stringify(err));
        });
    }

    toggleModal() {
        this.setState({ showdModal: !this.state.showdModal });
    }

    exportStateMinDetails = (props, state) => {
        var filterModel = state.gridApi.getFilterModel();
        var request: IServerSideGetRowsRequest = {
            startRow: 0,
            endRow: null,
            rowGroupCols: null,
            valueCols: null,
            pivotCols: null,
            pivotMode: null,
            groupKeys: null,
            sortModel: null,
            filterModel: filterModel
        };
        MasterContext.StateMinimumService.exportStateMinimums(request,
            (success) => {
                // Tell the Parent that we've received the export call and set the export flag back to false
                // Child to Parent
                props.onExport(false, false);
            }, (err) => {
                console.log("Failed to generate and download file");
                props.onExport(false, false);
            });
    }

    handleAgExportData = (params) => {
        if (this.props.isExportingFlag === true || this.props.isLoadingExportFlag === true) {
            console.log("File is already exporting");
        } else {
            this.props.onExport(false, true);
            this.exportStateMinDetails(this.props, this.state);
        }
    }

    // When a row is selected, send the data of the selected record from the grid back to the Parent
    // Child to Parent
    handleRowClicked = () => {
        var selectedRow = this.state.gridApi.getSelectedRows();
        this.props.onSelectedState(selectedRow[0], false);
    }

    // Update grid when the parent properties has changed
    // Parent to Child 
    static getDerivedStateFromProps = (props, state) => {
        try {
            // Create a filter using the selectedState from the Side Panel whenever the selectedState changes
            // Parent to child
            if (props.search && props.selectedState !== state.selectedState) {
                var StateDesc = props.selectedState;
                if (StateDesc != null) {
                    state.gridApi.getFilterInstance("StateMinimumDescription").setModel({
                        filterType: "set",
                        values: [StateDesc]
                    }).then(() => { state.gridApi.onFilterChanged(); });
                } else {
                    state.gridApi.destroyFilter("StateMinimumDescription");
                }
                return { 
                    selectedState: StateDesc 
                };
            } 
            // Use the grid's params to generate and download the state min file
            // Parent to child
            else if (props.isExportingFlag && props.isLoadingExportFlag) {
                state.exportStateMinDetails(props, state);
            }
        } catch(ex) {
            console.log("Error getting dreived State From Props for State Minimums." + ex)
        }
        return null;
    }

    onModelUpdated = (params) => {
        if (this.state.totalRowCount == null) {
            this.setState({
                totalRowCount: 0
            });
        }
    }

    onGridReady = params => {
        // Create a datasource for the grid using the State Minimum Service and the StateMinimumDetails component
        // The datasource makes a call to the State Minimum Service to retrieve State Minimum Details
        var dataSource = MasterContext.StateMinimumDatasource.createServerSideDatasource(MasterContext.StateMinimumService, this);
        params.api.setServerSideDatasource(dataSource);
        
        // Override the ag grid export to csv functionality
        params.api.origExportDataAsCsv = params.api.exportDataAsCsv;
        params.api.exportDataAsCsv = this.handleAgExportData;
        // params.api.exportDataAsExcel = this.handleAgExportData(params);
        this.setState({
            gridApi: params.api,
            gridColumnApi: params.columnApi,
        });
    }

    render() {
        return (
            <React.Fragment>
                <Modal zIndex={3002} isOpen={this.state.showdModal}>
                    <ModalHeader>{MasterContext.translate("buydown_modal_header")}</ModalHeader>
                    <ModalBody>
                        {MasterContext.translate("buydown_modal_too_many_records")}
                    </ModalBody>
                    <ModalFooter>
                        <button className="btn btn-sm btn-outline-info" color="primary" onClick={() => this.toggleModal()}>{MasterContext.translate("buydown_modal_footer")}</button>{' '}
                    </ModalFooter>
                </Modal>
                <div className="container-fluid">
                    <div id="pnlStateMinimum" className="row card">
                        <div className="row" style={{ marginTop: "5px" }}>
                            <div className="col-md-12" style={{ overflowX: "hidden" as "hidden", height: "55vh" }}>
                                <div className="ag-theme-balham" style={{ height: '100%' }}>
                                    <AgGridReact
                                        debug={this.state.debug}
                                        modules={this.state.modules}
                                        columnDefs={this.state.columnDefs}
                                        columnTypes={this.state.columnTypes}
                                        defaultColDef={this.state.defaultColDef}
                                        cacheBlockSize={this.state.cacheBlockSize}
                                        maxBlocksInCache={this.state.maxBlocksInCache}
                                        blockLoadDebounceMillis={this.state.blockLoadDebounceMillis}
                                        onGridReady={this.onGridReady}
                                        //statusBar={this.state.statusBar}
                                        enableRangeSelection={true}
                                        frameworkComponents={this.state.frameworkComponents}
                                        rowData={this.state.rowData}
                                        rowSelection={this.state.rowSelection}
                                        enableCharts={true}
                                        multiSortKey={this.state.multiSortKey}
                                        rowModelType={this.state.rowModelType}
                                        onRowClicked={this.handleRowClicked}
                                        allowContextMenuWithControlKey={true}
                                        getContextMenuItems={this.getContextMenuItems}
                                        sideBar={this.state.sideBar}
                                        onModelUpdated={this.onModelUpdated}
                                        suppressExcelExport={this.state.suppressExcelExport}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="ag-status-bar" style={{ marginLeft: "15px", marginTop: "5px", textAlign: "left" }}>
                            <span style={{ paddingBottom: "5px" }}>
                                <span className="bold">Total Rows: </span>
                                <span>{this.state.totalRowCount.toLocaleString()}</span>
                            </span>
                            { this.props.isLoadingExportFlag===true ? <span className="bold" style={{ paddingRight: "15px" }}> Exporting Data <Spinner size="sm" color="primary" /></span> : null} 
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}