import React, { useEffect, useState, SetStateAction } from 'react';
import UsersService from '../../../Services/Users/Users.service';
import RolesService from '../../../Services/Roles/Roles.service';
import HierarchyService from '../../../Services/Hierarchy/Service/Hierarchy.service';
import ViewWrapper from '../Common/ViewWrapper/ViewWrapper';
import { Spinner, Row, Col, ListGroupItem, ListGroup, FormGroup, Label, Input, Button } from 'reactstrap';
import UsersGrid from './UsersGrid';
import EnhancedRsModal from '../Common/EnhancedRsModal/EnhancedRsModal';
import { IReqSetIdentityProductHierarchyAccess, IUser } from '../../../Services/Users/Users.service.types';
import { IIdentitySimple, IReqGetRoles, IRoleUser } from '../../../Services/Roles/Roles.service.types';
import { IServerSideGetRowsRequest } from '@ag-grid-enterprise/all-modules';
import { INodeSimple } from '../../../Services/Hierarchy/Types/Hierarchy.service.types';

interface IProps {
    users: IUser[];
    setUsers: React.Dispatch<SetStateAction<IUser[]>>;
    editRolesForId: number | null;
    setEditRolesForId: React.Dispatch<SetStateAction<number | null>>;
}

export default function UserRolesDeptsForm({ users, setUsers, editRolesForId, setEditRolesForId } : IProps) {
    const usersService = new UsersService();
    const rolesService = new RolesService();
    const hierarchyService = new HierarchyService();

    const [roleOptions, setRoleOptions] = useState<IIdentitySimple[]>([]);
    const [roleIds, setRoleIds] = useState<number[]>([]);
    const [roleOptionsLoading, setRoleOptionsLoading] = useState<boolean>(false);
    const [deptOptions, setDeptOptions] = useState<INodeSimple[]>([]);
    const [deptIds, setDeptIds] = useState<number[]>([]);

    useEffect(() => {
            setRoleOptionsLoading(true);
            rolesService.getRoles(
                null,
                res => {
                    setRoleOptionsLoading(false)
                    setRoleOptions(res)
                },
                err => {
                    setRoleOptionsLoading(false)
                    alert(err)
                }
            )

            hierarchyService.getMajorDepartments(
                (res) => { setDeptOptions(res) },
                (err) => { alert(err) }
            )

    }, [])


    useEffect(() => {
        if (roleOptions.length > 0 && editRolesForId) {
            rolesService.getRoles(
                { Id: editRolesForId } as IReqGetRoles,
                res => setRoleIds(res.map(r => r.Id)),
                err => alert(err)
            )
        }
    }, [roleOptions, editRolesForId])

    useEffect(() => {
        if (deptOptions.length > 0 && editRolesForId) {
            usersService.getProductHierarchyAccessByIdentityId(
                editRolesForId,
                (res) => {
                    const usersTreeIds = res.map(n => n.TreeId) // TODO review options in controller/sql (do we want the child nodes as well?)
                    setDeptIds(usersTreeIds)
                },
                (err) => alert(err)
            )
        }
    }, [deptOptions, editRolesForId])

    const handleSaveUserRoles = () => {
        const rolesToPost = roleOptions.filter(r => roleIds.includes(r.Id));

        const postBodySUR = rolesToPost.map(ro => {
            return {
                RoleId: ro.Id,
                IdentityId: editRolesForId,
            };
        }) as IRoleUser[];

        usersService.setUserRoles(
            postBodySUR,
            (res) => {
                const newUsers = users.map(u => {
                    if (u.Id === res.Id) {
                        return res;
                    }
                    return u;
                })
                setUsers(newUsers);
                setEditRolesForId(null);
            },
            (err) => { alert(err) },
        )

        const postBodySIPHA = { IdentityId: editRolesForId, TreeIds: deptIds } as IReqSetIdentityProductHierarchyAccess;

        usersService.setIdentityProductHierarchyAccess(
            postBodySIPHA,
            (res) => {
                const savedDeptIds = res.map(ns => ns.TreeId)
                setDeptIds(savedDeptIds);
            },
            (err) => alert(err)
        )
    }

    function getEditRolesForUsernameStr(): string {
        return users?.find(u => u.Id === editRolesForId)?.Name ?? ""
    }

    return (
        <ViewWrapper isNarrowOnLargerScreen={ true }>
            <div className="d-flex justify-content-between">
                <Label>Select Roles and Access for User:</Label>
                <Label>{getEditRolesForUsernameStr()}</Label>
            </div>
            <Row>
                <Col>
                    <Label>Major Department Access</Label>
                    {
                        deptOptions.map((dept, i) =>
                            <ListGroupItem key={i}>
                                <FormGroup check>
                                    <Label check>
                                        <Input
                                            type="checkbox"
                                            id={`${i}`}
                                            onChange={(e) => {
                                                if (e.target.checked) {
                                                    const newDeptIds = [...deptIds.filter(di => di !== dept.TreeId), dept.TreeId]
                                                    setDeptIds(newDeptIds)
                                                } else {
                                                    const newDeptIds = deptIds.filter(di => di !== dept.TreeId)
                                                    setDeptIds(newDeptIds)
                                                }
                                            }}
                                            checked={deptIds.includes(dept.TreeId)}
                                        />
                                        {' '}{dept.NodeDesc}
                                    </Label>
                                </FormGroup>
                            </ListGroupItem>
                        )
                    }
                </Col>
                <Col>
                    <Label>Roles</Label>
                    {
                        roleOptionsLoading
                            ? <Spinner />
                            : (roleOptions.map((ro, i) =>
                                <ListGroupItem key={i}>
                                    <FormGroup check>
                                        <Label check>
                                            <Input
                                                type="checkbox"
                                                id={`${i}`}
                                                onChange={(e) => {
                                                    if (e.target.checked) {
                                                        let newIds = [...roleIds.filter(id => id !== ro.Id), ro.Id];
                                                        setRoleIds(newIds);
                                                    } else {
                                                        let newIds = roleIds.filter(id => id !== ro.Id);
                                                        setRoleIds(newIds);
                                                    }
                                                }}
                                                checked={roleIds.includes(ro.Id)}
                                            />
                                            {' '}{ro.Name}
                                        </Label>
                                    </FormGroup>
                                </ListGroupItem>
                            )
                            )

                    }
                </Col>
            </Row>
            <div className="mt-5">
                <Button
                    size="md"
                    color="primary"
                    onClick={handleSaveUserRoles}
                    className="mr-2"
                >
                    Save Selected
                </Button>
                <Button
                    size="md"
                    color="danger"
                    onClick={() => setEditRolesForId(null)}
                >
                    Cancel
                </Button>
            </div>
        </ViewWrapper>
    )
}