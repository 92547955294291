import { IRes2 } from '../Common/Types/Common.types';
import IPMOBaseService from '../Generics/Service/IPMOBaseService'
import { IAuditAction, IAuditActionFrequencyAccess, IAuditDetailType, IFrequency, IReqDeleteAuditActionFrequencyAccess, IReqGetAuditActionFrequencyAccess, IReqSaveAuditActionFrequencyAccess, IResGetAuditActionFrequencyAccess, IResGetAuditActions, IResGetAuditDetailTypes, IResGetFrequencies, IResSaveAuditActionFrequencyAccess } from './AuditAction.service.types';

export default class ActionService extends IPMOBaseService {

    getAuditActionFrequencyAccessByTreeId(treeId: number, callback: (res: IAuditActionFrequencyAccess[]) => void, errorCallback: (err: string) => void) {
        let methodName = "getAuditActionFrequencyAccessByTreeId";
        const postBody = { TreeId: treeId, HierarchyTypeId: 0 } as IReqGetAuditActionFrequencyAccess;
        super.post(
            "/PricingEngineService/AuditAction/GetAuditActionFrequencyAccessByHierarchy",
            postBody,
            methodName,
            (res: IResGetAuditActionFrequencyAccess) => {
                if (!res.Successful) throw res.ErrorMessage;
                callback(res.Data)
            },
            (err: string) => {
                errorCallback(`Error getting scheduled audit action access: ${err}`)
            }
        )
    }

    saveAuditActionFrequencyAccess(postBody: IReqSaveAuditActionFrequencyAccess, callback: (res: IAuditActionFrequencyAccess) => void, errorCallback: (err: string) => void) {
        let methodName = "saveAuditActionFrequencyAccess";
        super.post(
            "/PricingEngineService/AuditAction/SaveAuditActionFrequencyAccess",
            postBody,
            methodName,
            (res: IResSaveAuditActionFrequencyAccess) => {
                if (!res.Successful) throw res.ErrorMessage;
                callback(res.Data)
            },
            (err: string) => {
                errorCallback(`Error saving scheduled audit action access: ${err}`)
            }
        )
    }

    deleteAuditActionFrequencyAccess(postBody: IReqDeleteAuditActionFrequencyAccess, callback: (res: string) => void, errorCallback: (err: string) => void) {
        let methodName = "deleteAuditActionFrequencyAccess";
        super.post(
            "/PricingEngineService/AuditAction/DeleteAuditActionFrequencyAccess",
            postBody,
            methodName,
            (res: IRes2<string>) => {
                if (!res.Successful) throw res.ErrorMessage;
                callback(res.Data)
            },
            (err: string) => {
                errorCallback(`Error getting scheduled audit action access: ${err}`)
            }
        )
    }

    getAuditDetailTypes(callback: (res: IAuditDetailType[]) => void, errorCallback: (err: string) => void) {
        let methodName = "getAuditDetailTypes";
        super.get(
            "/PricingEngineService/AuditAction/GetAuditDetailTypes",
            methodName,
            (res: IResGetAuditDetailTypes) => {
                if (!res.Successful) throw res.ErrorMessage;
                callback(res.Data)
            },
            (err: string) => {
                errorCallback(`Error getting audit detail types: ${err}`)
            }
        )
    }

    getAuditActions(callback: (res: IAuditAction[]) => void, errorCallback: (err: string) => void) {
        let methodName = "getAuditActions";
        super.get(
            "/PricingEngineService/AuditAction/GetAuditActions",
            methodName,
            (res: IResGetAuditActions) => {
                if (!res.Successful) throw res.ErrorMessage;
                callback(res.Data)
            },
            (err: string) => {
                errorCallback(`Error getting audit actions: ${err}`)
            }
        )
    }

    getFrequencies(callback: (res: IFrequency[]) => void, errorCallback: (err: string) => void) {
        let methodName = "getFrequencies";
        super.get(
            "/PricingEngineService/AuditAction/GetFrequencies",
            methodName,
            (res: IResGetFrequencies) => {
                if (!res.Successful) throw res.ErrorMessage;
                callback(res.Data)
            },
            (err: string) => {
                errorCallback(`Error getting frequencies: ${err}`)
            }
        )
    }
}