import IPMOBaseService from '../../Generics/Service/IPMOBaseService'
import { INodeSimple, IResGetMajorDepartments } from '../Types/Hierarchy.service.types';

export default class HierarchyService extends IPMOBaseService {
    getProductRootNode(nodeId: string, level: number, callback: any, errorCallback: any) {
        let methodName = 'GetProductNode';
        // PricingEngineService / Hierarchy / products / { nodeId } / { level }
        let uri = '/PricingEngineService/Hierarchy/products/' + nodeId + '/' + level;
        super.get(uri, methodName,
            (data) => {
                callback(data);
            },
            (error) => {
                errorCallback(error)
            });
    }

    getProductNodes(parentNodeId: string, level: number, callback: any, errorCallback: any) {
        let methodName = 'GetProductHierarchyNodes';
        let uri = '/PricingEngineService/Hierarchy/products?level=' + level + '&parentNodeId=' + parentNodeId;
        super.get(uri, methodName,
            (data) => {
                callback(data);
            },
            (error) => {
                errorCallback(error)
            });
    }

    getLocationRootNode(nodeId: string, level: number, callback: any, errorCallback: any) {
        let methodName = 'GetLocationNode';
        // PricingEngineService / Hierarchy / locations / { nodeId } / { level }
        let uri = '/PricingEngineService/Hierarchy/locations/' + nodeId + '/' + level;
        super.get(uri, methodName,
            (data) => {
                callback(data);
            },
            (error) => {
                errorCallback(error)
            });
    }

    getLocationNodes(parentNodeId: string, level: number, callback: any, errorCallback: any) {
        let methodName = 'GetLocationHierarchyNodes';
        let uri = '/PricingEngineService/Hierarchy/locations?level=' + level + '&parentNodeId=' + parentNodeId;
        super.get(uri, methodName,
            (data) => {
                callback(data);
            },
            (error) => {
                errorCallback(error)
            });
    }

    getMajorDepartments(callback: (res: INodeSimple[]) => void, errorCallback: (err: string) => void) {
        let methodName = 'GetLocationHierarchyNodes';
        let uri = '/PricingEngineService/Hierarchy/Products/GetMajorDepartments';
        super.get(
            uri,
            methodName,
            (res: IResGetMajorDepartments) => {
                if (!res.Successful) throw res.ErrorMessage;
                callback(res.Data);
            },
            (err: string) => {
                errorCallback(err)
            });
    }
}