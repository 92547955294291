import React, { SetStateAction } from 'react';
import { Modal, ModalProps } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import './EnhancedRsModal.css';

interface IProps extends ModalProps {
    isFullScreen?: boolean;
    isOpen: boolean;
    setIsOpen?: React.Dispatch<SetStateAction<boolean>>;
    children?: React.ReactNode;
}

export default function EnhancedRsModal({ isFullScreen = false,  isOpen, setIsOpen=null, children, ...restProps }: IProps) {

    return (
        <Modal modalClassName={isFullScreen ? "lg-view-modal" : ""} isOpen={isOpen} zIndex={5000} {...restProps}>
            { setIsOpen
                ? (
                    <FontAwesomeIcon
                        icon={faTimes}
                        size="lg"
                        onClick={() => setIsOpen(false)}
                        style={{position: 'absolute', right: '18px', top: '16px'}}
                    />
                )
                : null
            }
            {children}
        </Modal>
    );
}