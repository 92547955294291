import * as React from 'react';
import { NavItem, NavLink, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { Link } from 'react-router-dom';
import "../React/css/misc.css"
import MasterContext from './Misc/MasterContext';
import { LoginToken } from '../../Services/Login/Types/Login.model';
export interface IProps {

}
export interface IState {
    homeMessages: any[];
    showHomeMessageModal: boolean;

}
export class Home extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            homeMessages: [],
            showHomeMessageModal: false
        }
        this.homeMessageModalComplete = this.homeMessageModalComplete.bind(this);
        this.getHomeMessages = this.getHomeMessages.bind(this);
        this.changeRead = this.changeRead.bind(this);
    }
    homeMessageModalComplete() {
        this.setState({ showHomeMessageModal: false }, () => {
            let loginToken: LoginToken = new LoginToken();
            loginToken.UserName = MasterContext.UserData.userName;
            let Obj: any = {};
            Obj.LoginCreds = loginToken;
            Obj.Messages = this.state.homeMessages;
            MasterContext.HomeService.confirmHomeMessages(Obj,
                (res) => { },
                (err) => { })

        });
    }
    componentDidMount() {

        if (MasterContext.hasNavigationPermission('messagingclient')) {
            let loginToken: LoginToken = new LoginToken();
            loginToken.UserName = MasterContext.UserData.userName;
            MasterContext.HomeService.getHomeMessages(loginToken,
                (res) => {
                    this.setState({ homeMessages: res.Data },
                        () => {
                            if (this.state.homeMessages.length > 0) {
                                this.setState({ showHomeMessageModal: true });
                            }
                        })
                },
                (err) => { }
            )
        }


    }
    static displayName = Home.name;
    changeRead(itm: any) {
        let copy: any[] = [];
        Object.assign(copy, this.state.homeMessages);
        for (let itm2 of copy) {
            if (itm.MessageID === itm2.MessageID) {
                itm2.read = !itm2.read;
            }
        }
        this.setState({ homeMessages: copy });
    }
    getHomeMessages() {
        let retVal: any[] = [];

        if (this.state.homeMessages.length > 0) {
            for (let itm of this.state.homeMessages) {
                retVal.push(<React.Fragment key={itm.MessageID}>
                    <div className="row">
                        <div className="col-md-12">
                            <strong>{itm.MessageID})</strong>&nbsp;{itm.MessageBody}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-10"><span className="pull-right">{MasterContext.translate("home_mark_as_read")}</span></div>
                        <div className="col-md-2">
                            <input name="rememberMe" onChange={() => { this.changeRead(itm) }} checked={itm.read} type="checkbox" />
                        </div>
                    </div>
                    <hr /></React.Fragment>)
            }
        }

        return <div>{retVal}</div>;
    }

    render() {
        let style1 = { marginTop: "65px", marginBottom: "8px" };
        let style2 = { height: "48px", verticalAlign: "middle" };
        let style3 = { fontFamily: 'Halis-GR-Light', letterSpacing: "3px", verticalAlign: "middle", fontSize: "48px", color: "#35b44a" };
        let style4 = { fontFamily: 'Halis-GR-Light', letterSpacing: "3px", verticalAlign: "middle", fontSize: "48px", color: "#0c56a9" };
        return (
            <React.Fragment>
                <Modal zIndex={3002} isOpen={this.state.showHomeMessageModal}>
                    <ModalHeader>{MasterContext.translate("home_message_modal")}</ModalHeader>
                    <ModalBody>
                        <div style={{ height: "300px", overflowY: "auto" }}>
                            {this.getHomeMessages()}
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <button className="btn btn-sm btn-outline-info" color="primary" onClick={() => this.homeMessageModalComplete()}>{MasterContext.translate("login_recovery_modal_button")}</button>{' '}

                    </ModalFooter>
                </Modal>
                <div className="text-align-center container">
                    <div className="row">
                        <div className="col-md-12 col-xs-12" style={style1}>
                            {/*<span style={style4}>QUICK</span>&nbsp;&nbsp;<span style={style3}>LINKS</span>*/}

                        </div>
                        <div className="row">
                            {
                                MasterContext.hasNavigationPermission('Regular > Price Review')
                                    && MasterContext.QueryModule('regular')
                                    && MasterContext.QueryModule('price review') ?
                                    <div className="col-md-4 col-xs-6">
                                        <div className="homecard">
                                            <h1 className="homecard-title">Price Review</h1>
                                            <p className="homecard-parapraph">View, manage, and export our price recommendations for regular and EDLP</p>
                                            <div className="homecard-subcard">
                                                <NavLink tag={Link} to="/pricereview"> <i className="fa fa-angle-double-right"></i></NavLink>
                                            </div>
                                        </div>
                                    </div>
                                    : null
                            }
                            {
                                MasterContext.hasNavigationPermission('Promotions > Events > Manage')
                                    && MasterContext.QueryModule('promotion') ?
                                    <div className="col-md-4 col-xs-6">
                                        <div className="homecard">
                                            <h1 className="homecard-title">Promotion Events</h1>
                                            <p className="homecard-parapraph">Manage promotional events, associated forecasts, and view informative reports</p>
                                            <div className="homecard-subcard">
                                                <NavLink tag={Link} to="/promotionseventsmanage"> <i className="fa fa-angle-double-right"></i></NavLink>
                                            </div>
                                        </div>
                                    </div>
                                    : null
                            }
                            {
                                MasterContext.hasNavigationPermission('Manage > Rules') ?                                    <div className="col-md-4 col-xs-6">
                                        <div className="homecard">
                                            <h1 className="homecard-title">Rule Management</h1>
                                            <p className="homecard-parapraph">Utilize our industry leading rules system to ensure forecast and pricing accuracy</p>
                                            <div className="homecard-subcard">
                                                <NavLink tag={Link} to="/managerules"> <i className="fa fa-angle-double-right"></i></NavLink>
                                            </div>
                                        </div>
                                    </div>
                                    : null                            }
                            {
                                MasterContext.hasNavigationPermission('Regular > Rules Explorer')
                                    && MasterContext.QueryModuleFeature('regular', 'rulesexplorer') ?
                                    <div className="col-md-4 col-xs-6">
                                        <div className="homecard">
                                            <h1 className="homecard-title">Rule Explorer</h1>
                                            <p className="homecard-parapraph">View and evaluate your current rules and their impact our pricing recommendations</p>
                                            <div className="homecard-subcard">
                                                <NavLink tag={Link} to="/rulesexplorer"> <i className="fa fa-angle-double-right"></i></NavLink>
                                            </div>
                                        </div>
                                    </div>
                                    : null
                            }
                            {
                                MasterContext.hasNavigationPermission('Engine > Job Status') ?
                                    <div className="col-md-4 col-xs-6">
                                        <div className="homecard">
                                            <h1 className="homecard-title">Science Status</h1>
                                            <p className="homecard-parapraph">View current progress on science and other data processing tasks</p>
                                            <div className="homecard-subcard">
                                                <NavLink tag={Link} to="/enginejobstatus"> <i className="fa fa-angle-double-right"></i></NavLink>
                                            </div>
                                        </div>
                                    </div>
                                    : null
                            }
                            {
                                MasterContext.hasNavigationPermission('Regular > Buydown Explorer')
                                    && MasterContext.QueryModule('regular') ?
                                    <div className="col-md-4 col-xs-6">
                                        <div className="homecard">
                                            <h1 className="homecard-title">Buydown Explorer</h1>
                                            <p className="homecard-parapraph">View and validate your vendor buydown data to ensure accuracy and compliance</p>
                                            <div className="homecard-subcard">
                                                <NavLink tag={Link} to="/buydowns"> <i className="fa fa-angle-double-right"></i></NavLink>
                                            </div>
                                        </div>
                                    </div>
                                    : null
                            }
                            {
                                MasterContext.hasNavigationPermission('Regular > State Minimum Editor') ?
                                    <div className="col-md-4 col-xs-6">
                                        <div className="homecard">
                                            <h1 className="homecard-title">State Minimum Editor</h1>
                                            <p className="homecard-parapraph">Quickly view and configure complex state minimum rules with our revolutionary editor</p>
                                            <div className="homecard-subcard">
                                                <NavLink tag={Link} to="/stateminimum"> <i className="fa fa-angle-double-right"></i></NavLink>
                                            </div>
                                        </div>
                                    </div>
                                    : null
                            }
                        </div>
                    </div>
                </div>
            </React.Fragment >
        );
    }
}
