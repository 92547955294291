import * as React from 'react';
import Spinner from '../../React/Misc/Spinner';
import MasterContext from '../../React/Misc/MasterContext';
export interface IProps {
}
export interface IState {
    loaded: boolean;
}

export default class PromotionsTemplatesManage extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = { loaded: true }
        this.componentDidMount = this.componentDidMount.bind(this);

    }
    componentDidMount() {
        window.onmessage = (e) => {
            if (e.data === 'childDidLoad') {
                this.setState({ loaded: false });
            }
        }
    }

    componentWillUnmount() {
        window.onmessage = (e) => {

        }
    }
    getURL(): string {
        let retVal = '';
        var token = localStorage.getItem("userToken");
        if (token != null) {
            retVal = MasterContext.LEGACYURL + "indexPRTPMG.html?apiKey=" + token + "#/manageTemplates";
        } else {
            retVal = MasterContext.LEGACYURL + "indexPRTPMG.html#/manageTemplates";
        }
        //retVal = MasterContext.LEGACYURL + "indexPRTPMG.html#/manageTemplates";
        return retVal;
    }



    render() {
        let style1 = { height: "405px" }
        let style2 = { border: "0px", height: "97vh" };
        return (
            <div id="mainBody" className="main-body container-fill ng-scope body-fill" event-control="" cdi-body-fill-container="" ui-view="" role="main" style={style1}>

                <Spinner
                    spinnerActive={this.state.loaded}
                    heightSet="99vh"
                    paddingSet="10%"
                    spinnerText={MasterContext.translate("spinner_loading_template_manager")}
                />
                <iframe title='iframePromotionTemplateManage' id="iframePromotionTemplateManage" src={this.getURL()} width="100%" style={style2} ></iframe>
            </div>
        );

    }

}