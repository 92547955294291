// TODO
// 1. Initial Grid - fires twice
// 2. Fix CurrencyFormatter and numeric filters
// 3. Add Wrapping to headers and text columns

import React from "react";
import moment from 'moment';


import { AgGridReact } from '@ag-grid-community/react';
import { ColDef, ColGroupDef, SideBarDef } from '@ag-grid-community/core';
import {
    StatusPanelDef,
    IServerSideGetRowsRequest,
    ColumnApi,
    MenuItemDef,
    SetFilterModule,
    FiltersToolPanelModule,
    ColumnsToolPanelModule,
    SideBarModule,
    ClipboardModule,
    ExcelExportModule,
    GridChartsModule,
    MenuModule,
    ServerSideRowModelModule,
} from '@ag-grid-enterprise/all-modules';
import '@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css';
import '@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css';
import '../css/cdi-ag-grid.css';
import StatusBarComponent from './StatusBar';
import MasterContext from '../Misc/MasterContext';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { UIGrid } from '../../../Services/Grid/Types/UIGrid.model';
import TaskCustomTooltip from './TaskCustomTooltip';
import FileDownloadGrid from './FileDownloadGrid';
import { DownloadRequest } from '../../../Services/JobControl/Types/DownloadMgr.model';
import { GetLovsRequest } from '../../../Services/Generics/Types/IPMOBaseService.model';
import App_Property from '../../../Services/Common/Types/AppProperty.model';
import { GetRunModeFileRequest } from '../../../Services/JobControl/Types/JobControl.model';

export interface IProps {
}

export interface IState {
    debug: boolean,
    modules: any[],
    frameworkComponents: any,
    rowData: any[],
    totalRowCount: number,
    columnDefs?: (ColDef | ColGroupDef)[];
    multiSortKey: string,
    rowModelType: string,
    rowSelection: string,
    cacheBlockSize: number,
    maxBlocksInCache: number,
    blockLoadDebounceMillis: number,
    defaultColDef?: ColDef,
    columnTypes: {
        [key: string]: ColDef;
    },
    sideBar: (string | boolean | SideBarDef),
    statusBar: {
        statusPanels: StatusPanelDef[];
    },
    rowClassRules: any,
    selectedVendors: string[],
    selectedBases: string[],
    gridApi: any,
    gridColumnApi: ColumnApi,
    lastFilterColumn: string,
    agExportDataAsExcel: any,
    showPriceCostFlag: boolean,
    showdModal: boolean;
    jobId: number;
    fileId: number;
    tooltipShowDelay: number;
    contextMenuItems: MenuItemDef[];
}

export default class TaskStatusGrid extends React.Component<IProps, IState> {
    // Constructor
    constructor(props: IProps) {
        super(props);

        let search = window.location.search;
        let params = new URLSearchParams(search);
        let jobId = 0;
        if (params.get('jobId'))
            jobId = parseInt(params.get('jobId'));

        this.state = {
            jobId: jobId,
            fileId: 0,
            showdModal: false,
            gridApi: null,
            gridColumnApi: null,
            agExportDataAsExcel: null,

            debug: false,
            modules: [
                SetFilterModule,
                FiltersToolPanelModule,
                ColumnsToolPanelModule,
                SideBarModule,
                ClipboardModule,
                ExcelExportModule,
                GridChartsModule,
                MenuModule,
                ServerSideRowModelModule
            ],
            frameworkComponents: {
                statusBarComponent: StatusBarComponent,
                customTooltip: TaskCustomTooltip,
            },
            rowData: [],
            totalRowCount: 0,
            columnDefs: this.getColumnDefs(),
            multiSortKey: 'ctrl',
            rowModelType: 'serverSide',
            rowSelection: 'single',
            cacheBlockSize: 1000,
            maxBlocksInCache: 100,
            blockLoadDebounceMillis: 300,
            selectedVendors: [],
            selectedBases: [],
            lastFilterColumn: null,
            showPriceCostFlag: false,
            tooltipShowDelay: 0,
            defaultColDef: {
                editable: false,
                hide: false,
                flex: 1,
                cellClass: 'cell-wrap-text',
                filter: 'agSetColumnFilter',
                filterParams: {
                    buttons: ['clear', 'apply'],
                },
                floatingFilter: true,
                resizable: true,
                sortable: true,
                enableRowGroup: false,
                enableValue: false,
                enablePivot: false,
                pivot: false,
                tooltipComponent: 'customTooltip',
            },
            columnTypes: {
                numberColumn: {
                    width: 130,
                    filter: 'agNumberColumnFilter',
                    filterParams: {
                        buttons: ['clear', 'apply'],
                    },
                    type: 'numericColumn'
                },
                currencyColumn: {
                    width: 130,
                    filter: 'agNumberColumnFilter',
                    filterParams: {
                        buttons: ['clear', 'apply'],
                    },
                    valueFormatter: function (params) {
                        return '$ ' + this.formatNumber(params.value);
                    },
                    type: 'numericColumn'
                },
                nonEditableColumn: { editable: false },
                dateColumn: {
                    valueFormatter: function (params) {
                        return moment(params.value).format('MM/DD/YYYY');
                    },
                    filter: 'agDateColumnFilter',
                    filterParams: {
                        buttons: ['clear', 'apply'],
                        comparator: function (filterLocalDateAtMidnight, cellValue) {
                            var dateParts = cellValue.split('/');
                            var day = Number(dateParts[0]);
                            var month = Number(dateParts[1]) - 1;
                            var year = Number(dateParts[2]);
                            var cellDate = new Date(year, month, day);
                            if (cellDate < filterLocalDateAtMidnight) {
                                return -1;
                            } else if (cellDate > filterLocalDateAtMidnight) {
                                return 1;
                            } else {
                                return 0;
                            }
                        },
                    },
                },
            },
            sideBar: {

                toolPanels: [
                    'filters',
                    {
                        id: 'columns',
                        labelDefault: 'Columns',
                        labelKey: 'columns',
                        iconKey: 'columns',
                        toolPanel: 'agColumnsToolPanel',
                        toolPanelParams: {
                            suppressRowGroups: true,
                            suppressValues: true,
                            suppressPivots: true,
                            suppressPivotMode: true,
                            suppressSideButtons: false,
                            suppressColumnFilter: false,
                            suppressColumnSelectAll: false,
                            suppressColumnExpandAll: false,
                            suppressSyncLayoutWithGrid: true,
                        },
                    }
                ],
            },
            statusBar: {
                statusPanels: [
                    {
                        statusPanel: 'statusBarComponent',
                        align: 'left',
                    },
                ],
            },
            rowClassRules: {
                'job-completed': 'data.RunStatus == "Completed"',
                'job-failed': 'data.RunStatus == "Failed"',
                'job-warning': 'data.RunStatus == "Warning"',
                'job-running': 'data.RunStatus == "Running',
            },
            contextMenuItems: [],
        };

        // Function Binding
        this.getColumnDefs = MasterContext.translate.bind(this);
        MasterContext.translate = MasterContext.translate.bind(this);
        this.currencyFormatter = this.currencyFormatter.bind(this);
        this.formatNumber = this.formatNumber.bind(this);
        this.getColumnValues = this.getColumnValues.bind(this);
        this.onModelUpdated = this.onModelUpdated.bind(this);
        this.toggleModal = this.toggleModal.bind(this);
        this.loadGrid = this.loadGrid.bind(this);
        this.onColumnResized = this.onColumnResized.bind(this);
        this.submitDownload = this.submitDownload.bind(this);
        this.onCellContextMenu = this.onCellContextMenu.bind(this);
    }

    toggleModal() {
        this.setState({ showdModal: !this.state.showdModal });
    }

    myExportDataAsExcel = (params) => {
        if (this.state.totalRowCount > 50000) {
            this.setState({ showdModal: true })
            return;
        }

        // TODO - Figure out how to download all records or use server-side Export
        params.fileName = 'JobStatus.' + params.exportMode;
        params.sheetName = 'JobStatus';
        this.state.gridApi.origExportDataAsExcel(params);
    }

    onSelectionChanged = () => {
        var selectedRows = this.state.gridApi.getSelectedRows();
        this.setState({
            fileId: selectedRows.length === 1 ? selectedRows[0].TaskId : 0
        });
    }

    onColumnResized = (params) => {
        params.api.resetRowHeights();
    };

    onModelUpdated = (params) => {
        if (this.state.totalRowCount == null) {
            this.setState({
                totalRowCount: 0
            });
        }
    }

    //
    // TODO - Figure this out, so that whenever a Filter is changed, it forces a refresh of all other filters
    //
    onFilterChanged = (params) => {
        var api = this.state.gridApi;
        console.log(params, api);

        // Reset Filters
        //var allFilterModels = api.getFilterModel();

        //if (this.state.lastFilterColumn !== null) {
        //    for (let key in allFilterModels) {
        //        let filtModel = allFilterModels[key];
        //        if (this.state.lastFilterColumn !== key) {
        //            let filtInst = api.getFilterInstance(key);
        //            filtInst.valueModel.allValues = [];
        //        }
        //    }
        //}

        //var vendorFilter = api.getFilterInstance('Vendor');
        //vendorFilter.setModel(null);

        // TODO - Figure out why this doesn't work...
        //basisFilter.refreshFilterValues();
    }

    //
    //
    //
    submitDownload = (fileName: string) => {
        console.log('Downloading File: [' + fileName + '] from server...');

        let fileId = this.state.fileId;

        let downloadRequest: DownloadRequest = {
            Type: 'Task',
            FileId: fileId,
            FileName: fileName
        };

        let requestId: string = '';
        MasterContext.DownloadMgrService.submitDownload(downloadRequest,
            (data) => {
                console.log('Data=' + JSON.stringify(data));
                requestId = data;
                fileName = 'Test.log';

                MasterContext.DownloadMgrService.getUserDownloadRequests(
                    (data: DownloadRequest[]) => {
                        console.log('Data=' + JSON.stringify(data));

                        MasterContext.DownloadMgrService.downloadFile(requestId, fileName, 
                            (data: any) => {
                                console.log('Data=' + JSON.stringify(data));
                                return data;
                            },
                            (error) => {
                                // TODO - Figure out how to create an Alert
                                // alert('Error: ' + JSON.stringify(err));
                                return null;
                            });
                    },
                    (error) => {
                        // TODO - Figure out how to create an Alert
                        // alert('Error: ' + JSON.stringify(err));
                        return null;
                    });
            },
            (error) => {
                // TODO - Figure out how to create an Alert
                // alert('Error: ' + JSON.stringify(err));
                return null;
            });
    }

    /*
     * 
     */
    onCellContextMenu = (params) => {
        var menuItems = [
            'copy',
            'copyWithHeaders',
            'separator',
            {
                name: 'Download',
                subMenu: [],
                //    {
                //        name: 'Diagnostic.zip',
                //        action: function () {
                //            params.api.context.thisComponent.submitDownload('Diagnostic.zip');
                //        },
                //    },
                //    {
                //        name: 'ClearPrice.log',
                //        action: function () {
                //            params.api.context.thisComponent.submitDownload('ClearPrice.log');
                //        }
                //    },
                //    {
                //        name: 'ScienceEngine.log',
                //        action: function () {
                //            params.api.context.thisComponent.submitDownload('ScienceEngine.log')
                //        }
                //    }
                //],
            },
            {
                name: 'Run Task',
                action: function () {
                    console.log('Run Task was pressed');
                },
            },
            {
                name: 'Task Details',
                action: function () {
                    console.log('Task Details was pressed');
                },
            },
            {
                name: 'Cancel Task',
                action: function () {
                    console.log('Cancel Task was pressed');
                },
            },
            {
                name: 'Show Report',
                action: function () {
                    console.log('Show Report was pressed');
                },
            },
        ];

        let rmReq: GetRunModeFileRequest = {
            RunModeId: params.data.RunModeId,
            InOut: 'out'
        };

        let downloadMenuItems: (MenuItemDef[]) = [];
        if (params.data.JobType === 'ScienceJob') {
            downloadMenuItems.push(
                {
                    name: 'Diagnostic.zip',
                    action: function () {
                        params.api.context.thisComponent.submitDownload('Diagnostic.zip');
                    },
                }, 
                {
                    name: 'ClearPrice.log',
                    action: function () {
                        params.api.context.thisComponent.submitDownload('ClearPrice.log');
                    },
                }, 
                {
                    name: 'ScienceEngine.log',
                    action: function () {
                        params.api.context.thisComponent.submitDownload('ScienceEngine.log');
                    },
                }, 
            );
        }

        // Loop through data (list of RunModeFile's) and create MenuItemDefs for DownloadFile action
        MasterContext.JobControlService.getRunModeFiles(rmReq,
            (data: any[]) => {
                let downloadMenu: (MenuItemDef) = menuItems[3] as MenuItemDef;
                data.forEach(function (runModeFile) {
                    if (runModeFile.isDownload) {
                        let fileName = runModeFile.fileName;
                        if (runModeFile.inOut === 'Out')
                            fileName = 'Data/' + fileName;

                        let downloadMenuItem: (MenuItemDef) = {
                            name: runModeFile.fileName,
                            action: function () {
                                params.api.context.thisComponent.submitDownload(fileName);
                            },
                        }
                        downloadMenuItems.push(downloadMenuItem);
                    }
                }); 

                downloadMenu.subMenu = downloadMenuItems;

                // Dynamically add "Opt Curve" menu
                MasterContext.AppPropertyService.findAppProperty('CP_Features', 'Opportunity Curve',
                    (appProp: App_Property) => {
                        console.log('App Prop=' + JSON.stringify(appProp));
                        if (appProp) {
                            menuItems.push(
                                {
                                    name: 'Opt Curve',
                                    action: function () {
                                        console.log('Opt Curve was pressed');
                                    }
                                }
                            )
                        }

                        const factory = params.api.contextMenuFactory; // if you use ts cast api to `any` becasue factory is private 
                        factory.getMenuItems = () => menuItems; // must override this method otherwise wont work
                        factory.showMenu(params.node, params.column, params.value, params.event);
                    },
                    (error) => {
                        console.log('Error=' + JSON.stringify(error));
                    });
            },
            (error) => {
                console.log('Error=' + JSON.stringify(error));
            });
    }

    loadGrid = () => {
        MasterContext.GridControlService.getGridById(UIGrid.PRICEREVIEW, true,
            (data: UIGrid) => {
                console.log('Data=' + JSON.stringify(data));
                return data;
            },
            (error) => {
                // TODO - Figure out how to create an Alert
                // alert('Error: ' + JSON.stringify(err));
                return null;
            });
    }

    getColumnValues = (params: any) => {
        let field: string = params.colDef.field;
        var filterModel = this.state.gridApi.getFilterModel();
        let getRowsReq: IServerSideGetRowsRequest = {
            startRow: 1,
            endRow: 100000,
            sortModel: null,
            filterModel: filterModel,
            rowGroupCols: null,
            pivotCols: null,
            pivotMode: false,
            valueCols: null,
            groupKeys: null
        };

        let request: GetLovsRequest = {
            LovName: field,
            SearchCriteria: getRowsReq
        };

        MasterContext.JobControlService.getTaskDetailsLovs(request, (data, totalCount) => {
            var values: string[] = data;
            this.setState({
                lastFilterColumn: field
            });
            params.success(values);
        }, (err) => {
            console.log('Error: ' + JSON.stringify(err));
        });
    }

    getColumnDefs = (): (ColDef | ColGroupDef)[] => {
        var colDefs: (ColDef | ColGroupDef)[] = [
            {
                field: 'JobId',
                headerName: 'Job ID',
                filter: 'agNumberColumnFilter',
                filterParams: {
                    buttons: ['apply',],
                    closeOnApply: true, 
                    defaultOption: 'equals',
                },
                type: 'numericColumn',
            },
            {
                field: 'TaskId',
                headerName: 'Task ID',
                filter: 'agNumberColumnFilter',
                filterParams: {
                    buttons: ['apply',],
                    closeOnApply: true,
                    defaultOption: 'equals',
                },
                type: 'numericColumn',
            },
            {
                field: 'JobType',
                headerName: 'Job Type',
                filter: 'agSetColumnFilter',
                filterParams: {
                    buttons: ['apply',],
                    closeOnApply: true,
                    values: this.getColumnValues,
                    refreshValuesOnOpen: true
                },
            },
            {
                field: 'ScenarioName',
                headerName: 'Scenario',
                filter: 'agSetColumnFilter',
                filterParams: {
                    buttons: ['apply',],
                    closeOnApply: true,
                    values: this.getColumnValues,
                    refreshValuesOnOpen: true
                },
            },
            {
                field: 'RunModeName',
                headerName: 'Run Mode',
                filter: 'agSetColumnFilter',
                filterParams: {
                    buttons: ['apply',],
                    closeOnApply: true,
                    values: this.getColumnValues,
                    refreshValuesOnOpen: true
                },
            },
            {
                field: 'UserName',
                headerName: 'User',
                filter: 'agSetColumnFilter',
                filterParams: {
                    buttons: ['apply',],
                    closeOnApply: true,
                    values: this.getColumnValues,
                    refreshValuesOnOpen: true
                },
            },
            {
                field: 'ProductNodeId',
                headerName: 'Product Node',
                filter: 'agSetColumnFilter',
                filterParams: {
                    buttons: ['apply',],
                    closeOnApply: true,
                    values: this.getColumnValues,
                    refreshValuesOnOpen: true
                },
                tooltipField: 'ProductNodeId',
                tooltipComponentParams: { color: '#ececec' },
            },
            {
                field: 'LocationNodeId',
                headerName: 'Location Node',
                filter: 'agSetColumnFilter',
                filterParams: {
                    buttons: ['apply',],
                    closeOnApply: true,
                    values: this.getColumnValues,
                    refreshValuesOnOpen: true
                },
                tooltipField: 'LocationNodeId',
            },
            {
                field: 'StartDate',
                headerName: 'Start Date',
                type: 'dateColumn',
                cellClass: '.grid-cell-center',
                headerClass: '.grid-header-cell-center',
            },
            {
                field: 'StopDate',
                headerName: 'Stop Date',
                type: 'dateColumn',
                cellClass: '.grid-cell-center',
                headerClass: '.grid-header-cell-center',
            },
            {
                field: 'RunStatus',
                headerName: 'Status',
                filter: 'agNumberColumnFilter',
                filterParams: {
                    buttons: ['apply',],
                    closeOnApply: true,
                    defaultOption: 'equals',
                },
            },
            //{
            //    field: 'StatusMessage',
            //    headerName: 'Status Message',
            //    filter: 'agSetColumnFilter',
            //    width: 350,
            //    filterParams: {
            //        buttons: ['apply',],
            //        closeOnApply: true, 
            //        values: this.getColumnValues,
            //        refreshValuesOnOpen: true
            //    },
            //},
        ];
        return colDefs;
    }

    currencyFormatter = (params) => {
        if (params.value != null)
            return '$' + params.value.toFixed(2);
        else
            return '$0.00';
    }

    formatNumber = (number) => {
        return Math.floor(number)
            .toString()
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    }

    //
    // TODO - Figure out how to initialize the grid on page load - currently hitting server twice 
    //
    onGridReady = (params) => {
        // Test GridControlService
        //this.loadGrid();

        let agApi = params.api;
        agApi.origExportDataAsExcel = agApi.exportDataAsExcel;
        agApi.exportDataAsExcel = this.myExportDataAsExcel;

        this.setState({
            gridApi: agApi,
            gridColumnApi: params.columnApi,
        });

        params.api.context = {
            thisComponent: this
        };

        this.setState({
            gridApi: params.api,
            gridColumnApi: params.columnApi,
            agExportDataAsExcel: params.api.exportDataAsExcel
        });

        const updateData = (data: any[], totalCount: number) => {
            var taskDetailsDatasource = MasterContext.JobControlDatasource.createTaskDetailsDatasource(MasterContext.JobControlService, this);
            params.api.setServerSideDatasource(taskDetailsDatasource);
            this.setState({
                totalRowCount: totalCount
            });
        };

        let request: IServerSideGetRowsRequest = {
            startRow: 1,
            endRow: 200,
            rowGroupCols: null,
            valueCols: null,
            pivotCols: null,
            pivotMode: false,
            groupKeys: null,
            filterModel: null,
            sortModel: null
        };

        if (this.state.jobId > 0) {
            var jobIdFilter = this.state.gridApi.getFilterInstance('JobId');
            jobIdFilter.setModel({
                filterType: 'number',
                type: 'equals',
                filter: this.state.jobId
            });

            request.filterModel = this.state.gridApi.getFilterModel();
            this.state.gridApi.onFilterChanged();
        }

        MasterContext.JobControlService.getTaskDetails(request, (data, totalCount) => {
            updateData(data, totalCount);
        }, () => {
            // TODO - Figure out how to create an Alert
            // alert('Error: ' + JSON.stringify(err));
        });

    };

    // Render to screen
    render() {
        return (
            <React.Fragment>
                <Modal zIndex={3002} isOpen={this.state.showdModal}>
                    <ModalHeader>{MasterContext.translate("jobstatus_modal_header")}</ModalHeader>
                    <ModalBody>
                        {MasterContext.translate("taskstatus_modal_too_many_records")}
                    </ModalBody>
                    <ModalFooter>
                        <button className="btn btn-sm btn-outline-info" color="primary" onClick={() => this.toggleModal()}>{MasterContext.translate("taskstatus_modal_footer")}</button>{' '}

                    </ModalFooter>
                </Modal>

                <div className="container-fluid">
                    <div id="divFilterPanel" className="row card">
                        <div className="col-md-12">
                            <h4>New Task Status</h4>
                        </div>
                    </div>
                    <div id="pnlTaskStatusDetail" className="row card">
                        <div className="row" style={{ marginTop: "5px" }}>
                            <div className="col-md-12" style={{ overflowY: "scroll" as "scroll", overflowX: "hidden" as "hidden", height: "50vh" }}>
                                <div className="ag-theme-balham" style={{ height: '100%' }}>
                                    <AgGridReact
                                        debug={this.state.debug}
                                        modules={this.state.modules}
                                        columnDefs={this.state.columnDefs}
                                        columnTypes={this.state.columnTypes}
                                        defaultColDef={this.state.defaultColDef}
                                        cacheBlockSize={this.state.cacheBlockSize}
                                        maxBlocksInCache={this.state.maxBlocksInCache}
                                        blockLoadDebounceMillis={this.state.blockLoadDebounceMillis}
                                        onGridReady={this.onGridReady}
                                        //enableRangeSelection={true}
                                        frameworkComponents={this.state.frameworkComponents}
                                        rowData={this.state.rowData}
                                        onModelUpdated={this.onModelUpdated}
                                        rowSelection={this.state.rowSelection}
                                        //enableCharts={true}
                                        multiSortKey={this.state.multiSortKey}
                                        rowModelType={this.state.rowModelType}
                                        onFilterChanged={this.onFilterChanged}
                                        allowContextMenuWithControlKey={true}
                                        onSelectionChanged={this.onSelectionChanged}
                                        sideBar={this.state.sideBar}
                                        onColumnResized={this.onColumnResized}
                                        tooltipShowDelay={this.state.tooltipShowDelay}
                                        rowClassRules={this.state.rowClassRules}
                                        onCellContextMenu={this.onCellContextMenu}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className={'ag-theme-balham.ag-status-bar'} style={{ marginLeft: "15px", marginTop: "5px", textAlign: "left" }}>
                            Total Rows:&nbsp;{this.state.totalRowCount.toLocaleString()}
                        </div>
                    </div>
                </div>
                <FileDownloadGrid />
            </React.Fragment>
        );
    }

}
