import UiPermission from "./Permissions/Types/UiPermission.model";

const PERMISSIONS_KEY = 'userPermissions';

const LocalPermissionsService = {
	savePermissions(ps: UiPermission[]) {
		const newPerms = ps.map(p => {
			let newP = new UiPermission();
			newP.ComponentName = p.ComponentName;
			newP.PermissionName = p.PermissionName;
			newP.CanRead = p.CanRead;
			newP.CanEdit = p.CanEdit;
			newP.CanDelete = p.CanDelete;
			newP.CanExecute = p.CanExecute;
			return newP;
		})
		localStorage.setItem(PERMISSIONS_KEY, JSON.stringify(newPerms));
	},

	getPermissions() {
		return localStorage.getItem(PERMISSIONS_KEY);
	},

	clearPermissions() {
		localStorage.removeItem(PERMISSIONS_KEY);
	},

	hasPermission() {
		return !!localStorage.getAuthToken();
	},

	getPermissionsByComponentName(cN: string) {
		return JSON.parse(localStorage.getItem(PERMISSIONS_KEY))
			? JSON.parse(localStorage.getItem(PERMISSIONS_KEY)).filter((p: UiPermission) => p.ComponentName === cN)
			: [];
    },

	isPermittedToEditByComponentName(cN: string) {
		let ps = JSON.parse(localStorage.getItem(PERMISSIONS_KEY)) ? JSON.parse(localStorage.getItem(PERMISSIONS_KEY)) : [];
		return ps.filter((p: UiPermission) => p.ComponentName === cN && p.CanEdit).length > 0;
	},
	isPermittedToEditByComponentNameAndPermission(cN: string, pN: string) {
		let ps = JSON.parse(localStorage.getItem(PERMISSIONS_KEY)) ? JSON.parse(localStorage.getItem(PERMISSIONS_KEY)) : [];
		return ps.filter((p: UiPermission) => p.ComponentName === cN && p.PermissionName === pN && p.CanEdit).length > 0;
	},

	isPermittedToExecuteByComponentName(cN: string) {
		let ps = JSON.parse(localStorage.getItem(PERMISSIONS_KEY)) ? JSON.parse(localStorage.getItem(PERMISSIONS_KEY)) : [];
		return ps.filter((p: UiPermission) => p.ComponentName === cN && p.CanEdit).length > 0;
	},
	isPermittedToCreateByPermissionName(cN: string) {
		let ps = JSON.parse(localStorage.getItem(PERMISSIONS_KEY)) ? JSON.parse(localStorage.getItem(PERMISSIONS_KEY)) : [];
		return ps.filter((p: UiPermission) => p.PermissionName === cN && p.CanEdit).length > 0;
	},
	isPermittedToReadByPermissionName(cN: string) {
		let ps = JSON.parse(localStorage.getItem(PERMISSIONS_KEY)) ? JSON.parse(localStorage.getItem(PERMISSIONS_KEY)) : [];
		return ps.filter((p: UiPermission) => p.PermissionName === cN && p.CanRead).length > 0;
	},
	isPermittedToEditByPermissionName(cN: string) {
		let ps = JSON.parse(localStorage.getItem(PERMISSIONS_KEY)) ? JSON.parse(localStorage.getItem(PERMISSIONS_KEY)) : [];
		return ps.filter((p: UiPermission) => p.PermissionName === cN && p.CanEdit).length > 0;
	},
	isPermittedToDeleteByPermissionName(cN: string) {
		let ps = JSON.parse(localStorage.getItem(PERMISSIONS_KEY)) ? JSON.parse(localStorage.getItem(PERMISSIONS_KEY)) : [];
		return ps.filter((p: UiPermission) => p.PermissionName === cN && p.CanDelete).length > 0;
	},
}

export default LocalPermissionsService