
export default class Permission {
    identityID: number = 0;
    permissionID: number = 0;
    id: number = 0;
    text: string = "";
    feature: string = "";
    component: string = "";
    hasRead: boolean = false;
    hasEdit: boolean = false;
    hasDelete: boolean = false;
    hasExecute: boolean = false;
    shallRead: boolean = false;
    shallEdit: boolean = false;
    shallDelete: boolean = false;
    shallExecute: boolean = false;
    isEnabled: boolean = false;
    permissionName: string = "";
    expanded: boolean = false;
    description: string = "";
    items: Permission[] = [];
    changeDeltas: string[] = [];
    identityId: number = 0;
    children: Permission[] =[];
}
