export default class Role {
  id: number =0;
  identityTypeId: number =0;
  name: string ="";
  displayName: string ="";
  userID: string ="";
  roleID: string ="";
  text: string ="";
  expanded: boolean = false;
  items: Role[] =  Role[0];
  description: string = "";
}
