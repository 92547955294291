// TODO
// 1. Initial Grid - fires twice
// 2. Fix CurrencyFormatter and numeric filters
// 3. Add Wrapping to headers and text columns

import React from "react";
import moment from 'moment';


import { ColDef, ColGroupDef, SideBarDef } from '@ag-grid-community/core';
import {
    ColumnApi,
    MenuItemDef,
    ServerSideRowModelModule,
	SetFilterModule,
    FiltersToolPanelModule,
	ColumnsToolPanelModule,
	SideBarModule,
    RowGroupingModule,
} from '@ag-grid-enterprise/all-modules';
import '@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css';
import '@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css';
import '../css/cdi-ag-grid.css';
import MasterContext from '../Misc/MasterContext';
import ProductHierarchyTree from '../Hierarchy/ProductHierarchyTree';
import LocationHierarchyTree from '../Hierarchy/LocationHierarchyTree';

export interface IProps {
}

export interface IState {
    debug: boolean,
    modules: any[],
    frameworkComponents: any,
    rowData: any[],
    columnDefs?: (ColDef | ColGroupDef)[];
    autoGroupColumnDef: any,
    multiSortKey: string,
    rowModelType: string,
    rowSelection: string,
    cacheBlockSize: number,
    maxBlocksInCache: number,
    blockLoadDebounceMillis: number,
    defaultColDef?: ColDef,
    columnTypes: {
        [key: string]: ColDef;
    },
    sideBar: (string | boolean | SideBarDef),
    gridApi: any,
    gridColumnApi: ColumnApi,
    tooltipShowDelay: number;
    contextMenuItems: MenuItemDef[];
    isServerSideGroup: any;
    getServerSideGroupKey: any;
}

export default class RunManager extends React.Component<IProps, IState> {
    // Constructor
    constructor(props: IProps) {
        super(props);

        this.state = {
            gridApi: null,
            gridColumnApi: null,

            debug: false,
            modules: [
                ServerSideRowModelModule,
                SetFilterModule,
                FiltersToolPanelModule,
                ColumnsToolPanelModule,
                SideBarModule,
                RowGroupingModule
            ],
            frameworkComponents: {},
            rowData: [],
            columnDefs: this.getColumnDefs(),
            multiSortKey: 'ctrl',
            rowModelType: 'serverSide',
            rowSelection: 'single',
            cacheBlockSize: 1000,
            maxBlocksInCache: 100,
            blockLoadDebounceMillis: 300,
            tooltipShowDelay: 0,
            defaultColDef: {
                editable: false,
                hide: false,
                flex: 1,
                cellClass: 'cell-wrap-text',
                filter: 'agSetColumnFilter',
                filterParams: {
                    buttons: ['clear', 'apply'],
                },
                floatingFilter: true,
                resizable: true,
                sortable: true,
                enableRowGroup: false,
                enableValue: false,
                enablePivot: false,
                pivot: false,
                tooltipComponent: 'customTooltip',
            },
            columnTypes: {
                numberColumn: {
                    width: 130,
                    filter: 'agNumberColumnFilter',
                    filterParams: {
                        buttons: ['clear', 'apply'],
                    },
                    type: 'numericColumn'
                },
                currencyColumn: {
                    width: 130,
                    filter: 'agNumberColumnFilter',
                    filterParams: {
                        buttons: ['clear', 'apply'],
                    },
                    valueFormatter: function (params) {
                        return '$ ' + this.formatNumber(params.value);
                    },
                    type: 'numericColumn'
                },
                nonEditableColumn: { editable: false },
                dateColumn: {
                    valueFormatter: function (params) {
                        return moment(params.value).format('MM/DD/YYYY');
                    },
                    filter: 'agDateColumnFilter',
                    filterParams: {
                        buttons: ['clear', 'apply'],
                        comparator: function (filterLocalDateAtMidnight, cellValue) {
                            var dateParts = cellValue.split('/');
                            var day = Number(dateParts[0]);
                            var month = Number(dateParts[1]) - 1;
                            var year = Number(dateParts[2]);
                            var cellDate = new Date(year, month, day);
                            if (cellDate < filterLocalDateAtMidnight) {
                                return -1;
                            } else if (cellDate > filterLocalDateAtMidnight) {
                                return 1;
                            } else {
                                return 0;
                            }
                        },
                    },
                },
            },
            sideBar: {

                toolPanels: [
                    'filters',
                    {
                        id: 'columns',
                        labelDefault: 'Columns',
                        labelKey: 'columns',
                        iconKey: 'columns',
                        toolPanel: 'agColumnsToolPanel',
                        toolPanelParams: {
                            suppressRowGroups: true,
                            suppressValues: true,
                            suppressPivots: true,
                            suppressPivotMode: true,
                            suppressSideButtons: false,
                            suppressColumnFilter: false,
                            suppressColumnSelectAll: false,
                            suppressColumnExpandAll: false,
                            suppressSyncLayoutWithGrid: true,
                        },
                    }
                ],
            },
            contextMenuItems: [],
            autoGroupColumnDef: {
                cellRenderer: 'agGroupCellRenderer',
                cellRendererParams: {
                    checkbox: true, 
                    innerRenderer: function (params) {
                        return params.data.NodeName;
                    },
                },
            },
            isServerSideGroup: function (dataItem) {
                return dataItem.Group;
            },
            getServerSideGroupKey: function (dataItem) {
                return dataItem.NodeId;
            },
        };

        // Function Binding
        this.getColumnDefs = MasterContext.translate.bind(this);
        MasterContext.translate = MasterContext.translate.bind(this);
        this.formatNumber = this.formatNumber.bind(this);
        this.onModelUpdated = this.onModelUpdated.bind(this);
        this.onColumnResized = this.onColumnResized.bind(this);
    }

    onColumnResized = (params) => {
        params.api.resetRowHeights();
    };

    onModelUpdated = (params) => {
    }

    getColumnDefs = (): (ColDef | ColGroupDef)[] => {
        var colDefs: (ColDef | ColGroupDef)[] = [
            {
                field: 'NodeId',
                headerName: 'Node ID',
                hide: true,
            },
            {
                field: 'NodeName',
                headerName: 'Node Name',
                hide: true,
            },
            {
                field: 'NodeDesc',
                headerName: 'Description',
                tooltipField: 'NodeDesc',
                tooltipComponentParams: { color: '#ececec' },
            },
        ];
        return colDefs;
    }

    formatNumber = (number) => {
        return Math.floor(number)
            .toString()
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    }

    //
    // TODO - Figure out how to initialize the grid on page load - currently hitting server twice 
    //
    onGridReady = (params) => {
        params.api.context = {
            thisComponent: this
        };

        this.setState({
            gridApi: params.api,
            gridColumnApi: params.columnApi,
        });

        const updateData = (data: any[], totalCount: number) => {
            var fakeServer = MasterContext.JobControlDatasource.createFakeServer();
            var prodHierDatasource = MasterContext.JobControlDatasource.createProductHierarchyDatasource(MasterContext.JobControlService, this, fakeServer);
            params.api.setServerSideDatasource(prodHierDatasource);
        };

        //let request: IServerSideGetRowsRequest = {
        //    startRow: 1,
        //    endRow: 200,
        //    rowGroupCols: null,
        //    valueCols: null,
        //    pivotCols: null,
        //    pivotMode: false,
        //    groupKeys: null,
        //    filterModel: null,
        //    sortModel: null
        //};

        MasterContext.HierarchyService.getProductRootNode('0', 0, 
            (data) => {
                updateData(data, data.length);
            },
            (error) => {
                // TODO - Figure out how to create an Alert
                // alert('Error: ' + JSON.stringify(err));
            });
    };

    // Render to screen
    render() {
        return (
            <React.Fragment>
                <div className="container-fluid">
                    <div id="divFilterPanel" className="row card">
                        <div className="col-md-12">
                            <h4>New Run Manager</h4>
                        </div>
                    </div>
                    <div id="pnlRunManager" className="row card">
                        <div className="row" style={{ marginTop: "5px", height: "75vh" }}>
                            <div className="col-md-6" style={{ overflowY: "scroll" as "scroll", overflowX: "hidden" as "hidden", height: "100%" }}>
                                <ProductHierarchyTree /> 
                            </div>
                            <div className="col-md-6" style={{ overflowY: "scroll" as "scroll", overflowX: "hidden" as "hidden", height: "100%" }}>
                                <LocationHierarchyTree />
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }

}
