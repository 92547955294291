import IPMOBaseService from '../../Generics/Service/IPMOBaseService'
import { DownloadRequest } from '../Types/DownloadMgr.model';
import { urlConfig } from '../../Misc/dataLayer.config';

export default class DownloadMgrService extends IPMOBaseService {
    //
    //
    //
    submitDownload(downloadRequest: DownloadRequest, callback: any, errorCallback: any) {
        let methodName = "SubmitDownload";
        super.post("/PricingEngineService/DownloadMgr/SubmitDownload", downloadRequest, methodName,
            (success) => {
                callback(success.Data);
            },
            (error) => {
                errorCallback(error)
            });
    }

    //
    //
    //
    getUserDownloadRequests(callback: any, errorCallback: any) {
        let methodName = "GetUserDownloadRequests";
        super.post("/PricingEngineService/DownloadMgr/GetUserDownloadRequests", null, methodName,
            (success) => {
                callback(success.Data, success.TotalCount);
            },
            (error) => {
                errorCallback(error)
            });
    }


    //
    //
    //
    downloadFile(requestId: string, fileName: string, callback: any, errorCallback: any) {
        let uri = "/PricingEngineService/DownloadMgr/DownloadFile";
        var token = localStorage.getItem("userToken");
        let head: any = { 'Content-Type': 'application/json' };
        if (token != null)
            head = {
                'Content-Type': 'application/json',
                'apiKey': '' + token
            }

        fetch(urlConfig.apiBase + uri, {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: head,
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
            body: JSON.stringify(requestId)
        })
        .then(response => {
            const contentType = response.headers.get('content-type');
            const fileName = response.headers.get('Filename');
            return response;
        })
        .then(response => response.blob())
        .then(blob => {
            var url = window.URL.createObjectURL(blob);
            var a = document.createElement('a');
            a.href = url;
            a.download = fileName;
            document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
            a.click();
            a.remove();  //afterwards we remove the element again    
            callback('Successful download!');
        })
        .catch (error => {
            console.error(error);
            errorCallback(error);
        });
    }

}

