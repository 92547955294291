import PagingFilter from "../../Common/Types/PagingFilter.model";
import IPMOBaseService from '../../Generics/Service/IPMOBaseService'

export default class ScenarioService extends IPMOBaseService {
    scenarios(params: PagingFilter, callback: any, errorCallback: any) {
        let methodName = "Get Scenarios";
        let QS ="?page=1&filter=PanelId~eq~" + params.ruleID + "&pageSize=13&count=true";
        params.apiKey = "11111111-1111-1111-1111-111111111111";
        params.appKey = "asdf";

        super.get("/PricingEngineService/Scenarios/" + QS,  methodName,
            (result) => {
                    callback(result.data);
            },
            (error) => {
                errorCallback(error)
            }); 
  }
}
