import IPMOBaseService from '../../Generics/Service/IPMOBaseService'
import { urlConfig } from "../../Misc/dataLayer.config";
import * as types from '../Types/StateMinimum.model';
import { GetLovsRequest, JsonReturnResult } from '../../Generics/Types/IPMOBaseService.model';
import { IServerSideGetRowsRequest } from '@ag-grid-enterprise/all-modules';

export default class StateMinimumService extends IPMOBaseService{
    getStateMinimumProperties(data: IServerSideGetRowsRequest, callback: any, errorCallback: any) {
        let methodName = "StateMinimum Search";
        super.post("/PricingEngineService/StateMinimum/StateMinimumProperties", data, methodName,
            (success) => {
                callback(success.Data, success.TotalCount);
            },
            (error: any) => {
                errorCallback(error)
            });
    }

    getStateMinimums(data: IServerSideGetRowsRequest, callback: any, errorCallback: any) {
        let methodName = "StateMinimum Search";
        super.post("/PricingEngineService/StateMinimum/StateMinimumRecords", data, methodName,
            (success) => {
                callback(success.Data, success.TotalCount);
            },
            (error) => {
                errorCallback(error)
            });
    }

    getLovs(getLovsRequest: GetLovsRequest, callback: any, errorCallback: any) {
        let methodName = "StateMinimum GetLovs";
        super.post("/PricingEngineService/StateMinimum/GetLovs", getLovsRequest, methodName,
            (success) => {
                callback(success.Data, success.TotalCount);
            },
            (error) => {
                errorCallback(error)
            });
    }

    generateStateMinimums(callback: any, errorCallback: any) {
        let methodName = "StateMinimum Generate";
        super.get("/PricingEngineService/StateMinimum/GenerateStateMinimums", methodName,
            (success) => {
                callback(success);
            }, (error) => {
                errorCallback(error);
            });
    }

    exportStateMinimums(request: IServerSideGetRowsRequest, callback: any, errorCallback: any) {
        let methodName = "StateMinimum Export";
        super.downloadFilePost("/PricingEngineService/StateMinimum/Export", request, methodName,
            (success) => {
                callback(success);
            }, (error) => {
                errorCallback(error);
            })
    }
}