import React, { useEffect, useState } from 'react';
import { IRolePermissionTemplate, IReqRolePermission } from '../../../Services/Roles/Roles.service.types';
import { IAuditActionFrequencyAccess } from '../../../Services/AuditAction/AuditAction.service.types';
import RolesService from '../../../Services/Roles/Roles.service';
import { Col, Input, Label, Row } from 'reactstrap';
import SpinnerWithMessage from '../Common/SpinnerWithMessage/SpinnerWithMessage';
import ViewWrapper from '../Common/ViewWrapper/ViewWrapper';
import PermissionActionIdentityFrequencyFormGrid from './AuditActionFrequencyAccessFormGrid';
import { IStateSelectOption } from '../../../Services/Common/Types/Common.types';
import HierarchyService from '../../../Services/Hierarchy/Service/Hierarchy.service';
import { INodeSimple } from '../../../Services/Hierarchy/Types/Hierarchy.service.types';

interface IProps {
}

export default function ManageDepartmentNotifications({ }: IProps) {
    const rolesService = new RolesService(); // has the permission template
    const hierarchyService = new HierarchyService();

    const [selectedHierarchyTypeId, setSelectedHierarchyTypeId] = useState<number | null>(0); // Product type hierarchy for "Department"
    const [selectedTreeId, setSelectedTreeId] = useState<number | null>(null); // TODO remove hardcoding for tests 
    const [treeOptionsLoading, setTreeOptionsLoading] = useState<boolean>(false);
    const [treeOptions, setTreeOptions] = useState<INodeSimple[]>([]);

    useEffect(() => {
        setTreeOptionsLoading(true);
        if (selectedHierarchyTypeId === 0) {
            hierarchyService.getMajorDepartments(
                (res) => {
                    setTreeOptions(res)
                },
                (err) => {
                    alert(err)
                }
            )
        }
        else if (selectedHierarchyTypeId === 1) {
            console.log('Location hierarchy select options not available yet!');
        }

        return () => { }
    }, [selectedHierarchyTypeId]);

    return (
        <ViewWrapper>
            <Row className="mb-4">
                <Col>
                    <h2 className="h4">Manage Department Notifications</h2>
                </Col>
            </Row>
            <Row>
                <Col lg="3" className="mb-4">
                    <Label>Notify Department</Label>
                    <Input type="select" onChange={(e: any) => {
                        if (e.target.value === 'Select') {
                            setSelectedTreeId(null)
                        } else {
                            const parsedInt = parseInt(e.target.value)
                            setSelectedTreeId(e.target.value)
                        }
                        
                    }} >
                        <option value={null}>Select</option>
                        {treeOptions.map((t, i) =>
                            <option key={i} value={t.TreeId} >{ t.NodeDesc }</option>
                         )}
                    </Input>
                </Col>
                <Col>
                    <Label>Audit Action</Label>
                    <PermissionActionIdentityFrequencyFormGrid treeId={selectedTreeId} hierTypeId={selectedHierarchyTypeId}/>
                </Col>
            </Row>
        </ViewWrapper>
    )
}