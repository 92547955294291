import React from "react";


import { AgGridReact } from '@ag-grid-community/react';
import { ColDef, ColGroupDef, SideBarDef } from '@ag-grid-community/core';
import '@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css';
import { ColumnApi, ServerSideRowModelModule, RowGroupingModule, FiltersToolPanelModule, ColumnsToolPanelModule, MenuModule } from '@ag-grid-enterprise/all-modules';
import '@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css';
import '../css/cdi-ag-grid.css';
import MasterContext from '../Misc/MasterContext';
import ProductHierarchyCustomTooltip from './ProductHierarchyCustomTooltip';

export interface IProps {
}

export interface IState {
    debug: boolean,
    modules: any[],
    frameworkComponents: any,
    rowData: any[],
    columnDefs?: (ColDef | ColGroupDef)[];
    autoGroupColumnDef: any,
    rowModelType: string,
    cacheBlockSize: number,
    maxBlocksInCache: number,
    blockLoadDebounceMillis: number,
    defaultColDef?: ColDef,
    sideBar: (string | boolean | SideBarDef),
    gridApi: any,
    gridColumnApi: ColumnApi,
    tooltipShowDelay: number,
    isServerSideGroup: any,
    getServerSideGroupKey: any,
    rowSelection: string,
}

export default class ProductHierarchyTree extends React.Component<IProps, IState> {
    // Constructor
    constructor(props: IProps) {
        super(props);

        this.state = {
            gridApi: null,
            gridColumnApi: null,
            debug: false,
            modules: [ServerSideRowModelModule, RowGroupingModule, MenuModule, FiltersToolPanelModule, ColumnsToolPanelModule],
            frameworkComponents: {
                customTooltip: ProductHierarchyCustomTooltip,
            },
            rowData: [],
            rowSelection: 'multiple',
            columnDefs: this.getColumnDefs(),
            rowModelType: 'serverSide',
            cacheBlockSize: 1000,
            maxBlocksInCache: 100,
            blockLoadDebounceMillis: 300,
            tooltipShowDelay: 0,
            defaultColDef: {
                editable: false,
                hide: false,
                flex: 1,
                cellClass: 'cell-wrap-text',
                resizable: true,
                enableRowGroup: true,
                tooltipComponent: 'customTooltip',
            },
            sideBar: {
                toolPanels: [
                    'filters',
                    {
                        id: 'columns',
                        labelDefault: 'Columns',
                        labelKey: 'columns',
                        iconKey: 'columns',
                        toolPanel: 'agColumnsToolPanel',
                        toolPanelParams: {
                            suppressRowGroups: true,
                            suppressValues: true,
                            suppressPivots: true,
                            suppressPivotMode: true,
                            suppressSideButtons: false,
                            suppressColumnFilter: false,
                            suppressColumnSelectAll: false,
                            suppressColumnExpandAll: false,
                            suppressSyncLayoutWithGrid: true,
                        },
                    }
                ],
            },
            autoGroupColumnDef: {
                headerName: 'Product Hierarchy',
                cellRenderer: 'agGroupCellRenderer',
                cellRendererParams: {
                    checkbox: this.checkbox,
                    checkboxSelection: this.checkboxSelection,
                    innerRenderer: function (params) {
                        return params.data.NodeName;
                    },
                },
            },
            isServerSideGroup: function (dataItem) {
                return dataItem.Group;
            },
            getServerSideGroupKey: function (dataItem) {
                return dataItem.NodeId;
            },
        };

        // Function Binding
        this.getColumnDefs = MasterContext.translate.bind(this);
        this.checkboxSelection = this.checkboxSelection.bind(this);
        this.onRowSelected = this.onRowSelected.bind(this);
        MasterContext.translate = MasterContext.translate.bind(this);
    }

    getColumnDefs = (): (ColDef | ColGroupDef)[] => {
        var colDefs: (ColDef | ColGroupDef)[] = [
            {
                field: 'NodeId',
                headerName: 'Node ID',
                hide: true,
                tooltipField: 'NodeId',
                tooltipComponentParams: { color: '#ececec' },
            },
            {
                field: 'NodeName',
                headerName: 'Node Name',
                hide: true,
                tooltipField: 'NodeName',
                tooltipComponentParams: { color: '#ececec' },
                rowGroup: true,
            },
            {
                field: 'NodeDesc',
                headerName: 'Description',
                tooltipField: 'NodeDesc',
                tooltipComponentParams: { color: '#ececec' },
                hide: true,
            },
            {
                field: 'ProductId',
                headerName: 'Product ID',
                hide: true,
            },
            {
                field: 'UPC',
                headerName: 'UPC',
                hide: true,
            },
            {
                field: 'PriceFamilyDesc',
                headerName: 'Price Family',
                hide: true,
            },
            {
                field: 'Brand',
                headerName: 'Brand',
                hide: true,
            }
        ];
        return colDefs;
    }

    //
    // TODO - Figure out how to initialize the grid on page load - currently hitting server twice 
    //
    onGridReady = (params) => {
        params.api.context = {
            thisComponent: this
        };

        this.setState({
            gridApi: params.api,
            gridColumnApi: params.columnApi,
        });

        const updateData = (data: any[], totalCount: number) => {
            var prodHierDatasource = MasterContext.ProductHierarchyDatasource.createProductHierarchyDatasource(MasterContext.HierarchyService, this);
            params.api.setServerSideDatasource(prodHierDatasource);
        };

        MasterContext.HierarchyService.getProductRootNode('0', 0,
            (data) => {
                updateData(data, data.length);
            },
            (error) => {
                // TODO - Figure out how to create an Alert
                // alert('Error: ' + JSON.stringify(err));
            });

    };

    checkboxSelection = (params) => {
        return params.node.data.Group === true;
    }

    checkbox = (params) => {
        return params.node.data.Group === true;
    }

    censor =  (censor) => {
        var i = 0;

        return function (key, value) {
            if (i !== 0 && typeof (censor) === 'object' && typeof (value) === 'object' && censor === value)
                return '[Circular]';

            if (i >= 29) // seems to be a harded maximum of 30 serialized objects?
                return '[Unknown]';

            ++i; // so we know we aren't using the original object anymore

            return value;
        }
    }

    onRowSelected = (params) => {
        console.log('onRowSelected: ' + JSON.stringify(params, this.censor(params)));
    }

    onRowValueChanged = (params) => {
        console.log('onRowDataChanged: ' + JSON.stringify(params, this.censor(params)));
    }

    // Render to screen
    render() {
        return (
            <React.Fragment>
                <div className="ag-theme-balham" style={{ height: '100%' }}>
                    <AgGridReact
                        debug={this.state.debug}
                        modules={this.state.modules}
                        columnDefs={this.state.columnDefs}
                        defaultColDef={this.state.defaultColDef}
                        cacheBlockSize={this.state.cacheBlockSize}
                        maxBlocksInCache={this.state.maxBlocksInCache}
                        blockLoadDebounceMillis={this.state.blockLoadDebounceMillis}
                        onGridReady={this.onGridReady}
                        rowSelection={this.state.rowSelection}
                        onRowSelected={this.onRowSelected}
                        frameworkComponents={this.state.frameworkComponents}
                        rowData={this.state.rowData}
                        rowModelType={this.state.rowModelType}
                        allowContextMenuWithControlKey={true}
                        sideBar={this.state.sideBar}
                        tooltipShowDelay={this.state.tooltipShowDelay}
                        autoGroupColumnDef={this.state.autoGroupColumnDef}
                        treeData={true}
                        animateRows={false}
                        isServerSideGroup={this.state.isServerSideGroup}
                        getServerSideGroupKey={this.state.getServerSideGroupKey}
                        suppressRowClickSelection={true}
                        suppressAggFuncInHeader={true}
                        onRowValueChanged={this.onRowValueChanged}
                    />
                </div>
            </React.Fragment>
        );
    }

}
