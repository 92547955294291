import * as React from "react";

export class TL {
    static translate(key: string, Lang: string, Lib: any) {
        let retVal = "";
        for (let item of Lib) {

            if (item.key === key) {

                for (let langs of item.languages) {

                    if (langs.lang === Lang) {
                        retVal = langs.translation;
                    }
                }
                break;
            }
        }
        return retVal;
    }
}

export interface TranslatorProps {
    translationKey: string;
    translationLanguage: string;
    translationLibrary: any;
    translationClass: string;
}
interface IState {
    translationKey: string,
    translationLanguage: string,
    translationLibrary: any,
    translationClass: string
}
export default class Translator extends React.Component<TranslatorProps, IState> {
    constructor(props: TranslatorProps) {
        super(props);
        this.state = {
            translationKey: this.props.translationKey,
            translationLanguage: this.props.translationLanguage,
            translationLibrary: this.props.translationLibrary,
            translationClass: this.props.translationClass
        }
    };

    translate(key: string, Lang: string, Lib: any) {
        let retVal = "";
        for (let item of Lib) {
            if (item.key === key) {
                for (let langs of item.languages) {
                    if (langs.lang === Lang) {
                        retVal = langs.translation;
                        break;
                    }
                }
                break;
            }
        }
        return retVal;
    }
    render() {
        let retVal = "";
        for (let item of this.state.translationLibrary) {
            if (item.key === this.state.translationKey) {
                for (let langs of item.languages) {
                    if (langs.lang === this.state.translationLanguage) {
                        retVal = langs.translation;
                    }
                }
                break;
            }
        }

        return <span className={this.state.translationClass}>{retVal}</span>;
    }
}
