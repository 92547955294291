import PagingFilter from "../../Common/Types/PagingFilter.model";
import RuleTreeNode from "../Types/RuleTreeNode.model";
import IPMOBaseService from '../../Generics/Service/IPMOBaseService'
export default class RulesService extends IPMOBaseService {
    rulesExplorer(params: PagingFilter, callback: any, errorCallback: any) {
        let methodName = "Rules Explorer";
        let QS = "?page=1&pageSize= &filter=" + params.filter + "&count=true";
        super.get('/PricingEngineService/rulesExplorer/' + QS,  methodName,
            (result) => {
                    callback(result.data as RuleTreeNode[]);
            },
            (error) => {
                errorCallback(error)
            }); 
  }
    rules(params: PagingFilter, callback: any, errorCallback: any) {
        let methodName = "Rules";
    let QS = "?page=1&filter=ScenarioId~eq~1&pageSize=14&count=true";
        super.get('/PricingEngineService/rules/' + QS, methodName,
            (result) => {
                callback(result.data as RuleTreeNode[]);
            },
            (error) => {
                errorCallback(error)
            }); 
  }
}
