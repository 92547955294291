import React from 'react';
import './ViewWrapper.css'

interface IProps {
    children?: React.ReactNode;
    isNarrowOnLargerScreen?: boolean;
}

export default function ViewWrapper({ children, isNarrowOnLargerScreen=false }: IProps) {
    return (
        <div className={`pt-5 pb-5 pr-5 pl-5 ${isNarrowOnLargerScreen ? "narrowOnLargerScreen" : ""}`}>
            { children }
        </div>
    )
}


