/**
 * 
 */
export class UIGridField {
    public fieldId: number;
    public gridId: number;
    public fieldName: string;
    public displayName: string; 
    public fieldSequence: number;
    public description: string;
    public displayFlag: boolean;
    public activeFlag: boolean;
    public filter: string;
    public staticVal: string;
    public strFormat: string;
    public attribute: string;
    public width: string;
    public format: string;
    public template: string;
    public sort?: boolean;
    public lockable?: boolean;
    public locked?: boolean;
    public hidden: boolean;
    public padChar: string;
    public regex: string;
}

/**
 * 
 */
export class UIGrid {
    static PRICEREVIEW: string = "PriceReview";
    static BUYDOWNEXPLORER: string = "BuydownExplorer";

    //public CDI_UIGrid() {
    //    Fields = new List<CDI_UIGridField>();
    //}

    public gridId: number;
    public gridName: string;
    public description: string;
    public export: boolean;
    public headers: boolean;
    public delimiter: string;
    public fileExtension: string;
    public compressionType: string;
    public fileName: string; 
    public fields: UIGridField[] = [];
}
