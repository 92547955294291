import React from "react";
import moment from 'moment';


import { AgGridReact } from '@ag-grid-community/react';
import { ColDef, StatusPanelDef, ColGroupDef } from '@ag-grid-community/core';
import {
    ClientSideRowModelModule, 
    SetFilterModule,
	StatusBarModule,
    ClipboardModule,
    ExcelExportModule,
    GridChartsModule,
    MenuModule } from '@ag-grid-enterprise/all-modules';
import '@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css';
import '@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css';
import "react-datepicker/dist/react-datepicker.css";
import MasterContext from '../Misc/MasterContext';
import { urlConfig } from "../../../Services/Misc/dataLayer.config";

export interface IProps {

}
export interface IState {
    modules: any[],
    frameworkComponents: any,
    components: any,
    loaded: boolean;
    fileDownloads: any,
    columnDefs: any[],
    multiSortKey: string,
    columnTypes: {
        [key: string]: ColDef;
    },
    statusBar: {
        statusPanels: StatusPanelDef[];
    },
    apiBase: any,
}

export default class FileDownloadGrid extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);

        this.state = {
            modules: [
                ClientSideRowModelModule,
                SetFilterModule,
                StatusBarModule,
                ClipboardModule,
                ExcelExportModule,
                GridChartsModule,
                MenuModule
            ],
            frameworkComponents: {
            },
            components: {
                downloadFileRenderer: this.downloadFileRenderer,
            },
            loaded: false,
            multiSortKey: 'ctrl',
            fileDownloads: [],
            statusBar: {
                statusPanels: [
                    {
                        statusPanel: 'agTotalAndFilteredRowCountComponent',
                        align: 'left',
                    },
                ],
            },
            columnDefs: this.getColumnDefs(), 
            columnTypes: {
                dateColumn: {
                    valueFormatter: function (params) {
                        return moment(params.value).format('MM/DD/YYYY hh:mm:ss A');
                    },
                    filter: 'agDateColumnFilter',
                    filterParams: {
                        comparator: function (filterLocalDateAtMidnight, cellValue) {
                            var dateParts = cellValue.split('/');
                            var day = Number(dateParts[0]);
                            var month = Number(dateParts[1]) - 1;
                            var year = Number(dateParts[2]);
                            var cellDate = new Date(year, month, day);
                            if (cellDate < filterLocalDateAtMidnight) {
                                return -1;
                            } else if (cellDate > filterLocalDateAtMidnight) {
                                return 1;
                            } else {
                                return 0;
                            }
                        },
                    },
                }
            },
            apiBase: urlConfig.apiBase,
        }
        this.getFileDownloads = this.getFileDownloads.bind(this);
        this.getStartDate = this.getStartDate.bind(this);
        this.getEndDate = this.getEndDate.bind(this);
        this.getColumnDefs = this.getColumnDefs.bind(this);
        this.downloadFileRenderer = this.downloadFileRenderer.bind(this);
        this.downloadFileClick = this.downloadFileClick.bind(this);
    }

    onGridReady = (params) => {
        this.getFileDownloads();
    };

    getContextMenuItems = (params) => {
        var result = [
        ];
        return result;
    };

    getStartDate = () => {
        // TODO - Hard-coded to beginning of 2020 - need to figure out solution for LARGE sets of results
        // with ClientSideRowModel or change to ServerSideRowModel
        let retVal: Date = new Date(2020, 0, 1);
        retVal.setMonth(retVal.getMonth() - 1)
        return retVal;
    }

    getEndDate = () => {
        let retVal: Date = new Date();
        return retVal;
    }

    downloadFileClick = (params) => {
        console.log('downloadFileClick = ' + params);
    }

    downloadFileRenderer = (params) => {
        var eDiv = document.createElement('div');
        eDiv.innerHTML = '<span class="my-css-class"><button class="btn-simple">Download</button></span>';
        var eButton = eDiv.querySelectorAll('.btn-simple')[0];

        var requestId = params.data.RequestId;
        var fileName = params.data.FileName;
        eButton.addEventListener('click', function () {
            MasterContext.DownloadMgrService.downloadFile(requestId, fileName, 
                (res) => {
                    console.log('res = ' + res);
                },
                (err) => { }
            )
        });

        return eDiv;
    }

    //Job/Task ID	Time	File Name	PriceBooks	ConstraintBooks	Status	View
    //753165	09: 10 PM	ClearPrice_07_07_020 - UT - 1.log	0	0	Completed	View
    //753166	09: 10 PM	Diagnostic_07_07_020 - VA - 1.zip			Completed	View
    getColumnDefs = (): (ColDef | ColGroupDef)[] => {
        var colDefs: (ColDef | ColGroupDef)[] = [
            {
                field: 'FileId',
                headerName: 'Job/Task ID',
                filter: false,
                type: 'numericColumn',
            },
            {
                field: 'Type', 
                headerName: 'Request Type', 
                filter: false, 
            }, 
            {
                field: 'RequestTime',
                headerName: 'Time',
                filter: false,
            },
            {
                field: 'FileName',
                headerName: 'File Name',
                filter: false,
            },
            {
                field: 'NumAlerts',
                headerName: '# Price Books',
                filter: false,
                type: 'numericColumn',
            },
            {
                field: 'NumRules',
                headerName: '# Constraint Books',
                filter: false,
                type: 'numericColumn',
            },
            {
                field: 'RequestStatus',
                headerName: 'Status',
                filter: false,
            },
            {
                field: 'Download',
                headerName: 'Download',
                filter: false,
                cellRenderer: 'downloadFileRenderer',
                flex: 1,
           }
        ];
        return colDefs;
    }

    /* 
     * 
     */ 
    getFileDownloads = () => {
        this.setState({ loaded: true }, () => {
            MasterContext.DownloadMgrService.getUserDownloadRequests(
                (res) => {
                    this.setState({ fileDownloads: res, loaded: false });
                },
                (err) => { }
            )
        })
    }

    render() {
        return (
            <React.Fragment>
                <div className="container-fluid">
                    <div id="divFilterPanel" className="row card">
                        <div className="col-md-12">
                            <h4>File Downloads</h4>
                        </div>
                    </div>
                    <div id="pnlFileDownloads" className="row card">
                        <div className="row" style={{ marginTop: "5px" }}>
                            <div className="col-md-12" style={{ overflowY: "scroll" as "scroll", overflowX: "hidden" as "hidden", height: "23vh" }}>
                                <div className="ag-theme-balham" style={{ height: '100%' }}>
                                    <AgGridReact
                                        debug={true}
                                        modules={this.state.modules}
                                        frameworkComponents={this.state.frameworkComponents}
                                        components={this.state.components}
                                        columnDefs={this.state.columnDefs}
                                        rowData={this.state.fileDownloads}
                                        columnTypes={this.state.columnTypes}
                                        onGridReady={this.onGridReady}
                                        statusBar={this.state.statusBar}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}



