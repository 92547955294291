import * as React from 'react';
import { ProductGroupQueryRequest } from '../../../Services/ProductGroups/Types/ProductGroups.model';
import Moment from 'moment';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import Spinner from '../../React/Misc/Spinner';

import MasterContext from '../Misc/MasterContext';


export interface IProps {
}

export interface IState {

    rows: [],
    phrase: string,
    searchType: number,
    groupTypeId: number,
    groupSourceTypeId: number,
    count: number,
    prefixText: string,
    showRefreshButton: boolean,
    isSearchTypeExpanded: boolean,
    isGroupTypeExpanded: boolean,
    isSourceExpanded: boolean,
    showRefreshModal: boolean,
    showDeleteModal: boolean,
    masterChecked: boolean,
    loaded: boolean,
    isPageSizeExpanded: boolean,
    isPageExpanded: boolean,
    currentPage: number,
    pages: number,
    recordCount: number,
    autoCompleteSuggestions: [],
    showingAutoComplete: boolean

}
export default class SearchProductGroup extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            rows: [],
            phrase: '',
            searchType: 1,
            groupTypeId: 1,
            groupSourceTypeId: 0,
            count: 15,
            prefixText: '',
            showRefreshButton: true,
            isSearchTypeExpanded: false,
            isGroupTypeExpanded: false,
            isSourceExpanded: false,
            showRefreshModal: false,
            showDeleteModal: false,
            masterChecked: false,
            loaded: true,
            isPageSizeExpanded: false,
            isPageExpanded: false,
            currentPage: 1,
            pages: 1,
            recordCount: 0,
            autoCompleteSuggestions: [],
            showingAutoComplete: false
        };
        
        this.computeHeaderStyle = this.computeHeaderStyle.bind(this);
        this.handlePhraseChange = this.handlePhraseChange.bind(this);
        this.rblSearchTypeChange = this.rblSearchTypeChange.bind(this);
        this.ddlGroupTypesChange = this.ddlGroupTypesChange.bind(this);
        this.ddlGroupSourceTypesChange = this.ddlGroupSourceTypesChange.bind(this);
        this.ddlPageSizeChange = this.ddlPageSizeChange.bind(this);
        this.getRefreshButton = this.getRefreshButton.bind(this);
        this.setSearchType = this.setSearchType.bind(this);
        this.setGroupType = this.setGroupType.bind(this);
        this.setSource = this.setSource.bind(this);
        this.toggleSearchTypeExpanded = this.toggleSearchTypeExpanded.bind(this);
        this.toggleGroupTypeExpanded = this.toggleGroupTypeExpanded.bind(this);
        this.toggleSourceExpanded = this.toggleSourceExpanded.bind(this);
        this.getSourceName = this.getSourceName.bind(this);
        this.getSearchTypeName = this.getSearchTypeName.bind(this);
        this.getGroupTypeName = this.getGroupTypeName.bind(this);
        this.toggleRefreshModal = this.toggleRefreshModal.bind(this);
        this.saveRefreshModal = this.saveRefreshModal.bind(this);
        this.cancelRefreshModal = this.cancelRefreshModal.bind(this);
        this.canShowCreateGroup = this.canShowCreateGroup.bind(this);
        this.canShowRefreshGroup = this.canShowRefreshGroup.bind(this);
        this.saveDeleteModal = this.saveDeleteModal.bind(this);
        this.cancelDeleteModal = this.cancelDeleteModal.bind(this);
        this.countSelectedItems = this.countSelectedItems.bind(this);
        this.checkItem = this.checkItem.bind(this);
        this.checkAllItems = this.checkAllItems.bind(this);
        this.getRowStyle = this.getRowStyle.bind(this);
        this.renderDisplayInPB = this.renderDisplayInPB.bind(this);
        this.renderDimensionGroup = this.renderDimensionGroup.bind(this);
        this.togglePageSizeExpanded = this.togglePageSizeExpanded.bind(this);
        this.getPageSizeName = this.getPageSizeName.bind(this);
        this.setPageSize = this.setPageSize.bind(this);
        this.togglePageExpanded = this.togglePageExpanded.bind(this);
        this.getPageName = this.getPageName.bind(this);
        this.setPage = this.setPage.bind(this);
        this.getTotalPageSizeName = this.getTotalPageSizeName.bind(this);
        this.getPagesList = this.getPagesList.bind(this);
        this.registerGID = this.registerGID.bind(this);
        this.handlePhraseKeypress = this.handlePhraseKeypress.bind(this);
        this.renderAutoCompleteList = this.renderAutoCompleteList.bind(this);
        this.search = this.search.bind(this);
        
    }
    componentDidMount() {
        this.search();
    }

    registerGID(id) {
        MasterContext.CreateProductGroupGID = id;
    }
    getTotalPageSizeName(): string {
        let retVal: string = "";
        retVal = MasterContext.translate("product_groups_page_num_of") + " " + this.state.pages.toString();
        return retVal;
    }
    togglePageExpanded = () => {
        this.toggleAllOff();
        this.setState({ isPageExpanded: !this.state.isPageExpanded });
    }
    getPageName(): string {
        let retVal: string = "";
        retVal = this.state.currentPage.toString();
        return retVal;
    }
    setPage(val: any) {
        this.setState({ currentPage: val }, () => { this.search(); });
        this.toggleAllOff();

        return 0;
    }
    togglePageSizeExpanded = () => {
        this.toggleAllOff();
        this.setState({ isPageSizeExpanded: !this.state.isPageSizeExpanded });
    }
    getPageSizeName(): string {
        let retVal: string = "";
        switch (this.state.count) {
            case 15:
                retVal = "15";
                break;
            case 50:
                retVal = "50";
                break;
            case 75:
                retVal = "75";
                break;
            case 100:
                retVal = "100";
                break;
        }
        return retVal;
    }
    setPageSize(val: any) {
        this.setState({ count: val }, () => { this.search(); });
        this.toggleAllOff();
        return 0;
    }
    renderDimensionGroup(itm) {
        let retVal: any;
        if (itm === true) {
            retVal = <i className="fa fa-check"></i>
        }

        return retVal;
    }
    renderDisplayInPB(itm) {
        let retVal: any;
        if (itm === true) {
            retVal = <i className="fa fa-check"></i>
        }

        return retVal;
    }
    isEven(n) {
        return n % 2 === 0;
    }
    getRowStyle(idx) {

        if (this.isEven(idx)) {
            return { backgroundColor: "#ffffff" };
        }
        else {
            return { backgroundColor: "#35b44a26" };
        }
    }
    canShowCreateGroup() {
        let retVal = {}
        if (this.state.groupSourceTypeId === 0) {
            retVal = { display: "flex" };
        }
        else {
            retVal = { display: "none" };
        }
        return retVal;
    }
    canShowRefreshGroup() {
        let retVal = {}
        if (this.state.groupSourceTypeId === 1) {
            retVal = { display: "flex" };
        }
        else {
            retVal = { display: "none" };
        }
        return retVal;
    }
    checkAllItems() {


        let copy: [];
        copy = [];
        Object.assign(copy, this.state.rows);
        if (this.state.masterChecked === false) {
            for (let it of copy) {
                //@ts-ignore
                it.checked = true;
            }
        }
        else {
            for (let it of copy) {
                //@ts-ignore
                it.checked = false;
            }
        }



        this.setState({ rows: copy, masterChecked: !this.state.masterChecked });

    }
    checkItem(itm: any) {
        let copy: [];
        copy = [];
        Object.assign(copy, this.state.rows);

        for (let it of copy) {
            //@ts-ignore
            if (itm.GroupId === it.GroupId) {
                //@ts-ignore
                it.checked = !it.checked;
            }
        }

        this.setState({ rows: copy });
    }
    countSelectedItems(): string {
        let retVal: string = "";
        let count = 0;
        for (let itm of this.state.rows) {
            //@ts-ignore
            if (itm.checked === true) {
                count++
            }
        }
        retVal = count.toString();
        return retVal;
    }
    cancelRefreshModal() {
        this.toggleRefreshModal();
    }
    saveRefreshModal() {
        this.toggleRefreshModal();
    }
    saveDeleteModal() {

        let itemsCopy: number[] = [];
        for (let itm of this.state.rows) {
            //@ts-ignore
            if (itm.checked === true && itm.Count === 0) {
                //@ts-ignore
                itemsCopy.push(itm.GroupId);
            }
        }

        this.setState({ loaded: true, rows: [] });

        let pgqr: ProductGroupQueryRequest = new ProductGroupQueryRequest();

        pgqr.Count = this.state.count;
        pgqr.GroupId = 0;
        pgqr.GroupSourceTypeId = this.state.groupSourceTypeId;
        pgqr.GroupTypeId = this.state.groupTypeId;
        pgqr.Phrase = this.state.phrase;
        pgqr.PrefixText = 'as';
        pgqr.SearchType = this.state.searchType;
        pgqr.Page = this.state.currentPage;


        pgqr.Items = itemsCopy;


        
        MasterContext.ProductGroupsService.delete(pgqr, (e) => {
            this.setState({ rows: e.grps });
            this.setState({ loaded: false });
            let pages = 1;
            let recs = e.totalRecs;
            pages = recs / this.state.count;
            this.setState({ recordCount: recs, pages: Math.trunc(pages) });
        }, () => { })

        this.toggleDeleteModal();
    }
    cancelDeleteModal() {
        this.toggleDeleteModal();
    }
    toggleRefreshModal() {
        this.setState({ showRefreshModal: !this.state.showRefreshModal });
    }
    toggleDeleteModal() {
        this.setState({ showDeleteModal: !this.state.showDeleteModal });
    }
    getSourceName(): string {
        let retVal: string = "";
        switch (this.state.groupSourceTypeId) {
            case 0:
                retVal =  MasterContext.translate("product_groups_source_clear_demand") ;
                break;
            case 1:
                retVal =  MasterContext.translate("product_groups_source_client") ;
                break;
        }
        return retVal;
    }
    getSearchTypeName(): string {
        let retVal: string = "";
        switch (this.state.searchType) {
            case 0:
                retVal = MasterContext.translate("product_groups_search_product_sku");
                break;
            case 1:
                retVal = MasterContext.translate("product_groups_search_group_name");
                break;
        }
        return retVal;
    }
    getGroupTypeName(): string {
        let retVal: string = "";
        switch (this.state.groupTypeId) {
            case 1:
                retVal =  MasterContext.translate("product_groups_group_type_rules") ;
                break;
            case 2:
                retVal =  MasterContext.translate("product_groups_group_type_ads") ;
                break;
        }
        return retVal;
    }
    setSearchType(val: any) {
        this.setState({ searchType: val }, () => { this.search(); });
        this.toggleAllOff();
        return 0;
    }
    setGroupType(val: any) {
        this.setState({ groupTypeId: val }, () => { this.search(); });
        this.toggleAllOff();
        return 0;
    }
    setSource(val: any) {
        this.setState({ groupSourceTypeId: val }, () => { this.search(); });
        this.toggleAllOff();
        return 0;
    }
    toggleAllOff = () => {
        this.setState({ isSearchTypeExpanded: false });
        this.setState({ isGroupTypeExpanded: false });
        this.setState({ isSourceExpanded: false });
        this.setState({ isPageSizeExpanded: false });
        this.setState({ isPageExpanded: false });
    }
    toggleSearchTypeExpanded = () => {
        this.toggleAllOff();
        this.setState({ isSearchTypeExpanded: !this.state.isSearchTypeExpanded });
    }
    toggleGroupTypeExpanded = () => {
        this.toggleAllOff();
        this.setState({ isGroupTypeExpanded: !this.state.isGroupTypeExpanded });
    }
    toggleSourceExpanded = () => {
        this.toggleAllOff();
        this.setState({ isSourceExpanded: !this.state.isSourceExpanded });
    }
    handlePhraseChange(event: any) {
        let v = event.target.value;
        this.setState({ phrase: v }, () => {
            if (this.state.phrase.length >= 3) {
                let pgqr: ProductGroupQueryRequest = new ProductGroupQueryRequest();

                pgqr.Count = this.state.count;
                pgqr.GroupId = 0;
                pgqr.GroupSourceTypeId = this.state.groupSourceTypeId;
                pgqr.GroupTypeId = this.state.groupTypeId;
                pgqr.Phrase = this.state.phrase;
                pgqr.PrefixText = this.state.phrase;
                pgqr.SearchType = this.state.searchType;
                pgqr.Page = this.state.currentPage;

                MasterContext.ProductGroupsService.searchAutoComplete(pgqr, (e) => {
                    this.setState({ autoCompleteSuggestions: e, showingAutoComplete: true });
                }, () => { })



            }
        });
    }
    getPagesList() {
        let retVal = []
        for (let i: number = 0; i < this.state.pages; i++) {
            retVal.push(<span className="dropdown-item" onClick={() => this.setPage((i + 1))}>{(i + 1).toString()}</span>);
        }
        return retVal;
    }
    ddlPageSizeChange(event: any) {
        let v = event.target.value;
        this.setState({ count: v });
    }
    rblSearchTypeChange(event: any) {
        let v = event.target.value;
        this.setState({ searchType: v });
    }
    ddlGroupTypesChange(event: any) {
        let v = event.target.value;
        this.setState({ groupTypeId: v });
    }
    ddlGroupSourceTypesChange(event: any) {
        let v = event.target.value;
        this.setState({ groupSourceTypeId: v });
    }
    getRefreshButton() {
        if (this.state.showRefreshButton === false) {
            return { display: "none" };
        }
        else {
            return { display: "inline-block" };
        }

    }
    computeHeaderStyle(header: string) {
        let retVal: any = { fontWeight: 400, textAlign: 'center' as 'center', backgroundColor: "#0167a7", color: "#ffffff", borderColor: "#000000", borderWidth: "1px", borderStyle: "solid" };
        switch (header) {
            case 'checkBox':
                retVal.minWidth = "24px";
                break;
            case 'groupName':
                retVal.minWidth = "232px";
                break;
            case 'description':
                retVal.minWidth = "749px";
                break;
            case 'groupType':
                retVal.minWidth = "82px";
                break;
            case 'createdBy':
                retVal.minWidth = "107px";
                break;
            case 'createdDate':
                retVal.minWidth = "82px";
                break;
            case 'updatedBy':
                retVal.minWidth = "82px";
                break;
            case 'updatedDate':
                retVal.minWidth = "96px";
                break;
            case 'displayInPricebook':
                retVal.minWidth = "142px";
                break;
            case 'dimensionGroup':
                retVal.minWidth = "121px";
                break;
            case 'panelsUtilized':
                retVal.minWidth = "103px";
                break;
        }
        return retVal;
    }
    handlePhraseKeypress(e) {
        //console.log(e.key);
        if (e.key === 'Enter') {
            this.search();
        } else {


        }
    }
    renderAutoCompleteList() {
        let retVal = [];
        for (let acItem of this.state.autoCompleteSuggestions) {
            retVal.push(<span className="dropdown-item" onClick={() => this.setAutocompleted(acItem)}>
                {/*
 // @ts-ignore*/}
                {acItem.First}
            </span>);
        }
        return retVal;

    }
    setAutocompleted(val: any) {
        this.setState({ phrase: val.First, showingAutoComplete: false, autoCompleteSuggestions: [] }, () => { this.search() });
    }

    search() {
        this.setState({ loaded: true, rows: [] });

        let pgqr: ProductGroupQueryRequest = new ProductGroupQueryRequest();

        pgqr.Count = this.state.count;
        pgqr.GroupId = 0;
        pgqr.GroupSourceTypeId = this.state.groupSourceTypeId;
        pgqr.GroupTypeId = this.state.groupTypeId;
        pgqr.Phrase = this.state.phrase;
        pgqr.PrefixText = 'as';
        pgqr.SearchType = this.state.searchType;
        pgqr.Page = this.state.currentPage;

        
        MasterContext.ProductGroupsService.search(pgqr, (e) => {
            //debugger;
            this.setState({ rows: e.grps });
            this.setState({ loaded: false });
            let pages = 1;
            let recs = e.totalRecs;
            pages = recs / this.state.count;
            this.setState({ recordCount: recs, pages: Math.trunc(pages) });
        }, () => { })

    }
   
    render() {
        let rowItemStyle = { textAlign: 'center' as 'center', borderColor: "#aba8a8", borderWidth: "1px", borderStyle: "solid", marginleft: "2px", marginRight: "2px" };
        let rowItemStyleLeft = { textAlign: 'left' as 'left', borderColor: "#aba8a8", borderWidth: "1px", borderStyle: "solid", marginleft: "2px", marginRight: "2px" };
        let searchType = `dropdown-menu${this.state.isSearchTypeExpanded ? " show" : ""}`;
        let groupType = `dropdown-menu${this.state.isGroupTypeExpanded ? " show" : ""}`;
        let showAutoComplete = `dropdown-menu${this.state.showingAutoComplete ? " show" : ""}`;
        let source = `dropdown-menu${this.state.isSourceExpanded ? " show" : ""}`;
        let pageSize = `dropdown-menu${this.state.isPageSizeExpanded ? " show" : ""}`;
        let page = `dropdown-menu${this.state.isPageExpanded ? " show" : ""}`;

        return (
            <React.Fragment>
                <div className="container-fluid">
                    <div id="divFilterPanel" className="row card">
                        <div className="col-md-12">
                            <h4>{MasterContext.translate("product_groups_search_header")}</h4>
                            <div className="row">
                                <div className="col-md-2">
                                    <div className="dropdown">
                                        <button className="btn btn-sm btn-outine-info dropdown-toggle" onClick={this.toggleSearchTypeExpanded} type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            {this.getSearchTypeName()}
                                        </button>
                                        <div className={searchType} aria-labelledby="dropdownMenuButton">
                                            <span className="dropdown-item" onClick={() => this.setSearchType(1)}>{MasterContext.translate("product_groups_search_group_name")}</span>
                                            <span className="dropdown-item" onClick={() => this.setSearchType(0)}>{MasterContext.translate("product_groups_search_product_sku")}</span>

                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="row">
                                        <div className="col-md-4">
                                            <b>{MasterContext.translate("product_groups_search_label")}:</b>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="dropdown">
                                                <input id="txtSearch" onKeyPress={this.handlePhraseKeypress} type="text" value={this.state.phrase} onChange={this.handlePhraseChange} />

                                                <div className={showAutoComplete} aria-labelledby="dropdownMenuButton">
                                                    {this.renderAutoCompleteList()}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <button className="btn btn-outline-primary btn-sm" id="btnGetGroups" onClick={this.search}>{MasterContext.translate("product_groups_search_label")}</button>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-4">
                                            <b>{MasterContext.translate("product_groups_group_type_label")}:</b>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="dropdown">
                                                <button className="btn btn-sm btn-outline-info dropdown-toggle" onClick={this.toggleGroupTypeExpanded} type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    {this.getGroupTypeName()}
                                                </button>

                                                <div className={groupType} aria-labelledby="dropdownMenuButton">
                                                    <span className="dropdown-item" onClick={() => this.setGroupType(1)}>{MasterContext.translate("product_groups_group_type_rules")}</span>
                                                    <span className="dropdown-item" onClick={() => this.setGroupType(2)}>{MasterContext.translate("product_groups_group_type_ads")}</span>

                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-4">
                                            <b>{MasterContext.translate("product_groups_source")}:</b>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="dropdown">
                                                <button className="btn btn-sm btn-outline-info dropdown-toggle" onClick={this.toggleSourceExpanded} type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    {this.getSourceName()}
                                                </button>
                                                <div className={source} aria-labelledby="dropdownMenuButton">
                                                    <span className="dropdown-item" onClick={() => this.setSource(0)}>{MasterContext.translate("product_groups_source_clear_demand")}</span>
                                                    <span className="dropdown-item" onClick={() => this.setSource(1)}>{MasterContext.translate("product_groups_source_client")}</span>

                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4" >
                                            <div className="row" style={this.canShowCreateGroup()}>
                                                <div className="col-md-12">

                                                    <a href="#/manageproductgroupscreate" className="btn btn-outline-primary btn-sm">{MasterContext.translate("product_groups_create_group")}</a>
                                                </div>
                                            </div>
                                            <div className="row" style={this.canShowRefreshGroup()}>
                                                <div className="col-md-12">
                                                    <button onClick={() => this.toggleRefreshModal()} className="btn btn-outline-secondary btn-sm" style={this.getRefreshButton()} >{MasterContext.translate("product_groups_refresh_groups")}</button>
                                                    <Modal zIndex={3002} toggle={() => this.toggleRefreshModal()} isOpen={this.state.showRefreshModal}>
                                                        <ModalHeader toggle={() => this.toggleRefreshModal()}>{MasterContext.translate("product_groups_refresh_groups_modal_header")}</ModalHeader>
                                                        <ModalBody>{MasterContext.translate("product_groups_refresh_groups_modal_body")}</ModalBody>
                                                        <ModalFooter>
                                                            <button className="btn btn-sm btn-outline-info" color="primary" onClick={() => this.saveRefreshModal()}>{MasterContext.translate("product_groups_refresh_groups_modal_yes")}</button>{' '}
                                                            <button className="btn btn-sm btn-outline-danger" color="secondary" onClick={() => this.cancelRefreshModal()}>{MasterContext.translate("product_groups_refresh_groups_modal_no")}</button>
                                                        </ModalFooter>
                                                    </Modal>
                                                </div>
                                            </div>
                                            <div className="row" style={this.canShowRefreshGroup()}>
                                                <div className="col-md-12">
                                                    <a id="lnkJobStatus" href="#/enginejobstatus">{MasterContext.translate("product_groups_view_product_group_jobs")}</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">&nbsp;</div>
                    </div>

                    <div id="pnlProductGroupsDetail" className="row card">
                        <div className="col-md-12">
                            <div className="row">
                                <div className="col-md-2">
                                    <button onClick={() => this.toggleDeleteModal()} className="btn btn-outline-danger btn-sm">{MasterContext.translate("product_groups_delete_button")}</button>

                                    <Modal zIndex={3002} toggle={() => this.toggleDeleteModal()} isOpen={this.state.showDeleteModal}>
                                        <ModalHeader toggle={() => this.toggleDeleteModal()}>{MasterContext.translate("product_groups_refresh_groups_modal_header")}</ModalHeader>
                                        <ModalBody>{MasterContext.translate("product_groups_delete_body")} {this.countSelectedItems()} {MasterContext.translate("product_groups_delete_body_2")}<br />
                                            <span style={{ fontStyle: "italic", fontSize: "14px" }}>{MasterContext.translate("product_groups_delete_body_3")}</span>
                                        </ModalBody>
                                        <ModalFooter>
                                            <button className="btn btn-sm btn-outline-info" color="primary" onClick={() => this.saveDeleteModal()}>{MasterContext.translate("product_groups_refresh_groups_modal_yes")}</button>{' '}
                                            <button className="btn btn-sm btn-outline-danger" color="secondary" onClick={() => this.cancelDeleteModal()}>{MasterContext.translate("product_groups_refresh_groups_modal_no")}</button>
                                        </ModalFooter>
                                    </Modal>

                                </div>
                                <div className="col-md-8">
                                    <div className="row">
                                        <div className="col-md-2">{MasterContext.translate("product_groups_records_per_page")}</div>
                                        <div className="col-md-1">
                                            <div className="dropdown">
                                                <button className="btn btn-sm btn-outline-info dropdown-toggle" onClick={this.togglePageSizeExpanded} type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    {this.getPageSizeName()}
                                                </button>
                                                <div className={pageSize} aria-labelledby="dropdownMenuButton">
                                                    <span className="dropdown-item" onClick={() => this.setPageSize(15)}>15</span>
                                                    <span className="dropdown-item" onClick={() => this.setPageSize(50)}>50</span>
                                                    <span className="dropdown-item" onClick={() => this.setPageSize(75)}>75</span>
                                                    <span className="dropdown-item" onClick={() => this.setPageSize(100)}>100</span>

                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-2">{MasterContext.translate("product_groups_records_count")}</div>
                                        <div className="col-md-2"><span id="lblRecordCount" style={{ width: "60px" }} />{this.state.recordCount}</div>
                                        <div className="col-md-1">{MasterContext.translate("product_groups_page_num")}:</div>
                                        <div className="col-md-2">

                                            <div className="dropdown">
                                                <button className="btn btn-sm btn-outline-info dropdown-toggle" onClick={this.togglePageExpanded} type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    {this.getPageName()}
                                                </button>
                                                <div className={page} aria-labelledby="dropdownMenuButton">
                                                    {this.getPagesList()}
                                                </div>
                                            </div>

                                        </div>
                                        <div className="col-md-2">
                                            {this.getTotalPageSizeName()}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-2">
                                </div>
                            </div>
                            <div className="row" style={{ marginTop: "5px" }}>
                                <div className="col-md-12" style={{ overflowY: "scroll" as "scroll", overflowX: "hidden" as "hidden", height: "62vh" }}>
                                    <Spinner
                                        spinnerActive={this.state.loaded}
                                        heightSet="99vh"
                                        paddingSet="10%"
                                        spinnerText={MasterContext.translate("spinner_loading_data")}
                                    />

                                    <table>
                                        <tr>
                                            <th style={this.computeHeaderStyle('checkBox')}><input checked={this.state.masterChecked} onChange={() => this.checkAllItems()} type="checkbox" /></th>
                                            <th style={this.computeHeaderStyle('groupName')}>{MasterContext.translate("product_groups_column_group_name")}</th>
                                            <th style={this.computeHeaderStyle('description')}>{MasterContext.translate("product_groups_column_description")}</th>
                                            <th style={this.computeHeaderStyle('groupType')}>{MasterContext.translate("product_groups_column_group_type")}</th>
                                            <th style={this.computeHeaderStyle('createdBy')}>{MasterContext.translate("product_groups_column_created_by")}</th>
                                            <th style={this.computeHeaderStyle('createdDate')}>{MasterContext.translate("product_groups_column_create_date")}</th>
                                            <th style={this.computeHeaderStyle('updatedBy')}>{MasterContext.translate("product_groups_column_updated_by")}</th>
                                            <th style={this.computeHeaderStyle('updatedDate')}>{MasterContext.translate("product_groups_column_updated_date")}</th>
                                            <th style={this.computeHeaderStyle('displayInPricebook')}>{MasterContext.translate("product_groups_column_display_in_pricebook")}</th>
                                            <th style={this.computeHeaderStyle('dimensionGroup')}>{MasterContext.translate("product_groups_column_dimension_group")}</th>
                                            <th style={this.computeHeaderStyle('panelsUtilized')}>{MasterContext.translate("product_groups_column_panels_utilized")}</th>
                                        </tr>
                                        <tbody>
                                            {this.state.rows.map((item: any, index) => {
                                                return <tr style={this.getRowStyle(index)}>
                                                    <td style={rowItemStyle}><input onChange={() => this.checkItem(item)} checked={item.checked} type="checkbox" /></td>
                                                    <td style={rowItemStyleLeft}>
                                                        <a onClick={() => this.registerGID(item.GroupId)} href="#/manageproductgroupscreate">{item.Name}</a>
                                                    </td>
                                                    <td style={rowItemStyleLeft}>{item.Description}</td>
                                                    <td style={rowItemStyle}>{item.GroupType}</td>
                                                    <td style={rowItemStyle}>{item.CreatedBy}</td>
                                                    <td style={rowItemStyle}>{Moment(item.CreateDate).format('MM/dd/YYYY')}</td>
                                                    <td style={rowItemStyle}>{item.UpdatedBy}</td>
                                                    <td style={rowItemStyle}>{Moment(item.UpdateDate).format('d MMM')}</td>
                                                    <td style={rowItemStyle}>{this.renderDisplayInPB(item.DisplayInPB)}</td>
                                                    <td style={rowItemStyle}>{this.renderDimensionGroup(item.GroupFilterFlag)}</td>
                                                    <td style={rowItemStyle}>{item.Count}</td>
                                                </tr>
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment >
        );
    }
}