import * as React from 'react';
import Spinner from '../../React/Misc/Spinner';
import MasterContext from '../../React/Misc/MasterContext';
export interface IProps {
}
export interface IState {
    loaded: boolean;
}

export default class ManageLocationGroupsSearch extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = { loaded: true }
        this.finishLoad = this.finishLoad.bind(this);
    }

    finishLoad() {
        this.setState({ loaded: false });
    }


    getURL(): string {
        let retVal = '';
        //debugger;
        var token = localStorage.getItem("userToken");
        retVal = MasterContext.DOTNETURL + "Configuration/LocationGroups/SearchLocationGroup.aspx?source=C3PO&apiKey=" + token;
        return retVal;
    }

    
    render() {
        let style1 = { height: "405px" }
        let style2 = { border: "0px", height: "97vh" };
        return (
            <div id="mainBody" className="main-body container-fill ng-scope body-fill" event-control="" cdi-body-fill-container="" ui-view="" role="main" style={style1}>
                              
  <Spinner

                    spinnerActive={this.state.loaded}
                    heightSet="99vh"
                    paddingSet="10%"
                    spinnerText={MasterContext.translate("spinner_loading_group_search")}
                />
                <iframe id="iframeManageLocationGroupSearch" title='iframeManageLocationGroupSearch' onLoad={this.finishLoad}  src={this.getURL()} width="100%" style={style2} ></iframe>
            </div>
        );

    }

}