import * as React from 'react';
import './Explorer.css';
import CDIExplorerTreeView from './TreeView';
import CDIExplorerGrid from './Grid';
import CDIExplorerDetail from './Detail';

export interface IProps {
    
}

export interface IState {
    treeData: any[]; // this is the main array of items that drives the tree list view, it must live here in the parent
    gridData: any[]; // this is the main array of items that drives the grid view, as you make different selections in the tree, the grid data will change
    detailData: {}   //this is the mai object store for the data that will be rendered in the detail pane. It will be changed out as you select a grid item
}

export default class CDIExplorer extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            treeData: [
                {
                    id: 0, name: 'Item A', expanded:true,
                        children: [
                        {
                            id: 1, name: 'Child of A #1', expanded: true,
                            children: [
                                {
                                    id: 2, name: 'Grandchild of A #1', expanded: true,
                                    children: [
                                        { id: 6, name: 'Great Grandchild of A #1', expanded: true },
                                        { id: 7, name: 'Great Grandchild of A #2', expanded: true }]
                                },
                                { id: 3, name: 'Grandchild of A #2', expanded: true }]
                            }, { id: 4, name: 'Child of A #2', expanded: true }]
                },
                { id: 5, name: 'Item B', expanded: true }],
            gridData: [{ rowID: 1, val1: 'foo', val2: 'bar' }, { rowID: 2, val1: 'boo', val2: 'far' }],
            detailData: {
                rowID: 1, val1: 'foo', val2: 'bar', tabs: [
                    { tabId: 0, tabTitle: 'test tab', tabType: 'grid', tabData: [{ id: 0, value: 'hi' }, { id: 1, value: 'bye' }] },
                    { tabId: 1, tabTitle: 'test tab 2', tabType: 'grid', tabData: [{ id: 0, value: 'hi' }, { id: 1, value: 'bye' }] },
                    { tabId: 2, tabTitle: 'test tab 3', tabType: 'grid', tabData: [{ id: 0, value: 'hi' }, { id: 1, value: 'bye' }] }
                ]
            }
        };

        this.treeItemClicked = this.treeItemClicked.bind(this);
        this.treeItemExpanded = this.treeItemExpanded.bind(this);
        this.treeItemRightClicked = this.treeItemRightClicked.bind(this); 
        this.gridRowSelected = this.gridRowSelected.bind(this);
        this.gridRowDeleted = this.gridRowDeleted.bind(this);
        this.detailChanged = this.detailChanged.bind(this);

        this.RandomTree = this.RandomTree.bind(this);
    }

    UNs: number[] = [];

    componentDidMount() {
        this.setState({ treeData: this.RandomTree(10,0) }, () => {
            console.log(this.state.treeData);
        });
 
    }

    //utility functions
    isEven(n) {
        return n % 2 === 0;
    }

    getUniqueRandomNumber():number {
        let val = new Date().valueOf() * Math.floor(Math.random() * 13);
        for (let num of this.UNs) {
            if (num === val)
            {
                val = this.getUniqueRandomNumber();
                break;
            }
        }
        this.UNs.push(val);
        return val;
    }
    RandomTree(max: number, depth: number) {
        let maxDepth = 5;
        let newArr: any[] = [];
        let rando = Math.floor(Math.random() * max);
        console.log(rando);
        if (this.isEven(rando)) {
            for (let i = 0; i < max; i++) {
                newArr.push({ id: this.getUniqueRandomNumber(), name: 'Item ' + this.getUniqueRandomNumber(), expanded: true});
            }
        }
        else
        {
            if (depth <= maxDepth) {
                for (let i = 0; i < max; i++) {
                    newArr.push({ id: this.getUniqueRandomNumber(), name: 'Item ' + this.getUniqueRandomNumber(), expanded: true, children: this.RandomTree(max, depth++) });
                }
            }
        }
        return newArr;
    }

    //callbacks

    //a left click command on a tree view item, will be used to change the context of the grid
    treeItemClicked(item: any) {
        console.log(item)
        let newGridData: any[] = [];
        for (let i = 0; i < 100; i++) {
            newGridData.push({ rowID: i, val1: item.name + i, val2: 'bar' + i });
        }
        this.setState({gridData:newGridData});
    }

    //a right click call to a tree view item, here we can determine the kind of item it is, and make a context menu based on that
    treeItemRightClicked(item: any) { }

    //clicked on the +/- tree item
    treeItemExpanded(item: any) {
        let treeClone: any[] = [];
        Object.assign(treeClone, this.state.treeData);
        for (let itm of treeClone) {
            if (itm.id === item.id) {
                itm.expanded = !itm.expanded;
                break;
            }
            if (itm.children) {
                itm.chidren = this.recursiveToggle(itm, item.id);
            }
        }
        this.setState({ treeData: treeClone });
    }

    //a grid item has been selected in the child component and we can/should change the content in the detail pane
    gridRowSelected(item: any) {
        console.log('hitting ', item)
        let copy: any = {};
        Object.assign(copy, this.state.detailData);
        copy.val1 = item.val1;

       this.setState({detailData:item})
        
    }

    //a grid item has been deleted int he child component
    gridRowDeleted(item: any) { }

    //something in the detail pane has changes and needs to sync up
    detailChanged(item: any) { }

    recursiveToggle(item: any, key: any) {
        for (let itm of item.children) {
            if (itm.id === key) {
                itm.expanded = !itm.expanded;
                break;
            }
            if (itm.children) {
                itm.chidren = this.recursiveToggle(itm, key);
            }
        }
    }


    render() {

        return (
            <React.Fragment>
                <div className="row" style={{minHeight:"600px"}}>
                    <div className="col-md-2 card">
                        <CDIExplorerTreeView
                            treeData={this.state.treeData}
                            treeItemClicked={this.treeItemClicked}
                            treeItemRIghtClicked={this.treeItemRightClicked}
                            treeItemExpanded={this.treeItemExpanded}
                        >
                        </CDIExplorerTreeView>
                    </div>
                    <div className="col-md-10 card">
                        <div className="row">
                            <div className="col-md-12 card" style={{height:"300px", overflowY:"auto"}}>
                            <CDIExplorerGrid
                                gridData={this.state.gridData}
                                gridRowSelected={this.gridRowSelected}
                                gridRowDeleted={this.gridRowDeleted}
                            >
                                </CDIExplorerGrid>
                                </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 card" style={{ height: "300px", overflowY: "auto" }}>
                            <CDIExplorerDetail
                                detailData={this.state.detailData}
                                detailChanged={this.detailChanged}
                            >
                                </CDIExplorerDetail>
                                </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}