import moment from 'moment';
import IPMOBaseService from '../../Generics/Service/IPMOBaseService'
import App_Property from '../Types/AppProperty.model';
import MasterContext from '../../../Components/React/Misc/MasterContext';
import CacheManager from '../../CacheManager/CacheManager';

export default class AppPropertyService extends IPMOBaseService {
    getAppPropertyBySectionName(section:string, name:string, callback: any, errorCallback: any) {
        let methodName = "Get App Property";
        super.startRequest(methodName, '');
        super.get("/PricingEngineService/AppProperties/" + section + "/" + name +"/", methodName,
            (result) => {
                if (result.Name) {
                    let ret = result;
                    callback(ret)
                }
                else {
                    errorCallback();
                }

            },
            (error) => {
                errorCallback(error)
            });

    }

    getApplicationProperties(callback: any, errorCallback: any, ignoreCache = false) {
        let cached = null;
        let CM: CacheManager = new CacheManager();

        try {
            cached = MasterContext.CacheMan.getCacheItem("AppProperties");
        } catch {
            cached = null;
        }

        if (cached == null || ignoreCache) {
            let methodName = "Get Application Properties";
            super.post(`/PricingEngineService/AppProperties/Active/${ignoreCache}`, null, methodName,
                (result) => {
                    let ret = result.Data;
                    CM.setLocalStorageItem("AppProperties", ret);
                    callback(result.Data as App_Property[]);
                },
                (error) => {
                    errorCallback(error)
                });
        } else {
            callback(cached);
        }
    }

    findAppProperty(section: string, name: string, callback: any, errorCallback: any, ignoreCache = false) {
        this.getApplicationProperties(
            (data: App_Property[]) => {
                let appProp = data.filter(x => x.Section === section && x.Name === name)[0];
                callback(appProp);
            },
            (error) => {
                errorCallback(error);
            },
            ignoreCache)
    }

    updateAppProperty(prop: App_Property, callback: any, errorCallback: any) {
        let methodName = "Get Application Properties";
        super.put("/PricingEngineService/AppProperties/" + prop.Section + "/" + prop.Name, prop, methodName,
            (result) => {
                MasterContext.CacheMan.removeCacheItem("AppProperties");
                callback(result);
            },
            (error) => {
                errorCallback(error)
            });
    }

    getFutureBuydownDate(callback: any, errorCallback: any) {
        var startDate = new Date();
        try {
            this.findAppProperty('AppDefaults', 'FutureBuydownDays',
            (res) => {
                var days = parseInt(res.Value);
                startDate.setDate(startDate.getDate() + days);
                callback(moment(startDate).format('YYYY-MM-DD'));
                
            }, (err) => {
                errorCallback(moment(startDate).format('YYYY-MM-DD'))
            });
        } catch (ex) {
            errorCallback(moment(startDate).format('YYYY-MM-DD'))
        }
    }
}
