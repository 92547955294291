import * as React from 'react';
import Spinner from '../../React/Misc/Spinner';
import './Finder.css';
import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu";
import MasterContext from '../Misc/MasterContext';


export interface IProps {
    sourceData: any[],
    depth: number,
    containerHeight: string,
    itemClickedCallback: Function,
    itemPickedCallback: Function,
    selectedPaths: number[],
    fontSize: string,
    hilightedBackgroundColor: string,
    columnWidth: string
    spinners: boolean[],
    searchMatchColor: string,
    addCallBackTitle: string,
    finderName: string,
    pickedItems: any[]

}

export interface IState {
    finderSource: any[],
    selectedKey: any,
    selectedData: any,
    finderSearch: string
}

export default class CDIFinder extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            
            finderSource: props.sourceData,
            selectedKey: {},
            selectedData: null,
            finderSearch: ''
        };
        this.renderColumns = this.renderColumns.bind(this);
        this.renderRow = this.renderRow.bind(this);
        this.itemClicked = this.itemClicked.bind(this);
        this.itemPicked = this.itemPicked.bind(this);
        this.getRowStyle = this.getRowStyle.bind(this);
        this.handleFinderSearchChanged = this.handleFinderSearchChanged.bind(this);

    }
    handleFinderSearchChanged(event: any) {
        let v = event.target.value;
        this.setState({ finderSearch: v });
    }

    itemClicked(item, bay: number) {
        item.selected = true;
        this.props.itemClickedCallback(item, bay);
    }
    itemPicked(item, bay: number) {
        this.props.itemPickedCallback(item, bay);
    }
    getRowStyle(item: any, bay: number) {
        if (this.props.selectedPaths[bay] === item.position) {
            let tempBG = this.props.hilightedBackgroundColor;
            let tempFG = "#ffffff";
            if (item.nodeDescription.toLowerCase().includes(this.state.finderSearch) === true && this.state.finderSearch.length > 0) {
                tempBG = this.props.searchMatchColor;
                tempFG = "#000000";
            }

            return { backgroundColor: tempBG, color: tempFG, cursor: "pointer", fontSize: this.props.fontSize };
        }
        else {
            let tempBG = "#ffffff";
            let tempFG = "#000000";
            if (item.nodeDescription.toLowerCase().includes(this.state.finderSearch) === true && this.state.finderSearch.length > 0) {
                tempBG = this.props.searchMatchColor;
                tempFG = "#ffffff";
            }
            return { backgroundColor: tempBG, color: tempFG, cursor: "pointer", fontSize: this.props.fontSize };
        }
    }
    renderPickedRow(item: any) {
        let found: boolean = false;
        let retVal: any[] = [];
        for (let it of this.props.pickedItems) {
            if (it.nodeDescription === item.nodeDescription) {
                found = true;
            }
        }

        if (found === true) {
            retVal.push(<i style={{ paddingRight: "4px" }} className="fa fa-check" aria-hidden="true"></i>);
        }

        return retVal;
    }
    renderRow(item: any, bay: number) {
        return <div className="row" style={this.getRowStyle(item, bay)}>

            <div className="col-md-9 text-nowrap" onDoubleClick={() => { this.itemPicked(item, bay) }} onClick={() => { this.itemClicked(item, bay) }}> <ContextMenuTrigger id={item.keyG}>{item.nodeDescription}</ContextMenuTrigger></div>
            <div className="col-md-3" onDoubleClick={() => { this.itemPicked(item, bay) }} onClick={() => { this.itemClicked(item, bay) }}><div className="float-right"><ContextMenuTrigger id={item.keyG}>{this.renderPickedRow(item)}<i className="fa fa-caret-right" aria-hidden="true"></i></ContextMenuTrigger></div></div>

            <ContextMenu id={item.keyG}>
                <MenuItem onClick={() => { this.itemPicked(item, bay) }}>{this.props.addCallBackTitle}</MenuItem>
            </ContextMenu>
        </div>
    }
    prepRows(row: number) {
        if (row === 0) {
            return this.props.sourceData.map((item: any, index) => {
                return <li>{this.renderRow(item, row)}</li>
            })
        }
        if (row === 1 && this.props.selectedPaths[0] > -1) {
            return this.props.sourceData[this.props.selectedPaths[0]]
                .children.map((item: any, index) => {
                    return <li>{this.renderRow(item, row)}</li>
                })
        }
        if (row === 2 && this.props.selectedPaths[1] > -1) {
            return this.props.sourceData[this.props.selectedPaths[0]]
                .children[this.props.selectedPaths[1]]
                .children.map((item: any, index) => {
                    return <li>{this.renderRow(item, row)}</li>
                })
        }
        if (row === 3 && this.props.selectedPaths[2] > -1) {
            return this.props.sourceData[this.props.selectedPaths[0]]
                .children[this.props.selectedPaths[1]]
                .children[this.props.selectedPaths[2]]
                .children.map((item: any, index) => {
                    return <li>{this.renderRow(item, row)}</li>
                })
        }
        if (row === 4 && this.props.selectedPaths[3] > -1) {
            return this.props.sourceData[this.props.selectedPaths[0]]
                .children[this.props.selectedPaths[1]]
                .children[this.props.selectedPaths[2]]
                .children[this.props.selectedPaths[3]]
                .children.map((item: any, index) => {
                    return <li>{this.renderRow(item, row)}</li>
                })
        }
        if (row === 5 && this.props.selectedPaths[4] > -1) {
            return this.props.sourceData[this.props.selectedPaths[0]]
                .children[this.props.selectedPaths[1]]
                .children[this.props.selectedPaths[2]]
                .children[this.props.selectedPaths[3]]
                .children[this.props.selectedPaths[4]].children.map((item: any, index) => {
                    return <li>{this.renderRow(item, row)}</li>
                })
        }
    }
    renderColumns() {
        let retVal: any[] = [];
        if (this.props.sourceData.length > 0) {
            for (let i = 0; i < this.props.depth; i++) {

                retVal.push(<div className="card" style={{ paddingLeft: "5px", paddingRight: "5px", width: this.props.columnWidth, height: this.props.containerHeight, overflowX: "hidden", overflowY: "auto" }}>
                    <Spinner
                        spinnerActive={this.props.spinners[i]}
                        heightSet="99vh"
                        paddingSet="10%"
                        spinnerText={MasterContext.translate("spinner_loading_items")}
                    />
                    <ul style={{ paddingInlineStart: 'unset' }}>
                        {this.prepRows(i)}
                    </ul>
                </div>)
                    ;
            }
        }
        else {
            retVal.push(<div className="card" style={{ paddingLeft: "5px", paddingRight: "5px", width: this.props.columnWidth, height: this.props.containerHeight, overflowX: "hidden", overflowY: "auto" }}>
                <Spinner
                    spinnerActive={this.props.spinners[0]}
                    heightSet="99vh"
                    paddingSet="10%"
                    spinnerText={MasterContext.translate("spinner_loading_items")}
                />

            </div>);
        }
        return retVal;
    }

    render() {

        return (
            <React.Fragment>
                <div className="row finderMaster">
                    <div className="col-12">
                        <div className="row finderSearch">
                            <div className="col-8 finderSearchRow">
                                <h3 style={{ color: "#424242" }}>{this.props.finderName}</h3>
                            </div>
                            <div className="col-4 finderSearchRow">
                                <input className="form-control mr-sm-2 finderSearchRowInput float-right" type='text' placeholder="Search" value={this.state.finderSearch} onChange={this.handleFinderSearchChanged} />
                            </div>
                        </div>
                        <div className="row finderResults">
                            <div className="col-12">
                                <div className="row finderRoot flex-row flex-nowrap">
                                    {this.renderColumns()}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}