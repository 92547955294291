import * as React from 'react';
import MasterContext from "../Misc/MasterContext"
import Spinner from '../../React/Misc/Spinner';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import LoginService from '../../../Services/Login/Service/Login.service';
import LocalPermissionsService from '../../../Services/local-permissions-service';
import AppPropertyService from '../../../Services/Common/Service/AppProperty.service';
import CacheManager from '../../../Services/CacheManager/CacheManager';

import * as types from '../../../Services/Login/Types/Login.model'


export class ErrorDefinition {
    controlName: string;
    error: string;
    severity: number;
}

export interface IProps {
    handleLogin: Function;
}
export interface IState {

    spinnerMessage: string;

    rememberMe: boolean;
    userName: string;
    password: string;

    expiredPWCurrent: string;
    expiredPWNew: string;
    expiredPWConfirm: string;
    showPWExpiredErr: boolean;

    mode: number;
    loginError: number;
    loaded: boolean;
    isLoggingIn: boolean;
    isRegistering: boolean;
    isRecovering: boolean;

    registerUserName: string;
    registerFirstName: string;
    registerLastName: string;
    registerCompanyName: string;
    registerEmailAddress: string;
    registerPhoneNumber: string;

    registrtionPassword: string;
    registrtionPasswordConfirmed: string;
    registrationValidationErrors: any[],
    resetPasswordValidationErrors: any[],
    recoveryValidationErrors: any[],
    loginValidationErrors: any[],

    recoveryUserName: string,
    recoveryResponse: boolean,
    registrationResponse: boolean,

    showPWExpiredModal: boolean;
    showRegisteredModal: boolean;
    showRecoveryModal: boolean;
    showRegisteredButNotApproved: boolean;
    showAccountLockoutModal: boolean;
    showMiscErrorModal: boolean;
    loggedOutModal: boolean;
    miscError: string
    showRegisterButton: boolean;

    showMessageModal: boolean;
    messageBody: string;
    messageHeader: string;
}

export default class Login extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            spinnerMessage: "Please Stand By...",
            rememberMe: false,
            userName: '',
            password: '',
            mode: 1,
            loginError: 0,
            loaded: false,
            registerUserName: '',
            registerFirstName: '',
            registerLastName: '',
            registerCompanyName: '',
            registerEmailAddress: '',
            registerPhoneNumber: '',
            registrtionPassword: '',
            registrtionPasswordConfirmed: '',
            registrationValidationErrors: [],
            resetPasswordValidationErrors: [],
            recoveryValidationErrors: [],
            loginValidationErrors: [],
            showPWExpiredModal: false,
            showRecoveryModal: false,
            expiredPWConfirm: '',
            expiredPWCurrent: '',
            expiredPWNew: '',
            showRegisteredModal: false,
            recoveryUserName: '',
            showAccountLockoutModal: false,
            showRegisteredButNotApproved: false,
            recoveryResponse: false,
            registrationResponse: false,
            showMiscErrorModal: false,
            loggedOutModal: false,
            miscError: '',
            isLoggingIn: false,
            isRecovering: false,
            isRegistering: false,
            showPWExpiredErr: false,
            showMessageModal: false,
            messageBody: '',
            messageHeader: '',
            showRegisterButton: false
        };

        let appPropService: AppPropertyService = new AppPropertyService();
        appPropService.findAppProperty('AppDefaults', 'UserRegistration',
            (cb) => {
                if (cb && cb.Value == 1) {
                    this.setState({ showRegisterButton: true });
                }
                
            },
            (err) => {

            }
        );

        //bind events
        this.handleUserNameChange = this.handleUserNameChange.bind(this);
        this.handlePasswordChange = this.handlePasswordChange.bind(this);
        this.handleRememberMeChanged = this.handleRememberMeChanged.bind(this);


        this.handleExpiredPWConfirmChange = this.handleExpiredPWConfirmChange.bind(this);
        this.handleExpiredPWCurrentChange = this.handleExpiredPWCurrentChange.bind(this);
        this.handleExpiredPWNewChange = this.handleExpiredPWNewChange.bind(this);

        this.showMessageModalComplete = this.showMessageModalComplete.bind(this);

        this.handleLoginKeypress = this.handleLoginKeypress.bind(this);
        this.loginMe = this.loginMe.bind(this);
        this.registerMe = this.registerMe.bind(this);
        this.recoverMe = this.recoverMe.bind(this);
        this.finishLoad = this.finishLoad.bind(this);
        this.startLoad = this.startLoad.bind(this);

        this.handleFirstNameChanged = this.handleFirstNameChanged.bind(this);
        this.handleLastNameChanged = this.handleLastNameChanged.bind(this);
        this.handleRegistrationPasswordChanged = this.handleRegistrationPasswordChanged.bind(this);
        this.handleRegistrationPasswordConfirmedChanged = this.handleRegistrationPasswordConfirmedChanged.bind(this);

        this.handleCompanyNameChange = this.handleCompanyNameChange.bind(this);
        this.handleEmailAddressChange = this.handleEmailAddressChange.bind(this);
        this.handleRegisterUserNameChange = this.handleRegisterUserNameChange.bind(this);
        this.handlePhoneNumberChange = this.handlePhoneNumberChange.bind(this);

        this.handleRecoveryUserNameChange = this.handleRecoveryUserNameChange.bind(this);
        this.cancelRegistration = this.cancelRegistration.bind(this);
        this.cancelRecovery = this.cancelRecovery.bind(this);


        this.validateRegistration = this.validateRegistration.bind(this);
        this.validateRecovery = this.validateRecovery.bind(this);
        this.validateResetPassword = this.validateResetPassword.bind(this);

        this.togglePWExpiredModal = this.togglePWExpiredModal.bind(this);
        this.saveNewPassword = this.saveNewPassword.bind(this);
        this.checkPasswordRegex = this.checkPasswordRegex.bind(this);
        this.toggleRegisteredModal = this.toggleRegisteredModal.bind(this);
        this.getMyRecoveryValidationErrors = this.getMyRecoveryValidationErrors.bind(this);

        this.toggleRecoveryModal = this.toggleRecoveryModal.bind(this);
        this.recoveryModalComplete = this.recoveryModalComplete.bind(this);
        this.lockoutModalComplete = this.lockoutModalComplete.bind(this);
        this.notApprovedModalComplete = this.notApprovedModalComplete.bind(this);

        this.getLoginButton = this.getLoginButton.bind(this);
        this.getRegisterButton = this.getRegisterButton.bind(this);
        this.getRecoverButton = this.getRecoverButton.bind(this);


    }

    componentDidMount() {
        if (localStorage.getItem("FLO") === "1") {
            localStorage.removeItem("FLO");
            this.setState({ loggedOutModal: true }, () => {
            })
        }
        if (localStorage.getItem("cachedUserName")) {
            this.setState({ userName: localStorage.getItem("cachedUserName"), rememberMe: true });
        }
    }
    cancelRegistration() {
        this.setState({
            registerCompanyName: '',
            registerFirstName: '',
            registerEmailAddress: '',
            registerLastName: '',
            registerPhoneNumber: '',
            registerUserName: '',
            registrtionPassword: '',
            registrtionPasswordConfirmed: '',
            registrationValidationErrors: []
        }, () => {
            this.changeMode(1)
        });
    }
    cancelRecovery() {
        this.setState({ recoveryUserName: '', recoveryValidationErrors: [] }, () => {
            this.changeMode(1);
        })
    }

    toggleRegisteredModal() {
        this.setState({ showRegisteredModal: !this.state.showRegisteredModal });
    }
    togglePWExpiredModal() {
        this.setState({ showPWExpiredModal: !this.state.showPWExpiredModal });
    }
    toggleRecoveryModal() {
        this.setState({ showRecoveryModal: !this.state.showRecoveryModal });
    }

    startLoad() {
        this.setState({ loaded: true });
    }
    finishLoad() {
        this.setState({ loaded: false });
    }

    handleRecoveryUserNameChange(event: any) {
        let v = event.target.value;
        this.setState({ recoveryUserName: v }, () => { this.validateRecovery() });
    }
    handleCompanyNameChange(event: any) {
        let v = event.target.value;
        this.setState({ registerCompanyName: v }, () => { this.validateRegistration() });
    }
    handleEmailAddressChange(event: any) {
        let v = event.target.value;
        this.setState({ registerEmailAddress: v }, () => { this.validateRegistration() });
    }
    handleRegisterUserNameChange(event: any) {
        let v = event.target.value;
        this.setState({ registerUserName: v }, () => { this.validateRegistration() });
    }
    handlePhoneNumberChange(event: any) {
        let v = event.target.value;
        this.setState({ registerPhoneNumber: v }, () => { this.validateRegistration() });
    }
    handleFirstNameChanged(event: any) {

        let v = event.target.value;
        this.setState({ registerFirstName: v }, () => { this.validateRegistration() });
    }
    handleLastNameChanged(event: any) {
        let v = event.target.value;
        this.setState({ registerLastName: v }, () => { this.validateRegistration() });
    }
    handleRegistrationPasswordChanged(event: any) {
        let v = event.target.value;
        this.setState({ registrtionPassword: v }, () => { this.validateRegistration() });
    }
    handleRegistrationPasswordConfirmedChanged(event: any) {
        let v = event.target.value;
        this.setState({ registrtionPasswordConfirmed: v }, () => { this.validateRegistration() });
    }

    handleExpiredPWConfirmChange(event: any) {
        let v = event.target.value;
        this.setState({ expiredPWConfirm: v }, () => { this.validateResetPassword() });
    }
    handleExpiredPWCurrentChange(event: any) {
        let v = event.target.value;
        this.setState({ expiredPWCurrent: v }, () => { this.validateResetPassword() });
    }
    handleExpiredPWNewChange(event: any) {
        let v = event.target.value;
        this.setState({ expiredPWNew: v }, () => { this.validateResetPassword() });
    }

    handleUserNameChange(event: any) {
        let v = event.target.value;
        this.setState({ userName: v }, () => { this.validateLogin() });
    }
    handlePasswordChange(event: any) {
        let v = event.target.value;
        this.setState({ password: v }, () => { this.validateLogin() });
    }
    handleRememberMeChanged(event: any) {
        this.setState({ rememberMe: !this.state.rememberMe }, () => { this.validateLogin() });
    }

    validateLogin(): boolean {
        let retVal: boolean = false;

        let newErrors: any[] = [];

        if (this.state.userName.length === 0) {
            let newErr: any = {};
            newErr.controlName = 'userName';
            newErr.error = MasterContext.translate('validation_login_login_username');
            newErr.severity = 1;
            newErrors.push(newErr);
            retVal = true;
        }

        if (this.state.password.length === 0) {
            let newErr: any = {};
            newErr.controlName = 'password';
            newErr.error = MasterContext.translate('validation_login_login_password');
            newErr.severity = 1;
            newErrors.push(newErr);
            retVal = true;
        }

        this.setState({ loginValidationErrors: newErrors });
        return retVal;
    }
    validateResetPassword(): boolean {
        let retVal: boolean = false;

        let newErrors: any[] = [];

        if (this.state.expiredPWCurrent.length === 0) {
            let newErr: any = {};
            newErr.controlName = 'expCurPW';
            newErr.error = MasterContext.translate('validation_login_reset_cur_password');
            newErr.severity = 1;
            newErrors.push(newErr);
            retVal = true;
        }

        if (this.state.expiredPWNew.length === 0) {
            let newErr: any = {};
            newErr.controlName = 'expNewPW';
            newErr.error = MasterContext.translate('validation_login_reset_new_password');
            newErr.severity = 1;
            newErrors.push(newErr);
            retVal = true;
        }

        if (this.state.expiredPWConfirm.length === 0) {
            let newErr: any = {};
            newErr.controlName = 'expConfPW';
            newErr.error = MasterContext.translate('validation_login_reset_confirmed_password');
            newErr.severity = 1;
            newErrors.push(newErr);
            retVal = true;
        }

        if (this.state.expiredPWNew.length > 0 && this.state.expiredPWConfirm.length > 0) {
            if (this.state.expiredPWNew !== this.state.expiredPWConfirm) {
                let newErr: any = {};
                newErr.controlName = 'expNewPW';
                newErr.error = MasterContext.translate('validation_login_reset_no_match');
                newErr.severity = 1;
                newErrors.push(newErr);

                let newErr2: any = {};
                newErr2.controlName = 'expConfPW';
                newErr2.error = MasterContext.translate('validation_login_reset_no_match');
                newErr2.severity = 1;
                newErrors.push(newErr2);
                retVal = true;
            }
            else {
                if (this.checkPasswordRegex(this.state.expiredPWNew) === true) {

                }
                else {

                    let newErr: any = {};
                    newErr.controlName = 'expNewPW';
                    newErr.error = MasterContext.translate('validation_login_reset_complexity');
                    newErr.severity = 1;
                    newErrors.push(newErr);

                    let newErr2: any = {};
                    newErr2.controlName = 'expConfPW';
                    newErr2.error = MasterContext.translate('validation_login_reset_complexity');
                    newErr2.severity = 1;
                    newErrors.push(newErr2);
                    retVal = true;
                }
            }
        }

        this.setState({ resetPasswordValidationErrors: newErrors });
        return retVal;


    }
    validateRecovery(): boolean {
        let retVal: boolean = false;

        let newErrors: any[] = [];
        if (this.state.recoveryUserName.length === 0) {
            let newErr: any = {};
            newErr.controlName = 'recoveryUserName';
            newErr.error = MasterContext.translate('validation_login_recovery_username');
            newErr.severity = 1;
            newErrors.push(newErr);
            retVal = true;
        }
        this.setState({ recoveryValidationErrors: newErrors });
        return retVal;
    }
    validateRegistration(): boolean {
        let retVal: boolean = false;

        let newErrors: any[] = [];

        //do examination of the this.state.firstName value and see if it has any problems
        if (this.state.registerFirstName.length === 0) {
            let newErr: any = {};
            newErr.controlName = 'firstName';
            newErr.error = MasterContext.translate('validation_login_registration_fn');
            newErr.severity = 1;
            newErrors.push(newErr);
            retVal = true;
        }

        //do examination of the this.state.firstName value and see if it has any problems
        if (this.state.registerLastName.length === 0) {
            let newErr: any = {};
            newErr.controlName = 'lastName';
            newErr.error = MasterContext.translate('validation_login_registration_ln');
            newErr.severity = 1;
            newErrors.push(newErr);
            retVal = true;
        }

        if (this.state.registerCompanyName.length === 0) {
            let newErr: any = {};
            newErr.controlName = 'registerCompanyName';
            newErr.error = MasterContext.translate('validation_login_registration_cn');
            newErr.severity = 1;
            newErrors.push(newErr);
            retVal = true;
        }

        if (this.state.registerUserName.length === 0) {
            let newErr: any = {};
            newErr.controlName = 'registerUserName';
            newErr.error = MasterContext.translate('validation_login_registration_un');
            newErr.severity = 1;
            newErrors.push(newErr);
            retVal = true;
        }
        else {
            if (this.state.registerUserName.length < 5) {
                let newErr: any = {};
                newErr.controlName = 'registerUserName';
                newErr.error = MasterContext.translate('validation_login_registration_un_length');
                newErr.severity = 1;
                newErrors.push(newErr);
                retVal = true;
            }
        }

        if (this.state.registerEmailAddress.length === 0) {
            let newErr: any = {};
            newErr.controlName = 'registerEmail';
            newErr.error = MasterContext.translate('validation_login_registration_email');
            newErr.severity = 1;
            newErrors.push(newErr);
            retVal = true;
        }
        else {
            if (MasterContext.EMAILREGEX.test(this.state.registerEmailAddress) === false) {
                let newErr: any = {};
                newErr.controlName = 'registerEmail';
                newErr.error = MasterContext.translate('validation_login_registration_email_invalid');
                newErr.severity = 1;
                newErrors.push(newErr);
                retVal = true;
            }

        }

        if (this.state.registerPhoneNumber.length === 0) {
            let newErr: any = {};
            newErr.controlName = 'registerPhoneNumber';
            newErr.error = MasterContext.translate('validation_login_registration_phone');
            newErr.severity = 1;
            newErrors.push(newErr);
            retVal = true;
        }
        else {
            if (MasterContext.PHONEREGEX.test(this.state.registerPhoneNumber) === false) {
                let newErr: any = {};
                newErr.controlName = 'registerPhoneNumber';
                newErr.error = MasterContext.translate('validation_login_registration_phone_invalid');
                newErr.severity = 1;
                newErrors.push(newErr);
                retVal = true;
            }

        }



        let failedPass1 = false;

        //do examination of the this.state.password value and see if it has any problems
        if (this.state.registrtionPassword.length === 0) {
            let newErr: any = {};
            newErr.controlName = 'registrationPassword';
            newErr.error = MasterContext.translate('validation_login_registration_password');
            newErr.severity = 1;
            newErrors.push(newErr);
            retVal = true;
            failedPass1 = true;
        }

        //do examination of the this.state.password value and see if it has any problems
        if (this.state.registrtionPasswordConfirmed.length === 0) {
            let newErr: any = {};
            newErr.controlName = 'registrationPasswordConfirmed';
            newErr.error = MasterContext.translate('validation_login_registration_password');
            newErr.severity = 1;
            newErrors.push(newErr);
            retVal = true;
            failedPass1 = true;
        }

        //do examination of the this.state.password value and see if it has any problems
        if (failedPass1 === false) {
            if (this.state.registrtionPassword !== this.state.registrtionPasswordConfirmed) {
                let newErr: any = {};
                newErr.controlName = 'registrationPasswordConfirmed';
                newErr.error = MasterContext.translate('validation_login_registration_password_no_match');
                newErr.severity = 1;
                newErrors.push(newErr);

                let newErr2: any = {};
                newErr2.controlName = 'registrationPassword';
                newErr2.error = MasterContext.translate('validation_login_registration_password_no_match');
                newErr2.severity = 1;
                newErrors.push(newErr2);

                retVal = true;
            }
            else {
                if (this.checkPasswordRegex(this.state.registrtionPassword) === true) {

                }
                else {

                    let newErr: any = {};
                    newErr.controlName = 'registrationPasswordConfirmed';
                    newErr.error = MasterContext.translate('validation_login_registration_password_complexity');
                    newErr.severity = 1;
                    newErrors.push(newErr);

                    let newErr2: any = {};
                    newErr2.controlName = 'registrationPassword';
                    newErr2.error = MasterContext.translate('validation_login_registration_password_complexity');
                    newErr2.severity = 1;
                    newErrors.push(newErr2);
                    retVal = true;


                }
            }
        }


        //todo
        /*
         validate username not already taken
         */


        this.setState({ registrationValidationErrors: newErrors });
        return retVal;


    }

    getMyLoginValidationErrors(control: string): string {

        let retVal: string = '';
        if (this.state.loginValidationErrors) {
            for (let err of this.state.loginValidationErrors) {
                if (err.controlName === control) {
                    retVal = retVal + err.error + '\r\n';
                }
            }
        }
        return retVal;
    }
    getMyPRResetValidationErrors(control: string): string {

        let retVal: string = '';
        if (this.state.resetPasswordValidationErrors) {
            for (let err of this.state.resetPasswordValidationErrors) {
                if (err.controlName === control) {
                    retVal = retVal + err.error + '\r\n';
                }
            }
        }
        return retVal;
    }
    getMyValidationErrors(control: string): string {
        let retVal: string = '';
        if (this.state.registrationValidationErrors) {
            for (let err of this.state.registrationValidationErrors) {
                if (err.controlName === control) {
                    retVal = retVal + err.error + '\r\n';
                }
            }
        }
        return retVal;
    }
    getMyRecoveryValidationErrors(control: string): string {
        let retVal: string = '';
        if (this.state.recoveryValidationErrors) {
            for (let err of this.state.recoveryValidationErrors) {
                if (err.controlName === control) {
                    retVal = retVal + err.error + '\r\n';
                }
            }
        }
        return retVal;
    }

    checkPasswordRegex(suggestedPassword: string): boolean {
        let retVal: boolean = true

        if (MasterContext.PWREGEX.test(suggestedPassword) === false) {
            retVal = false;
        }
        return retVal;
    }
    handleLoginKeypress(e) {
        if (e.key === 'Enter') {
            this.loginMe();
        }
    }

    registrationModalComplete() {
        this.setState({
            registerCompanyName: '',
            registerFirstName: '',
            registerEmailAddress: '',
            registerLastName: '',
            registerPhoneNumber: '',
            registerUserName: '',
            registrtionPassword: '',
            registrtionPasswordConfirmed: ''
        });
        this.toggleRegisteredModal();
        this.changeMode(1);
    }
    recoveryModalComplete() {
        this.setState({
            recoveryUserName: ''
        });
        this.toggleRecoveryModal();
        this.changeMode(1);
    }
    lockoutModalComplete() {
        this.setState({ showAccountLockoutModal: false })
        this.changeMode(1);
    }
    notApprovedModalComplete() {
        this.setState({ showRegisteredButNotApproved: false })
        this.changeMode(1);
    }

    miscErrorModalComplete() {
        this.setState({ showMiscErrorModal: false })
    }

    showMessageModalComplete() {
        this.setState({showMessageModal: false})
    }
    floErrorModalComplete() {
        this.setState({ loggedOutModal: false })
    }
    changeMode(modeID: number): number {

        this.setState({ mode: modeID });
        return 0;
    }
    getPWComplexityData() {
        return MasterContext.PWREGEXINFO;
    }
    saveNewPassword() {
        if (this.validateResetPassword() === false || this.state.showPWExpiredErr) {


            this.startLoad();

            let LS: LoginService = new LoginService();
            let RT: types.LoginToken = new types.LoginToken();
            RT.UserName = this.state.userName.trim();
            RT.Password = this.state.expiredPWCurrent.trim();
            RT.NewPassword = this.state.expiredPWConfirm.trim();
            ;
            this.startLoad();

            LS.changePassword(RT,
                (res) => {
                    if (res === true) {
                        this.finishLoad();
                    }
                    if (res.ErrorMessage) {
                        this.setState({ showPWExpiredModal: false }, () => {
                            this.setState({ showMessageModal: true, messageBody: res.ErrorMessage, messageHeader: 'Could not update', showPWExpiredErr: false, password: '', resetPasswordValidationErrors: [], expiredPWCurrent: '', expiredPWNew: '', expiredPWConfirm: '' });
                        });
                    } else {
                        this.setState({ showPWExpiredModal: false }, () => {
                            this.setState({ showMessageModal: true, messageBody: 'Successfully changed password', messageHeader: 'Success',showPWExpiredErr: false, password: '', resetPasswordValidationErrors: [], expiredPWCurrent: '', expiredPWNew: '', expiredPWConfirm: '' });
                        });
                    }
                    
                },
                (res) => {

                }
            )

            //make server call to reset password and on return do this
            this.finishLoad();
        }
    }
    //translate(phrase: string) {

    //    let retVal = '';
    //    retVal = TL.translate(phrase, MasterContext.selectedLanguage, translationSource);

    //    return retVal;
    //}

    getRecoveryReponseMessage(): string {
        let retVal = "";
        if (this.state.recoveryResponse === true) {
            retVal = MasterContext.translate("login_recovery_modal_body");
        } else {
            retVal = MasterContext.translate("login_recovery_modal_body_failed");
        }
        return retVal;
    }

    getRegistrationReponseMessage(): string {
        let retVal = "";
        if (this.state.registrationResponse === true) {
            retVal = MasterContext.translate("login_registration_success_modal_body")
        } else {
            retVal = MasterContext.translate("login_registration_failed_user_exists_modal_body");
        }
        return retVal;
    }

    loginMe() {
        let CM: CacheManager = new CacheManager();
        CM.clearLocalStorageCache();
        if (this.validateLogin() === false) {
            this.setState({ isLoggingIn: true });
            let LS: LoginService = new LoginService();
            let LT: types.LoginToken = new types.LoginToken();
            LT.ClientVersion = MasterContext.ClientVersion;
            LT.UserName = this.state.userName.trim();
            LT.Password = this.state.password.trim();
            this.startLoad();
            LS.login(LT,
                (resp) => {
                    if (resp.Status === "success") {

                        let navigationPermissions = [];

                        if (resp !== null && resp.TokenTimeout !== null) {
                            MasterContext.ClientTimeoutValue = +resp.TokenTimeout;
                            localStorage.setItem("clientTimeoutValue", resp.TokenTimeout);
                        }

                        if (resp.PasswordResetRequired === true || resp.FailedLoginsHit === true || resp.RegisteredButNotApproved === true || resp.IsMiscError === true) {
                            if (resp.PasswordResetRequired === true) {
                                this.setState({ showPWExpiredModal: true, isLoggingIn: false }, () => {
                                    this.finishLoad();
                                });
                            }

                            if (resp.FailedLoginsHit === true) {
                                this.setState({ showAccountLockoutModal: true, isLoggingIn: false }, () => {
                                    this.finishLoad();
                                });
                            }

                            if (resp.RegisteredButNotApproved === true) {
                                this.setState({ showRegisteredButNotApproved: true, isLoggingIn: false }, () => {
                                    this.finishLoad();
                                });
                            }


                            if (resp.IsMiscError === true) {
                                this.setState({ showMiscErrorModal: true, miscError: resp.MiscError, isLoggingIn: false }, () => {
                                    this.finishLoad();
                                });
                            }

                            localStorage.setItem("userToken", resp.Token);

                        }
                        else {
                            for (let navigationPermission of resp.NavigationPermissions) {
                                navigationPermissions.push(navigationPermission.PermissionName);
                            }
                            if (this.state.rememberMe === true) {
                                localStorage.setItem("cachedUserName", this.state.userName);
                            }
                            else {
                                localStorage.removeItem("cachedUserName");
                            }
                            localStorage.setItem("cachedUser", this.state.userName);
                            localStorage.setItem("navigationPermissions", JSON.stringify(navigationPermissions));
                            MasterContext.UserData = { userName: this.state.userName, navigationPermissions: navigationPermissions };
                            localStorage.setItem("userToken", resp.Token);
                            LocalPermissionsService.savePermissions(resp.UserUiPermissions);
                            this.props.handleLogin();
                            this.setState({ isLoggingIn: false });
                            this.finishLoad();
                        }
                    }
                    else {
                        this.setState({ loginError: 1, isLoggingIn: false });
                        this.finishLoad();
                    }
                },
                (resp) => {

                    this.setState({ loginError: 1, isLoggingIn: false });
                    this.finishLoad();
                }
            )
        }
    }
    registerMe() {
        if (this.validateRegistration() === false) {
            this.setState({ isRegistering: true });
            let LS: LoginService = new LoginService();
            let RT: types.RegistrationToken = new types.RegistrationToken();
            RT.FirstName = this.state.registerFirstName.trim();
            RT.LastName = this.state.registerLastName.trim();
            RT.CompanyName = this.state.registerCompanyName.trim();
            RT.UserName = this.state.registerUserName.trim();
            RT.EmailAddress = this.state.registerEmailAddress.trim();
            RT.Password = this.state.registrtionPassword.trim();
            RT.PhoneNumber = this.state.registerPhoneNumber.trim();
            this.startLoad();

            LS.register(RT,
                (res) => {
                    if (res.RegistrationResultStatus === 1) {
                        this.setState({ registrationResponse: true, isRegistering: false }, () => {
                            this.toggleRegisteredModal();
                            this.finishLoad();
                        });

                    }
                    if (res.RegistrationResultStatus === 2) {
                        this.setState({ registrationResponse: false, isRegistering: false }, () => {
                            this.toggleRegisteredModal();
                            this.finishLoad();
                        });
                    }
                },
                (res) => {
                    this.setState({ isRegistering: false });
                }

            )

        }
    }
    recoverMe() {
        if (this.validateRecovery() === false) {
            this.setState({ isRecovering: true });
            let LS: LoginService = new LoginService();
            let RT: types.RegistrationToken = new types.RegistrationToken();


            RT.UserName = this.state.recoveryUserName.trim();

            this.startLoad();

            LS.recoverPassword(RT,
                (res) => {
                    if (res === true) {
                        this.setState({ recoveryResponse: true, isRecovering: false }, () => {
                            this.toggleRecoveryModal();
                            this.finishLoad();
                            this.changeMode(1);
                        });

                    }
                    else {
                        this.setState({ recoveryResponse: false, isRecovering: false }, () => {
                            this.toggleRecoveryModal();
                            this.finishLoad();
                        });

                    }
                },
                (res) => {
                    this.setState({ isRecovering: false });
                }

            )

        }
    }

    getLoginButton() {
        let retVal: any = <i />;
        if (this.state.isLoggingIn === false) {
            retVal = <input type="submit" name="submit" onClick={this.loginMe} className="btn loginColorsButton btn-md" value="Login" />
        }
        return retVal
    }

    getRegisterButton() {
        let retVal: any = <i />;
        if (this.state.isRegistering === false) {
            retVal = <div>
                <input type="submit" name="submit" onClick={this.registerMe} className="btn loginColorsButton btn-md" value={MasterContext.translate('register_user_register_button')} /> &nbsp;
                <button onClick={() => this.cancelRegistration()} className="btn btn-danger btn-md" value="Cancel">{MasterContext.translate('register_user_cancel_button')}</button>
            </div>
        }
        return retVal
    }

    getRecoverButton() {
        let retVal: any = <i />;
        if (this.state.isRecovering === false) {
            retVal = <div>
                <input type="submit" name="submit" onClick={this.recoverMe} className="btn loginColorsButton btn-md" value={MasterContext.translate("login_recover_button")} /> &nbsp;
                <button onClick={() => this.cancelRecovery()} className="btn btn-danger btn-md" value="Cancel">{MasterContext.translate("login_recover_button_cancel")}</button>
            </div>
        }
        return retVal
    }

    render() {
        let logoStyle = {
            background: 'no-repeat 10px 50%',
            width: '300px',
            backgroundSize: '300px',
            cursor: 'pointer',
            marginTop: '10px'
        };
        let fakeLink = { cursor: 'pointer' }
        let errorClass = { color: "#ff0000" };
        return (
            <React.Fragment>
                <Spinner
                    spinnerActive={this.state.loaded}
                    heightSet="99vh"
                    paddingSet="10%"
                    spinnerText={this.state.spinnerMessage}
                />

                <Modal zIndex={3002} isOpen={this.state.loggedOutModal}>
                    <ModalHeader>{MasterContext.translate("login_FLO_error_title")}</ModalHeader>
                    <ModalBody>
                        {MasterContext.translate("login_FLO_error")}
                    </ModalBody>
                    <ModalFooter>
                        <button className="btn btn-sm btn-outline-info" color="primary" onClick={() => this.floErrorModalComplete()}>{MasterContext.translate("login_FLO_error_OK")}</button>{' '}
                    </ModalFooter>
                </Modal>

                <Modal zIndex={3002} isOpen={this.state.showMessageModal}>
                    <ModalHeader>{this.state.messageHeader}</ModalHeader>
                    <ModalBody>
                        {this.state.messageBody}
                    </ModalBody>
                    <ModalFooter>
                        <button className="btn btn-sm btn-outline-info" color="primary" onClick={() => this.showMessageModalComplete()}>{MasterContext.translate("login_not_approved_modal_button")}</button>{' '}
                    </ModalFooter>
                </Modal>

                <Modal zIndex={3002} isOpen={this.state.showMiscErrorModal}>
                    <ModalHeader>{MasterContext.translate("login_misc_error_modal_header")}</ModalHeader>
                    <ModalBody>
                        {MasterContext.translate(this.state.miscError)}
                    </ModalBody>
                    <ModalFooter>
                        <button className="btn btn-sm btn-outline-info" color="primary" onClick={() => this.miscErrorModalComplete()}>{MasterContext.translate("login_not_approved_modal_button")}</button>{' '}
                    </ModalFooter>
                </Modal>

                <Modal zIndex={3002} isOpen={this.state.showRegisteredButNotApproved}>
                    <ModalHeader>{MasterContext.translate("login_not_approved_modal_header")}</ModalHeader>
                    <ModalBody>
                        {MasterContext.translate("login_not_approved_modal_body")}
                    </ModalBody>
                    <ModalFooter>
                        <button className="btn btn-sm btn-outline-info" color="primary" onClick={() => this.notApprovedModalComplete()}>{MasterContext.translate("login_not_approved_modal_button")}</button>{' '}
                    </ModalFooter>
                </Modal>

                <Modal zIndex={3002} isOpen={this.state.showAccountLockoutModal}>
                    <ModalHeader>{MasterContext.translate("login_lockout_modal_header")}</ModalHeader>
                    <ModalBody>
                        {MasterContext.translate("login_lockout_modal_body")}
                    </ModalBody>
                    <ModalFooter>
                        <button className="btn btn-sm btn-outline-info" color="primary" onClick={() => this.lockoutModalComplete()}>{MasterContext.translate("login_lockout_modal_button")}</button>{' '}
                    </ModalFooter>
                </Modal>

                <Modal zIndex={3002} isOpen={this.state.showRegisteredModal}>
                    <ModalHeader>{MasterContext.translate("login_registration_success_modal_header")}</ModalHeader>
                    <ModalBody>
                        {this.getRegistrationReponseMessage()

                        }
                    </ModalBody>
                    <ModalFooter>
                        <button className="btn btn-sm btn-outline-info" color="primary" onClick={() => this.registrationModalComplete()}>{MasterContext.translate("login_registration_success_modal_ok")}</button>{' '}

                    </ModalFooter>
                </Modal>

                <Modal zIndex={3002} isOpen={this.state.showRecoveryModal}>
                    <ModalHeader>{MasterContext.translate("login_recovery_modal_header")}</ModalHeader>
                    <ModalBody>
                        {this.getRecoveryReponseMessage()}

                    </ModalBody>
                    <ModalFooter>
                        <button className="btn btn-sm btn-outline-info" color="primary" onClick={() => this.recoveryModalComplete()}>{MasterContext.translate("login_recovery_modal_button")}</button>{' '}

                    </ModalFooter>
                </Modal>

                <Modal zIndex={3002} isOpen={this.state.showPWExpiredModal}>
                    <ModalHeader>{MasterContext.translate("login_password_expired_modal_header")}</ModalHeader>
                    <ModalBody>
                        {
                            this.state.showPWExpiredErr
                            ?
                            (<div className="row">
                                <div className="col-md-12">
                                    {this.state.resetPasswordValidationErrors.map(i => (<div>{i}</div>) )}
                                </div>
                            </div>)
                            :
                            (<div className="row">
                                <div className="col-md-12">
                                    {MasterContext.translate("login_password_expired_modal_body")}
                                </div>
                            </div>)
                        }
                        <div className="row">
                            <div className="col-md-12">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="form-group">

                                            <div className="row">
                                                <div className="col-md-4">
                                                    <label className="loginColors">{MasterContext.translate('login_password_expired_modal_old_pw')}:</label>
                                                </div>
                                                <div className="col-md-8">
                                                    <input placeholder={MasterContext.translate("reset_password_placeholder_old_password")} type="password" name="expCurPW" id="expCurPW" value={this.state.expiredPWCurrent} onChange={this.handleExpiredPWCurrentChange} className="form-control" />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-4">
                                                </div>
                                                <div className="col-md-8">
                                                    <span className="pull-right" style={errorClass}>{this.getMyPRResetValidationErrors('expCurPW')}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <label className="loginColors">{MasterContext.translate('login_password_expired_modal_new_pw')}:</label>
                                                </div>
                                                <div className="col-md-8">
                                                    <input placeholder={MasterContext.translate("reset_password_placeholder_new_password")} type="password" name="expNewPW" id="expNewPW" value={this.state.expiredPWNew} onChange={this.handleExpiredPWNewChange} className="form-control" />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-4">
                                                </div>
                                                <div className="col-md-8">
                                                    <span className="pull-right" style={errorClass}>{this.getMyPRResetValidationErrors('expNewPW')}</span>
                                                </div>
                                            </div>




                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <label className="loginColors">{MasterContext.translate('login_password_expired_modal_confirm_pw')}:</label>
                                                </div>
                                                <div className="col-md-8">
                                                    <input placeholder={MasterContext.translate("reset_password_placeholder_confirm_password")} type="password" name="expConfPW" id="expConfPW" value={this.state.expiredPWConfirm} onChange={this.handleExpiredPWConfirmChange} className="form-control" />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-4">
                                                </div>
                                                <div className="col-md-8">
                                                    <span className="pull-right" style={errorClass}>{this.getMyPRResetValidationErrors('expConfPW')}</span>
                                                </div>
                                            </div>




                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="alert alert-secondary" style={{ fontSize: "12px", fontStyle: "italic", paddingLeft: "20px", color: "#0b243c" }}>
                                            {
                                                this.getPWComplexityData()
                                            }
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>


                    </ModalBody>
                    <ModalFooter>
                        <button className="btn btn-sm btn-outline-info" color="primary" onClick={() => this.saveNewPassword()}>{MasterContext.translate("login_password_expired_modal_footer_OK")}</button>{' '}

                    </ModalFooter>
                </Modal>

                <div className="loginBody">
                    <div className="row" style={{ width: "100%", padding: "0 0 45px 0" }}>
                        <div className="col-md-12 text-center">
                            <img alt='' src="https://cleardemand.com/wp-content/uploads/2022/08/loginLogo.png" /> <br />
                        </div>
                    </div>
                    <div className="row" style={{ width: "100%" }}>
                        <div className="col-md-4"></div>
                        <div className="col-md-4">
                            {this.state.mode === 1 ?
                                <div className='container'>

                                    {/*   <h3 className="text-center text-info">{MasterContext.translate("login_title")}</h3>*/}
                                    {this.state.loginError === 0 ?
                                        <div className="row">

                                            {/*<div className="col-md-12">*/}
                                            {/*    <div className="alert alert-secondary" role="alert">*/}
                                            {/*        {MasterContext.translate("login_header")} <br />*/}
                                            {/*    </div>*/}

                                            {/*</div>*/}
                                        </div> : <i></i>}
                                    {this.state.loginError === 1 ?
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="alert alert-danger" role="alert">
                                                    {MasterContext.translate("login_error")}

                                                </div>

                                            </div>
                                        </div> :
                                        <i></i>
                                    }


                                    <div className="form-group">
                                        <div className="row">
                                            <div className="col-md-3">
                                                <label className="loginColors">{MasterContext.translate('login_username')}:</label>
                                            </div>
                                            <div className="col-md-9">
                                                <input placeholder={MasterContext.translate("login_placeholder_username")} type="text" name="username" id="username" value={this.state.userName} onKeyPress={this.handleLoginKeypress} onChange={this.handleUserNameChange} className="form-control" />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-3">
                                            </div>
                                            <div className="col-md-9">
                                                <span className="pull-right" style={errorClass}>{this.getMyLoginValidationErrors('userName')}</span>
                                            </div>
                                        </div>


                                    </div>
                                    <div className="form-group">
                                        <div className="row">
                                            <div className="col-md-3">
                                                <label className="loginColors">{MasterContext.translate('login_password')}:</label>
                                            </div>
                                            <div className="col-md-9">
                                                <input placeholder={MasterContext.translate("login_placeholder_password")} type="password" name="password" id="password" value={this.state.password} onKeyPress={this.handleLoginKeypress} onChange={this.handlePasswordChange} className="form-control" />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-3">
                                            </div>
                                            <div className="col-md-9">
                                                <span className="pull-right" style={errorClass}>{this.getMyLoginValidationErrors('password')}</span>
                                            </div>
                                        </div>



                                    </div>
                                    <div className="form-group">
                                        <div className="row">
                                            <div className="col-md-4">
                                                <label className="loginColors"><span>{MasterContext.translate('login_remember_me')}</span>&nbsp;&nbsp;<span><input id="rememberMe" name="rememberMe" onChange={this.handleRememberMeChanged} checked={this.state.rememberMe} type="checkbox" /></span></label>
                                            </div>
                                            <div className="col-md-8">
                                                <span className="loginButton" > {this.getLoginButton()}</span>
                                            </div>
                                        </div>

                                    </div>

                                    <div id="register-link" className="text-right">

                                        {this.state.showRegisterButton
                                            &&
                                            < span style={fakeLink} onClick={() => this.changeMode(2)}>{MasterContext.translate("login_register")} | </span>
                                        }
                                        <span style={fakeLink} onClick={() => this.changeMode(3)}>{MasterContext.translate("login_recover_password")}</span>

                                    </div>
                                    {this.state.showRegisterButton != true &&
                                        <div className="row" style={{ paddingTop: "50px" }}>
                                            <div className="col-md-12">
                                                <span className="pull-right">Don't have an account? Contact your Administrator to create one.</span>
                                            </div>
                                        </div>
                                    }

                                </div> :
                                <br />
                            }

                            {this.state.mode === 2 ?
                                <div className='container'>

                                    <h3 className="text-center text-info">{MasterContext.translate("login_registration_title")}</h3>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="alert alert-secondary" role="alert">
                                                {MasterContext.translate('register_user_header')}<br />
                                            </div>

                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="row">
                                            <div className="col-md-3">
                                                <label className="loginColors">{MasterContext.translate('register_user_label_first_name')}</label>
                                            </div>
                                            <div className="col-md-9">
                                                <input placeholder={MasterContext.translate("registration_placeholder_first_name")} type="text" id="firstName" value={this.state.registerFirstName} onChange={this.handleFirstNameChanged} className="form-control" />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-3">
                                            </div>
                                            <div className="col-md-9">
                                                <span className="pull-right" style={errorClass}>{this.getMyValidationErrors('firstName')}</span>
                                            </div>
                                        </div>



                                    </div>
                                    <div className="form-group">
                                        <div className="row">
                                            <div className="col-md-3">
                                                <label className="loginColors">{MasterContext.translate('register_user_label_last_name')}</label>
                                            </div>
                                            <div className="col-md-9">
                                                <input placeholder={MasterContext.translate("registration_placeholder_last_name")} type="text" id="lastName" value={this.state.registerLastName} onChange={this.handleLastNameChanged} className="form-control" />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-3">
                                            </div>
                                            <div className="col-md-9">
                                                <span className="pull-right" style={errorClass}>{this.getMyValidationErrors('lastName')}</span>
                                            </div>
                                        </div>




                                    </div>
                                    <div className="form-group">
                                        <div className="row">
                                            <div className="col-md-3">
                                                <label className="loginColors">{MasterContext.translate('register_user_label_company')}</label>
                                            </div>
                                            <div className="col-md-9">
                                                <input placeholder={MasterContext.translate("registration_placeholder_company_name")} type="text" id="registerCompanyName" value={this.state.registerCompanyName} onChange={this.handleCompanyNameChange} className="form-control" />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-3">
                                            </div>
                                            <div className="col-md-9">
                                                <span className="pull-right" style={errorClass}>{this.getMyValidationErrors('registerCompanyName')}</span>
                                            </div>
                                        </div>



                                    </div>
                                    <div className="form-group">
                                        <div className="row">
                                            <div className="col-md-3">
                                                <label className="loginColors">{MasterContext.translate('register_user_label_username')}</label>
                                            </div>
                                            <div className="col-md-9">
                                                <input placeholder={MasterContext.translate("registration_placeholder_user_name")} type="text" id="registerUserName" value={this.state.registerUserName} onChange={this.handleRegisterUserNameChange} className="form-control" />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-3">
                                            </div>
                                            <div className="col-md-9">
                                                <span className="pull-right" style={errorClass}>{this.getMyValidationErrors('registerUserName')}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="row">
                                            <div className="col-md-3">
                                                <label className="loginColors">{MasterContext.translate('register_user_label_email')}</label>
                                            </div>
                                            <div className="col-md-9">
                                                <input placeholder={MasterContext.translate("registration_placeholder_email_address")} type="text" id="registerEmail" value={this.state.registerEmailAddress} onChange={this.handleEmailAddressChange} className="form-control" />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-3">
                                            </div>
                                            <div className="col-md-9">
                                                <span className="pull-right" style={errorClass}>{this.getMyValidationErrors('registerEmail')}</span>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="form-group">
                                        <div className="row">
                                            <div className="col-md-3">
                                                <label className="loginColors">{MasterContext.translate('register_user_label_phone_number')}</label>
                                            </div>
                                            <div className="col-md-9">
                                                <input placeholder={MasterContext.translate("registration_placeholder_phone_number")} type="text" id="registerPhoneNumber" value={this.state.registerPhoneNumber} onChange={this.handlePhoneNumberChange} className="form-control" />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-3">
                                            </div>
                                            <div className="col-md-9">
                                                <span className="pull-right" style={errorClass}>{this.getMyValidationErrors('registerPhoneNumber')}</span>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="alert alert-secondary" style={{ fontSize: "12px", fontStyle: "italic", paddingLeft: "20px", color: "#0b243c" }}>
                                                {
                                                    this.getPWComplexityData()
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="row">
                                            <div className="col-md-3">
                                                <label className="loginColors">{MasterContext.translate('register_user_label_password')}</label>
                                            </div>
                                            <div className="col-md-9">
                                                <input placeholder={MasterContext.translate("registration_placeholder_password")} type="password" id="registrtionPassword" value={this.state.registrtionPassword} onChange={this.handleRegistrationPasswordChanged} className="form-control" />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-3">
                                            </div>
                                            <div className="col-md-9">
                                                <span className="pull-right" style={errorClass}>{this.getMyValidationErrors('registrationPassword')}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="row">
                                            <div className="col-md-3">
                                                <label className="loginColors">{MasterContext.translate('register_user_label_password_confirm')}</label>
                                            </div>
                                            <div className="col-md-9">
                                                <input placeholder={MasterContext.translate("registration_placeholder_password")} type="password" id="registrtionPasswordConfirmed" value={this.state.registrtionPasswordConfirmed} onChange={this.handleRegistrationPasswordConfirmedChanged} className="form-control" />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-3">
                                            </div>
                                            <div className="col-md-9">
                                                <span className="pull-right" style={errorClass}>{this.getMyValidationErrors('registrationPasswordConfirmed')}</span>
                                            </div>
                                        </div>



                                    </div>

                                    <div className="form-group">
                                        {this.getRegisterButton()}
                                    </div>


                                </div> :
                                <br />
                            }

                            {this.state.mode === 3 ?
                                <div className='container'>

                                    <h3 className="text-center text-info">{MasterContext.translate("login_recover_password_title")}</h3>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="alert alert-secondary" role="alert">
                                                {MasterContext.translate("login_recover_password_body")}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="row">
                                            <div className="col-md-3">
                                                <label className="loginColors"> {MasterContext.translate("login_recover_password_username")}</label><br />
                                            </div>
                                            <div className="col-md-9">
                                                <input type="text" placeholder={MasterContext.translate("login_placeholder_username")} name="username" id="recoveryUserName" value={this.state.recoveryUserName} onChange={this.handleRecoveryUserNameChange} className="form-control" />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-3">
                                            </div>
                                            <div className="col-md-9">
                                                <span className="pull-right" style={errorClass}>{this.getMyRecoveryValidationErrors('recoveryUserName')}</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="form-group">
                                        {this.getRecoverButton()}
                                    </div>
                                </div> :
                                <br />
                            }
                        </div>
                        <div className="col-md-4"></div>
                    </div>
                </div>




            </React.Fragment>
        );

    }

}