import * as React from 'react';
import MasterContext from "../Misc/MasterContext"
import {  Redirect } from 'react-router';
import LocalPermissionsService from '../../../Services/local-permissions-service';
import LogoutService from '../../../Services/Logout/Logout.service';

export interface IProps {
    handleLogout: Function;
}
export interface IState { }

export default class LogOut extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        localStorage.removeItem("cachedUser");
        localStorage.removeItem("navigationPermissions");
        localStorage.removeItem("userToken");
        localStorage.removeItem("clientTimeoutValue");
        LocalPermissionsService.clearPermissions();                         
        MasterContext.UserData = { userName: '', navigationPermissions: [] };
        this.props.handleLogout();
    }

    componentDidMount() {
        let LS = new LogoutService();
        LS.logout(() => {console.log("Logout success")}, () => {console.log("Logout failed")});
    }
    
    render() {
        return (
            <Redirect to='/' />
        );
    }
}