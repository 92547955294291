import * as React from 'react'
import PermissionsService from "../../../Services/Permissions/Service/Permissions.service";
import TableauService from "../../../Services/Tableau/Service/Tableau.service";
import RulesService from "../../../Services/RulesExplorer/Services/RulesService.service";
import ScenarioService from "../../../Services/Scenario/Service/ScenarioService.service";
import CommonService from "../../../Services/Common/Service/Common.service";
import LOVService from "../../../Services/LOV/Service/LOV.service";
import CDI_ModuleFeatureService from "../../../Services/Common/Service/CDIModuleFeature.service";
import { urlConfig } from "../../../Services/Misc/dataLayer.config";
import CacheManager from "../../../Services/CacheManager/CacheManager";
import ProductGroupsService from '../../../Services/ProductGroups/Service/ProductGroups.service';
import LockDaysService from '../../../Services/LockDays/Service/LockDays.service';
import BuydownService from '../../../Services/Buydown/Service/Buydown.service';
import StateMinimumService from '../../../Services/StateMinimum/Service/StateMinimum.service';
import HomeService from '../../../Services/Home/Service/home.service';
import AdminAuditService from '../../../Services/AuditObject/Service/AdminAudit.service';
import BuydownDatasource from '../../../Services/Buydown/Service/Buydown.datasource';
import StateMinimumDatasource from '../../../Services/StateMinimum/Service/StateMinimum.datasource';
import MessageManagerService from '../../../Services/MessageManager/Service/MessageManager.service'
import AppPropertyService from '../../../Services/Common/Service/AppProperty.service';
import GridControlService from '../../../Services/Grid/Service/GridControl.service';

import Translator from '../Translator/Translator';
import translationSource from "../../../Services/translations/en.json";

import HierarchyService from '../../../Services/Hierarchy/Service/Hierarchy.service';
import ProductHierarchyDatasource from '../../../Services/Hierarchy/Service/Hierarchy.datasource';
import LocationHierarchyDatasource from '../../../Services/Hierarchy/Service/Hierarchy.datasource';

import JobControlDatasource from '../../../Services/JobControl/Service/JobControl.datasource';
import JobControlService from '../../../Services/JobControl/Service/JobControl.service';
import DownloadMgrService from '../../../Services/JobControl/Service/DownloadMgr.service';

let TL = new Translator(
    {
        translationKey: '',
        translationLanguage: '',
        translationLibrary: '',
        translationClass: ''
    }
);

export default class MasterContext {
    static AppPropertyService = new AppPropertyService();
    static MessageManagerService = new MessageManagerService();
    static AdminAuditService = new AdminAuditService();
    static HomeService = new HomeService();
    static RulesService = new RulesService();
    static ScenarioService = new ScenarioService();
    static PermissionsService = new PermissionsService();
    static ProductGroupsService = new ProductGroupsService();
    static LockDaysService = new LockDaysService();
    static BuydownService = new BuydownService();
    static StateMinimumService = new StateMinimumService();
    static TableauService = new TableauService();
    static CommonService = new CommonService();
    static CacheMan = new CacheManager();
    static LOVService = new LOVService();
    static ModuleFeatureService = new CDI_ModuleFeatureService();
    static GridControlService = new GridControlService();
    static HierarchyService = new HierarchyService();
    static JobControlService = new JobControlService();
    static DownloadMgrService = new DownloadMgrService();

    static ProductHierarchyDatasource = new ProductHierarchyDatasource();
    static LocationHierarchyDatasource = new LocationHierarchyDatasource();
    static BuydownDatasource = new BuydownDatasource();
    static StateMinimumDatasource = new StateMinimumDatasource();
    static JobControlDatasource = new JobControlDatasource();

    static translate(phrase: string) {

        let retVal = '';
        retVal = TL.translate(phrase, MasterContext.selectedLanguage, translationSource);

        return retVal;
    }

    static QueryModule(moduleName: string): boolean {

        let modules = JSON.parse(localStorage.getItem('modules'));
        let retVal = false;

        for (let mod of modules) {
            if (mod.ModuleName.toLowerCase() === moduleName.toLowerCase()) {
                retVal = mod.Active;
                break;
            }
        }

        return retVal;
    }

    static QueryModuleFeature(moduleName: string, featureName: string): boolean {

        let modules = JSON.parse(localStorage.getItem('modules'));
        let retVal: boolean = false;

        for (let mod of modules) {
            if ((mod.ModuleName.toLowerCase() === moduleName.toLowerCase()) && mod.Active) {
                for (let feature of mod.Features) {
                    if (feature.FeatureName.toLowerCase() === featureName.toLowerCase()) {
                        retVal = feature.IsActive;
                        break;
                    }
                }
                break;
            }
        }

        return retVal;
    }
    static getModules() {
        return MasterContext.AllModules; 
    }
    static AllModules: any;
    
    static hasNavigationPermission(permissionName: string): boolean {
        let hasPermission = false;

        for (let navigationPermission of MasterContext.UserData.navigationPermissions) {
            if (navigationPermission.toLowerCase() === permissionName.toLowerCase()) {
                hasPermission = true;
                break;
            }
        }

        return hasPermission;
    }

    static hasNavigationParentPermission(parentPermissionName: string): boolean {
        let hasPermission = false;

        for (let navigationPermission of MasterContext.UserData.navigationPermissions) {
            if (navigationPermission.toLowerCase().startsWith(parentPermissionName.toLowerCase())) {
                hasPermission = true;
                break;
            }
        }

        return hasPermission;
    }

    //static needsHardReload() : boolean{
    //    let retVal: boolean = false;
    //    if (MasterContext.DingCount >= 10)
    //    {
    //        retVal = true;
    //        MasterContext.DingCount = 0;
    //    }
    //    return retVal;
    //}
    static DingCount = 0;
    //static Ding() {
    //    MasterContext.DingCount++;
    //}

    static selectedLanguage = MasterContext.getLanguage();
    static pageSize = MasterContext.getPageSize();
    static ClientVersion = "1.0.0";
    // Deafult is set to 30 minutes - This gets dynamically updated when the user logs in
    static ClientTimeoutValue = 1800000;

    static PWREGEX = new RegExp(/(?=.*[a-z])(?=.*[A-Z])(?=.*[\d!@#$%^&*()]{1,})(?=.*[^`~\-_=+[{\]};:'"\|,<.>\/?\/*-+.])([a-zA-Z\d!@#$%^&*()]){8,}/);
    static EMAILREGEX = new RegExp(/^([a-zA-Z0-9_\-.]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,5})$/)
    static PHONEREGEX = new RegExp(/^[2-9]\d{2}-\d{3}-\d{4}$/)
    static UserData = { userName: '', navigationPermissions: [] };

    //static DOTNETURL = "http://cleardemand/";
    static DOTNETURL = MasterContext.getDOTNET()
    static LEGACYURL = urlConfig.apiBase + "/WebUI/dist/"; 
    static REACTURL = urlConfig.apiBase;

    static TableauReportSREF = "";

    static CreateProductGroupGID = "";

    static PWREGEXINFO = <div>All new passwords must contain all of the items below:<br /> &nbsp; &nbsp; &nbsp; &nbsp; At least 8 total character long <br /> &nbsp; &nbsp; &nbsp; &nbsp; At least 1 Upper Case letter <br /> &nbsp; &nbsp; &nbsp; &nbsp; At least 1 Lower Case letter <br /> &nbsp; &nbsp; &nbsp; &nbsp; At least 1 Number <br />&nbsp;&nbsp;&nbsp;&nbsp;or <br />&nbsp; &nbsp; &nbsp; &nbsp; At least 1 of the following Special Characters: !@#$%^&*( )</div>;
    static getDOTNET() {
        if (document.location.hostname === 'localhost') {
            return "http://localhost:44363/";
        }
        else {
            return "/";
        }
    }
    static getLanguage()
    {
        let retVal: string = 'en';
            if (localStorage.getItem("selectedLanguage") == null) {

                localStorage.setItem("selectedLanguage", "en")
            }
            else {
                retVal = localStorage.getItem("selectedLanguage");
            }
        return retVal;
    }

    static getPageSize() {
        let retVal: string = '25';

        if (localStorage.getItem("pageSize") == null) {

            localStorage.setItem("pageSize", "25")
        }
        else {
            retVal = localStorage.getItem("pageSize");
        }

        return retVal;

    }
}