import * as React from "react";

// export default class JobControl extends React.Component<IProps, IState> {
export interface IProps {
    api: any;
}

export interface IState {
    count: number;
}

export default class StatusBarComponent extends React.Component<IProps, IState> {
    // Constructor
    constructor(props: IProps) {
        super(props);

        this.state = {
            count: 10,
        };

        setTimeout(() => {
            this.setState({
//                count: this.props.api.getModel().rowsToDisplay.length,
                count: this.props.api.getModel().gridOptionsWrapper.gridOptions.rowData, 
            });
        });
    }

    render() {
        return (
            <div className="ag-status-name-value">
                <span className="component">Total Row Count&nbsp;</span>
                <span className="ag-status-name-value-value">{this.state.count.toLocaleString()}</span>
            </div>
        );
    }
}