import * as React from 'react';
import './Explorer.css';

export interface IProps {
    gridData: any[];
    gridRowSelected: Function;
    gridRowDeleted: Function;
}

export interface IState {

}

export default class CDIExplorerGrid extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {

        };
        this.clickRow = this.clickRow.bind(this);
    }
    clickRow(item: any) {
        this.props.gridRowSelected(item);
    }
    renderItems() {
        let retVal: any[] = [];
        for (let item of this.props.gridData) {
            retVal.push(<tr>
                <td><span style={{cursor:"pointer"}} onClick={() => { this.clickRow(item) }}>{item.rowID}</span></td>
                <td>{item.val1}</td>
                <td>{item.val2}</td>
            </tr>)
        }
        return retVal;
    }
    render() {

        return (
            <React.Fragment>
                <table>
                    {this.renderItems()}
                    </table>
                 
            </React.Fragment>
        )
    }
}