import * as React from 'react';
import './Finder.css';
import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu";

export interface IProps {
    sourceData: any[],
    itemPickedCallback: Function,
    addCallBackTitle: string,
    primaryColor: string,
    alternateColor: string,
    alternateFontColor:string,
    finderResultsName: string,
    columnWidth: string,
    containerHeight: string,
    fontSize: string,
    clearButtonText: string,
    clearAllCallback: Function,
    saveButtonText: string,
    saveCallback: Function

}

export interface IState {

}

export default class CDIFinderResults extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
        };
        this.itemPicked = this.itemPicked.bind(this);
        this.handleFinderSearchChanged = this.handleFinderSearchChanged.bind(this);
        this.getRowStyle = this.getRowStyle.bind(this);
        this.isEven = this.isEven.bind(this);
        this.renderItems = this.renderItems.bind(this);
        this.clearSelection = this.clearSelection.bind(this);
        this.saveSelection = this.saveSelection.bind(this);
        this.getButtons = this.getButtons.bind(this);


    }
    handleFinderSearchChanged(event: any) {
        let v = event.target.value;
        this.setState({ finderSearch: v });
    }

    itemPicked(item) {
        this.props.itemPickedCallback(item);
    }
    isEven(n) {
        return n % 2 === 0;
    }
    getRowStyle(idx) {

        if (this.isEven(idx)) {
            return { backgroundColor: this.props.primaryColor, cursor: "pointer", fontSize: this.props.fontSize };
        }
        else {
            return { backgroundColor: this.props.alternateColor, cursor: "pointer", fontSize: this.props.fontSize, color:this.props.alternateFontColor };
        }
    }
    renderItems() {
        if (this.props.sourceData.length === 0) {
            return <li style={{ fontSize: this.props.fontSize }}>No Items Selected</li>
        }
        else {
            return this.props.sourceData.map((item: any, index) => {
                return <li style={this.getRowStyle(index)} onDoubleClick={() => { this.itemPicked(item) }}>
                    <ContextMenuTrigger id={item.keyG}><div className="text-nowrap">{item.nodeDescription}</div></ContextMenuTrigger>
                    <ContextMenu id={item.keyG}>
                        <MenuItem onClick={() => { this.itemPicked(item) }}>{this.props.addCallBackTitle}</MenuItem>
                    </ContextMenu>
                </li>
            })
        }

    }
    clearSelection() {
        this.props.clearAllCallback();
    }
    saveSelection() {
        this.props.saveCallback();
    }
    getButtons() {
        let retVal: any[] = [];
        if (this.props.sourceData.length > 0) {
            retVal.push(<button className="btn btn-outline-primary btn-sm" id="btnSaveSelection" onClick={this.saveSelection}>{this.props.saveButtonText}</button>);
            retVal.push(<button className="btn btn-outline-primary btn-sm" id="btnClearSelection" onClick={this.clearSelection}>{this.props.clearButtonText}</button>);
        }
        return retVal;
    }
    render() {

        return (
            <React.Fragment>
                <div className="row finderSearch">
                    <div className="col-12 finderResultsRow">
                        <div className="row">
                            <div className="col-7">
                                <h3 style={{ color: "#424242" }}>{this.props.finderResultsName}</h3>
                            </div>
                            <div className="col-5 float-right">
                                {this.getButtons()}

                            </div>
                        </div>

                    </div>

                </div>
                <div className="row">

                    <div className="col-md-12 finderResultsMaster">
                        <div className="card" style={{ paddingLeft: "5px", paddingRight: "5px", width: this.props.columnWidth, height: this.props.containerHeight, overflowX: "hidden", overflowY: "auto" }}>
                            <ul style={{ paddingInlineStart: 'unset' }}>
                                {this.renderItems()}
                            </ul>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}