import moment from 'moment';
import IPMOBaseService from '../../Generics/Service/IPMOBaseService'
import * as types from '../Types/UIGrid.model';
import { ColDef, ColGroupDef, IServerSideGetRowsRequest} from '@ag-grid-community/core';
import { GetLovsRequest } from '../../Generics/Types/IPMOBaseService.model';

export default class GridControlService extends IPMOBaseService{
    getGridById(gridName: string, activeFlag: boolean, callback: any, errorCallback: any) {
        let methodName = "Get Grid by ID";
        super.get('/PricingEngineService/UIGrid/' + gridName + '/active/' + activeFlag, methodName,
            (data: types.UIGrid) => {
                callback(data); 
            },
            (error) => {
                errorCallback(error);
            });
    }

    getUIGridColumnsByGridName(gridName: string, activeFlag: boolean, grid: any, callback: any, errorCallback: any) {
        var columns : (ColDef | ColGroupDef)[] = [];
        let methodName = "Get Ag Grid Columns by UI Grid Name";
        super.get('/PricingEngineService/UIGrid/' + gridName + '/active/' + activeFlag, methodName,
        (data: types.UIGrid) => {
            //if (data && data.fields) {
            //    data.fields.forEach(field => {
            //        columns.push(this.generateAgGridColoumnFromUiGridField(field, grid));
            //    });
            //}
            //callback(columns); 
            callback(data); 
        },
        (error) => {
            errorCallback(error);
        });
    }
    
    getAgGridColumns(gridName: any, gridComponent: any, gridService: any, callback: any) {
        this.getUIGridColumnsByGridName(gridName, true, gridComponent, (data) => {
            let columns : (ColDef | ColGroupDef)[] = [];
            if (data && data.fields) {
                data.fields.forEach(field => {
                    let agGridCol = this.createAgGridColoumnFromUiGridField(field, gridComponent, gridService)
                    columns.push(agGridCol);
                });
            }
            callback(columns);
            return 
        }, () => {
            console.log("Error")
        });
    }

    createAgGridColoumnFromUiGridField(gridField : types.UIGridField, gridComponent: any, gridService: any) {
        var column : (ColDef | ColGroupDef);

        // Type => Template
            // Type contains Format, Attribute and Filter
        // ValueFormatter => Format
        // CellClass => Attribute

        column = {
            field : gridField.fieldName,
            headerName : gridField.displayName,
            hide : (gridField.hidden != null) ? gridField.hidden : false,
            width: (gridField.width != null) ? Number.parseInt(gridField.width) : 100,
            
            cellClass: gridField.attribute,
            headerClass: gridField.attribute,
            
            headerTooltip : gridField.description,
        };

        if (gridField.filter != null) {
            if (gridField.filter == "false") {
                column.filter = false;
            } else {
                column.filter = gridField.filter;
                column.filterParams = {
                    buttons: ['apply', 'clear'],
                    closeOnApply: true,
                    values: (params: any) => {
                        let field: string = params.colDef.field;
                        var filterModel = gridComponent.state.gridApi.getFilterModel();
                        let getRowsReq: IServerSideGetRowsRequest = {
                            startRow: 1,
                            endRow: 100000,
                            sortModel: null,
                            filterModel: filterModel,
                            rowGroupCols: null,
                            pivotCols: null,
                            pivotMode: false,
                            valueCols: null,
                            groupKeys: null
                        };

                        let request: GetLovsRequest = {
                            LovName: field,
                            SearchCriteria: getRowsReq
                        };

                        gridService.getLovs(request, (data, totalCount) => {
                            var values: string[] = data;
                            if (values) {
                                gridComponent.setState({
                                    lastFilterColumn: field
                                });
                                params.success(values);
                            } else {
                                params.success([]);
                            }
                        }, (err) => {
                            console.log('Error: ' + JSON.stringify(err));
                        });
                    },
                    refreshValuesOnOpen: true
                }
            }
        }

        if (gridField.format != null) {
            column.valueFormatter = this.mapFormat(gridField.format);
        }

        if (gridField.template != null) {
            column.type = gridField.template;
        }

        return column;
    }
    
    mapFormat(format) {
        switch (format) {
            case "currencyFormat":
                return this.currencyFormatter;
                case "numericFormat":
                return this.numberFormatter;
            default:
                return null;
        }
    }

    currencyFormatter = (params) => {
        if (params.value != null)
            return '$' + params?.value.toFixed(2);
        else
            return '$0.00';
    }

    numberFormatter = (params) => {
        return params?.value.toFixed(2);
    }

    agGridDefaultColumn = {
        width: 150,
        editable: false,
        filter: 'agSetColumnFilter',
        filterParams: {
            buttons: ['clear', 'apply'],
        },
        floatingFilter: true,
        resizable: true,
        sortable: true,
        enableRowGroup: false,
        enableValue: false,
        enablePivot: false, 
        pivot: false,
    }

    agGridColumnTypes = {
        numberColumn: {
            width: 130,
            filter: 'agNumberColumnFilter',
            filterParams: {
                buttons: ['clear', 'apply'],
            },
            type: 'numericColumn'
        },
        currencyColumn: {
            width: 130,
            filter: 'agNumberColumnFilter',
            filterParams: {
                buttons: ['clear', 'apply'],
            },
            valueFormatter: function (params) {
                try {
                    if (params.value != null)
                        return '$' + params?.value.toFixed(2);
                    else
                        return '$0.00';
                } catch (err) {
                    return params.value;
                }
            },
            type: 'numericColumn'
        },
        nonEditableColumn: { editable: false },
        dateColumn: {
            valueFormatter: function (params) {
                return moment(params.value).format('MM/DD/YYYY');
            },
            filter: 'agDateColumnFilter',
            filterParams: {
                buttons: ['clear', 'apply'],
                comparator: function (filterLocalDateAtMidnight, cellValue) {
                    var dateParts = cellValue.split('/');
                    var day = Number(dateParts[0]);
                    var month = Number(dateParts[1]) - 1;
                    var year = Number(dateParts[2]);
                    var cellDate = new Date(year, month, day);
                    if (cellDate < filterLocalDateAtMidnight) {
                        return -1;
                    } else if (cellDate > filterLocalDateAtMidnight) {
                        return 1;
                    } else {
                        return 0;
                    }
                },
            }
        }
    }

    agGridSideBar = {
        toolPanels: [
            'filters',
            {
                id: 'columns',
                labelDefault: 'Columns',
                labelKey: 'columns',
                iconKey: 'columns',
                toolPanel: 'agColumnsToolPanel',
                toolPanelParams: {
                    suppressRowGroups: true,
                    suppressValues: true,
                    suppressPivots: true,
                    suppressPivotMode: true,
                    suppressSideButtons: false,
                    suppressColumnFilter: false,
                    suppressColumnSelectAll: false,
                    suppressColumnExpandAll: false,
                    suppressSyncLayoutWithGrid: true,
                },
            }
        ],
    }

    agGridStatusBar = {
        statusPanels: [
            {
                statusPanel: 'statusBarComponent',
                align: 'left',
            },
        ],
    }

    agGridRowClassRules = {
        'sick-days-warning': function (params) {
            var numSickDays = params.data.sickDays;
            return numSickDays > 5 && numSickDays <= 7;
        },
        'sick-days-breach': 'data.sickDays >= 8',
    }
}