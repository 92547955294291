import React from "react";

import moment from 'moment';


import { AgGridReact } from '@ag-grid-community/react';
import { ColDef, ColGroupDef, SideBarDef } from '@ag-grid-community/core';
import {
    StatusPanelDef,
    IServerSideGetRowsRequest,
    ColumnApi,
	ServerSideRowModelModule,
	SetFilterModule,
    FiltersToolPanelModule,
	ColumnsToolPanelModule,
	SideBarModule,
	StatusBarModule,
    ClipboardModule,
    ExcelExportModule,
    GridChartsModule,
    MenuModule,
    RowGroupingModule,
} from '@ag-grid-enterprise/all-modules';
import '@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css';
import '@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css';
import '../css/cdi-ag-grid.css';
import StatusBarComponent from './StatusBar';
import MasterContext from '../Misc/MasterContext';
import { Modal, ModalBody, ModalFooter, ModalHeader, Collapse, NavbarToggler, DropdownItem, DropdownMenu, Navbar, DropdownToggle, UncontrolledDropdown, Nav, Spinner } from 'reactstrap';
import { GetLovsRequest } from '../../../Services/Generics/Types/IPMOBaseService.model';
import { UIGrid }  from '../../../Services/Grid/Types/UIGrid.model';

export interface IProps {
}

export interface IState {
    debug: boolean,
    modules: any[],
    frameworkComponents: any,
    rowData: any[],
    serverSideDatasource?: any,
    totalRowCount: number,
    columnDefs?: (ColDef | ColGroupDef)[];
    multiSortKey: string,
    rowModelType: string,
    rowSelection: string,
    cacheBlockSize: number,
    maxBlocksInCache: number,
    blockLoadDebounceMillis: number,
    defaultColDef?: ColDef,
    columnTypes: {
        [key: string]: ColDef;
    },
    sideBar: (string | boolean | SideBarDef),
    statusBar: {
        statusPanels: StatusPanelDef[];
    },
    rowClassRules: any,
    selectedVendors: string[],
    selectedBases: string[],
    gridApi: any,
    gridColumnApi: ColumnApi,
    lastFilterColumn: string,
    suppressExcelExport: boolean,
    showPriceCostFlag: boolean,
    hasDealTypeFlag: boolean,
    showdModal: boolean,
    isExporting: boolean,
    isMenuOpen: boolean,
    futureBuydownStartDate?: string
}

export default class BuydownGrid extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            showdModal:false,
            futureBuydownStartDate: null,
            gridApi: null,
            gridColumnApi: null,
            suppressExcelExport: true,
            debug: false,
            modules: [
                ServerSideRowModelModule,
                SetFilterModule,
                FiltersToolPanelModule,
                ColumnsToolPanelModule,
                SideBarModule,
                StatusBarModule,
                ClipboardModule,
                ExcelExportModule,
                GridChartsModule,
                MenuModule,
                RowGroupingModule,
            ],
            frameworkComponents: {
                statusBarComponent: StatusBarComponent,
            },
            rowData: [],
            totalRowCount: 0,
            columnDefs: null,
            multiSortKey: 'ctrl',
            rowModelType: 'serverSide',
            rowSelection: 'multiple',
            cacheBlockSize: 1000,
            maxBlocksInCache: 100,
            blockLoadDebounceMillis: 300,
            selectedVendors: [],
            selectedBases: [],
            lastFilterColumn: null,
            showPriceCostFlag: false,
            hasDealTypeFlag: false,
            isExporting: false,
            defaultColDef: MasterContext.GridControlService.agGridDefaultColumn,
            columnTypes: MasterContext.GridControlService.agGridColumnTypes,
            sideBar: MasterContext.GridControlService.agGridSideBar,
            statusBar: MasterContext.GridControlService.agGridStatusBar,
            rowClassRules: MasterContext.GridControlService.agGridRowClassRules,
            isMenuOpen: false
        };

        // Function Binding
        MasterContext.translate = MasterContext.translate.bind(this);
        this.getContextMenuItems = this.getContextMenuItems.bind(this);
        this.onModelUpdated = this.onModelUpdated.bind(this);
        this.toggleModal = this.toggleModal.bind(this);
        this.loadGrid = this.loadGrid.bind(this);

    }

    // TODO - Do we want to add loading buydown features to the state so the grid will wait until loading has finished?
    // Right now if component finishes after the GridReady, it will update using the state from the callbacks
    // If the component finished before the GridReady, the GridReady will use the flags to update the grid.
    componentDidMount() {

        // Check to see if PriceCost columns should be displayed
        MasterContext.AppPropertyService.findAppProperty('Buydown_Features', 'ShowPriceCost',
        (res) => {
            this.setState({showPriceCostFlag: (res != null && res.Value === '1')});
            // Update the grid if it has already been loaded before this callback
            if (this.state.gridColumnApi != null) {
                this.state.gridColumnApi.setColumnsVisible(['CurPrice', 'GrossCost', 'NetCost', 'TotalBuydownAmt'], this.state.showPriceCostFlag);
            }
        }, () => {
            this.setState({showPriceCostFlag: false});
        });

        // Check to see if DealType columns should be displayed
        MasterContext.AppPropertyService.findAppProperty('Buydown_Features', 'HasDealType',
        (res) => {
            this.setState({hasDealTypeFlag: (res != null && res.Value === '1')});
            // Update the grid if it has already been loaded before this callback
            if (this.state.gridColumnApi != null) {
                this.state.gridColumnApi.setColumnsVisible(['DealTypeName'], this.state.hasDealTypeFlag);
            }
        },() => {
            this.setState({hasDealTypeFlag: false});
        });        
    }

    toggleModal() {
        this.setState({ showdModal: !this.state.showdModal });
    }

    onExport = () => {
        this.setState({ isExporting: true });
        var filterModel = this.state.gridApi.getFilterModel();
        var request: IServerSideGetRowsRequest = {
            startRow: 0,
            endRow: null,
            rowGroupCols: null,
            valueCols: null,
            pivotCols: null,
            pivotMode: null,
            groupKeys: null,
            sortModel: null,
            filterModel: filterModel
        };
        MasterContext.BuydownService.exportBuydowns(request,
            (success) => {
                this.setState({ isExporting: false });
            }, (err) => {
                console.log("Failed to generate and download file");
                this.setState({ isExporting: false });
            });
    }

    onSelectionChanged = () => {
        //var selectedRows = this.state.gridApi.getSelectedRows();
    }

    onModelUpdated = (params) => {
        if (this.state.totalRowCount == null) {
            this.setState({
                totalRowCount: 0
            });
        }
    }

    onFilterChanged = (params) => {
        var api = this.state.gridApi;
    }

    getContextMenuItems = (params) => {
        var result = [
            'copy',
            'copyWithHeaders',
            'separator',
            'export',
            'chartRange',
        ];
        return result;
    };

    setDefaultFilters = (api, callback) => {
        MasterContext.AppPropertyService.getFutureBuydownDate((res) => {
            this.setState({futureBuydownStartDate: res});
            var filters = {
                Basis: {
                    filterType: "set",
                    values: ["Purchase"]
                },
                PeriodStartDate: {
                    dateFrom: this.state.futureBuydownStartDate,
                            dateTo: null,
                            filterType: "date",
                            type: "lessThan"
                },
                PeriodEndDate: {
                    dateFrom: this.state.futureBuydownStartDate,
                    dateTo: null,
                    filterType: "date",
                    type: "greaterThan"
                }
            };
            api.setFilterModel(filters);
            callback();
        }, (err) => {
            var filters = {
                Basis: {
                    filterType: "set",
                    values: ["Purchase"]
                }
            };
            api.setFilterModel(filters);
            callback();
        });
    }

    onResetFilters = () => {
        this.setDefaultFilters(this.state.gridApi, () => {
            
        });
    }

    loadGrid = (callback) => {
        var colDefs: (ColDef | ColGroupDef)[] = [];
        MasterContext.GridControlService
            .getAgGridColumns(UIGrid.BUYDOWNEXPLORER, this, MasterContext.BuydownService, (columns) => {
                colDefs = columns; 
                this.setState({
                    columnDefs: colDefs
                });
                callback();
            });
    }

    onGridReady = (params) => {
        
        this.loadGrid(() => {
            // Create and set the buydown server side datasource
            var buydownDatasource = MasterContext.BuydownDatasource.createServerSideDatasource(MasterContext.BuydownService, this);
            params.api.setServerSideDatasource(buydownDatasource);

            // Show/Hide columns based on the app properties
            params.columnApi.setColumnsVisible(['CurPrice', 'GrossCost', 'NetCost', 'TotalBuydownAmt'], this.state.showPriceCostFlag);
            params.columnApi.setColumnsVisible(['DealTypeName'], this.state.hasDealTypeFlag);

            // Override the ag grid export to csv functionality
            params.api.origExportDataAsCsv = params.api.exportDataAsCsv;
            params.api.exportDataAsCsv = this.onExport;
        
            this.setState({
                gridApi: params.api,
                gridColumnApi: params.columnApi
            });

            // Set the default filters on load
            this.setDefaultFilters(params.api, () => {});    
        });
    };

    onSaveGridState = () => {
        let columnState = this.state.gridColumnApi.getColumnState();
        let columnGroupState = this.state.gridColumnApi.getColumnGroupState();
        let filterState = this.state.gridApi.getFilterModel();
        let sortState = this.state.gridApi.getSortModel();
    }

    toggle = () => {
        this.setState({ isMenuOpen: !this.state.isMenuOpen})
    }

    // Render to screen
    render() {
        let navBarHeader = { paddingLeft: "15px"};
        return (
            <React.Fragment>
                <Modal zIndex={3002} isOpen={this.state.showdModal}>
                    <ModalHeader>{MasterContext.translate("buydown_modal_header")}</ModalHeader>
                    <ModalBody>
                        {MasterContext.translate("buydown_modal_too_many_records")}
                    </ModalBody>
                    <ModalFooter>
                        <button className="btn btn-sm btn-outline-info" color="primary" onClick={() => this.toggleModal()}>{MasterContext.translate("buydown_modal_footer")}</button>{' '}

                    </ModalFooter>
                </Modal>
                <div className="container-fluid">
                    <div id="divFilterPanel" className="row card">
                    <span>
                        <Navbar color="light" light expand="md" id="BuydownNavbar">
                            <NavbarToggler onClick={this.toggle} />
                            <Collapse isOpen={this.state.isMenuOpen} navbar>
                                <Nav className="mr-auto" navbar id="BuydownNav">
                                    <UncontrolledDropdown nav inNavbar>
                                        <DropdownToggle nav caret className="btn btn-primary" id="BuydownDropdownToggle">
                                            <i className="fa fa-bars"></i>
                                        </DropdownToggle>
                                        <DropdownMenu left="0" id="BuydownDropdownMenu">
                                            <DropdownItem onClick={this.onExport} id="ExportBuydownDropdownItem" disabled={this.state.isExporting}> 
                                                {MasterContext.translate("export_buydowns")}
                                            </DropdownItem>
                                            <DropdownItem onClick={this.onResetFilters} id="ClearFiltersDropdownItem">
                                                {MasterContext.translate("reset_filters")}
                                            </DropdownItem>
                                            <DropdownItem onClick={this.onSaveGridState} id="SaveGridStateDropdownItem">
                                                Save State //{MasterContext.translate("reset_filters")}
                                            </DropdownItem>
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                    <div style={navBarHeader} id="BuydownExplorer">
                                        <h4>{MasterContext.translate("homepage_button_buydown_explorer")}</h4>
                                    </div>
                                </Nav>
                            </Collapse>
                        </Navbar>
                    </span>
                    </div>
                    <div id="pnlBuydownDetail" className="row card">
                        <div className="row" style={{ marginTop: "5px" }}>
                            <div className="col-md-12" style={{ overflowY: "scroll" as "scroll", overflowX: "hidden" as "hidden", height: "75vh" }}>
                                <div className="ag-theme-balham" style={{ height: '100%' }}>
                                    <AgGridReact
                                        debug={this.state.debug}
                                        modules={this.state.modules}
                                        columnDefs={this.state.columnDefs}
                                        columnTypes={this.state.columnTypes}
                                        defaultColDef={this.state.defaultColDef}
                                        cacheBlockSize={this.state.cacheBlockSize}
                                        maxBlocksInCache={this.state.maxBlocksInCache}
                                        blockLoadDebounceMillis={this.state.blockLoadDebounceMillis}
                                        onGridReady={this.onGridReady}
                                        enableRangeSelection={true}
                                        frameworkComponents={this.state.frameworkComponents}
                                        rowData={this.state.rowData}
                                        onModelUpdated={this.onModelUpdated}
                                        rowSelection={this.state.rowSelection}
                                        enableCharts={true}
                                        multiSortKey={this.state.multiSortKey}
                                        rowModelType={this.state.rowModelType}
                                        onFilterChanged={this.onFilterChanged}
                                        allowContextMenuWithControlKey={true}
                                        getContextMenuItems={this.getContextMenuItems}
                                        onSelectionChanged={this.onSelectionChanged}
                                        sideBar={this.state.sideBar}
                                        suppressExcelExport={this.state.suppressExcelExport}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="ag-status-bar" style={{ marginLeft: "15px", marginTop: "5px", textAlign: "left" }}>
                            <span style={{ paddingBottom: "5px" }}>
                                <span className="bold">Total Rows: </span>
                                <span>{this.state.totalRowCount.toLocaleString()}</span>
                            </span>
                            { this.state.isExporting===true ? <span className="bold" style={{ paddingRight: "15px" }}> Exporting Data <Spinner size="sm" color="primary" /></span> : null} 
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }

}
