import { IServerSideGetRowsParams, IServerSideGetRowsRequest } from '@ag-grid-enterprise/all-modules';
import BuydownService from './Buydown.service';
import BuydownGrid from '../../../Components/React/Buydown/BuydownGrid';

export default class BuydownDatasource {

    createServerSideDatasource = (buydownService: BuydownService, buydownGrid: BuydownGrid) => {
        return {
            getRows: function (params: IServerSideGetRowsParams) {
                // console.log('[Datasource] - rows requested by grid: ', params.request);

                let request: IServerSideGetRowsRequest = params.request;

                buydownService.search(request, (data: any[], totalCount: number) => {
                    if (totalCount != null) {
                        buydownGrid.setState({
                            //rowData: data,
                            totalRowCount: totalCount
                        });
                    }
                    params.successCallback(data, totalCount);
                }, () => {
                        // alert('Error: ' + JSON.stringify(err));
                    params.failCallback();
                });
            },
        };
    }
}