import React, { useEffect, useState } from 'react';
import { NavigationPermissionIdentity } from '../../../Services/Roles/NavigationRoles.service.types';
import NavigationRolesService from '../../../Services/Roles/NavigationRoles.service';
import { Label } from 'reactstrap';
import SpinnerWithMessage from '../Common/SpinnerWithMessage/SpinnerWithMessage';
import { AgGridReact } from '@ag-grid-community/react';
import { ColDef, ColGroupDef, ICellRendererParams } from '@ag-grid-community/core';
import { GridApi, ColumnApi, RowNode, AllCommunityModules, Column, RowDataTransaction, ClientSideRowModelModule, RowGroupingModule } from '@ag-grid-enterprise/all-modules';
import { CellChangedEvent } from '@ag-grid-community/core/dist/cjs/entities/rowNode';
import gridHelper from '../helpers/grid-helper';

interface IRoleNavigationCellRendererParams extends ICellRendererParams {
    data: NavigationPermissionIdentity;
    rolePermissions?: NavigationPermissionIdentity[];
    masterPermissions?: NavigationPermissionIdentity[];
    permissions: NavigationPermissionIdentity[];
}

const CheckboxRenderer = (params: IRoleNavigationCellRendererParams) => {
    const globalAdminId = 1;
    const checkedHandler = (event) => params.setValue(event.target.checked);

    return (
        <div>
            <input
                type="checkbox"
                onClick={checkedHandler}
                checked={params.value}
                disabled={params.data.IdentityId === globalAdminId}
                />
        </div>
    );
}

interface IProps {
    masterPermissions: NavigationPermissionIdentity[];
    rolePermissions: NavigationPermissionIdentity[];
    roleId: number | null;
}


export default function RoleNavigationFormGrid({ masterPermissions, rolePermissions, roleId }: IProps) {
    const navigationRolesService = new NavigationRolesService();
    const [gridApi, setGridApi] = useState<GridApi>(null);
    const [colApi, setColApi] = useState<ColumnApi>(null);
    const [permissions, setPermissions] = useState<NavigationPermissionIdentity[]>([]);
    const [colDefs, setColDefs] = useState<(ColDef | ColGroupDef)[]>([
        {
            headerName: "Permission",
            field: "PermissionName",
            width: 260,
        },
        {
            headerName: "Enabled",
            field: "Enabled",
            width: 80,
            cellRenderer: "checkboxRenderer",
        }
    ]);
    const [colDefComponents, setColDefComponents] = useState<object>({
        checkboxRenderer: CheckboxRenderer
    })

    useEffect(() => {
        if (gridApi) {
            gridApi.expandAll();
            gridHelper.shrinkOrExpandColsToFitGrid(gridApi);
        }
    }, [permissions])

    useEffect(() => {
        if (gridApi) {
            gridApi.setColumnDefs(colDefs)
        }
    }, [colDefs])

    useEffect(() => {
        setPermissions(rolePermissions)
    }, [rolePermissions])

    const onGridReady = (params: any) => {
        setGridApi(params.api);
        setColApi(params.columnApi)
    }

    const onCellValueChanged = (params: CellChangedEvent) => {
        if (permissions.length > 0 && roleId) {
            navigationRolesService.putPermission(params.node.data, () => {}, () => {})
        }

        let newPermissions = [];
        gridApi.forEachNode((n: RowNode) => {
            if (n.data) newPermissions.push(n.data)
        });

        setPermissions(newPermissions);
    }

    return (
        <div>
            <Label>Navigation Permissions</Label>
            <div className='ag-theme-balham' >
                <AgGridReact
                    modules={[ClientSideRowModelModule, RowGroupingModule]}
                    onGridReady={onGridReady}
                    onCellValueChanged={onCellValueChanged}
                    domLayout={'autoHeight'}
                    columnDefs={colDefs as ColDef[]}
                    defaultColDef={{
                        resizable: true,
                        width: 80
                    }}
                    autoGroupColumnDef={{
                        headerName: 'Component',
                        minWidth: 180,
                        cellRendererParams: {
                            suppressCount: false,
                        }
                    }}
                    animateRows={true}
                    rowData={permissions}
                    rowModelType={'clientSide'}
                    frameworkComponents={{
                        customLoadingOverlayComponent: SpinnerWithMessage,
                        ...colDefComponents
                    }}
                    loadingOverlayComponent="customLoadingOverlayComponent"
                    loadingOverlayComponentParams={{
                        loadingMessage: 'Loading Permissions...',
                    }}
                />
            </div>
        </div>
    )
}