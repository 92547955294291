import IPMOBaseService from '../../Generics/Service/IPMOBaseService'
import { IServerSideGetRowsRequest } from '@ag-grid-enterprise/all-modules';
import { GetLovsRequest } from '../../Generics/Types/IPMOBaseService.model';
import { GetRunModeFileRequest } from '../Types/JobControl.model';

export default class JobControlService extends IPMOBaseService {
    getJobDetails(data: IServerSideGetRowsRequest, callback: any, errorCallback: any) {
        let methodName = "GetJobDetails";
        super.post("/PricingEngineService/JobControl/GetJobDetails", data, methodName,
            (success) => {
                callback(success.Data, success.TotalCount);
            },
            (error) => {
                errorCallback(error)
            });
    }

    getJobDetailsLovs(getLovsRequest: GetLovsRequest, callback: any, errorCallback: any) {
        let methodName = "GetJobDetailsLovs";
        super.post("/PricingEngineService/JobControl/GetJobDetailsLovs", getLovsRequest, methodName,
            (success) => {
                callback(success.Data, success.TotalCount);
            },
            (error) => {
                errorCallback(error)
            });
    }

    getTaskDetails(data: IServerSideGetRowsRequest, callback: any, errorCallback: any) {
        let methodName = "GetTaskDetails";
        super.post("/PricingEngineService/JobControl/GetTaskDetails", data, methodName,
            (success) => {
                callback(success.Data, success.TotalCount);
            },
            (error) => {
                errorCallback(error)
            });
    }

    getTaskDetailsLovs(getLovsRequest: GetLovsRequest, callback: any, errorCallback: any) {
        let methodName = "GetTaskDetailsLovs";
        super.post("/PricingEngineService/JobControl/GetTaskDetailsLovs", getLovsRequest, methodName,
            (success) => {
                callback(success.Data, success.TotalCount);
            },
            (error) => {
                errorCallback(error)
            });
    }

    getRunModeFiles(getRunModeFileRequest: GetRunModeFileRequest, callback: any, errorCallback: any) {
        let methodName = "getRunModeFiles";
        super.post("/PricingEngineService/JobControl/GetRunModeFiles", getRunModeFileRequest, methodName,
            (success) => {
                callback(success.Data, success.TotalCount);
            },
            (error) => {
                errorCallback(error)
            });
    }

    getProductHierarchy(callback: any, errorCallback: any) {
        let prodHierData: (any[]) = [
            {
                NodeId: '1',
                NodeName: 'Enterprise',
                Description: 'Enterprise',
                children: [
                    {
                        NodeId: '1/1',
                        NodeName: 'Dept 1',
                        Description: 'Department 1',
                        children: [
                            {
                                NodeId: '1/1/1',
                                NodeName: 'D1-Category 1',
                                Description: 'D1: Category 1',
                            },
                            {
                                NodeId: '1/1/2',
                                NodeName: 'D1-Category 2',
                                Description: 'D1: Category 2',
                            },
                            {
                                NodeId: '1/1/3',
                                NodeName: 'D1-Category 3',
                                Description: 'D1: Category 3',
                            },
                        ]
                    },
                    {
                        NodeId: '1/2',
                        NodeName: 'Dept 2',
                        Description: 'Department 2',
                        children: [
                            {
                                NodeId: '1/2/1',
                                NodeName: 'D2-Category 1',
                                Description: 'D2: Category 1',
                            },
                            {
                                NodeId: '1/2/2',
                                NodeName: 'D2-Category 1',
                                Description: 'D2: Category 2',
                            },
                            {
                                NodeId: '1/2/3',
                                NodeName: 'D2-Category 1',
                                Description: 'D2: Category 3',
                            },
                        ]
                    },
                    {
                        NodeId: '1/3',
                        NodeName: 'Dept 3',
                        Description: 'Department 3',
                        children: [
                            {
                                NodeId: '1/3/1',
                                NodeName: 'D3-Category 1',
                                Description: 'D3: Category 1',
                            },
                            {
                                NodeId: '1/3/2',
                                NodeName: 'D3-Category 2',
                                Description: 'D3: Category 2',
                            },
                            {
                                NodeId: '1/3/3',
                                NodeName: 'D3-Category 3',
                                Description: 'D3: Category 3',
                            },
                        ]
                    }
                ]
            }
        ];
        callback(prodHierData, 3);
    }

}