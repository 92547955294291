import IPMOBaseService from '../Generics/Service/IPMOBaseService'
import { NavigationPermissionIdentity } from './NavigationRoles.service.types';

export default class NavigationRolesService extends IPMOBaseService {

    getAll(callback: (res: Array<NavigationPermissionIdentity>) => void, errorCallback: (err: string) => void) {
        super.get(
            "/PricingEngineService/NavigationRoles",
            "GetAll",
            (res: any) => { callback(res) },
            (err: string) => { errorCallback(`Error getting all navigation permissions: ${err}`) }
        )
    }

    getByIdentityId(identityId: number, callback: (res: Array<NavigationPermissionIdentity>) => void, errorCallback: (err: string) => void) {
        super.get(
            `/PricingEngineService/NavigationRoles/${identityId}`,
            "GetByIdentityId",
            (res: any) => { callback(res) },
            (err: string) => { errorCallback(`Error getting navigation permissions by identity: ${err}`) }
        )
    }

    postPermission(data: NavigationPermissionIdentity, callback: (res: string) => void, errorCallback: (err: string) => void) {
        super.post(
            "/PricingEngineService/NavigationRoles",
            data,
            "Post",
            (res: string) => { callback(res) },
            (err: string) => { errorCallback(`Error posting navigation permission: ${err}`) }
        )
    }

    putPermission(data: NavigationPermissionIdentity, callback: (res: string) => void, errorCallback: (err: string) => void) {
        super.put(
            "/PricingEngineService/NavigationRoles",
            data,
            "Post",
            (res: string) => { callback(res) },
            (err: string) => { errorCallback(`Error posting navigation permission: ${err}`) }
        )
    }

    deletePermission(data: number, callback: (res: string) => void, errorCallback: (err: string) => void) {
        super.post(
            `/PricingEngineService/NavigationRoles/${data}`,
            data,
            "Delete",
            (res: string) => { callback(res) },
            (err: string) => { errorCallback(`Error deleting navigation permission: ${err}`) }
        )
    }
}