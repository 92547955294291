import * as React from "react";
import './hierarchy.css';
import { ColDef } from '@ag-grid-community/all-modules';

export interface IProps {
    api: any;
    color: string;
    rowIndex: number;
    colDef: ColDef;
}

export interface IState {
}

export default class LocationHierarchyCustomTooltip extends React.Component < IProps, IState > {
    // Constructor
    constructor(props: IProps) {
        super(props);

        this.state = {
        };
    }

    getReactContainerClasses() {
        return ['custom-tooltip'];
    }

    render() {
        const data = this.props.api.getDisplayedRowAtIndex(this.props.rowIndex).data;
        return (
            <div
                className="location-hierarchy-tooltip"
                style={{ backgroundColor: this.props.color || 'white' }}
            >
                <p>
                    <span><strong>Node Name: </strong></span>{data.NodeName}
                </p>
                <p>
                    <span><strong>Description: </strong></span>{data.NodeDesc}
                </p>
                <p>
                    <span><strong>Product ID: </strong></span>{data.ProductId}
                </p>
                <p>
                    <span><strong>UPC: </strong></span>{data.UPC}
                </p>
                <p>
                    <span><strong>UPC: </strong></span>{data.PriceFamilyId}&nbsp;-&nbsp;{data.PriceFamilyDesc}
                </p>
                <p>
                    <span><strong>UPC: </strong></span>{data.Brand}
                </p>
            </div>
        );
    }
}

