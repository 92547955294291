import * as types from '../Types/Login.model';
import IPMOBaseService from '../../Generics/Service/IPMOBaseService'
export default class LoginService extends IPMOBaseService {
    register(data: types.RegistrationToken, callback: any, errorCallback: any) {
        let methodName = "Register";

        super.post("/api/UserManagement/Register", data, methodName,
            (result) => {
                if (result.Successful) {

                    if (callback !== null) {
                        callback(result.Data);
                    }
                } else {
                    if (errorCallback != null)
                        errorCallback(result);
                }
            },
            (error) => {
                errorCallback(error)
            });
    }
    updateUser(data: types.RegistrationToken, callback: any, errorCallback: any) {
        let methodName = "Update User";

        super.post("/api/UserManagement/UpdateUser", {
            FirstName: data.FirstName,
            LastName: data.LastName,
            CompanyName: data.CompanyName,
            UserName: data.UserName,
            EmailAddress: data.EmailAddress,
            Password: data.Password,
            PhoneNumber: data.PhoneNumber
        }, methodName,
            (result) => {
                if (result.Successful) {

                    if (callback !== null) {
                        callback(result.Data);
                    }
                } else {
                    if (errorCallback != null)
                        errorCallback(result);
                }
            },
            (error) => {
                errorCallback(error)
            });     
    }
    recoverPassword(data: types.RegistrationToken, callback: any, errorCallback: any) {
        let methodName = "Recover Password";
        //debugger;
        super.post("/api/UserManagement/Recover", { UserName: data.UserName }, methodName,
            (result) => {
                if (result.Successful) {

                    if (callback !== null) {
                        callback(result.Data.RecoverySent);
                    }
                } else {
                    if (errorCallback != null)
                        errorCallback(result);
                }
            },
            (error) => {
                errorCallback(error)
            }); 
    }
    changePassword(data: types.LoginToken, callback: any, errorCallback: any) {
        let methodName = "Change Password";

        super.post("/api/UserManagement/ChangePassword", { UserName: data.UserName, OldPassword: data.Password, NewPassword: data.NewPassword }, methodName,
            (result) => {
                if (result.Successful) {

                    if (callback !== null) {
                        callback(result);
                    }
                } else {
                    if (callback != null)
                        callback(result);
                }
            },
            (error) => {
                errorCallback(error)
            }); 
    }
    checkUserExists(data: types.RegistrationToken, callback: any, errorCallback: any) {
        let methodName = "Check User Exists";

        super.post("/api/UserManagement/CheckUserExists", { UserName: data.UserName }, methodName,
            (result) => {
                if (result.Successful) {

                    if (callback !== null) {
                        callback(result.Data);
                    }
                } else {
                    if (errorCallback != null)
                        errorCallback(result);
                }
            },
            (error) => {
                errorCallback(error)
            });
    }
    getUserData(userName: string, callback: any, errorCallback: any) {
        let methodName = "Get User Data";
        super.post("/api/UserManagement/GetUserData", {}, methodName,
            (result) => {
                if (result.Successful) {

                    if (callback !== null) {
                        callback(result.Data);
                    }
                } else {
                    if (errorCallback != null)
                        errorCallback(result);
                }
            },
            (error) => {
                errorCallback(error)
            });
    }
    login(data: types.LoginToken, callback: any, errorCallback: any) {
        let methodName = "Login";
        super.post("/api/UserManagement/Login", data, methodName,
            (result) => {
                if (result.Successful) {

                    if (callback !== null) {
                        callback(result.Data);
                    }
                } else {
                    if (errorCallback != null)
                        errorCallback(result);
                }
            },
            (error) => {
                errorCallback(error)
            });
    }
    oktaLogin(data: types.LoginToken, callback: any, errorCallback: any) {
        let methodName = "OktaLogin";
        super.post("/api/UserManagement/OktaLogin", data, methodName,
            (result) => {
                if (result.Successful) {

                    if (callback !== null) {
                        callback(result.Data);
                    }
                } else {
                    if (errorCallback != null)
                        errorCallback(result);
                }
            },
            (error) => {
                errorCallback(error)
            });
    }
    ssoEnabled(callback: any, errorCallback: any) {
        super.get("/api/Sso/SsoEnabled", "SsoEnabled",
            (result) => {
                if (result.Successful) {
                    callback();
                }
                else {
                    errorCallback();
                }
            },
            () => {errorCallback()}
        );
    }
    ssoRedirectUrl(callback: any) {
        super.get("/api/Sso/GetSsoRedirectUrl", "GetSsoRedirectUrl",
            (result) => {
                if (result.Successful) {
                    callback(result.Data);
                }
            },
            () => {}
        );
    }
}    